export default class ImageFormatter {
    constructor()
    {
    }

    requiresHTML() {
        return true;
    }

    format(value) {
        let rtn = '';
        if(value === null || value.length === 0){
            return rtn
        }
        if(!Array.isArray(value)){
            value = [value]
        }

        value.forEach(item => {
            rtn += item.hasOwnProperty('src')  ? '<img src="' + item.src + '" class="img-fluid" />' : ''
        });
        return rtn
    }
}
