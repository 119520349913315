import Vue from "vue";

export default class CurrencyFormatter {
    constructor(settings) {
        this.locale = settings.locale
    }

    requiresHTML() {
        return false;
    }
    format(value) {
        const i18n = Vue.prototype.$lumui.i18n;
        let parsedValue = Number.parseFloat(value);
        if (isNaN(parsedValue)) {
            return ''
        } else {
            return i18n.n(parsedValue, null, this.locale);
        }
    }
}
