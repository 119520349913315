import Bool from './formatter/bool';
import KeyValue from './formatter/keyvalue';
import Currency from './formatter/currency';
import FloatFormatter from './formatter/float';
import Date from './formatter/date';
import DateTime from './formatter/datetime';
import LocalizedFormatter from "./formatter/localized";
import SelectFormatter from "./formatter/select";
import CheckboxFormatter from "./formatter/checkbox";
import RadioFormatter from "./formatter/radio";
import ImageFormatter from "./formatter/image";
import LocaleFormatter from "./formatter/locale"
import StatusFormatter from "./formatter/status";
import StatusdetailFormatter from "./formatter/statusdetail";
import StriptagsFormatter from "./formatter/striptags";
import FilesFormatter from "./formatter/files"
import TimeFormatter from "./formatter/time";
import MimeIconFormatter from "./formatter/mimeicon";
import NestedSetFormatter from "./formatter/nestedset";
import MaterializedPathFormatter from "./formatter/materialized_path";
import TruncateFormatter from "./formatter/truncate";
import LinkFormatter from "./formatter/link";
import NoopFormatter from './formatter/noop'
import md5 from 'md5';

const constructorMap = {
    'Bool': Bool,
    'KeyValue': KeyValue,
    'Currency': Currency,
    'Date': Date,
    'DateTime': DateTime,
    'Localized': LocalizedFormatter,
    'Select': SelectFormatter,
    'Checkbox': CheckboxFormatter,
    'Radio': RadioFormatter,
    'Image': ImageFormatter,
    'Locale': LocaleFormatter,
    'Status': StatusFormatter,
    'Statusdetail': StatusdetailFormatter,
    'Striptags': StriptagsFormatter,
    'Files' : FilesFormatter,
    'Float': FloatFormatter,
    'Time': TimeFormatter,
    'MimeIcon': MimeIconFormatter,
    'NestedSet': NestedSetFormatter,
    'MaterializedPath': MaterializedPathFormatter,
    'Truncate': TruncateFormatter,
    'Link': LinkFormatter,
    'Noop': NoopFormatter
};

let instanceMap = {};

export default function create(name, settings) {
    let hash = name + '_' + md5(JSON.stringify(settings));
    if(!instanceMap[hash]) {
        if (typeof constructorMap[name] === 'undefined') {
            throw "Constructor for " + name + " not found";
        }
        instanceMap[hash] = new (constructorMap[name])(settings);
    }
    return instanceMap[hash]
}
