<template>
    <asa-dialog v-if="show">
        <v-card>
            <v-toolbar :color="options.color" dark dense flat class="flex-grow-0">
                <v-toolbar-title class="white--text">
                    {{ title }}
                </v-toolbar-title>
                <v-spacer />
                <v-icon @click="cancel">
                    $close
                </v-icon>
            </v-toolbar>
            <v-card-text v-show="!!message" class="pa-4">
                {{ message }}
            </v-card-text>
            <v-card-actions :class="{'flex-column': $vuetify.breakpoint.mobile, 'flex-wrap': !$vuetify.breakpoint.mobile, 'mobile': $vuetify.breakpoint.mobile, 'form-actions': true, 'pl-2': !$vuetify.breakpoint.mobile, 'pt-0': true }">
                <v-btn :block="$vuetify.breakpoint.mobile" color="success" type="button" @click="agree">
                    {{ options.confirmLabel }}
                </v-btn>
                <v-btn :block="$vuetify.breakpoint.mobile" type="button" @click="cancel">
                    {{ options.rejectLabel }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </asa-dialog>
</template>

<script>
import AsaDialog from './AsaDialog';
import {VBtn, VCard, VCardActions, VCardText, VIcon, VSpacer, VToolbar, VToolbarTitle} from 'vuetify/lib';

/**
 * Displays a promise based confirmation dialog.
 *
 * @example
 * ```vue
 *     <template>
 *       <asa-confirm ref="confirm" />
 *     </template>
 *     <script>
 *         export default {
 *             methods: {
 *                 async example() {
 *                     let accepted = await this.$refs.confirm.open('Title', 'Message');
 *                     if (accepted) {
 *                         // do something
 *                     }
 *
 *                 }
 *             }
 *         }
 *     </​script>
 *  ```
 */
export default {
    name: "AsaConfirm",
    components: {AsaDialog, VCard, VToolbar, VToolbarTitle, VCardText, VCardActions, VSpacer, VBtn, VIcon},
    data() {
        return {
            dialog: false,
            resolve: null,
            reject: null,
            message: null,
            title: null,
            options: {
                color: 'primary',
                width: 290,
                confirmLabel: this.$lumui.i18n.t('lumui.confirm.accept'),
                rejectLabel: this.$lumui.i18n.t('lumui.confirm.reject'),
            }
        }
    },
    computed: {
        show: {
            get() {
                return this.dialog
            },
            set(value) {
                this.dialog = value;
                if (value === false) {
                    this.cancel()
                }
            }
        }
    },
    methods: {
        /**
         * @param {string} title
         * @param {string} message
         * @param {object} options default {color: "primary", width: 290, zIndex: 200}
         * @return {Promise}
         */
        open(title, message, options) {
            this.dialog = true;
            this.title = title;
            this.message = message;
            this.options = Object.assign(this.options, options);
            return new Promise((resolve, reject) => {
                this.resolve = resolve;
                this.reject = reject;
            })
        },
        /** @private */
        agree() {
            this.resolve(true);
            this.dialog = false;
        },
        /** @private */
        cancel() {
            this.resolve(false);
            this.dialog = false;
        }
    }
}
</script>
