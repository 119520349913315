var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-autocomplete", {
    attrs: {
      id: "filter_" + _vm.config.key,
      items: _vm.items,
      label:
        typeof _vm.config.filter.select.label !== "undefined"
          ? _vm.config.filter.select.label
          : _vm.config.label,
      multiple: _vm.multiple,
      clearable: _vm.clearable,
      "menu-props": { offsetY: true },
      "item-text": "text",
      "item-value": "value",
      "hide-details": "",
    },
    scopedSlots: _vm._u([
      {
        key: "item",
        fn: function ({ item, on, attrs }) {
          return [
            _c(
              "v-list-item",
              _vm._g(
                { attrs: { "input-value": attrs.inputValue && !_vm.multiple } },
                on
              ),
              [
                _vm.multiple
                  ? _c(
                      "v-list-item-action",
                      [
                        _c("v-simple-checkbox", {
                          attrs: { value: attrs.inputValue },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c("v-list-item-content", [
                  _vm._v(
                    "\n                " + _vm._s(item.text) + "\n            "
                  ),
                ]),
                _vm._v(" "),
                item.icon
                  ? _c(
                      "v-list-item-avatar",
                      [
                        _c(
                          "v-icon",
                          {
                            staticStyle: { "vertical-align": "middle" },
                            attrs: {
                              color: item.color ? item.color : "gray",
                              small: "",
                            },
                          },
                          [
                            _vm._v(
                              "\n                    " +
                                _vm._s(item.icon) +
                                "\n                "
                            ),
                          ]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
          ]
        },
      },
      {
        key: "selection",
        fn: function ({ item }) {
          return [
            _vm.multiple
              ? _c(
                  "v-chip",
                  { attrs: { dense: "", small: "" } },
                  [
                    item.icon
                      ? _c(
                          "v-avatar",
                          [
                            _c(
                              "v-icon",
                              {
                                attrs: {
                                  color: item.color ? item.color : "gray",
                                  small: "",
                                },
                              },
                              [
                                _vm._v(
                                  "\n                    " +
                                    _vm._s(item.icon) +
                                    "\n                "
                                ),
                              ]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c("span", [
                      _vm._v(
                        "\n                " +
                          _vm._s(item.text) +
                          "\n            "
                      ),
                    ]),
                  ],
                  1
                )
              : _c(
                  "span",
                  [
                    item.icon
                      ? _c(
                          "v-icon",
                          {
                            attrs: {
                              color: item.color ? item.color : "grey",
                              small: "",
                            },
                          },
                          [_vm._v(_vm._s(item.icon))]
                        )
                      : _vm._e(),
                    _vm._v("\n            " + _vm._s(item.text) + "\n        "),
                  ],
                  1
                ),
          ]
        },
      },
    ]),
    model: {
      value: _vm.config.value,
      callback: function ($$v) {
        _vm.$set(_vm.config, "value", $$v)
      },
      expression: "config.value",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }