<template>
    <span>
        <v-menu v-if="$vuetify.breakpoint.smAndDown || routes.length > iconMenuThreshold" bottom left>
            <template #activator="{ on, attrs }">
                <v-btn :text="isMobile" :icon="!isMobile" v-bind="attrs" v-on="on">
                    <span v-if="isMobile">{{ $lumui.i18n.t('lumui.table_action.menu') }}</span>
                    <v-icon :right="isMobile">$moreVert</v-icon>
                </v-btn>
            </template>
            <v-list>
                <template v-for="route in routes">
                    <v-list-item v-if="route.to || route.action" :key="route.key" :disabled="route.disabled" @click="callAction(route)">
                        <v-list-item-icon>
                            <v-icon v-text="route.icon" />
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>
                                {{ route.label }}
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </template>
            </v-list>
        </v-menu>
        <template v-else>
            <template v-for="route in routes">
                <v-tooltip v-if="route.to || route.action" :key="route.key" bottom>
                    <template #activator="{ on }">
                        <v-btn
                            :disabled="route.disabled"
                            :x-small="btnSize === 'x-small'"
                            :small="btnSize === 'small'"
                            :large="btnSize === 'large'"
                            :x-large="btnSize === 'x-large'"
                            text
                            icon
                            @click="callAction(route)"
                            v-on="on"
                        >
                            <v-icon
                                :x-small="iconSize === 'x-small'"
                                :small="iconSize === 'small'"
                                :large="iconSize === 'large'"
                                :x-large="iconSize === 'x-large'"
                                v-text="route.icon"
                            />
                        </v-btn>
                    </template>
                    <span>{{ route.label }}</span>
                </v-tooltip>
            </template>
        </template>
    </span>
</template>

<script>
import {
    VBtn,
    VIcon,
    VList,
    VListItem,
    VListItemContent,
    VListItemIcon,
    VListItemTitle,
    VMenu,
    VTooltip
} from 'vuetify/lib'

/**
 * Used by AsaTable to render the action buttons.
 *
 * @private
 * @internal
 */
export default {
    components: {
        VIcon,
        VBtn,
        VTooltip,
        VListItemTitle,
        VListItemIcon,
        VListItemContent,
        VListItem,
        VList,
        VMenu
    },
    props: {
        /** available actions */
        actions: {
            type: Object,
            default: () => ({})
        },
        /** current row */
        row: {
            type: Object,
            default: () => ({})
        },
        /** button size */
        btnSize: {
            type: String,
            default: 'default',
            validator(value) {
                return ['x-small', 'small', 'default', 'large', 'x-large'].includes(value)
            }
        },
        /** icon size */
        iconSize: {
            type: String,
            default: 'default',
            validator(value) {
                return ['x-small', 'small', 'default', 'large', 'x-large'].includes(value)
            }
        },

        /** icon menu threshold (amount of icons > iconMenuThrs: render icons as menu */
        iconMenuThreshold: {
            type: Number,
            default: 8
        }

    },
    data() {
        return {
            visible: false,
        };
    },
    computed: {
        isMobile () {
            return this.$vuetify.breakpoint.xsOnly
        },
        record() {
            const self = this;
            return {
                row: this.row,
                getData(column) {
                    return self.record.row[column];
                },
                isAllowed(resource, privilege, pathColumn) {
                    if (typeof pathColumn !== 'undefined') {
                        return self.isAllowedInPath(this.getData(pathColumn), resource, privilege);
                    } else {
                        return self.isAllowed(resource, privilege);
                    }
                }
            }
        },
        isAllowedInPath() {
            return this.$store.getters.isAllowedInPath;
        },
        isAllowed() {
            return this.$store.getters.isAllowed;
        },
        visibleActions() {
            const self = this;
            return Object.values(this.actions).filter((action) => {
                // eslint-disable-next-line no-unused-vars
                let record = self.record;
                let rtn = true;
                if (action.visible) {
                    rtn = eval(action.visible);
                }
                return rtn;
            });
        },
        routes() {
            let rtn = [];
            let self = this;
            this.visibleActions.forEach((action) => {
                if (!(action.route || action.action)) {
                    return;
                }

                let params = {};
                if (action.param) {
                    Object.keys(action.param).forEach((key) => {
                        params[key] = this.row[action.param[key]];
                    });
                }

                // eslint-disable-next-line no-unused-vars
                let record = self.record;
                let disabled = false;
                if (action.condition) {
                    try {
                        disabled = !eval(action.condition);
                    } catch (e) {
                        console.error('Failed to evaluate ' + action.condition + ' ' + e)
                    }
                }
                if (action.route) {
                    let route = {
                        label: action.label,
                        icon: action.icon,
                        to: {
                            name: action.route,
                            params: params
                        },
                        disabled: disabled,
                    };
                    rtn.push(route);
                } else if (action.action) {
                    let route = {
                        label: action.label,
                        icon: action.icon,
                        action: {
                            name: action.action,
                            params: params
                        },
                        disabled: disabled,
                    };
                    rtn.push(route);
                }

            });

            return rtn;
        }
    },
    methods: {
        /** @private */
        callAction(route) {
            if (typeof route.action !== 'undefined') {
                /**
                 * fired if the actions route has an `action` field defined
                 * @param {Object} action
                 */
                this.$emit('rowaction', route.action)
            } else if (typeof route.to !== 'undefined') {
                this.$router.push(route.to)
            }
        }
    }
}
</script>
