<template>
    <asa-alert :show="show">
        <template #content>
            Boo
        </template>
        <template #actions>
            <v-btn @click="show = false">
                Close
            </v-btn>
        </template>
    </asa-alert>
</template>

<script>
import AsaAlert from "../../../src/component/AsaAlert";
import {VBtn} from "vuetify/lib";

export default {
    name: "Alert",
    components: {AsaAlert, VBtn},
    data() {
        return {
            show: true
        }
    },
    mounted() {
        window.setTimeout(() => this.show = false, 2000);
    }
}
</script>
