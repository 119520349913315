var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("asa-form", {
    attrs: { config: _vm.form, loading: false },
    model: {
      value: _vm.data,
      callback: function ($$v) {
        _vm.data = $$v
      },
      expression: "data",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }