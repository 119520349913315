<template>
    <div>
        <v-container>
            <v-row>
                <v-col>
                    <h1 class="text-h3">Time column</h1>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <v-card>
                        <v-card-title>Default Detail View</v-card-title>
                        <v-divider></v-divider>
                        <v-card-text>
                            <asa-table :data="tableData"></asa-table>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import {VContainer, VRow, VCol, VCard, VCardTitle, VDivider, VCardText } from 'vuetify/lib';

export default {
    name: "TableTimecol",
    components: {VContainer, VRow, VCol, VCard, VCardTitle, VDivider, VCardText },
    data() {
        return {
            tableData: {
                columns: [
                    {key: "t", type: "time"}
                ],
                rows: [
                    {t: "04:20"}
                ]
            }
        }
    }
}
</script>

<style scoped>

</style>
