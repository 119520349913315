<template>
    <v-dialog
        :show="show"
        :fullscreen="$vuetify.breakpoint.smAndDown"
        :max-width="width"
        hide-overlay
        scrollable
        transition="dialog-bottom-transition"
    >
        <v-card>
            <v-toolbar>
                <v-toolbar-title>{{ $lumui.i18n.t('lumui.lightbox.title') }}</v-toolbar-title>
                <v-spacer />
                <v-btn icon @click.native="close()">
                    <v-icon>$close</v-icon>
                </v-btn>
            </v-toolbar>

            <v-divider />
            <v-card-text style="position: relative">
                <v-img v-if="image.src" :src="image.src" class="grey lighten-2">
                    <v-layout slot="placeholder" align-center fill-height justify-center ma-0>
                        <v-progress-circular color="grey lighten-5" indeterminate />
                    </v-layout>
                </v-img>

                <v-btn
                    :disabled="i <= 0" absolute color="primary" dark fab small style="top: 50%; left: 5%;"
                    @click="prev()"
                >
                    <v-icon dark>
                        $prev
                    </v-icon>
                </v-btn>
                <v-btn
                    :disabled="(i+1) >= images.length" absolute color="primary" dark fab small
                    style="top: 50%; right: 5%;" @click="next()"
                >
                    <v-icon dark>
                        $next
                    </v-icon>
                </v-btn>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import {
    VBtn,
    VCard,
    VCardText,
    VDialog,
    VDivider,
    VIcon,
    VImg,
    VLayout,
    VSpacer,
    VToolbar,
    VToolbarTitle
} from 'vuetify/lib';

export default {
    name: 'AsaLightbox',
    components: {VDialog, VCard, VToolbar, VToolbarTitle, VSpacer, VBtn, VIcon, VDivider, VCardText, VImg, VLayout},
    props: {
        show: {
            type: Boolean,
            default: false,
        },
        images: { // Objekt-Array mit src und title Attributen
            type: Array,
            default: () => []
        },
        width: {
            type: Number,
            default: 1200
        },
        index: {
            type: Number,
            default: 0
        }
    },
    data() {
        return {
            i: 0
        }
    },
    computed: {
        image() {
            if (this.images.length === 0) {
                return {'src': '', 'title': ''}
            }
            return (this.images.length > this.i) ? this.images[this.i] : this.images[0];
        }
    },
    watch: {
        index() {
            this.i = this.index
        }
    },
    methods: {
        next: function () {
            if (this.i < this.images.length) {
                this.i++;
            } else {
                this.i = 0;
            }
        },
        prev: function () {
            if (this.i > 0) {
                this.i--;
            } else {
                this.i = (this.images.length - 1);
            }
        },
        close: function () {
            this.$emit('close')
        },
    },
}
</script>
