<template>
    <div>
        <asa-form :loading="false" :value="value" :config="config" @save="addError" />
        <v-container>
            <p>Values: {{value}}</p>
        </v-container>
    </div>
</template>

<script>
import AsaForm from "../../../src/component/AsaForm";
import { VContainer } from 'vuetify/lib'

export default {
    name: "FormSwitch",
    components: {AsaForm, VContainer},
    data() {
        return {
            value: {
                f1: false,
                f2: 1,
                f3: "1"
            },
            config: {
                f1: {
                    name: "switch1",
                    label: 'Switch w/error',
                    type: 'switch',
                    errors: []
                },
                f2: {
                    name: "switch2",
                    label: 'Switch width number value',
                    type: 'switch',
                    errors: []
                },
                f3: {
                    name: "switch3",
                    label: 'Switch width string value',
                    type: 'switch',
                    errors: []
                }
            }
        }
    },
    methods: {
        addError() {
            this.$set(this.config.f1, 'errors', ['Error']);
        }
    }
}
</script>
