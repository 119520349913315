(function($R)
{
    $R.lang['sl'] = {
        "format": "Oblikovanje",
        "image": "Slika",
        "file": "Datoteka",
        "link": "Povezava",
        "bold": "Krepko",
        "italic": "Ležeče",
        "deleted": "Prečrtano",
        "underline": "Podčrtano",
        "superscript": "Nadpisano",
        "subscript": "Podpisano",
        "bold-abbr": "K",
        "italic-abbr": "L",
        "deleted-abbr": "P",
        "underline-abbr": "U",
        "superscript-abbr": "Nad",
        "subscript-abbr": "Pod",
        "lists": "Seznami",
        "link-insert": "Vstavi povezavo",
        "link-edit": "Uredi povezavo",
        "link-in-new-tab": "Odpri povezavo v novem zavihku",
        "unlink": "Odstrani povezavo",
        "cancel": "Prekliči",
        "close": "Zapri",
        "insert": "Vstavi",
        "save": "Shrani",
        "delete": "Izbriši",
        "text": "Besedilo",
        "edit": "Uredi",
        "title": "Naslov",
        "paragraph": "Navadno besedilo",
        "quote": "Navedek",
        "code": "Koda",
        "heading1": "Naslov 1",
        "heading2": "Naslov 2",
        "heading3": "Naslov 3",
        "heading4": "Naslov 4",
        "heading5": "Naslov 5",
        "heading6": "Naslov 6",
        "filename": "Ime",
        "optional": "izbirno",
        "unorderedlist": "Označen seznam",
        "orderedlist": "Oštevilčen seznam",
        "outdent": "Zmanjšaj zamik",
        "indent": "Povečaj zamik",
        "horizontalrule": "Črta",
        "upload": "Naloži",
        "upload-label": "Povleci datoteke sem ali klikni za nalaganje",
        "upload-change-label": "Drop a new image to change",
        "accessibility-help-label": "Urejevalnik obogatenega besedila",
        "caption": "Napis",
        "bulletslist": "Oznake",
        "numberslist": "Številčenje",
        "image-position": "Položaj",
        "none": "Brez",
        "left": "Levo",
        "right": "Desno",
        "center": "Sredinsko",
        "undo": "Razveljavi",
        "redo": "Uveljavi"
    };
})(Redactor);