var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    [
      _vm.column.type !== "file"
        ? _c("asa-formatter", {
            attrs: {
              formatter: _vm.column.formatter,
              options: _vm.column.options,
              type: _vm.column.type,
              value: _vm.value,
              context: _vm.row,
            },
          })
        : _vm.file !== null
        ? [
            _c(
              "v-tooltip",
              {
                attrs: { top: "" },
                scopedSlots: _vm._u([
                  {
                    key: "activator",
                    fn: function ({ on }) {
                      return [
                        _vm.isImage(_vm.file) && _vm.file.hasOwnProperty("src")
                          ? _c(
                              "v-img",
                              _vm._g(
                                {
                                  staticClass: "clickable",
                                  attrs: {
                                    src: _vm.base64(_vm.file),
                                    "aspect-ratio": "1.7",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.download(_vm.file)
                                    },
                                  },
                                },
                                on
                              )
                            )
                          : _vm.isFile(_vm.file) &&
                            _vm.file.hasOwnProperty("src")
                          ? _c(
                              "a",
                              _vm._g(
                                {
                                  attrs: { href: "#" },
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return _vm.download(_vm.file)
                                    },
                                  },
                                },
                                on
                              ),
                              [
                                _vm._v(
                                  "\n                    " +
                                    _vm._s(_vm.file.originalName) +
                                    "\n                "
                                ),
                              ]
                            )
                          : _c(
                              "a",
                              _vm._g(
                                { attrs: { href: _vm.file, target: "_blank" } },
                                on
                              ),
                              [
                                _vm._v(
                                  "\n                    " +
                                    _vm._s(_vm.file) +
                                    "\n                "
                                ),
                              ]
                            ),
                      ]
                    },
                  },
                ]),
              },
              [
                _vm._v(" "),
                _c("span", [
                  _vm._v(_vm._s(_vm.$lumui.i18n.t("lumui.table_columns.save"))),
                ]),
              ]
            ),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }