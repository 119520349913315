import {merge} from "webpack-merge";
import lt from './base/lt.js';

export default merge(lt, {
    "lumui.confirm.accept": "GERAI",
    "lumui.confirm.reject": "Nutraukti",
    "lumui.formatter.boolean.true": "Taip",
    "lumui.formatter.boolean.false": "Ne",
    "lumui.form.close": "Uždaryti",
    "lumui.form.cancel": "Nutraukti",
    "lumui.form.save": "įrašyti",
    "lumui.lightbox.title": "Esami paveikslėliai",
    "lumui.loading.message": "Duomenys įkeliami",
    "lumui.qr_scanner.camera": "Kamera",
    "lumui.qr_scanner.mirror": "Veidrodinė kamera ",
    "lumui.qr_scanner.torch": "Kameros šviesa",
    "lumui.table.reset_filters": "atkurti",
    "lumui.table.close_filters": "Suskleisti filtrą",
    "lumui.table.details": "Išsamus rodinys",
    "lumui.table.previous": "Esamas duomenų rinkinys",
    "lumui.table.next": "Kitas duomenų rinkinys",
    "lumui.table.no_data": "Duomenų nerasta.",
    "lumui.table.no_filter_results": "Pagal Jūsų paiešką nerasta jokių rezultatų",
    "lumui.table.filters_active": "Rezultatai išfiltruoti",
    "lumui.table.rows_per_page": "Eilutės kiekviename puslapyje",
    "lumui.table.page_text": "{0}-{1} of {2}",
    "lumui.table.btn.export": "Eksporto sąrašas",
    "lumui.table_action.menu": "Meniu",
    "lumui.table_columns.save": "Įrašyti failą",
    "lumui.form.row.invalid_email": "Įveskite teisingą el. pašto adresą.",
    "lumui.form.row.required": "Tai yra privalomasis laukas",
    "lumui.form.autocomplete.selected_count": "Pasirinkta {c} prekės",
    "lumui.form.autocomplete.select_all": "Parinkti viską",
    "lumui.form.autocomplete.deselect_all": "Neparinkti nieko",
    "lumui.form.autocomplete.search_hint": "Nurodykite, ko ieškote",
    "lumui.form.autocomplete.no_data": "Duomenų nėra",
    "lumui.form.autocomplete.nothing_selected": "Įrašai neparinkti",
    "lumui.form.autocomplete.selected_items": "Parinkti įrašai",
    "lumui.form.date.close": "Uždaryti",
    "lumui.form.date.save": "GERAI",
    "lumui.form.date.after_max": "Pasirinkta data yra po maksimalios leistinos datos {max_date}",
    "lumui.form.date.before_min": "Pasirinkta data yra ankstesnė už mažiausią leistiną datą {min_date}",
    "lumui.form.file.drag": "Vilkite failą čia arba spustelėkite, kad pasirinktumėte",
    "lumui.form.file.error": "Įkėlimas nepavyko.",
    "lumui.form.file.extensions.accepted": "Leidžiami failų tipai: {types}",
    "lumui.form.file.extensions.all": "Leidžiami visi failų tipai",
    "lumui.form.file.maxSize": "Didžiausias leistinas failo dydis {size}.",
    "lumui.form.file.maxSizeError": "Failas yra didesnis nei didžiausias leistinas dydis {size}.",
    "lumui.form.file.previewError": "Nėra failo {name} peržiūros.",
    "lumui.form.file.uploading": "Įkeliami {count} failai…",
    "lumui.form.file.warning.removed_file": "Failas {name} nebuvo įkeltas. Failo tipas {type} neatitinka leidžiamų tipų {acceptedTypes}.",
    "lumui.form.file.select_file": "Pasirinkite failą",
    "lumui.form.file.unknown_size": "nenurodyta",
    "lumui.form.localized.copy_to_all": "Perimti įvestį visoms kalboms",
    "lumui.form.ordered_choice.no_data": "įrašų nėra",
    "lumui.form.ordered_choice.select_placeholder": "parinkti naują įrašą",
    "lumui.form.password.repeat": "Kartoti slaptažodį",
    "lumui.form.password.not_matching_error": "Abu slaptažodžiai turi sutapti.",
    "lumui.form.signature.clear": "Ištrinti parašą",
    "lumui.form.signature.upload": "Įkelti parašą",
    "lumui.form.time.close": "Uždaryti",
    "lumui.form.time.save": "GERAI",
    "lumui.form.password.requirements.title": "Slaptažodis turi atitikti šiuos reikalavimus:",
    "lumui.form.password.requirements.lower_chars": "bent viena mažoji raidė | bent {count} mažųjų raidžių",
    "lumui.form.password.requirements.upper_chars": "bent viena didžioji raidė | bent {count} didžiųjų raidžių",
    "lumui.form.password.requirements.number_chars": "bent viena skaitmenis | bent {count} skaitmenų",
    "lumui.form.password.requirements.special_chars": "bent viena specialioji simbolis | bent {count} specialių simbolių",
    "lumui.form.password.requirements.total_chars": "bent viena raidė | bent {count} raidžių",
    "lumui.locale.lang.german": "Vokietijos",
    "lumui.locale.lang.english": "Anglų",
    "lumui.locale.lang.italian": "Italijos",
    "lumui.locale.lang.french": "Prancūziškas",
    "lumui.status.no_action_required": "jokių veiksmų nereikia",
    "lumui.status.hint": "Nuoroda",
    "lumui.status.action_required_soon": "Greitai reikės atlikti veiksmą!",
    "lumui.status.action_required_immediately": "būtina nedelsiant imtis veiksmų!",
    "lumui.locale.de-DE": "German",
    "lumui.locale.de-CH": "German (Suisse)",
    "lumui.locale.fr-CH": "French (Suisse)",
    "lumui.locale.it-CH": "Italian (Suisse)",
    "lumui.locale.en-US": "English (USA)",
    "lumui.locale.en-GB": "English (GB)",
    "lumui.locale.fi-FI": "Finnish",
    "lumui.locale.et-EE": "Estonian",
    "lumui.locale.lt-LT": "Lithuanian",
    "lumui.locale.lv-LV": "Latvian",
    "lumui.locale.lang.finnish": "Suomių",
    "lumui.locale.lang.estonian": "Estų",
    "lumui.locale.lang.lithuanian": "Lietuvių",
    "lumui.locale.lang.latvian": "Latvių",
    "lumui.locale.lang.hungarian": "Vengrijos",
});
