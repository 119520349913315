import Vue from "vue";
export default function (config) {
    const $set = Vue.prototype.$set;
    for (const i in config) {
        const child = config[i];
        if (typeof child.children !== 'undefined') {
            Vue.prototype.disableForm(child.children);
        }
        else {
            $set(child, 'disabled', true);
        }
    }
}
