export default class LinkFormatter {
    constructor(settings)
    {
        this.hrefCol = settings.hrefCol || null;
        this.target = settings.target || null;
    }

    requiresHTML() {
        return true;
    }
    format(value, context) {
        let href = encodeURI(this.hrefCol ? context[this.hrefCol] : value);
        let tmp1 = document.createTextNode(value);
        let tmp2 = document.createElement('p');
        tmp2.append(tmp1);
        let text = tmp2.innerHTML;
        return '<a ' + (this.target ? 'target="' + this.target + '" ' : '') + 'href="' + href + '">' + text + '</a>';
    }
}
