var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-menu",
    {
      ref: "dialog",
      attrs: {
        transition: "scroll-y-transition",
        "close-on-content-click": false,
        "offset-y": "",
        "max-width": "290px",
        "min-width": "auto",
      },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on }) {
            return [
              _c(
                "v-text-field",
                _vm._g(
                  {
                    attrs: {
                      id: "filter_" + _vm.config.key,
                      label: _vm.config.label,
                      clearable: _vm.clearable,
                      "prepend-inner-icon": "$calendar",
                      readonly: "",
                      "hide-details": "",
                    },
                    on: { "click:clear": _vm.reset },
                    model: {
                      value: _vm.viewValue,
                      callback: function ($$v) {
                        _vm.viewValue = $$v
                      },
                      expression: "viewValue",
                    },
                  },
                  on
                )
              ),
            ]
          },
        },
      ]),
      model: {
        value: _vm.modal,
        callback: function ($$v) {
          _vm.modal = $$v
        },
        expression: "modal",
      },
    },
    [
      _vm._v(" "),
      _c("v-date-picker", {
        attrs: {
          max: _vm.max,
          min: _vm.min,
          range: _vm.range,
          "no-title": "",
          scrollable: "",
          locale: _vm.locale,
          "first-day-of-week": _vm.firstDayOfWeek,
        },
        model: {
          value: _vm.date,
          callback: function ($$v) {
            _vm.date = $$v
          },
          expression: "date",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }