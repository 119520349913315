<template>
    <asa-form v-model="value" :config="config">
        <template slot="title">
            HTML Options
        </template>
    </asa-form>
</template>

<script>
import AsaForm from "../../../src/component/AsaForm";

export default {
    name: "HtmlOptions",
    components: {AsaForm},
    data() {
        return {
            value: {},
            config: {
                "users": {
                    "type": "select",
                    "label": "Teilnehmer",
                    "chips": true,
                    "html_options": true,
                    "value": ["1", "2"],
                    "required": false,
                    "disabled": false,
                    "multiple": true,
                    "multiOptions": [
                        {
                            "label": "<span style=\"color: green\">Lum, Admin<\/span>",
                            "value": "1"
                        }, {
                            "label": "<span style=\"color: red\">Reporter, Harry<\/span>",
                            "value": "2"
                        }
                    ],
                },
                "radios": {
                    type: "radio",
                    label: "Radio",
                    multiOptions: [
                        {
                            "label": "<span style=\"color: green;\">Green</span>",
                            "value": "1"
                        },
                        {
                            "label": "<span style=\"color: red;\">Red</span>",
                            "value": "2"
                        }
                    ]
                },
                "html": {
                    type: "html",
                    label: "Labeled",
                    value: "<div><strong>strong </strong> non blinky <span style=\"color: red;\"> red </span> Lorem ipsum dolor sit amet</div>"
                }
            }
        }
    },
    mounted() {
        console.log(this.config.users.multiOptions[0].label);
        console.log(this.config.users.multiOptions[0].label);
    }
}
</script>

<style scoped>

</style>
