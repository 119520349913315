<template>
    <div>
        <form-tabs-content :active-tab="activeTab" :config="config" :render-items="true" :value="value" @update:error="passThroughErrors($event)" />
        <form-accordion :config="config" :value="value" @update:error="passThroughErrors($event)" />
        <form-rows :config="config" :value="value" @update:error="passThroughErrors($event)" />
    </div>
</template>

<script>
import FormRows from './FormRows';
import FormTabsContent from './FormTabsContent';
import FormAccordion from './FormAccordion';

/**
 * Renders form content.
 * First all elements with a `type` of 'tab' are rendert as tabs.
 * Then all elements with a `type` of `'accordion'` are rendered as accordions.
 * At last all remaining elements are rendered as rows.
 *
 * While not strictly necessary, forms should only have elements of one of
 * these types as top level elements.
 */
export default {
    name: "FormContent",
    components: {
        FormRows,
        FormTabsContent,
        FormAccordion
    },
    props: {
        /** form value object */
        value: {
            type: Object,
            required: true,
        },
        /** form definition */
        config: {
            type: Object,
            required: true
        },
        /** currently active tab */
        activeTab: {
            type: String,
            default: ""
        }
    },
    methods: {
        /** @private */
        passThroughErrors(e) {
            /**
             * passed through from children
             * @param {Event} e - parent event
             */
            this.$emit('update:error', e)
        },
        /** @private */
        setActiveTab(e) {
            /**
             * active tab changed. parent needs to change the `activeTab` prop accordingly.
             * @param {string} e - the id of the newly active tab
             */
            this.$emit('tab', e);
        }
    }
}
</script>
