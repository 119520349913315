<template>
    <v-input
        :id="name"
        :error="errors.length > 0"
        :error-messages="errors"
        :label="config.label"
        :name="name"
        class="row-localized"
        error-count="3"
    >
        <asa-multi-lang :current-locale="currentLocale" :available-locales="availableLocales" @tabActivated="focusInput">
            <template #default="{ locale, localeKey }">
                <v-text-field
                    v-if="childType === 'text'"
                    :id="name+'-'+localeKey"
                    :ref="'input_' + localeKey"
                    v-model="viewValue[localeKey]"
                    filled
                    :disabled="disabled"
                    :name="name + '[' + localeKey + ']'"
                    :required="required"
                    :rules="rules"
                    autocomplete="off"
                    hide-details
                    type="text"
                    :lang="locale"
                >
                    <template #append>
                        <v-icon :title="$lumui.i18n.t('lumui.form.localized.copy_to_all')" @click="setAllToCurrent">
                            $setAllToCurrent
                        </v-icon>
                    </template>
                </v-text-field>
                <v-textarea
                    v-else-if="childType === 'textarea'"
                    :id="name+'-'+localeKey"
                    :ref="'input_' + localeKey"
                    v-model="viewValue[localeKey]"
                    filled
                    :disabled="disabled"
                    :name="name + '[' + localeKey + ']'"
                    :required="required"
                    :rules="rules"
                    append-icon="fa-equals"
                    auto-grow
                    autocomplete="off"
                    hide-details
                    type="text"
                    :lang="locale"
                    @click:append="setAllToCurrent"
                >
                    <template #append>
                        <v-icon :title="$lumui.i18n.t('lumui.form.localized.copy_to_all')" @click="setAllToCurrent">
                            $setAllToCurrent
                        </v-icon>
                    </template>
                </v-textarea>
                <redactor
                    v-else-if="childType === 'wysiwyg'"
                    :id="name+'-'+localeKey"
                    :ref="'input_' + localeKey"
                    v-model="viewValue[localeKey]"
                    filled
                    :config="config.redactor || {}"
                    :disabled="disabled"
                    :label="null"
                    :name="name + '[' + localeKey + ']'"
                    :required="required"
                    :rules="rules"
                    :lang="locale"
                    hide-details
                />
            </template>
        </asa-multi-lang>
    </v-input>
</template>

<script>
import {VIcon, VInput, VTextarea, VTextField} from 'vuetify/lib';
import AsaMultiLang from './AsaMultiLang';
import Redactor from "./Redactor";
import Validatable from 'vuetify/lib/mixins/validatable';
import {mapGetters} from "vuex";

/**
 * Selects the element that will be rendered based on the `type` value of the `config` object.
 *
 * ## Config
 *
 * | key                    | type                       | required | default    | description |
 * |------------------------|----------------------------|----------|------------|-------------|
 * | type                   | `String`                   | yes      |            | field type  |
 * | label                  | `String`, `false`          | no       | `false`    | fields label |
 * | class                  | `String`                   | no       | `null`     | css class for custom styling |
 * | languages              | `Array`                    | (yes)    |  store.currentLocales | which languages to render |
 * | required               | `Boolean`, `eval(String)`  | no       | `false`    | field is required.|
 * | disabled               | `Boolean`, `eval(String)`  | no       | `false`    | field is disabled.|
 * | visible                | `Boolean`, `eval(String)`  | no       | `false`    | field is rendered.  |
 * | child                  | `"text"`, `"textarea"`, `"wysiwyg"` | no      | `"text"`  | type of the child elements. |
 * | redactor               | `Object`                   | no       | `{}`       | config for redactor |
 */
export default {
    name: "FormRowLocalized",
    components: {
        AsaMultiLang,
        Redactor,
        VInput,
        VTextarea,
        VTextField,
        VIcon
    },
    mixins: [
        Validatable
    ],
    props: {
        /** field config */
        config: {
            type: Object,
            default: () => {
            }
        },
        /** value, only `object`s work as expected */
        value: {
            type: [String, Number, Array, Object],
            default: null
        },
        /** name used as prefix for the child elenents */
        name: {
            type: String,
            default: ""
        },
        /** input required */
        required: {
            type: Boolean,
            default: false
        },
        /** validation errors exist */
        error: {
            type: Boolean,
            default: false
        },
        /** validation messages */
        errorMessages: {
            type: Array,
            default: () => []
        },
        /** validation rules */
        rules: {
            type: Array,
            default: () => []
        },
        /** field disabled */
        disabled: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            viewValue: {},
            focusedLocale: null
        }
    },
    computed: {
        ...mapGetters('lumui/i18n', ['locale', 'locales']),
        childType() {
            return this.config.child || 'text';
        },
        overriddenLocales() {
            return typeof this.config.languages !== 'undefined' && Array.isArray(this.config.languages) && this.config.languages.length > 0
        },
        availableLocales() {
            if (this.overriddenLocales) {
                return this.config.languages.map((locale) => {
                    const backendLocale = this.$lumui.lookupLocale(locale);
                    if (!backendLocale) {
                        throw Error('could not map locale ' + locale);
                    }
                    return backendLocale;
                });
            } else {
                return this.locales;
            }
        },
        localeKeys() {
            return this.availableLocales.map(this.$lumui.mapLocale).filter((x) => x !== null);
        },
        currentLocale() {
            if (this.availableLocales.includes(this.locale)) {
                const k = this.$lumui.mapLocale(this.locale);
                if (!k) {
                    throw Error('could not map locale ' + this.locale);
                }
            }
            return this.localeKeys[0];
        },
        errors() {
            let errors = [];
            this.availableLocales.forEach((locale) => {
                const refId = 'input_' + locale;
                if (typeof this.$refs[refId] !== 'undefined' && !this.$refs[refId].valid) {
                    const loctrans = this.$te('lumui.locale.' + locale) ? this.$t('lumui.locale.' + locale) : locale
                    errors.push(loctrans.toUpperCase() + ': ' + this.$refs[refId].errorBucket[0])
                }
            });
            if (typeof this.config.errors !== 'undefined' && this.config.errors.length > 0) {
                errors.push(...this.config.errors);
            }
            return errors;
        }
    },
    watch: {
        value: {
            handler: function (value) {
                if (typeof value === 'object' && value !== null) {
                    this.viewValue = this.value;
                } else {
                    this.viewValue = {};
                }
                this.localeKeys.forEach((localeKey) => {
                    if (Object.keys(this.viewValue).indexOf(localeKey) < 0) {
                        this.$set(this.viewValue, localeKey, '');
                    }
                });
                this.$set(this.config, 'errors', []);
            },
            immediate: true,
            deep: true
        },
        viewValue: {
            handler: function () {
                this.$emit('input', this.viewValue);

            },
            deep: true
        },
    },
    mounted() {
        this.focusedLocale = this.locale;
        this.$nextTick(() => {
            //this.validate(true);
            this.localeKeys.forEach((localeKey) => {
                const refId = 'input_' + localeKey;
                if (typeof this.$refs[refId].validate !== 'undefined') {
                    this.$refs[refId].validate(true);
                }
            });
        });
    },
    methods: {
        /** set all locales to the value of the current */
        setAllToCurrent() {
            let value = this.viewValue[this.$lumui.mapLocale(this.focusedLocale)];
            this.localeKeys.forEach((localeKey) => {
                //this.$set(this.viewValue, locale, value);
                this.$refs['input_' + localeKey].internalValue = value;
            });
        },
        /* @private */
        focusInput(e) {
            this.focusedLocale = e.locale || this.availableLocales[0];
        }
    }
}
</script>

<style scoped>
div {
    width: 100%;
}
</style>

<style>
.row-localized > .v-input__control > .v-input__slot {
    flex-direction: column;
}

.row-localized > .v-input__control > .v-input__slot > label {
    width: 100%;
}
</style>
