import {merge} from "webpack-merge";
import de from './base/de.js';

export default merge(de, {
    "lumui.confirm.accept": "OK",
    "lumui.confirm.reject": "Abbrechen",
    "lumui.formatter.boolean.true": "Ja",
    "lumui.formatter.boolean.false": "Nein",
    "lumui.form.close": "Schließen",
    "lumui.form.cancel": "Abbrechen",
    "lumui.form.save": "Speichern",
    "lumui.lightbox.title": "Vorhandene Bilder",
    "lumui.loading.message": "Daten werden geladen",
    "lumui.qr_scanner.camera": "Kamera",
    "lumui.qr_scanner.mirror": "Kamera spiegeln",
    "lumui.qr_scanner.torch": "Kamera licht",
    "lumui.table.reset_filters": "Zurücksetzen",
    "lumui.table.close_filters": "Filter einklappen",
    "lumui.table.details": "Detailansicht",
    "lumui.table.previous": "Vorheriger Datensatz",
    "lumui.table.next": "Nächster Datensatz",
    "lumui.table.no_data": "Keine Daten gefunden.",
    "lumui.table.no_filter_results": "Ihre Suche ergab keine Ergebnisse",
    "lumui.table.filters_active": "Ergebnisse gefiltert",
    "lumui.table.rows_per_page": "Zeilen je Seite",
    "lumui.table.page_text": "{0}-{1} von {2}",
    "lumui.table.btn.export": "Angezeigte Liste exportieren",
    "lumui.table_action.menu": "Menu",
    "lumui.table_columns.save": "Datei speichern",
    "lumui.form.row.invalid_email": "Bitte geben Sie eine korrekte E-Mail-Adresse an.",
    "lumui.form.row.required": "Dies ist ein Pflichtfeld",
    "lumui.form.autocomplete.selected_count": "1 Eintrag gewählt | {c} Einträge ausgewählt",
    "lumui.form.autocomplete.select_all": "Alles auswählen",
    "lumui.form.autocomplete.deselect_all": "Nichts auswählen",
    "lumui.form.autocomplete.search_hint": "Bitte geben Sie ein wonach Sie suchen",
    "lumui.form.autocomplete.no_data": "Keine Daten vorhanden",
    "lumui.form.autocomplete.nothing_selected": "Keine Einträge gewählt",
    "lumui.form.autocomplete.selected_items": "Gewählte Einträge",
    "lumui.form.date.close": "Schließen",
    "lumui.form.date.save": "OK",
    "lumui.form.file.drag": "Ziehen Sie Ihre Datei hierher oder klicken Sie um auszuwählen",
    "lumui.form.file.error": "Upload fehlgeschlagen.",
    "lumui.form.file.extensions.accepted": "Dateien vom Typ {types} sind erlaubt.",
    "lumui.form.file.extensions.all": "Alle Dateitypen sind erlaubt.",
    "lumui.form.file.maxSize": "Die maximal erlaubte Dateigröße beträgt {size}.",
    "lumui.form.file.maxSizeError": "Die Datei ist größer als die erlaubte Dateigröße von {size}.",
    "lumui.form.file.previewError": "Eine Vorschau der Datei {name} wird leider nicht unterstützt.",
    "lumui.form.file.uploading": "{count} Dateien werden hochgeladen ...",
    "lumui.form.file.warning.removed_file": "Die Datei {name} wurde aus den hochzuladenden Dateien entfernt da, der Typ {type} nicht unter den erlaubten Dateitypen {acceptedTypes} ist.",
    "lumui.form.file.select_file": "Dateien wählen",
    "lumui.form.file.unknown_size": "k.A.",
    "lumui.form.localized.copy_to_all": "Eingabe für alle Sprachen übernehmen",
    "lumui.form.ordered_choice.no_data": "keine Einträge vorhanden",
    "lumui.form.ordered_choice.select_placeholder": "neuen Eintrag auswählen",
    "lumui.form.password.repeat": "Passwort wiederholen",
    "lumui.form.password.not_matching_error": "Beide Passwörter müssen übereinstimmen.",
    "lumui.form.signature.clear": "Signatur löschen",
    "lumui.form.signature.upload": "Signatur hochladen",
    "lumui.form.time.close": "Schließen",
    "lumui.form.time.save": "OK",
    "lumui.locale.lang.german": "Deutsch",
    "lumui.locale.lang.english": "Englisch",
    "lumui.locale.lang.italian": "Italienisch",
    "lumui.locale.lang.french": "Französisch",
    "lumui.status.no_action_required": "keine Aktion erforderlich",
    "lumui.status.hint": "Hinweis",
    "lumui.status.action_required_soon": "baldige Aktion erforderlich!",
    "lumui.status.action_required_immediately": "sofortige Aktion erforderlich!",
    "lumui.locale.de-DE": "Deutsch",
    "lumui.locale.de-CH": "Deutsch (Schweiz)",
    "lumui.locale.fr-CH": "Französisch (Schweiz)",
    "lumui.locale.it-CH": "Italienisch (Schweiz)",
    "lumui.locale.en-US": "Englisch (USA)",
    "lumui.locale.en-GB": "Englisch (Großbritannien)",
    "lumui.locale.fi-FI": "Finnisch",
    "lumui.locale.et-EE": "Estnisch",
    "lumui.locale.lt-LT": "Litauisch",
    "lumui.locale.lv-LV": "Lettisch",
    "lumui.locale.lang.finnish": "Finnisch",
    "lumui.locale.lang.estonian": "Estnisch",
    "lumui.locale.lang.lithuanian": "Litauisch",
    "lumui.locale.lang.latvian": "Lettisch",
    "lumui.locale.lang.hungarian": "Ungarisch",
});
