var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.error === "error_no_camera"
        ? _c("v-alert", { attrs: { type: "error" } }, [
            _vm._v(
              "\n        " +
                _vm._s(
                  _vm.$lumui.i18n.t("Es steht keine Kamera zur verfügung")
                ) +
                "\n    "
            ),
          ])
        : _vm.error
        ? _c("v-alert", { attrs: { type: "error" } }, [
            _vm._v("\n        Error: " + _vm._s(_vm.error) + "\n    "),
          ])
        : _vm._e(),
      _vm._v(" "),
      !_vm.error
        ? _c("div", { attrs: { id: "video-frame" } }, [
            _c("video", { ref: "video", class: { mirror: _vm.mirror } }),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { attrs: { id: "config" } },
        [
          _c("form-row-autocomplete", {
            ref: "cameraId",
            attrs: {
              id: "cameraId",
              config: {
                label: _vm.$lumui.i18n.t("lumui.qr_scanner.camera"),
                options: _vm.cameraOptions,
              },
              required: true,
            },
            model: {
              value: _vm.cameraId,
              callback: function ($$v) {
                _vm.cameraId = $$v
              },
              expression: "cameraId",
            },
          }),
          _vm._v(" "),
          _c("form-row-switch", {
            attrs: {
              id: "mirror",
              config: { label: _vm.$lumui.i18n.t("lumui.qr_scanner.mirror") },
            },
            model: {
              value: _vm.mirror,
              callback: function ($$v) {
                _vm.mirror = $$v
              },
              expression: "mirror",
            },
          }),
          _vm._v(" "),
          _vm.hasFlash
            ? _c("form-row-switch", {
                attrs: {
                  id: "torch",
                  config: {
                    label: _vm.$lumui.i18n.t("lumui.qr_scanner.torch"),
                  },
                },
                model: {
                  value: _vm.flash,
                  callback: function ($$v) {
                    _vm.flash = $$v
                  },
                  expression: "flash",
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }