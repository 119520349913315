(function($R)
{
    $R.lang['fi'] = {
        "format": "Muotoilut",
        "image": "Kuva",
        "file": "Tiedosto",
        "link": "Linkki",
        "bold": "Lihavointi",
        "italic": "Kursivointi",
        "deleted": "Poistettu",
        "underline": "Alleviivaa",
        "superscript": "Superscript",
        "subscript": "Subscript",
        "bold-abbr": "B",
        "italic-abbr": "I",
        "deleted-abbr": "S",
        "underline-abbr": "U",
        "superscript-abbr": "Sup",
        "subscript-abbr": "Sub",
        "lists": "Luettelot",
        "link-insert": "Lisää linkki",
        "link-edit": "Muokkaa linkkiä",
        "link-in-new-tab": "Avaa linkki uudessa välilehdessä",
        "unlink": "Poista linkki",
        "cancel": "Peru",
        "close": "Sulkea",
        "insert": "Lisää",
        "save": "Tallenna",
        "delete": "Poista",
        "text": "Teksti",
        "edit": "Muokata",
        "title": "Title",
        "paragraph": "Normaaliteksti",
        "quote": "Lainaus",
        "code": "Koodi",
        "heading1": "Otsikko 1",
        "heading2": "Otsikko 2",
        "heading3": "Otsikko 3",
        "heading4": "Otsikko 4",
        "heading5": "Otsikko 5",
        "heading6": "Otsikko 6",
        "filename": "Nimi (valinnainen)",
        "optional": "valinnainen",
        "unorderedlist": "Luettelo luettelomerkein",
        "orderedlist": "Numeroitu luettelo",
        "outdent": "Vähennä sisennystä",
        "indent": "Lisää sisennystä",
        "horizontalrule": "Viiva",
        "upload": "Upload",
        "upload-label": "Drop files here or click to upload",
        "upload-change-label": "Drop a new image to change",
        "accessibility-help-label": "Rich text editor",
        "caption": "Caption",
        "bulletslist": "Bullets",
        "numberslist": "Numbers",
        "image-position": "Position",
        "none": "None",
        "left": "Left",
        "right": "Right",
        "center": "Center",
        "undo": "Undo",
        "redo": "Redo"
    };
})(Redactor);