import Vue from "vue";
export default function (config) {
    const $set = Vue.prototype.$set;
    if (typeof config.errors !== 'undefined') {
        $set(config, 'errors', []);
    }
    for (const i in config) {
        const child = config[i];
        if (typeof child.children !== 'undefined') {
            $set(child, 'error', false);
            Vue.prototype.clearFormErrors(child.children);
        }
        else {
            $set(child, 'errors', []);
        }
    }
}
