<script>
import AsaTableFilterText from './AsaTableFilterText';
import AsaTableFilterSelect from './AsaTableFilterSelect';
import AsaTableFilterBool from './AsaTableFilterBool';
import AsaTableFilterDate from './AsaTableFilterDate';
import AsaTableFilterNestedSet from "./AsaTableFilterNestedSet";
import AsaTableFilterMaterializedPath from "./AsaTableFilterMaterializedPath.vue";

/**
 * Renders a table filter of the appropriate type
 * @internal
 */
export default {
    name: "AsaTableFilter",
    functional: true,
    props: {
        config: {
            type: Object,
            required: true,
        }
    },
    render(createElement, context) {
        function getFilterComponent() {
            let config = context.props.config;
            if (!config.filter) {
                if (config.type === 'bool') {
                    return AsaTableFilterBool;
                } else if (config.type === 'date') {
                    return AsaTableFilterDate;
                } else {
                    return AsaTableFilterText;
                }
            }
            if (typeof config.filter === 'string') {
                const filterName = config.filter.toLowerCase();
                if (filterName === 'bool') {
                    return AsaTableFilterBool;
                } else if (filterName === 'date') {
                    return AsaTableFilterDate;
                }
            }
            if (config.filter.select) {
                return AsaTableFilterSelect;
            } else if (config.filter.date) {
                return AsaTableFilterDate;
            }  else if (config.filter.nestedset) {
                return AsaTableFilterNestedSet;
            } else if (config.filter.materialized_path) {
                return AsaTableFilterMaterializedPath;
            } else {
                return AsaTableFilterText
            }
        }

        return createElement(
            getFilterComponent(),
            {
                props: {
                    config: context.props.config,
                }
            },
        );
    }
}
</script>
