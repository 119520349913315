import Vue from "vue";
const $set = Vue.prototype.$set;
function appendErrors(config, errors) {
    if (!(errors in config)) {
        $set(config, 'errors', []);
    }
    $set(config, 'errors', config.errors.concat(errors));
}
function processConfig(config, errors, parent, path) {
    const found = {};
    for (let c in config) {
        const child = config[c];
        const childPath = (path !== '' ? path + '.' + c : c);
        const isCompound = 'children' in child;
        if (isCompound) {
            for (c in child.children) {
                if (c === 'errors') {
                    continue;
                }
                const grandchild = child.children[c];
                if (typeof grandchild.type === "undefined") {
                    // compound
                    const childFound = processConfig(grandchild, errors, child, childPath + '.' + c);
                    Object.assign(found, found, childFound);
                }
                else {
                    // single field
                    const grandchildPath = childPath + '.' + c;
                    if (typeof errors[grandchildPath] !== 'undefined') {
                        appendErrors(grandchild, errors[childPath + '.' + c]);
                        $set(child, 'error', true);
                        found[grandchildPath] = true;
                    }
                }
            }
        }
        else if (childPath in errors) {
            if (parent) {
                $set(parent, 'error', true);
            }
            if (typeof errors[childPath] !== 'undefined') {
                appendErrors(child, errors[childPath]);
                found[childPath] = true;
            }
        }
    }
    // check if there are any errors that could not be assigned
    // if that is the case, assign them to the form element
    if (parent === null) {
        const formErrors = [];
        for (const e in errors) {
            if (!(e in errors) || e in found) {
                continue;
            }
            console.log("unassigned formError " + e + " = " + errors[e]);
            formErrors.push(errors[e]);
        }
        $set(config, 'errors', formErrors);
    }
    return found;
}
export default function (config, errors) {
    if (typeof errors !== 'object' || errors === null) {
        throw new Error('errors must be object');
    }
    if (typeof config !== 'object' || config === null) {
        throw new Error('config must be object');
    }
    Vue.prototype.clearFormErrors(config);
    processConfig(config, errors, null, "");
}
