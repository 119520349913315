<template>
    <div>
        <form-row-autocomplete id="xxx" :config="cfg.f0" />
        <form-row-autocomplete id="yyy" :config="cfg.f1" />
        <v-autocomplete :items="cfg.f0.options" label="XXX">
            <template #item="{item}">
                <v-list-item>
                    <v-list-item-content>{{ item.text }}</v-list-item-content>
                </v-list-item>
            </template>
        </v-autocomplete>
    </div>
</template>

<script>
import {VAutocomplete, VListItem, VListItemContent} from 'vuetify/lib';
import AsaForm from "../../../src/component/AsaForm";
import FormRowAutocomplete from "../../../src/component/FormRowAutocomplete";

export default {
    name: "AutocompleteLarge",
    components: {FormRowAutocomplete, VAutocomplete, AsaForm, VListItem, VListItemContent},
    data() {
        return {
            loading: false,
            value: {
                f0: "174"
            },
            cfg: {
                f0: {
                    label: 'large autocomplete',
                    type: 'select',
                    options: this.generateOptions()
                },
                f1: {
                    label: 'large autocomplete multiple + chips',
                    type: 'select',
                    options: this.generateOptions(),
                    multiple: true,
                    chips: true,
                    html_options: false
                }
            }
        }
    },
    methods: {
        generateOptions() {
            let options = [];
            for (let i = 0; i < 4000; i++) {
                options.push({value: i, text: 'Option ' + i, disabled: (i % 10 === 0)});
            }
            return options;
        }
    }

}
</script>
