var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: _vm.cssClasses },
    [
      _c(
        "v-tooltip",
        {
          attrs: { bottom: "" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function ({ on, attrs }) {
                return [
                  _c(
                    "v-btn",
                    _vm._g(
                      _vm._b(
                        {
                          attrs: {
                            absolute: "",
                            color: "black",
                            fab: "",
                            outlined: "",
                            right: "",
                            small: "",
                          },
                          on: { click: _vm.clear },
                        },
                        "v-btn",
                        attrs,
                        false
                      ),
                      on
                    ),
                    [
                      _c("v-icon", { attrs: { small: "" } }, [
                        _vm._v(
                          "\n                    $delete\n                "
                        ),
                      ]),
                    ],
                    1
                  ),
                ]
              },
            },
          ]),
        },
        [
          _vm._v(" "),
          _c("span", [
            _vm._v(_vm._s(_vm.$lumui.i18n.t("lumui.form.signature.clear"))),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "v-tooltip",
        {
          attrs: { bottom: "" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function ({ on, attrs }) {
                return [
                  _c(
                    "v-btn",
                    _vm._g(
                      _vm._b(
                        {
                          staticStyle: { "margin-right": "4em" },
                          attrs: {
                            absolute: "",
                            color: "black",
                            fab: "",
                            outlined: "",
                            right: "",
                            small: "",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.$refs.fileElement.click()
                            },
                          },
                        },
                        "v-btn",
                        attrs,
                        false
                      ),
                      on
                    ),
                    [
                      _c("v-icon", { attrs: { small: "" } }, [
                        _vm._v(
                          "\n                    $upload\n                "
                        ),
                      ]),
                    ],
                    1
                  ),
                ]
              },
            },
          ]),
        },
        [
          _vm._v(" "),
          _c("span", [
            _vm._v(_vm._s(_vm.$lumui.i18n.t("lumui.form.signature.upload"))),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "v-label",
        {
          attrs: {
            absolute: true,
            left: "12px",
            focused: _vm.focused,
            value: true,
          },
        },
        [_vm._v("\n        " + _vm._s(_vm.config.label) + "\n    ")]
      ),
      _vm._v(" "),
      _c(
        "div",
        [
          _c("vue-signature-pad", {
            ref: "signaturePad",
            attrs: { options: _vm.options },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("input", {
        ref: "fileElement",
        staticStyle: { display: "none" },
        attrs: { type: "file", accept: "image/*" },
        on: { change: _vm.imgUpload },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }