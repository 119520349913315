var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.visible
    ? _c(
        "div",
        { class: { "form-row": true, "form-row-required": _vm.required } },
        [
          _vm.config.type === "select"
            ? _c("form-row-autocomplete", {
                class: _vm.styleClass,
                attrs: {
                  id: _vm.name,
                  config: _vm.config,
                  "data-url": _vm.config.dataUrl ? _vm.config.dataUrl : null,
                  disabled: _vm.disabled,
                  error: _vm.hasError,
                  "error-messages": _vm.msgs,
                  name: _vm.name,
                  required: _vm.required,
                  rules: _vm.rules,
                },
                on: { "update:error": _vm.updateError },
                model: {
                  value: _vm.viewValue,
                  callback: function ($$v) {
                    _vm.viewValue = $$v
                  },
                  expression: "viewValue",
                },
              })
            : _vm.config.type === "orderedChoice"
            ? _c("form-row-ordered-choice", {
                attrs: {
                  config: _vm.config,
                  disable: _vm.disabled,
                  name: _vm.name,
                  value: _vm.viewValue,
                },
                on: {
                  input: (e) => {
                    _vm.viewValue = e
                  },
                  "update:error": _vm.updateError,
                },
              })
            : _vm.config.type === "hidden"
            ? _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.viewValue,
                    expression: "viewValue",
                  },
                ],
                class: _vm.styleClass,
                attrs: {
                  id: _vm.name,
                  disabled: _vm.disabled,
                  name: _vm.name,
                  type: "hidden",
                },
                domProps: { value: _vm.viewValue },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.viewValue = $event.target.value
                  },
                },
              })
            : _vm.config.type === "date"
            ? _c("form-row-date", {
                class: _vm.styleClass,
                attrs: {
                  id: _vm.name,
                  config: _vm.config,
                  disabled: _vm.disabled,
                  error: _vm.hasError,
                  "error-messages": _vm.msgs,
                  name: _vm.name,
                  required: _vm.required,
                  rules: _vm.rules,
                },
                on: { "update:error": _vm.updateError },
                model: {
                  value: _vm.viewValue,
                  callback: function ($$v) {
                    _vm.viewValue = $$v
                  },
                  expression: "viewValue",
                },
              })
            : _vm.config.type === "time"
            ? _c("form-row-time", {
                class: _vm.styleClass,
                attrs: {
                  id: _vm.name,
                  config: _vm.config,
                  disabled: _vm.disabled,
                  error: _vm.hasError,
                  "error-messages": _vm.msgs,
                  name: _vm.name,
                  required: _vm.required,
                  rules: _vm.rules,
                },
                on: { "update:error": _vm.updateError },
                model: {
                  value: _vm.viewValue,
                  callback: function ($$v) {
                    _vm.viewValue = $$v
                  },
                  expression: "viewValue",
                },
              })
            : _vm.config.type === "switch"
            ? _c("form-row-switch", {
                class: _vm.styleClass,
                attrs: {
                  id: _vm.name,
                  config: _vm.config,
                  disabled: _vm.disabled,
                  error: _vm.hasError,
                  "error-messages": _vm.msgs,
                  name: _vm.name,
                  required: _vm.required,
                  rules: _vm.rules,
                },
                on: { "update:error": _vm.updateError },
                model: {
                  value: _vm.viewValue,
                  callback: function ($$v) {
                    _vm.viewValue = $$v
                  },
                  expression: "viewValue",
                },
              })
            : _vm.config.type === "checkbox"
            ? _c("form-row-checkbox", {
                class: _vm.styleClass,
                attrs: {
                  config: _vm.config,
                  disabled: _vm.disabled,
                  error: _vm.hasError,
                  "error-messages": _vm.msgs,
                  name: _vm.name,
                  required: _vm.required,
                  rules: _vm.rules,
                },
                on: { "update:error": _vm.updateError },
                model: {
                  value: _vm.viewValue,
                  callback: function ($$v) {
                    _vm.viewValue = $$v
                  },
                  expression: "viewValue",
                },
              })
            : _vm.config.type === "treenode"
            ? _c("form-row-tree-node", {
                class: _vm.styleClass,
                attrs: {
                  config: _vm.config,
                  disabled: _vm.disabled,
                  error: _vm.hasError,
                  "error-messages": _vm.msgs,
                  name: _vm.name,
                  required: _vm.required,
                  rules: _vm.rules,
                  multiple: _vm.config.multiple || false,
                  "selection-type": _vm.config.selectionType,
                },
                on: { "update:error": _vm.updateError },
                model: {
                  value: _vm.viewValue,
                  callback: function ($$v) {
                    _vm.viewValue = $$v
                  },
                  expression: "viewValue",
                },
              })
            : _vm.config.type === "radio"
            ? _c("form-row-radio", {
                class: _vm.styleClass,
                attrs: {
                  config: _vm.config,
                  disabled: _vm.disabled,
                  error: _vm.hasError,
                  "error-messages": _vm.msgs,
                  name: _vm.name,
                  required: _vm.required,
                  rules: _vm.rules,
                },
                on: { "update:error": _vm.updateError },
                model: {
                  value: _vm.viewValue,
                  callback: function ($$v) {
                    _vm.viewValue = $$v
                  },
                  expression: "viewValue",
                },
              })
            : _vm.config.type === "file" || _vm.config.type === "image"
            ? _c("form-row-file", {
                attrs: {
                  accept: _vm.config.accept,
                  config: _vm.config,
                  disabled: _vm.disabled,
                  error: _vm.hasError,
                  "error-messages": _vm.msgs,
                  multiple: _vm.config.multiple,
                  "max-file-size": _vm.config.maxFileSize,
                  "max-image-size": _vm.config.maxImageSize,
                  name: _vm.name,
                  required: _vm.required,
                  rules: _vm.rules,
                },
                on: { "update:error": _vm.updateError },
                model: {
                  value: _vm.viewValue,
                  callback: function ($$v) {
                    _vm.viewValue = $$v
                  },
                  expression: "viewValue",
                },
              })
            : _vm.config.type === "signatur"
            ? _c("form-row-signatur", {
                class: _vm.styleClass,
                attrs: {
                  config: _vm.config,
                  disabled: _vm.disabled,
                  name: _vm.name,
                },
                model: {
                  value: _vm.viewValue,
                  callback: function ($$v) {
                    _vm.viewValue = $$v
                  },
                  expression: "viewValue",
                },
              })
            : _vm.config.type === "wysiwyg"
            ? _c("redactor", {
                attrs: {
                  disabled: _vm.disabled,
                  label: _vm.config.label,
                  config: _vm.config.redactor || {},
                },
                on: { "update:error": _vm.updateError },
                model: {
                  value: _vm.viewValue,
                  callback: function ($$v) {
                    _vm.viewValue = $$v
                  },
                  expression: "viewValue",
                },
              })
            : _vm.config.type === "localized"
            ? _c("form-row-localized", {
                class: _vm.styleClass,
                attrs: {
                  config: _vm.config,
                  disabled: _vm.disabled,
                  error: _vm.hasError,
                  "error-messages": _vm.msgs,
                  name: _vm.name,
                  required: _vm.required,
                  rules: _vm.rules,
                },
                on: { "update:error": _vm.updateError },
                model: {
                  value: _vm.viewValue,
                  callback: function ($$v) {
                    _vm.viewValue = $$v
                  },
                  expression: "viewValue",
                },
              })
            : _vm.config.type === "localizedMulti"
            ? _c("form-row-localized-multi", {
                class: _vm.styleClass,
                attrs: {
                  config: _vm.config,
                  disabled: _vm.disabled,
                  error: _vm.hasError,
                  "error-messages": _vm.msgs,
                  name: _vm.name,
                  required: _vm.required,
                  rules: _vm.rules,
                },
                on: { "update:error": _vm.updateError },
                model: {
                  value: _vm.viewValue,
                  callback: function ($$v) {
                    _vm.viewValue = $$v
                  },
                  expression: "viewValue",
                },
              })
            : _vm.config.type === "password"
            ? _c("form-row-password", {
                class: _vm.styleClass,
                attrs: {
                  config: _vm.config,
                  disabled: _vm.disabled,
                  error: _vm.hasError,
                  "error-messages": _vm.msgs,
                  name: _vm.name,
                  required: _vm.required,
                  rules: _vm.rules,
                },
                on: { "update:error": _vm.updateError },
                model: {
                  value: _vm.viewValue,
                  callback: function ($$v) {
                    _vm.viewValue = $$v
                  },
                  expression: "viewValue",
                },
              })
            : _vm.config.type === "html"
            ? _c("form-row-html", {
                class: _vm.styleClass,
                attrs: {
                  id: _vm.name,
                  error: _vm.hasError,
                  "error-messages": _vm.msgs,
                  "field-type": _vm.config.fieldType,
                  hint: _vm.config.description,
                  label: _vm.config.label,
                  name: _vm.name,
                },
                model: {
                  value: _vm.viewValue,
                  callback: function ($$v) {
                    _vm.viewValue = $$v
                  },
                  expression: "viewValue",
                },
              })
            : _vm.config.type === "collection"
            ? _c("form-row-collection", {
                attrs: { id: _vm.name, config: _vm.config },
                on: { "update:error": _vm.updateError },
                model: {
                  value: _vm.viewValue,
                  callback: function ($$v) {
                    _vm.viewValue = $$v
                  },
                  expression: "viewValue",
                },
              })
            : _vm.config.type === "colorpicker"
            ? _c("form-row-color-picker", {
                attrs: {
                  id: _vm.name,
                  name: _vm.name,
                  config: _vm.config,
                  "has-error": _vm.hasError,
                  msgs: _vm.msgs,
                  required: _vm.required,
                  rules: _vm.rules,
                  disabled: _vm.disabled,
                  mode: _vm.config.mode || "hex",
                  "hide-inputs": _vm.config.hideInputs,
                  "hide-canvas": _vm.config.hideCanvas,
                  "show-swatches": _vm.config.showSwatches,
                },
                on: { "update:error": _vm.updateError },
                model: {
                  value: _vm.viewValue,
                  callback: function ($$v) {
                    _vm.viewValue = $$v
                  },
                  expression: "viewValue",
                },
              })
            : _vm.config.type === "textarea"
            ? _c("form-row-textarea", {
                attrs: {
                  id: _vm.name,
                  name: _vm.name,
                  config: _vm.config,
                  error: _vm.hasError,
                  "error-messages": _vm.msgs,
                  required: _vm.required,
                  rules: _vm.rules,
                  disabled: _vm.disabled,
                },
                on: { "update:error": _vm.updateError },
                model: {
                  value: _vm.viewValue,
                  callback: function ($$v) {
                    _vm.viewValue = $$v
                  },
                  expression: "viewValue",
                },
              })
            : _vm.config.type === "number"
            ? _c("form-row-number", {
                attrs: {
                  id: _vm.name,
                  name: _vm.name,
                  config: _vm.config,
                  error: _vm.hasError,
                  "error-messages": _vm.msgs,
                  required: _vm.required,
                  rules: _vm.rules,
                  disabled: _vm.disabled,
                },
                on: { "update:error": _vm.updateError },
                model: {
                  value: _vm.viewValue,
                  callback: function ($$v) {
                    _vm.viewValue = $$v
                  },
                  expression: "viewValue",
                },
              })
            : _c("form-row-text", {
                attrs: {
                  id: _vm.name,
                  name: _vm.name,
                  config: _vm.config,
                  error: _vm.hasError,
                  "error-messages": _vm.msgs,
                  required: _vm.required,
                  rules: _vm.rules,
                  disabled: _vm.disabled,
                },
                on: { "update:error": _vm.updateError },
                model: {
                  value: _vm.viewValue,
                  callback: function ($$v) {
                    _vm.viewValue = $$v
                  },
                  expression: "viewValue",
                },
              }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }