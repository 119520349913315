(function($R)
{
    $R.lang['nl'] = {
        "format": "Format",
        "image": "Afbeelding",
        "file": "Bestand",
        "link": "Link",
        "bold": "Vet",
        "italic": "Cursief",
        "deleted": "Doorstreept",
        "underline": "Onderstreept",
        "superscript": "Superscript",
        "subscript": "Subscript",
        "bold-abbr": "B",
        "italic-abbr": "I",
        "deleted-abbr": "S",
        "underline-abbr": "U",
        "superscript-abbr": "Sup",
        "subscript-abbr": "Sub",
        "lists": "Lijsten",
        "link-insert": "Link invoegen",
        "link-edit": "Link Bewerken",
        "link-in-new-tab": "Open link in nieuw tabblad",
        "unlink": "Ontkoppelen",
        "cancel": "Annuleren",
        "close": "Afsluiten",
        "insert": "Invoegen",
        "save": "Opslaan",
        "delete": "Verwijder",
        "text": "Tekst",
        "edit": "Bewerken",
        "title": "Titel",
        "paragraph": "Normale tekst",
        "quote": "Citaat",
        "code": "Code",
        "heading1": "Koptekst 1",
        "heading2": "Koptekst 2",
        "heading3": "Koptekst 3",
        "heading4": "Koptekst 4",
        "heading5": "Koptekst 5",
        "heading6": "Koptekst 6",
        "filename": "Bestandsnaam",
        "optional": "Optioneel",
        "unorderedlist": "Ongeordende lijst",
        "orderedlist": "Geordende lijst",
        "outdent": "Uitspringen",
        "indent": "Inspringen",
        "horizontalrule": "Streep",
        "upload": "Upload",
        "upload-label": "Drop files here or click to upload",
        "upload-change-label": "Drop a new image to change",
        "accessibility-help-label": "RTF editor",
        "caption": "Caption",
        "bulletslist": "Bullets",
        "numberslist": "Numbers",
        "image-position": "Position",
        "none": "None",
        "left": "Left",
        "right": "Right",
        "center": "Center",
        "undo": "Undo",
        "redo": "Redo"
    };
})(Redactor);