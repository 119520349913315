export default {
    "$vuetify.badge": "Abzeichen",
    "$vuetify.close": "Schließen",
    "$vuetify.dataIterator.noResultsText": "Keine Elemente gefunden",
    "$vuetify.dataIterator.loadingText": "Lade Elemente...",
    "$vuetify.dataTable.itemsPerPageText": "Zeilen pro Seite:",
    "$vuetify.dataTable.ariaLabel.sortDescending": "Absteigend sortiert.",
    "$vuetify.dataTable.ariaLabel.sortAscending": "Aufsteigend sortiert.",
    "$vuetify.dataTable.ariaLabel.sortNone": "Nicht sortiert.",
    "$vuetify.dataTable.ariaLabel.activateNone": "Aktivieren um Sortierung zu entfernen.",
    "$vuetify.dataTable.ariaLabel.activateDescending": "Aktivieren um absteigend zu sortieren.",
    "$vuetify.dataTable.ariaLabel.activateAscending": "Aktivieren um aufsteigend zu sortieren.",
    "$vuetify.dataTable.sortBy": "Sortiere nach",
    "$vuetify.dataFooter.itemsPerPageText": "Elemente pro Seite:",
    "$vuetify.dataFooter.itemsPerPageAll": "Alle",
    "$vuetify.dataFooter.nextPage": "Nächste Seite",
    "$vuetify.dataFooter.prevPage": "Vorherige Seite",
    "$vuetify.dataFooter.firstPage": "Erste Seite",
    "$vuetify.dataFooter.lastPage": "Letzte Seite",
    "$vuetify.dataFooter.pageText": "{0}-{1} von {2}",
    "$vuetify.datePicker.itemsSelected": "{0} ausgewählt",
    "$vuetify.datePicker.nextMonthAriaLabel": "Nächsten Monat",
    "$vuetify.datePicker.nextYearAriaLabel": "Nächstes Jahr",
    "$vuetify.datePicker.prevMonthAriaLabel": "Vorheriger Monat",
    "$vuetify.datePicker.prevYearAriaLabel": "Vorheriges Jahr",
    "$vuetify.noDataText": "Keine Daten vorhanden",
    "$vuetify.carousel.prev": "Vorheriges Bild",
    "$vuetify.carousel.next": "Nächstes Bild",
    "$vuetify.carousel.ariaLabel.delimiter": "Element {0} von {1}",
    "$vuetify.calendar.moreEvents": "{0} mehr",
    "$vuetify.input.clear": "Clear {0}",
    "$vuetify.input.prependAction": "{0} prepended action",
    "$vuetify.input.appendAction": "{0} appended action",
    "$vuetify.fileInput.counter": "{0} Dateien",
    "$vuetify.fileInput.counterSize": "{0} Dateien ({1} gesamt)",
    "$vuetify.timePicker.am": "AM",
    "$vuetify.timePicker.pm": "PM",
    "$vuetify.pagination.ariaLabel.wrapper": "Seitennavigation",
    "$vuetify.pagination.ariaLabel.next": "Nächste Seite",
    "$vuetify.pagination.ariaLabel.previous": "Vorherige Seite",
    "$vuetify.pagination.ariaLabel.page": "Gehe zu Seite {0}",
    "$vuetify.pagination.ariaLabel.currentPage": "Aktuelle Seite, Seite {0}",
    "$vuetify.rating.ariaLabel.icon": "Rating {0} of {1}",
    "$vuetify.loading": "Loading..."
};