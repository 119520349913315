<template>
    <v-dialog v-if="!disabled" ref="timepicker" v-model="modal" :return-value.sync="time" persistent width="290px">
        <template #activator="{ on }">
            <v-text-field
                :id="id"
                v-model="viewValue"
                filled
                :disabled="disabled"
                :error="!valid"
                :error-messages="errorBucket"
                :label="config.label"
                :name="name"
                :required="required"
                :rules="rules"
                autocomplete="off"
                clearable
                prepend-inner-icon="$event"
                readonly
                v-on="on"
            />
        </template>
        <v-time-picker v-model="time" :allowed-minutes="allowedMinutes" color="red darken-3" :format="timeFormat">
            <v-spacer />
            <v-btn color="primary" text @click="modal = false">
                {{ $lumui.i18n.t('lumui.form.time.close') }}
            </v-btn>
            <v-btn color="primary" text @click="save">
                {{ $lumui.i18n.t('lumui.form.time.save') }}
            </v-btn>
        </v-time-picker>
    </v-dialog>
    <v-text-field
        v-else
        :id="name"
        v-model="viewValue"
        :disabled="disabled"
        :label="config.label"
        :name="name"
        autocomplete="off"
        prepend-inner-icon="$event"
        readonly
    />
</template>

<script>
import {VBtn, VDialog, VSpacer, VTextField, VTimePicker} from 'vuetify/lib';
import Validatable from 'vuetify/lib/mixins/validatable';
import FormatterFactory from '../lib/formatter';
import {mapGetters} from "vuex";
import moment from "moment";

/**
 * ## Config
 *
 * | key                    | type                       | required | default    | description |
 * |------------------------|----------------------------|----------|------------|-------------|
 * | type                   | `String`                   | yes      |            | field type  |
 * | label                  | `String`, `false`          | no       | `false`    | fields label |
 * | class                  | `String`                   | no       | `null`     | css class for custom styling |
 * | required               | `Boolean`, `eval(String)`  | no       | `false`    | field is required.|
 * | disabled               | `Boolean`, `eval(String)`  | no       | `false`    | field is disabled.|
 * | visible                | `Boolean`, `eval(String)`  | no       | `false`    | field is rendered.  |
 */
export default {
    name: "FormRowTime",
    components: {VDialog, VTextField, VTimePicker, VBtn, VSpacer},
    mixins: [
        Validatable
    ],
    props: {
        value: {
            type: String,
            default: null
        },
        /** element config */
        config: {
            type: Object,
            default: () => {
            }
        },
        /** element name */
        name: {
            type: String,
            default: ""
        },
        /** element id */
        id: {
            type: String,
            required: true
        },
        /** field is required */
        required: {
            type: Boolean,
            default: false,
        },
        /** field is disabled */
        disabled: {
            type: Boolean,
            default: false
        },
        /** validation rules */
        rules: {
            type: Array,
            default: () => [],
            required: false
        }
    },
    data() {
        return {
            modal: false,
            time: null,
            viewValue: null,
            formatter: null
        }
    },
    computed: {
        ...mapGetters('lumui/i18n', ['locale']),
        timeFormat() {
            return moment.localeData(this.locale).longDateFormat('LT').indexOf("A") >= 0 ? "ampm" : "24hr";
        }
    },
    watch: {
        hasError(val) {
            if (this.shouldValidate) {
                /** validation error detected */
                this.$emit('update:error', val)
            }
        },
        value(newValue) {
            if (newValue) {
                this.time = newValue;
            }
        },
        locale() {
            let formatter = this.getFormatter();
            this.viewValue = formatter.format(this.time);
        },
        time() {
            if (this.time) {
                let formatter = this.getFormatter();
                this.viewValue = formatter.format(this.time);
                /**
                 * user input
                 * @param {String} time
                 */
                this.$emit('input', this.time);
            } else {
                this.viewValue = null;
            }
        }
    },
    mounted() {
        this.$nextTick(() => {
            if (this.value) {
                let formatter = this.getFormatter();
                this.time = this.value;
                this.viewValue = formatter.format(this.value);
            } else {
                this.time = null;
                this.viewValue = null;
            }
            this.validate(true);
        });
    },
    methods: {
        /* @private */
        getFormatter() {
            if (!this.formatter) {
                this.formatter = FormatterFactory('Time', {});
            }
            return this.formatter;
        },
        /* @private */
        save() {
            this.$refs.timepicker.save(this.time);
        },
        /* @private */
        allowedMinutes(minute) {
            return minute % 5 === 0;
        }
    }
}
</script>
