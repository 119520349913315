<template>
    <asa-dialog>
        <v-card>
            <v-card-title>QR Scanner </v-card-title>
            <v-card-text>
                <asa-qr-scanner ref="scanner" @active="isActive = $event" @detected="log" />
                <v-alert v-if="data" type="info">{{ data }}</v-alert>
            </v-card-text>
            <v-btn @click="toggle">
                {{ isActive ? 'erkennung anhalten' : 'erkennung starten' }}
            </v-btn>
            <v-btn @click="$router.back()">Close</v-btn>
        </v-card>
    </asa-dialog>
</template>

<script>
import AsaQrScanner from "../../../src/component/AsaQrScanner";
import {VAlert, VBtn, VCard, VCardText, VCardTitle} from 'vuetify/lib';
import AsaDialog from "../../../src/component/AsaDialog";

export default {
    name: "Qr",
    components: {AsaDialog, AsaQrScanner, VCard, VCardText, VCardTitle, VBtn, VAlert},
    data() {
        return {
            show: true,
            isActive: false,
            data: null
        }
    },
    methods: {
        toggle() {
            if (this.isActive) {
                this.$refs.scanner.stop();
            } else {
                this.$refs.scanner.start();
            }
        },
        log(data) {
            this.data = data;
            console.log(data);
        }
    }
}
</script>
