<template>
    <v-switch
        filled
        v-model="viewValue"
        :disabled="disabled"
        :error="hasError"
        :error-messages="errorMessages"
        :label="config.label"
        :required="required"
        :rules="rules"
        :false-value="0"
        :true-value="1"
    />
</template>

<script>
import {VSwitch} from 'vuetify/lib';

/**
 * **Note:** this element return `false` if the switch is turned of and `1` when it is turned off.
 *
 * | key                    | type                       | required | default    | description |
 * |------------------------|----------------------------|----------|------------|-------------|
 * | type                   | `String`                   | yes      |            | field type  |
 * | label                  | `String`, `false`          | no       | `false`    | fields label |
 * | class                  | `String`                   | no       | `null`     | css class for custom styling |
 * | required               | `Boolean`, `eval(String)`  | no       | `false`    | field is required.|
 * | disabled               | `Boolean`, `eval(String)`  | no       | `false`    | field is disabled.|
 * | visible                | `Boolean`, `eval(String)`  | no       | `false`    | field is rendered.  |
 */
export default {
    name: "FormRowSwitch",
    components: {VSwitch},
    props: {
        value: {
            required: false,
            default: false
        },
        /** element config */
        config: {
            type: Object,
            required: true
        },
        /** element name (unused) */
        name: {
            type: String,
            required: false
        },
        /** input is required */
        required: {
            type: Boolean,
            required: false,
            default: false
        },
        /** validation errors exist */
        hasError: {
            type: Boolean,
            required: false,
            default: false
        },
        /** validation messages */
        errorMessages: {
            type: Array,
            required: false,
            default: () => []
        },
        /** validation rules */
        rules: {
            type: Array,
            required: false,
            default: () => []
        },
        /** field is disabled */
        disabled: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data() {
        return {
            viewValue: "",
        }
    },
    computed: {
        styleClass () {
            return this.config.class;
        },
        parsedValue () {
            if (typeof this.value === 'object'
                && this.value != null
                && this.value.id
            ) {
                return this.value.id
            } else if (!isNaN(+this.value) && Boolean(this.value)) {
                return +this.value
            }
            return this.value
        }
    },
    watch: {
        viewValue() {
            this.updateValue();
        },
        value() {
            this.viewValue = this.parsedValue
        }
    },
    methods: {
        /* @private */
        updateValue() {
            this.$emit('input', this.viewValue);
        },
    },
    mounted () {
        this.viewValue = this.parsedValue
    }
}
</script>

<style scoped>

</style>
