var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    _vm._l(_vm.childIndexes, function (i) {
      return _c("form-row-localized", {
        key: i,
        class: _vm.styleClass,
        attrs: {
          config: _vm.config,
          disabled: _vm.disabled,
          error: _vm.error,
          "error-messages": _vm.errorMessage,
          name: _vm.name,
          required: _vm.required,
          rules: _vm.rules,
        },
        on: {
          input: function ($event) {
            return _vm.onInputChange(i, $event)
          },
        },
        model: {
          value: _vm.extractedValues[i],
          callback: function ($$v) {
            _vm.$set(_vm.extractedValues, i, $$v)
          },
          expression: "extractedValues[i]",
        },
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }