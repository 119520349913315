<template>
    <div>
        <template v-for="(rowConfig, key) in config">
        <form-row
            v-if="isRow(key, rowConfig)"
            :key="key"
            v-model="value[key]"
            :config="rowConfig"
            :name="key"
            :values="value"
            @input="updateValue"
            @update:error="updateError"
        />
        </template>
    </div>
</template>

<script>
import FormRow from './FormRow'

/**
 * Renders the rows as defined in the config object
 */
export default {
    name: "FormRows",
    components: {
        FormRow: FormRow
    },
    props: {
        value: {
            type: Object,
            required: true,
        },
        /**
         * config object. Each key must define the config for one row, as per AsaFormRow.
         * Tabs and accordions are ignored, as is the errors key.
         */
        config: {
            type: Object,
            required: true
        },
    },
    methods: {
        isRow(key, element) {
            return key !== 'errors' && (!element.type || (element.type !== 'tab' && element.type !== 'accordion'));
        },
        updateValue() {
            this.$emit('input', this.value)
        },
        updateError(e) {
            this.$emit('update:error', e);
        }
    }
}
</script>
