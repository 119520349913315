var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-container",
        [
          _c(
            "v-row",
            [
              _c("v-col", [
                _c("h1", { staticClass: "text-h3" }, [
                  _vm._v("Datensäze aufklappen"),
                ]),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c("asa-table", {
                    attrs: {
                      data: _vm.tableData,
                      selected: _vm.selected,
                      "show-expand": "",
                    },
                    on: { select: _vm.onselect },
                    scopedSlots: _vm._u([
                      {
                        key: "expanded-item",
                        fn: function (props) {
                          return [
                            _vm._v(
                              "\n                        Expantion: " +
                                _vm._s(props) +
                                "\n                    "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "primary",
                        disabled: _vm.selected.length === 0,
                      },
                      on: {
                        click: function ($event) {
                          _vm.selected = []
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n                    Alle abwählen\n                "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("v-divider", { staticClass: "my-6" }),
                  _vm._v(" "),
                  _c("h4", [_vm._v("Gewählte Datensätze")]),
                  _vm._v(" "),
                  _c("p", { staticClass: "body-2" }, [
                    _vm._v(_vm._s(_vm.selected)),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }