<template>
    <div v-if="config" class="v-input v-label--active v-input--is-label-active theme-light mt-2">
        <div class="v-input__control">
            <label :for="name + '-ac'" class="v-label v-label--active theme--light ordered-choice-label">
                {{ config.label }}
            </label>
            <v-list dense flat>
                <div ref="dnd">
                    <v-list-item v-for="item in selectedItems" :key="item.value" class="list-item">
                        <v-list-item-content>
                            <span class="list-item-handle mr-2">::</span> {{ striphtml(item.label) }}
                        </v-list-item-content>
                        <v-list-item-action>
                            <v-btn color="grey lighten-1" icon small @click="remove(item.value)">
                                <v-icon>$delete</v-icon>
                            </v-btn>
                        </v-list-item-action>
                    </v-list-item>
                </div>
                <v-list-item v-if="selectedItems.length === 0" class="list-no-items">
                    <v-list-item-content>
                        <i class="text--secondary">{{ $lumui.i18n.t('lumui.form.ordered_choice.no_data') }}</i>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item class="list-add-item">
                    <v-list-item-content>
                        <v-autocomplete
                            :id="name + '-ac'"
                            ref="ac"
                            v-model="acValue"
                            filled
                            :filter="filter"
                            :items="availableOptions"
                            :placeholder="$lumui.i18n.t('lumui.form.ordered_choice.select_placeholder')"
                            :search-input.sync="acSearch"
                            autocomplete="off"
                            dense
                            hide-details
                            prepend-inner-icon="add"
                            style="font-size: 0.8125rem;"
                            @change="selected"
                        >
                            <template slot="selection" slot-scope="{ item }">
                                {{ striphtml(item.label) }}
                            </template>
                            <template slot="item" slot-scope="{ item }">
                                {{ striphtml(item.label) }}
                            </template>
                            <template slot="no-data">
                                <div class="mx-3 text--disabled">
                                    {{ $lumui.i18n.t('lumui.form.ordered_choice.no_data') }}
                                </div>
                            </template>
                        </v-autocomplete>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </div>
    </div>
</template>

<script>
import Sortable from "sortablejs";
import {VAutocomplete, VBtn, VIcon, VList, VListItem, VListItemAction, VListItemContent} from 'vuetify/lib';

/**
 * Input element for ordered multi select choices.
 *
 * #### Config
 *
 * | key                    | type                       | required | default    | description |
 * |------------------------|----------------------------|----------|------------|-------------|
 * | type                   | `String`                   | yes      |            | field type  |
 * | label                  | `String`, `false`          | no       | `false`    | fields label |
 * | required               | `Boolean`, `eval(String)`  | no       | `false`    | field is required.|
 * | disabled               | `Boolean`, `eval(String)`  | no       | `false`    | field is disabled.|
 * | visible                | `Boolean`, `eval(String)`  | no       | `false`    | field is rendered.  |
 * | multiOptions           | `Array`                    | yes      |            | Array of option objects: {value: "val", label: {de: "label"}}
 */
export default {
    name: "FormRowOrderedChoice",
    components: {VList, VListItem, VListItemContent, VListItemAction, VAutocomplete, VIcon, VBtn},
    props: {
        /** element config */
        config: {
            type: Object,
            required: true
        },
        /** element name */
        name: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            acValue: null,
            acSearch: null,
            items: []
        }
    },
    computed: {
        selectedItems() {
            let res = [];
            for (let i = 0; i < this.items.length; i++) {
                let val = this.items[i];
                for (let j = 0; j < this.config.multiOptions.length; j++) {
                    if (this.config.multiOptions[j].value == val) {
                        res.push(this.config.multiOptions[j]);
                        break;
                    }
                }
            }
            return res;
        },
        availableOptions() {
            let res = [];
            for (let i = 0; i < this.config.multiOptions.length; i++) {
                let item = this.config.multiOptions[i];
                if (!this.items.includes(item.value)) {
                    res.push(item);
                }
            }
            return res;
        },
    },
    watch: {
        config() {
            this.updateItems();
        }
    },
    mounted() {
        this.updateItems();
        const self = this;
        Sortable.create(this.$refs.dnd, {
            onStart(evt) {
                evt.item.style.border = '1px solid #BDBDBD';
            },
            onEnd(evt) {
                evt.item.style.border = null;
                this.items = self.move(self.items, evt.oldIndex, evt.newIndex);
                self.$emit('input', this.items);
            }
        })
    },
    methods: {
        updateItems() {
            if (this.config.value !== null && typeof this.config.value === 'object') {
                this.config.value = Object.values(this.config.value);
            }
            this.items = this.config.value || [];
        },
        striphtml(x) {
            let div = document.createElement("div");
            div.innerHTML = x;
            return div.textContent || div.innerText || "";
        },
        remove(value) {
            this.items.splice(this.items.indexOf(value), 1);
            this.$emit('input', this.items);
        },
        selected(n) {
            if (!this.items.includes(n)) {
                this.items.push(n);
                this.$emit('input', this.items);
            }
            this.$nextTick(() => {
                this.acSearch = '';
                this.acValue = null;
            });
        },

        filter(item, queryText) {
            return !this.items.includes(item.value) && item.label.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1
        },
        move(arr, oldIndex, newIndex) {
            while (oldIndex < 0) {
                oldIndex += arr.length;
            }
            while (newIndex < 0) {
                newIndex += arr.length;
            }
            if (newIndex >= arr.length) {
                let k = newIndex - arr.length + 1;
                while (k--) {
                    arr.push(undefined);
                }
            }
            arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
            return arr;
        }
    }
}
</script>

<style scoped>
.list-item-handle {
    width: 24px;
    display: inline-block;
    text-align: center;
    opacity: .5;
    flex: none;
}

.list-item {
    user-select: none;
    cursor: move;
}

.list-item:active {
    cursor: grabbing;
}

.list-no-items,
.list-item {
    border-top: 1px solid #EEEEEE;
    border-left: 1px solid #EEEEEE;
    border-right: 1px solid #EEEEEE;
}

.list-add-item {
    border: 1px solid #EEEEEE;
}

.list-add-item input {
    margin-top: 2px;
    font-size: 0.8125rem;
}

.ordered-choice-label {
    font-size: 0.8125rem;
    line-height: 12px;
    height: 12px;
    font-weight: 400;
}
</style>
