import Vue from 'vue';

export default class StatusFormatter {
    constructor(options) {
        if (options) {
            this.onlyText = options.onlyText || false;
            this.customIcon = options.customIcon || null
        } else {
            this.onlyText = false;
            this.customIcon = null
        }
    }

    requiresHTML() {
        return true;
    }

    format(value) {

        let icon = 'fas fa-check';
        let title = '';
        let color = 'green--text text--darken-4';

        if (value[0] === 0) {
            return ''
        }

        switch (value[0]) {
        case 1:
            icon = 'fas fa-check';
            title = Vue.prototype.$lumui.i18n.t('lumui.status.no_action_required');
            color = 'green--text text--darken-4';
            break;
        case 2:
            icon = 'fas fa-info-circle';
            title = Vue.prototype.$lumui.i18n.t('lumui.status.hint');
            color = 'blue--text text--darken-2';
            break;
        case 3:
            icon = 'fas fa-exclamation-triangle';
            title = Vue.prototype.$lumui.i18n.t('lumui.status.action_required_soon');
            color = 'orange--text text--darken-2';
            break;
        case 4:
            icon = 'fas fa-exclamation-triangle';
            title = Vue.prototype.$lumui.i18n.t('lumui.status.action_required_immediately');
            color = 'red--text';
            break;
        }

        if (this.customIcon !== null) {
            icon = this.customIcon
        }

        if (this.onlyText) {
            return title;
        } else {
            return '<i class="' + icon + ' ' + color + ' " title="' + title + '"></i>'
        }
    }
}
