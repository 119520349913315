import Vue from 'vue';

export default class LocaleFormatter {

    constructor() {
        this.languages = {
            de: Vue.prototype.$lumui.i18n.t('lumui.locale.lang.german'),
            en: Vue.prototype.$lumui.i18n.t('lumui.locale.lang.english'),
            it: Vue.prototype.$lumui.i18n.t('lumui.locale.lang.italian'),
            fr: Vue.prototype.$lumui.i18n.t('lumui.locale.lang.french'),
            hu: Vue.prototype.$lumui.i18n.t('lumui.locale.lang.hungarian'),
            fi: Vue.prototype.$lumui.i18n.t('lumui.locale.lang.finnish'),
            ee: Vue.prototype.$lumui.i18n.t('lumui.locale.lang.estonian'),
            lt: Vue.prototype.$lumui.i18n.t('lumui.locale.lang.lithuanian'),
            lv: Vue.prototype.$lumui.i18n.t('lumui.locale.lang.latvian')
        };
    }

    requiresHTML() {
        return false;
    }

    format(value) {
        let langCodes = Object.keys(this.languages);
        if (langCodes.indexOf(value) >= 0) {
            return this.languages[value];
        } else {
            return value;
        }
    }
}
