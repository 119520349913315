import {merge} from "webpack-merge";
import fi from './base/fi.js';

export default merge(fi, {
    "lumui.confirm.accept": "OK",
    "lumui.confirm.reject": "Keskeytä",
    "lumui.formatter.boolean.true": "Kyllä",
    "lumui.formatter.boolean.false": "Ei",
    "lumui.form.close": "Sulje",
    "lumui.form.cancel": "Keskeytä",
    "lumui.form.save": "tallenna",
    "lumui.lightbox.title": "Saatavilla olevat kuvat",
    "lumui.loading.message": "Tietoja ladataan",
    "lumui.qr_scanner.camera": "Kamera",
    "lumui.qr_scanner.mirror": "Peilikamera ",
    "lumui.qr_scanner.torch": "Kameran valo",
    "lumui.table.reset_filters": "nollaa",
    "lumui.table.close_filters": "Sulje suodatin",
    "lumui.table.details": "Yksityiskohtainen näkymä",
    "lumui.table.previous": "Edellinen tietue",
    "lumui.table.next": "Seuraava tietue",
    "lumui.table.no_data": "Tietoja ei löytynyt",
    "lumui.table.no_filter_results": "Hakusi ei tuottanut tuloksia",
    "lumui.table.filters_active": "Suodatetut tulokset",
    "lumui.table.rows_per_page": "Riviä sivua kohti",
    "lumui.table.page_text": "{0}-{1} of {2}",
    "lumui.table.btn.export": "Vientiluettelo",
    "lumui.table_action.menu": "Valikko",
    "lumui.table_columns.save": "Tallenna tiedosto",
    "lumui.form.row.invalid_email": "Anna oikea sähköpostiosoite.",
    "lumui.form.row.required": "Tämä kenttä on pakollinen",
    "lumui.form.autocomplete.selected_count": "{c} items selected",
    "lumui.form.autocomplete.select_all": "Valitse kaikki",
    "lumui.form.autocomplete.deselect_all": "Mitään ei valittu",
    "lumui.form.autocomplete.search_hint": "Kirjoita mitä etsit",
    "lumui.form.autocomplete.no_data": "Tietoja ei ole saatavilla",
    "lumui.form.autocomplete.nothing_selected": "Ei valittuja merkintöjä",
    "lumui.form.autocomplete.selected_items": "Valitut merkinnät",
    "lumui.form.date.close": "Sulje",
    "lumui.form.date.save": "OK",
    "lumui.form.date.after_max": "Valittu päivämäärä on sallitun enimmäispäivämäärän {max_date} jälkeen",
    "lumui.form.date.before_min": "Valittu päivämäärä on ennen sallittua vähimmäispäivää {min_date}",
    "lumui.form.file.drag": "Vedä tiedosto tähän tai valitse napsauttamalla",
    "lumui.form.file.error": "Lataus epäonnistui.",
    "lumui.form.file.extensions.accepted": "Sallitut tiedostotyypit: {types}",
    "lumui.form.file.extensions.all": "Kaikki tiedostotyypit sallittu",
    "lumui.form.file.maxSize": "Suurin sallittu tiedostokoko {size}.",
    "lumui.form.file.maxSizeError": "Tiedosto on suurempi kuin suurin sallittu koko {size}.",
    "lumui.form.file.previewError": "Tiedostolle {name} ei ole esikatselua saatavilla.",
    "lumui.form.file.uploading": "{count} tiedostoa ladataan…",
    "lumui.form.file.warning.removed_file": "Tiedostoa {name} ei ladattu. Tiedostotyyppi {type} ei ole sallittujen tyyppien {acceptedTypes} sisällä.",
    "lumui.form.file.select_file": "Valitse tiedosto",
    "lumui.form.file.unknown_size": "ei ole määritelty",
    "lumui.form.localized.copy_to_all": "Hyväksy kaikkien kielten syötteet",
    "lumui.form.ordered_choice.no_data": "merkintöjä ei ole saatavilla",
    "lumui.form.ordered_choice.select_placeholder": "valitse uusi merkintä",
    "lumui.form.password.repeat": "Toista salasana",
    "lumui.form.password.not_matching_error": "Molempien salasanojen on vastattava toisiaan.",
    "lumui.form.signature.clear": "Poista allekirjoitus",
    "lumui.form.signature.upload": "Lataa allekirjoitus",
    "lumui.form.time.close": "Sulje",
    "lumui.form.time.save": "OK",
    "lumui.form.password.requirements.title": "Salasanan on täytettävä seuraavat vaatimukset:",
    "lumui.form.password.requirements.lower_chars": "vähintään yksi pieni kirjain | vähintään {count} pientä kirjainta",
    "lumui.form.password.requirements.upper_chars": "vähintään yksi iso kirjain | vähintään {count} isoa kirjainta",
    "lumui.form.password.requirements.number_chars": "vähintään yksi numero | vähintään {count} numeroa",
    "lumui.form.password.requirements.special_chars": "vähintään yksi erikoismerkki | vähintään {count} erikoismerkkiä",
    "lumui.form.password.requirements.total_chars": "vähintään yksi merkki | vähintään {count} merkkiä",
    "lumui.locale.lang.german": "Saksa",
    "lumui.locale.lang.english": "Englannin",
    "lumui.locale.lang.italian": "Italialainen",
    "lumui.locale.lang.french": "Ranskalainen",
    "lumui.status.no_action_required": "ei vaadittua toimenpidettä",
    "lumui.status.hint": "Huomautus",
    "lumui.status.action_required_soon": "pikaista toimintaa vaaditaan!",
    "lumui.status.action_required_immediately": "tarvitaan välittömiä toimia!",
    "lumui.locale.de-DE": "German",
    "lumui.locale.de-CH": "German (Suisse)",
    "lumui.locale.fr-CH": "French (Suisse)",
    "lumui.locale.it-CH": "Italian (Suisse)",
    "lumui.locale.en-US": "English (USA)",
    "lumui.locale.en-GB": "English (GB)",
    "lumui.locale.fi-FI": "Finnish",
    "lumui.locale.et-EE": "Estonian",
    "lumui.locale.lt-LT": "Lithuanian",
    "lumui.locale.lv-LV": "Latvian",
    "lumui.locale.lang.finnish": "Suomalainen",
    "lumui.locale.lang.estonian": "Virolainen",
    "lumui.locale.lang.lithuanian": "Liettualainen",
    "lumui.locale.lang.latvian": "Latvialainen",
    "lumui.locale.lang.hungarian": "Unkarilainen",
});
