<template>
    <div>
        <v-toolbar>
            <v-toolbar-title>
                Hidden Columns
            </v-toolbar-title>
            <v-spacer />
            <v-toolbar-items>
                <v-btn tile icon large @click="showFilter = !showFilter"><v-icon>mdi-magnify</v-icon></v-btn>
            </v-toolbar-items>
        </v-toolbar>
        <asa-table :loading="false" :data="data" info-disabled :show-filter="showFilter"></asa-table>
    </div>
</template>

<script>
import AsaTable from "../../../src/component/AsaTable";
import {VToolbar, VToolbarTitle, VSpacer,  VToolbarItems, VBtn, VIcon} from 'vuetify/lib'

export default {
    name: "Hidden",
    components: {AsaTable, VToolbar, VToolbarTitle, VSpacer,  VToolbarItems, VBtn, VIcon},
    data() {
        return {
            showFilter: false,
            data: {
                columns: [
                    {
                        'key': 'a',
                        'label': 'A',
                        'type': 'text'
                    }, {
                        'key': 'b',
                        'label': 'B',
                        'hidden': true
                    }
                ],
                rows: [
                    {a: 'a1', b: 'b1'},
                    {a: 'a2', b: 'b2'},
                ],
                filter: [
                    {
                        'key': 'a',
                        'label': 'A',
                        'type': 'text'
                    }, {
                        'key': 'b',
                        'label': 'B',
                        'hidden': false
                    }
                ]
            }
        }
    }
}
</script>

<style scoped>

</style>
