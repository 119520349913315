<template>
    <v-autocomplete
        :id="'filter_' + config.key"
        v-model="config.value"
        :items="items"
        :label="typeof config.filter.select.label !== 'undefined' ? config.filter.select.label : config.label"
        :multiple="multiple"
        :clearable="clearable"
        :menu-props="{offsetY: true}"
        item-text="text"
        item-value="value"
        hide-details
    >
        <template #item="{ item, on, attrs }">
            <v-list-item :input-value="attrs.inputValue && !multiple" v-on="on">
                <v-list-item-action v-if="multiple">
                    <v-simple-checkbox :value="attrs.inputValue" />
                </v-list-item-action>
                <v-list-item-content>
                    {{ item.text }}
                </v-list-item-content>
                <v-list-item-avatar v-if="item.icon">
                    <v-icon :color="item.color ? item.color : 'gray' " small style="vertical-align: middle">
                        {{ item.icon }}
                    </v-icon>
                </v-list-item-avatar>
            </v-list-item>
        </template>

        <template #selection="{ item }">
            <v-chip v-if="multiple" dense small>
                <v-avatar v-if="item.icon">
                    <v-icon :color="item.color ? item.color : 'gray' " small>
                        {{ item.icon }}
                    </v-icon>
                </v-avatar>
                <span>
                    {{ item.text }}
                </span>
            </v-chip>
            <span v-else>
                <v-icon v-if="item.icon" :color="item.color ? item.color : 'grey' " small>{{ item.icon }}</v-icon>
                {{ item.text }}
            </span>
        </template>
    </v-autocomplete>
</template>

<script>
import {
    VAutocomplete,
    VAvatar,
    VChip,
    VIcon,
    VListItem,
    VListItemAction,
    VListItemAvatar,
    VListItemContent,
    VSimpleCheckbox
} from 'vuetify/lib';

/**
 * Renders a select filter
 * @internal
 */
export default {
    name: "AsaTableFilterSelect",
    components: {VSimpleCheckbox, VAutocomplete, VListItem, VListItemAction, VIcon, VListItemContent, VListItemAvatar, VChip, VAvatar},
    props: {
        config: {
            type: Object,
            required: true,
        }
    },
    computed: {
        clearable() {
            if (Object.hasOwn(this.config,'clearable')) {
                return !!this.config.clearable;
            } else {
                return true;
            }
        },
        items() {
            let rtn = [];
            Object.keys(this.config.filter.select.options).forEach((key) => {
                if (this.config.filter.select.options[key] !== '') {
                    if (typeof this.config.filter.select.options[key] == "object") {
                        let tmp = {
                            value: key
                        };
                        Object.keys(this.config.filter.select.options[key]).forEach((objKey) => {
                            tmp[objKey] = this.config.filter.select.options[key][objKey];
                        });
                        rtn.push(tmp);
                    } else {
                        rtn.push({
                            text: this.config.filter.select.options[key],
                            value: key
                        })
                    }
                }
            });

            return rtn;
        },
        multiple() {
            return this.config.filter.select.multiple ? this.config.filter.select.multiple : false
        }
    }

}
</script>
