<template>
    <div class="input-checkboxes">
        <v-input
            ref="inputField"
            filled
            :disabled="disabled"
            :error="hasError"
            :error-messages="errorMessages"
            :label="config.label"
            :name="name"
            :required="required"
            :rules="rules"
            class="input--treenode"
        >
            <v-treeview
                ref="treeview"
                :item-text="itemText"
                :items="treeData"
                :selection-type="selectionType"
                :value="viewValue"
                class="tree"
                item-key="path"
                return-object
                selectable
                transition
                @input="select"
            >
                <template #prepend="{ item }">
                    <asa-tree-icons :node="item" />
                </template>
            </v-treeview>
        </v-input>
    </div>
</template>

<script>
import {VInput, VTreeview} from 'vuetify/lib';
import Validatable from 'vuetify/lib/mixins/validatable';
import AsaTreeIcons from "./AsaTreeIcons";

/**
 * **Note:** this is an Asa specific component, that makes assumptions about
 * a vuex store being available and its structure. It probably won't work outside
 * the ASA context.
 *
 * #### Config
 *
 * | key                    | type                       | required | default    | description |
 * |------------------------|----------------------------|----------|------------|-------------|
 * | type                   | `String`                   | yes      |            | field type  |
 * | label                  | `String`, `false`          | no       | `false`    | fields label |
 * | class                  | `String`                   | no       | `null`     | css class for custom styling |
 * | required               | `Boolean`, `eval(String)`  | no       | `false`    | field is required.|
 * | disabled               | `Boolean`, `eval(String)`  | no       | `false`    | field is disabled.|
 * | visible                | `Boolean`, `eval(String)`  | no       | `false`    | field is rendered.  |
 * | selectionType          | `"leaf"`, `"independent"`  | no       | `"leaf"`   | selection model |
 * | multiple               | `Boolean`                  | no       | `false`    | allow multiple selections |
 *
 */
export default {
    name: "FormRowTreeNode",
    components: { AsaTreeIcons, VInput, VTreeview },
    mixins: [
        Validatable
    ],
    props: {
        /** seletion model */
        selectionType: {
            type: String,
            default: 'leaf'
        },
        value: {
            type: [String, Array, Number, Object],
            default: null
        },
        /** element configuration */
        config: {
            type: Object,
            required: true
        },
        /** element name */
        name: {
            type: String,
            required: true
        },
        /** validation errors exist */
        error: {
            type: Boolean,
            default: false,
            required: false
        },
        /** validation messages */
        errorMessages: {
            type: Array,
            default: () => [],
            required: false
        },
        /** field is required */
        required: {
            type: Boolean,
            default: false,
            required: false
        },
        /** validation rules */
        rules: {
            type: Array,
            default: () => [],
            required: false
        },
        /** field is disabled */
        disabled: {
            type: Boolean,
            default: false,
            required: false
        },
        /** multiple selection enabled */
        multiple: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            viewValue: [],
            treeData: [],
            nodeValues: []
        };
    },
    computed: {
        locale() {
            return this.$store.getters['lumui/i18n/mappedLocale'];
        },
        itemText() {
            return 'name.' + this.locale;
        },
        entryPoints() {
            return this.$store.getters.getEntryPoints;
        }
    },
    watch: {
        hasError(val) {
            if (this.shouldValidate) {
                /** validation error detected */
                this.$emit('update:error', val);
            }
        }
    },
    mounted: async function() {
        try {
            await this.updateData();
            this.initValues();
        } catch (err) {
            console.error(err);
        }
    },
    methods: {
        /** @private */
        async updateData() {
            //this.open = this.openNodes
            this.loading = true;
            this.treeData = await (this.$call('api.core.tree.tree.getTreeData', {
                module: this.module,
                exclude: ['asset', 'smartfolder']
            }));
            this.loading = false;
        },
        /** @private */
        select(items) {
            // items.forEach(node => {
            //     if(values.filter(n => node.path.indexOf(n.path) >= 0).length === 0) {
            //         values.push(node)
            //     }
            // })*/
            const values = items.filter(node => {
                const allowed = this.allowedInPath(node.path);
                if (!allowed) {
                    this.$store.dispatch('toast/warning', this.$lumui.i18n.t('Sie haben keine Berechtigung diesen Knoten auszuwählen'));
                }
                return allowed;
            });
            this.viewValue = values;

            /**
             * user input
             */
            this.$emit('input', values);
            this.$nextTick(() => {
                this.$refs.inputField.validate();
                this.validate(true);
            });
        },
        /* @private */
        findTreeNode(path, treeNode) {
            if (treeNode.path === path) {
                return treeNode;
            } else if (treeNode.children) {
                let n = null;
                treeNode.children.forEach(node => {
                    if (node.path === path) {
                        n = node;
                    } else if (path.indexOf(node.path) >= 0 && node.children) {
                        n = this.findTreeNode(path, node);
                    }
                });
                return n;
            }
            return null;
        },
        /* @private */
        initValues() {
            if (this.value && this.treeData.length > 0) {
                let values = [];
                this.value.forEach(path => {
                    if (Object.hasOwn(path, 'path')) {
                        path = path.path;
                    }
                    let v = this.findTreeNode(path, this.treeData[0]);
                    values.push(v);
                });
                this.viewValue = values;
            }
        },
        /* @private */
        allowedInPath(path) {
            let allowed = false;
            this.entryPoints.forEach((entryPoint) => {
                allowed |= path.indexOf(entryPoint) === 0;
            });
            return allowed;
        }
    }
};
</script>
