var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "input-checkboxes" },
    [
      _c(
        "v-input",
        {
          ref: "inputField",
          staticClass: "input--treenode",
          attrs: {
            filled: "",
            disabled: _vm.disabled,
            error: _vm.hasError,
            "error-messages": _vm.errorMessages,
            label: _vm.config.label,
            name: _vm.name,
            required: _vm.required,
            rules: _vm.rules,
          },
        },
        [
          _c("v-treeview", {
            ref: "treeview",
            staticClass: "tree",
            attrs: {
              "item-text": _vm.itemText,
              items: _vm.treeData,
              "selection-type": _vm.selectionType,
              value: _vm.viewValue,
              "item-key": "path",
              "return-object": "",
              selectable: "",
              transition: "",
            },
            on: { input: _vm.select },
            scopedSlots: _vm._u([
              {
                key: "prepend",
                fn: function ({ item }) {
                  return [_c("asa-tree-icons", { attrs: { node: item } })]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }