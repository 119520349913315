var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "input-checkboxes" },
    [
      _vm.config.label
        ? _c("div", { staticClass: "label" }, [
            _vm._v("\n        " + _vm._s(_vm.config.label) + "\n    "),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm._l(_vm.config.multiOptions, function (item, key) {
        return [
          _c("v-checkbox", {
            key: key,
            attrs: {
              filled: "",
              disabled: _vm.disabled,
              error: _vm.hasError,
              "error-messages": _vm.msgs,
              label: _vm.getLabel(item),
              required: _vm.required,
              rules: _vm.rules,
              value: _vm.getValue(item),
            },
            model: {
              value: _vm.viewValue,
              callback: function ($$v) {
                _vm.viewValue = $$v
              },
              expression: "viewValue",
            },
          }),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }