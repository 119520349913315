var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.hasAccordion
    ? _c(
        "v-expansion-panels",
        {
          attrs: { accordion: "", focusable: "" },
          model: {
            value: _vm.activeAccordion,
            callback: function ($$v) {
              _vm.activeAccordion = $$v
            },
            expression: "activeAccordion",
          },
        },
        _vm._l(_vm.accordions, function (element, index) {
          return _c(
            "v-expansion-panel",
            { key: index },
            [
              _c(
                "v-expansion-panel-header",
                {
                  attrs: {
                    "disable-icon-rotate": element.hasOwnProperty("error"),
                  },
                  scopedSlots: _vm._u(
                    [
                      element.hasOwnProperty("error")
                        ? {
                            key: "actions",
                            fn: function () {
                              return [
                                _c("v-icon", { attrs: { color: "error" } }, [
                                  _vm._v(
                                    "\n                    $warning\n                "
                                  ),
                                ]),
                              ]
                            },
                            proxy: true,
                          }
                        : null,
                    ],
                    null,
                    true
                  ),
                },
                [
                  _vm._v(
                    "\n            " + _vm._s(element.label) + "\n            "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "v-expansion-panel-content",
                { attrs: { eager: "" } },
                [
                  _c("form-rows", {
                    attrs: { config: element.children, value: _vm.value },
                  }),
                ],
                1
              ),
            ],
            1
          )
        }),
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }