var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-input",
    {
      staticClass: "row-localized",
      attrs: {
        id: _vm.name,
        error: _vm.errors.length > 0,
        "error-messages": _vm.errors,
        label: _vm.config.label,
        name: _vm.name,
        "error-count": "3",
      },
    },
    [
      _c("asa-multi-lang", {
        attrs: {
          "current-locale": _vm.currentLocale,
          "available-locales": _vm.availableLocales,
        },
        on: { tabActivated: _vm.focusInput },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function ({ locale, localeKey }) {
              return [
                _vm.childType === "text"
                  ? _c("v-text-field", {
                      ref: "input_" + localeKey,
                      attrs: {
                        id: _vm.name + "-" + localeKey,
                        filled: "",
                        disabled: _vm.disabled,
                        name: _vm.name + "[" + localeKey + "]",
                        required: _vm.required,
                        rules: _vm.rules,
                        autocomplete: "off",
                        "hide-details": "",
                        type: "text",
                        lang: locale,
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "append",
                            fn: function () {
                              return [
                                _c(
                                  "v-icon",
                                  {
                                    attrs: {
                                      title: _vm.$lumui.i18n.t(
                                        "lumui.form.localized.copy_to_all"
                                      ),
                                    },
                                    on: { click: _vm.setAllToCurrent },
                                  },
                                  [
                                    _vm._v(
                                      "\n                        $setAllToCurrent\n                    "
                                    ),
                                  ]
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        true
                      ),
                      model: {
                        value: _vm.viewValue[localeKey],
                        callback: function ($$v) {
                          _vm.$set(_vm.viewValue, localeKey, $$v)
                        },
                        expression: "viewValue[localeKey]",
                      },
                    })
                  : _vm.childType === "textarea"
                  ? _c("v-textarea", {
                      ref: "input_" + localeKey,
                      attrs: {
                        id: _vm.name + "-" + localeKey,
                        filled: "",
                        disabled: _vm.disabled,
                        name: _vm.name + "[" + localeKey + "]",
                        required: _vm.required,
                        rules: _vm.rules,
                        "append-icon": "fa-equals",
                        "auto-grow": "",
                        autocomplete: "off",
                        "hide-details": "",
                        type: "text",
                        lang: locale,
                      },
                      on: { "click:append": _vm.setAllToCurrent },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "append",
                            fn: function () {
                              return [
                                _c(
                                  "v-icon",
                                  {
                                    attrs: {
                                      title: _vm.$lumui.i18n.t(
                                        "lumui.form.localized.copy_to_all"
                                      ),
                                    },
                                    on: { click: _vm.setAllToCurrent },
                                  },
                                  [
                                    _vm._v(
                                      "\n                        $setAllToCurrent\n                    "
                                    ),
                                  ]
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        true
                      ),
                      model: {
                        value: _vm.viewValue[localeKey],
                        callback: function ($$v) {
                          _vm.$set(_vm.viewValue, localeKey, $$v)
                        },
                        expression: "viewValue[localeKey]",
                      },
                    })
                  : _vm.childType === "wysiwyg"
                  ? _c("redactor", {
                      ref: "input_" + localeKey,
                      attrs: {
                        id: _vm.name + "-" + localeKey,
                        filled: "",
                        config: _vm.config.redactor || {},
                        disabled: _vm.disabled,
                        label: null,
                        name: _vm.name + "[" + localeKey + "]",
                        required: _vm.required,
                        rules: _vm.rules,
                        lang: locale,
                        "hide-details": "",
                      },
                      model: {
                        value: _vm.viewValue[localeKey],
                        callback: function ($$v) {
                          _vm.$set(_vm.viewValue, localeKey, $$v)
                        },
                        expression: "viewValue[localeKey]",
                      },
                    })
                  : _vm._e(),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }