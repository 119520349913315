var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("v-text-field", {
        attrs: {
          id: _vm.name,
          filled: "",
          disabled: _vm.disabled,
          error: _vm.error,
          "error-count": _vm.errorMessages.length,
          "error-messages": _vm.errorMessages,
          hint: _vm.config.description || "",
          label: _vm.config.label,
          name: _vm.name,
          required: _vm.required,
          rules: _vm.computedRules,
          autocomplete: _vm.config.autocomplete || "off",
          type: _vm.reveal ? "text" : "password",
          "hide-details": _vm.config.no_confirm ? false : "auto",
        },
        scopedSlots: _vm._u([
          {
            key: "append",
            fn: function () {
              return [
                _vm.reveal
                  ? _c(
                      "v-icon",
                      {
                        on: {
                          click: function ($event) {
                            _vm.reveal = false
                          },
                        },
                      },
                      [_vm._v("\n                $hide\n            ")]
                    )
                  : _c(
                      "v-icon",
                      {
                        on: {
                          click: function ($event) {
                            _vm.reveal = true
                          },
                        },
                      },
                      [_vm._v("\n                $reveal\n            ")]
                    ),
                _vm._v(" "),
                _vm.showGenerator
                  ? _c(
                      "v-icon",
                      {
                        staticClass: "ml-1",
                        on: { click: _vm.generatePassword },
                      },
                      [_vm._v("\n                $randomize\n            ")]
                    )
                  : _vm._e(),
              ]
            },
            proxy: true,
          },
        ]),
        model: {
          value: _vm.viewValue,
          callback: function ($$v) {
            _vm.viewValue = $$v
          },
          expression: "viewValue",
        },
      }),
      _vm._v(" "),
      _vm.lowerChars +
        _vm.upperChars +
        _vm.numberChars +
        _vm.specialChars +
        _vm.totalChars >
      0
        ? _c("div", { staticClass: "ml-1 my-2 text-caption" }, [
            _vm._v(
              "\n        " +
                _vm._s(
                  _vm.$lumui.i18n.t("lumui.form.password.requirements.title")
                ) +
                "\n        "
            ),
            _c("ul", { staticClass: "p0" }, [
              _vm.lowerChars > 0
                ? _c(
                    "li",
                    { class: _vm.lowerCharsValid ? "valid" : "invalid" },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(
                            _vm.$lumui.i18n.tc(
                              "lumui.form.password.requirements.lower_chars",
                              _vm.lowerChars,
                              { count: _vm.lowerChars }
                            )
                          ) +
                          "\n            "
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.upperChars > 0
                ? _c(
                    "li",
                    { class: _vm.upperCharsValid ? "valid" : "invalid" },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(
                            _vm.$lumui.i18n.tc(
                              "lumui.form.password.requirements.upper_chars",
                              _vm.upperChars,
                              { count: _vm.upperChars }
                            )
                          ) +
                          "\n            "
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.numberChars > 0
                ? _c(
                    "li",
                    { class: _vm.numberCharsValid ? "valid" : "invalid" },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(
                            _vm.$lumui.i18n.tc(
                              "lumui.form.password.requirements.number_chars",
                              _vm.numberChars,
                              { count: _vm.numberChars }
                            )
                          ) +
                          "\n            "
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.specialChars > 0
                ? _c(
                    "li",
                    { class: _vm.specialCharsValid ? "valid" : "invalid" },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(
                            _vm.$lumui.i18n.tc(
                              "lumui.form.password.requirements.special_chars",
                              _vm.specialChars,
                              { count: _vm.specialChars }
                            )
                          ) +
                          "\n            "
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.totalChars > 0
                ? _c(
                    "li",
                    { class: _vm.totalCharsValid ? "valid" : "invalid" },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(
                            _vm.$lumui.i18n.tc(
                              "lumui.form.password.requirements.total_chars",
                              _vm.totalChars,
                              { count: _vm.totalChars }
                            )
                          ) +
                          "\n            "
                      ),
                    ]
                  )
                : _vm._e(),
            ]),
          ])
        : _c("div", { staticClass: "ma-7" }),
      _vm._v(" "),
      !_vm.config.no_confirm
        ? _c("v-text-field", {
            ref: "confirm",
            attrs: {
              id: _vm.name + "_confirm",
              filled: "",
              disabled: _vm.disabled,
              label: _vm.$lumui.i18n.t("lumui.form.password.repeat"),
              name: _vm.name + "_confirm",
              required: _vm.viewValue !== "",
              rules: [
                (v) =>
                  !_vm.viewValue ||
                  v === _vm.viewValue ||
                  _vm.$lumui.i18n.t("lumui.form.password.not_matching_error"),
              ],
              autocomplete: "off",
              type: _vm.reveal ? "text" : "password",
            },
            model: {
              value: _vm.confirm,
              callback: function ($$v) {
                _vm.confirm = $$v
              },
              expression: "confirm",
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }