import moment from "moment";

export default class DateFormatter {
    constructor(settings)
    {
        this.formatter = new Intl.DateTimeFormat(settings.locale, {
            hour: '2-digit',
            minute: '2-digit',
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
        });
    }

    requiresHTML() {
        return false;
    }
    format(value) {
        if(!value || typeof value === 'undefined') {
            return '';
        }
        if(!(value instanceof Date)) {
            let match = value.match(/(?<year>\d{4})-(?<month>\d{2})-(?<day>\d{2})[ T](?<hour>\d{2}):(?<minute>\d{2})(:(?<second>\d{2})Z?)?/);

            value = new Date(
                match.groups.year,
                match.groups.month - 1,
                match.groups.day,
                match.groups.hour,
                match.groups.minute,
                match.groups.second || 0
            );
        }
        const m = moment(value);
        return m.format("L") + " " + m.format("LT")
    }
}
