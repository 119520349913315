var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-container",
        [
          _c(
            "v-card",
            [
              _c(
                "v-toolbar",
                [
                  _c("v-toolbar-title", [
                    _vm._v(
                      "\n                    Filters & Persistent Filters\n                "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "v-toolbar-items",
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { tile: "", icon: "", large: "" },
                          on: {
                            click: function ($event) {
                              _vm.showFilter = !_vm.showFilter
                            },
                          },
                        },
                        [_c("v-icon", [_vm._v("$search")])],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-card-text",
                [
                  _c("asa-table", {
                    attrs: {
                      data: _vm.tableData,
                      "info-disabled": "",
                      "show-filter": _vm.showFilter,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }