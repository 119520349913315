(function($R)
{
    $R.lang['hu'] = {
        "format": "Formázás",
        "image": "Kép",
        "file": "Fájl",
        "link": "Hivatkozás",
        "bold": "Félkövér",
        "italic": "Dőlt",
        "deleted": "Áthúzott",
        "underline": "Aláhúzott",
        "superscript": "Felső index",
        "subscript": "Alsó index",
        "bold-abbr": "F",
        "italic-abbr": "D",
        "deleted-abbr": "H",
        "underline-abbr": "A",
        "superscript-abbr": "Sup",
        "subscript-abbr": "Sub",
        "lists": "Listák",
        "link-insert": "Hivatkozás beszúrása",
        "link-edit": "Hivatkozás módosítása",
        "link-in-new-tab": "Megnyitás új lapon",
        "unlink": "Hivatkozás törlése",
        "cancel": "Mégsem",
        "close": "Bezárás",
        "insert": "Beillesztés",
        "save": "Mentés",
        "delete": "Törlés",
        "text": "Szöveg",
        "edit": "Szerkesztés",
        "title": "Cím",
        "paragraph": "Normál szöveg",
        "quote": "Idézet",
        "code": "Forráskód",
        "heading1": "Címsor 1",
        "heading2": "Címsor 2",
        "heading3": "Címsor 3",
        "heading4": "Címsor 4",
        "heading5": "Címsor 5",
        "heading6": "Címsor 6",
        "filename": "Név",
        "optional": "választható",
        "unorderedlist": "Rendezett lista",
        "orderedlist": "Számozott lista",
        "outdent": "Behúzás csökkentése",
        "indent": "Behúzás növelése",
        "horizontalrule": "Vonal",
        "upload": "Feltöltés",
        "upload-label": "Át a fájlokat vagy kattintson ide, hogy kiválassza",
        "upload-change-label": "Drop a new image to change",
        "accessibility-help-label": "Szövegszerkesztő",
        "caption": "Felirat",
        "bulletslist": "Golyók",
        "numberslist": "Számok",
        "image-position": "Pozíció",
        "none": "Egyik sem",
        "left": "Balra",
        "right": "Jobb",
        "center": "Középre",
        "undo": "Kibont",
        "redo": "Újra"
    };
})(Redactor);