(function($R)
{
	$R.lang['cs'] = {
		"format": "Formát",
		"image": "Obrázek",
		"file": "Soubor",
		"link": "Odkaz",
		"bold": "Tučné",
		"italic": "Kurzíva",
		"deleted": "Přeškrtnuté",
		"underline": "Podtržené",
	    "superscript": "Horní index",
	    "subscript": "Dolní index",
		"bold-abbr": "B",
		"italic-abbr": "I",
		"deleted-abbr": "S",
		"underline-abbr": "U",
	    "superscript-abbr": "Sup",
	    "subscript-abbr": "Sub",
		"lists": "Seznamy",
		"link_insert": "Vložit odkaz ...",
		"link_edit": "Upravit odkaz",
		"link_new_tab": "Otevírat odkaz v novém okně",
		"unlink": "Odstranit odkaz",
		"cancel": "Zrušit",
		"close": "Zavřít",
		"insert": "Vložit",
		"save": "Uložit",
		"delete": "Smazat",
		"text": "Text",
		"edit": "Upravit",
		"title": "Titulek",
		"paragraph": "Odstavec",
		"quote": "Citace",
		"code": "Kód",
		"header1": "Nadpis 1",
		"header2": "Nadpis 2",
		"header3": "Nadpis 3",
		"header4": "Nadpis 4",
		"header5": "Nadpis 5",
		"header6": "Nadpis 6",
		"filename": "Název (volitelné)",
		"optional": "volitelný",
		"unorderedlist": "Seznam s odrážkami",
		"orderedlist": "Číslovaný seznam",
		"outdent": "Zmenšit odsazení",
		"indent": "Zvětšit odsazení",
		"horizontalrule": "Vodorovná čára",
	    "upload": "Nahrát",
		"upload-label": "Přesuňte sem soubory nebo klikněte pro výběr",
        "upload-change-label": "Drop a new image to change",
		"accessibility-help-label": "Rich textový editor",
		"caption": "Titulok",
		"bulletslist": "Odrážek",
		"numberslist": "Číslováníe",
		"image_position": "Zarovnání",
		"none": "Žádné",
		"left": "Vlevo",
		"right": "Vpravo",
		"center": "Center",
	    "undo": "Zpět",
	    "redo": "Obnovit"
    };
})(Redactor);