<template>
  <asa-form v-model="value" :loading="false" :config="formConf" :abort-btn="false" />
</template>

<script>
import AsaForm from "../../../src/component/AsaForm.vue";

export default {
  name: "FormColorpicker",
  components: {
    AsaForm
  },
  data() {
    return {
      formConf: {
        "picker1": {
          type: "colorpicker",
          label: "Color Picker w/o alpha",
          required: true,
          hideCanvas: true,
          order: 1,
        },
        "picker2": {
          type: "colorpicker",
          label: "Color Picker with alpha and inputs fix",
          order: 1,
          hideInputs: false,
          mode: "hexa",
          required: true,
          hideModeSwitch: true,
        },
        "picker3": {
          type: "colorpicker",
          label: "Color Picker complete",
          order: 1,
          hideCanvas: false,
          required: true,
          hideInputs: false
        }
      },
      value: {
        "picker1": "#FFB500",
        "picker2": "#FFB500FF",
        "picker3": "#FFB500FF",
      },
    }
  }
}
</script>
