<template>
    <div v-if="visible" :class="{'form-row': true, 'form-row-required': required}">
        <form-row-autocomplete
            v-if="config.type === 'select'"
            :id="name"
            v-model="viewValue"
            :class="styleClass"
            :config="config"
            :data-url="config.dataUrl ? config.dataUrl : null"
            :disabled="disabled"
            :error="hasError"
            :error-messages="msgs"
            :name="name"
            :required="required"
            :rules="rules"
            @update:error="updateError"
        />
        <form-row-ordered-choice
            v-else-if="config.type === 'orderedChoice'"
            :config="config"
            :disable="disabled"
            :name="name"
            :value="viewValue"
            @input="(e) => { viewValue = e; }"
            @update:error="updateError"
        />
        <input
            v-else-if="config.type === 'hidden'"
            :id="name"
            v-model="viewValue"
            :class="styleClass"
            :disabled="disabled"
            :name="name"
            type="hidden"
        />
        <form-row-date
            v-else-if="config.type === 'date'"
            :id="name"
            v-model="viewValue"
            :class="styleClass"
            :config="config"
            :disabled="disabled"
            :error="hasError"
            :error-messages="msgs"
            :name="name"
            :required="required"
            :rules="rules"
            @update:error="updateError"
        />
        <form-row-time
            v-else-if="config.type === 'time'"
            :id="name"
            v-model="viewValue"
            :class="styleClass"
            :config="config"
            :disabled="disabled"
            :error="hasError"
            :error-messages="msgs"
            :name="name"
            :required="required"
            :rules="rules"
            @update:error="updateError"
        />
        <form-row-switch
            v-else-if="config.type === 'switch'"
            :id="name"
            v-model="viewValue"
            :class="styleClass"
            :config="config"
            :disabled="disabled"
            :error="hasError"
            :error-messages="msgs"
            :name="name"
            :required="required"
            :rules="rules"
            @update:error="updateError"
        />

        <form-row-checkbox
            v-else-if="config.type === 'checkbox'"
            v-model="viewValue"
            :class="styleClass"
            :config="config"
            :disabled="disabled"
            :error="hasError"
            :error-messages="msgs"
            :name="name"
            :required="required"
            :rules="rules"
            @update:error="updateError"
        />
        <form-row-tree-node
            v-else-if="config.type === 'treenode'"
            v-model="viewValue"
            :class="styleClass"
            :config="config"
            :disabled="disabled"
            :error="hasError"
            :error-messages="msgs"
            :name="name"
            :required="required"
            :rules="rules"
            :multiple="config.multiple || false"
            :selection-type="config.selectionType"
            @update:error="updateError"
        />
        <form-row-radio
            v-else-if="config.type === 'radio'"
            v-model="viewValue"
            :class="styleClass"
            :config="config"
            :disabled="disabled"
            :error="hasError"
            :error-messages="msgs"
            :name="name"
            :required="required"
            :rules="rules"
            @update:error="updateError"
        />
        <form-row-file
            v-else-if="config.type === 'file' || config.type === 'image'"
            v-model="viewValue"
            :accept="config.accept"
            :config="config"
            :disabled="disabled"
            :error="hasError"
            :error-messages="msgs"
            :multiple="config.multiple"
            :max-file-size="config.maxFileSize"
            :max-image-size="config.maxImageSize"
            :name="name"
            :required="required"
            :rules="rules"
            @update:error="updateError"
        />
        <form-row-signatur
            v-else-if="config.type === 'signatur'"
            v-model="viewValue"
            :class="styleClass"
            :config="config"
            :disabled="disabled"
            :name="name"
        />
        <redactor
            v-else-if="config.type === 'wysiwyg'"
            v-model="viewValue"
            :disabled="disabled"
            :label="config.label"
            :config="config.redactor || {}"
            @update:error="updateError"
        />
        <form-row-localized
            v-else-if="config.type === 'localized'"
            v-model="viewValue"
            :class="styleClass"
            :config="config"
            :disabled="disabled"
            :error="hasError"
            :error-messages="msgs"
            :name="name"
            :required="required"
            :rules="rules"
            @update:error="updateError"
        />
        <form-row-localized-multi
            v-else-if="config.type === 'localizedMulti'"
            v-model="viewValue"
            :class="styleClass"
            :config="config"
            :disabled="disabled"
            :error="hasError"
            :error-messages="msgs"
            :name="name"
            :required="required"
            :rules="rules"
            @update:error="updateError"
        />
        <form-row-password
            v-else-if="config.type === 'password'"
            v-model="viewValue"
            :class="styleClass"
            :config="config"
            :disabled="disabled"
            :error="hasError"
            :error-messages="msgs"
            :name="name"
            :required="required"
            :rules="rules"
            @update:error="updateError"
        />
        <form-row-html
            v-else-if="config.type === 'html'"
            :id="name"
            v-model="viewValue"
            :class="styleClass"
            :error="hasError"
            :error-messages="msgs"
            :field-type="config.fieldType"
            :hint="config.description"
            :label="config.label"
            :name="name"
        />
        <form-row-collection
            v-else-if="config.type === 'collection'"
            :id="name"
            v-model="viewValue"
            :config="config"
            @update:error="updateError"
        />
        <form-row-color-picker
            v-else-if="config.type === 'colorpicker'"
            :id="name"
            v-model="viewValue"
            :name="name"
            :config="config"
            :has-error="hasError"
            :msgs="msgs"
            :required="required"
            :rules="rules"
            :disabled="disabled"
            :mode="config.mode || 'hex'"
            :hide-inputs="config.hideInputs"
            :hide-canvas="config.hideCanvas"
            :show-swatches="config.showSwatches"
            @update:error="updateError"
        />
        <form-row-textarea
            v-else-if="config.type === 'textarea'"
            :id="name"
            v-model="viewValue"
            :name="name"
            :config="config"
            :error="hasError"
            :error-messages="msgs"
            :required="required"
            :rules="rules"
            :disabled="disabled"
            @update:error="updateError"
        />
        <form-row-number
            v-else-if="config.type === 'number'"
            :id="name"
            v-model="viewValue"
            :name="name"
            :config="config"
            :error="hasError"
            :error-messages="msgs"
            :required="required"
            :rules="rules"
            :disabled="disabled"
            @update:error="updateError"
        />
        <form-row-text
            v-else
            :id="name"
            v-model="viewValue"
            :name="name"
            :config="config"
            :error="hasError"
            :error-messages="msgs"
            :required="required"
            :rules="rules"
            :disabled="disabled"
            @update:error="updateError"
        />
    </div>
</template>

<script>
import FormRowDate from './FormRowDate';
import FormRowTime from './FormRowTime';
import FormRowFile from './FormRowFile';
import FormRowAutocomplete from './FormRowAutocomplete';
import FormRowCheckbox from './FormRowCheckbox';
import FormRowTreeNode from './FormRowTreeNode';
import FormRowRadio from './FormRowRadio';
import FormRowSwitch from './FormRowSwitch';
import FormRowSignatur from "./FormRowSignatur";
import FormRowLocalized from "./FormRowLocalized";
import FormRowLocalizedMulti from "./FormRowLocalizedMulti";
import FormRowPassword from "./FormRowPassword";
import FormRowHtml from "./FormRowHtml";
import Redactor from "./Redactor";
import FormRowOrderedChoice from "./FormRowOrderedChoice";
import FormRowCollection from "./FormRowCollection";
import FormRowText from "./FormRowText";
import FormRowNumber from "./FormRowNumber";
import FormRowTextarea from "./FormRowTextarea";
import FormRowColorPicker from "./FormRowColor";

/**
 * Selects the element that will be rendered based on the `type` value of the `config` object.
 *
 * #### Common config options
 *
 * | key                    | type                       | required | default    | description |
 * |------------------------|----------------------------|----------|------------|-------------|
 * | type                   | `String`                   | yes      |            | field type  |
 * | label                  | `String`, `false`          | no       | `false`    | fields label |
 * | class                  | `String`                   | no       | `null`     | css class for custom styling |
 * | required               | `Boolean`, `eval(String)`  | no       | `false`    | field is required.|
 * | disabled               | `Boolean`, `eval(String)`  | no       | `false`    | field is disabled.|
 * | visible                | `Boolean`, `eval(String)`  | no       | `false`    | field is rendered.  |
 */
export default {
    name: 'FormRow',
    components: {
        FormRowColorPicker,
        FormRowText,
        FormRowNumber,
        FormRowTextarea,
        FormRowOrderedChoice,
        FormRowLocalized,
        FormRowLocalizedMulti,
        FormRowSignatur,
        FormRowDate,
        FormRowTime,
        FormRowFile,
        FormRowAutocomplete,
        FormRowCheckbox,
        FormRowTreeNode,
        FormRowRadio,
        FormRowSwitch,
        FormRowPassword,
        FormRowHtml,
        FormRowCollection,
        Redactor
    },
    props: {
        /** the rows value */
        "value": {
            required: false,
            default: null
        },
        /** the parent value object, as context for evaluating the visible/required properties */
        "values": {
            type: Object,
            required: false,
            default: () => ({})
        },
        /** config for this row */
        "config": {
            type: Object,
            required: true
        },
        /** name of the rows element */
        "name": {
            type: String,
            required: false,
            default: ""
        }
    },
    data() {
        return {
            myHasErrors: false,
            viewValue: "",
        }
    },
    computed: {
        hasError() {
            return this.config.errors && this.config.errors.length > 0;
        },
        msgs() {
            return this.config.errors;
        },
        required() {
            return this.isRequired()
        },
        styleClass() {
            return this.config.class;
        },
        rules() {
            let rtn = [];
            if (this.isRequired()) {
                if (this.config.type !== 'switch') {
                    rtn.push(this.validateRequired);
                } else {
                    rtn.push(this.validateReq)
                }
            }

            if (this.config.type === 'email') {
                rtn.push(v => {
                    if (!this.isRequired() && !v)
                        return true;
                    let m = v.match(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,63})+$/);
                    return m !== null ? true : this.$lumui.i18n.t('lumui.form.row.invalid_email')
                });
            }

            return rtn;
        },
        locale() {
            return this.$store.getters['lumui/i18n/locale'];
        },
        visible() {
            let record = {
                values: this.values,
                getData(column) {
                    if (typeof record.values === 'undefined') {
                        return null
                    }
                    return record.values[column]
                }
            };
            if (this.config.visible) {
                return eval(this.config.visible);
            }
            return true;
        },
        disabled() {
            return this.isDisabled();
        },
    },
    watch: {
        viewValue() {
            this.updateValue();
        },
        value() {
            this.viewValue = this.assertValue(this.value);
            // this watcher is triggert on user input, so we can clear errors here
            if ((this.config.errors && this.config.errors.length > 0) || !this.config.errors) {
                this.$set(this.config, 'errors', []);
            }
        }
    },
    mounted() {
        if (!this.config) {
            console.warn("No config for " + this.name);
        }
        this.viewValue = this.assertValue(this.value);
        // Defaultwert aus Form config setzen
        if ((this.value === null || typeof this.value === 'undefined')) {
            this.viewValue = this.assertValue(this.config.value);
            this.updateValue();
        }
    },
    methods: {
        /** @private */
        updateValue() {
            /**
             * when the elements value is changed
             * @param viewValue
             */
            this.$emit('input', this.viewValue);
        },
        /** @private */
        updateError(e) {
            /**
             * when the element has validation errors
             * @param {Event} e
             */
            this.$emit('update:error', e)
        },
        /** @private */
        mapMultiOptions(options) {
            let rtn = {};

            options.forEach((option) => {
                rtn[option.value] = option.label
            });

            return rtn;
        },
        /** @private */
        getAsFile(file) {
            if (typeof file !== 'object') {
                return null;
            }

            return file;
        },
        /**
         * Whether or not this field is required
         * @return {Boolean}
         */
        isRequired() {
            let required = false;
            if (this.config.required) {
                required = this.config.required
            }
            if (typeof required !== 'boolean') {
                let record = {
                    values: this.values,
                    getData(column) {
                        if (!record.values) {
                            return null
                        }
                        return record.values[column];
                    }
                };
                return eval(required);
            }

            return !!required;
        },
        /**
         * weather or not this field is disabled
         * @return {Boolean}
         */
        isDisabled() {
            if (this.config.disabled && typeof this.config.disabled !== 'boolean') {
                let record = {
                    values: this.values,
                    getData(column) {
                        if (!record.values) {
                            return null
                        }
                        return record.values[column];
                    }
                };
                return eval(this.config.disabled);
            }

            return !!this.config.disabled;
        },
        /** @private */
        assertValue(value) {
            // @TODO: omg?! refactor
            if (typeof value === 'object'
                && value != null
                && value.id
                && !value.hasOwnProperty('src')
            ) {
                return value.id;
            } else if (typeof value === 'object'
                && value != null
                && value.length === 0
            ) {
                return null
            } else {
                return value;
            }
        },
        /** @private */
        validateReq(value) {
            //required rule für switches
            if (!value) {
                return this.$lumui.i18n.t("lumui.form.row.required");
            } else {
                return true
            }
        },
        validateRequired(value) {
            if (Array.isArray(value)) {
                value = value.filter((v) => {
                    return (v !== undefined && v !== null && v.length !== 0)
                })
            }
            if (value === undefined || value === null || value.length === 0) {
                return this.$lumui.i18n.t("lumui.form.row.required");
            } else {
                return true;
            }
        }
    },
}
</script>

<style>
.form-row-required label:after {
    content: ' *';
}
</style>
