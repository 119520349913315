<template>
    <div>
        <v-label v-if="config.label" :color="config.errors && config.errors.length > 0 ? 'error' : 'xxx'" :focused="true">
            {{ config.label }}
        </v-label>
        <div>
            <v-container fluid>
                <template v-for="(child, index) in config.children">
                    <v-row :key="index" dense no-gutters>
                        <v-col :cols="allowDelete ? 11 : 12">
                            <form-row v-if="child.type && viewValue.hasOwnProperty(index)" v-model="viewValue[index]" :config="child" />
                            <form-rows v-else-if="viewValue.hasOwnProperty(index)" v-model="viewValue[index]" :config="child" />
                        </v-col>
                        <v-col v-if="allowDelete" cols="1">
                            <v-btn icon @click="removeItem(index)">
                                <v-icon>$delete</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                </template>
            </v-container>
        </div>
        <v-btn v-if="allowAdd" @click="addItem">
            {{ addLabel }}
        </v-btn>
        <v-messages v-if="config.errors && config.errors.length > 0" :value="config.errors" color="error" class="mt-3" />
    </div>
</template>

<script>
import {VBtn, VCol, VContainer, VIcon, VInput, VLabel, VMessages, VRow} from 'vuetify/lib';

/**
 * renders a collection of fields.
 *
 * #### Config
 *
 * | key                    | type                       | required | default        | description |
 * |------------------------|----------------------------|----------|----------------|-------------|
 * | type                   | `String`                   | yes      | `"select"`     | always `"select"` |
 * | label                  | `String`, `false`          | no       | `false`        | fields label |
 * | required               | `Boolean`, `eval(String)`  | no       | `false`        | field is required.|
 * | disabled               | `Boolean`, `eval(String)`  | no       | `false`        | field is disabled.|
 * | visible                | `Boolean`, `eval(String)`  | no       | `false`        | field is rendered.  |
 * | prototype              | `Object`                   | yes      | -              | configuration for child fields |
 * | allow_add              | `Boolean`                  | no       | `false`        | user can add fields to the collection |
 * | add_label              | `String`                   | no       | `"Hinzufügen"` | label for add button |
 * | allow_delete           | `Boolean`                  | no       | `false`        | user can remove fields from the collection |
 *
 * @example
 * ```vue
 * {
 *   "type": "collection",
 *   "label": "Tags",
 *   "allow_add": true,
 *   "allow_delete": true,
 *   "prototype": {
 *     "type": "text",
 *     "label": "",
 *     "value": "",
 *     "required": true,
 *     "disabled": false
 *    },
 * }
 * ```
 */
export default {
    name: "FormRowCollection",
    components: {VLabel, VContainer, VBtn, VRow, VCol, VIcon, VMessages},
    extends: VInput,
    props: {
        /** field config */
        config: {
            required: true,
            type: Object
        },
        /** field value */
        value: {
            required: true
        }
    },
    data() {
        return {
            viewValue: {},
        }
    },
    computed: {
        addLabel() {
            return this.config.add_label ? this.config.add_label : "Hinzufügen"
        },
        allowAdd() {
            return this.config.allow_add ? this.config.allow_add : false;
        },
        allowDelete() {
            return this.config.allow_delete ? this.config.allow_delete : false;
        }
    },
    watch: {
        value: {
            handler: function (value) {
                if (value === undefined || value === null || (Object.keys(value).length === 0)) {
                    let val = {};
                    if (!this.config.hasOwnProperty('children')) {
                        return;
                    }
                    Object.keys(this.config.children).forEach(c => {
                        const child = this.config.children[c];
                        if (typeof child.type !== 'undefined') {
                            val[c] = child.value;
                        } else if (typeof child === 'object') {
                            val[c] = {};
                            Object.keys(child).forEach(gc => {
                                val[c][gc] = child[gc].value;
                            });
                        }
                    });
                    if (Object.keys(val).length > 0) {
                        this.viewValue = val;
                    }
                } else {
                    let children = {};
                    Object.keys(value).forEach(v => {
                        const val = value[v];
                        let child = JSON.parse(JSON.stringify(this.config.prototype));
                        if (typeof child.type !== 'undefined') {
                            child.value = val;
                        } else if (typeof val === 'object' && val !== null) {
                            Object.keys(val).forEach(k => {
                                child[k].value = val[k];
                            })
                        }
                        children[v] = child;
                    });
                    this.$set(this.config, 'children', children);
                    this.viewValue = value;
                }
            },
        },
        viewValue: {
            handler: function (newValue) {
                this.$emit('input', newValue);
            },
            deep: true
        },
    },
    methods: {
        /** adds an item to the collection */
        addItem() {
            let value;
            if (this.config.prototype.type) {
                value = '';
            } else {
                value = {};
                Object.keys(this.config.prototype).forEach((key) => {
                    value[key] = '';
                });
            }

            const index = Math.max(...Object.keys(this.config.children), -1) + 1;
            this.$set(this.config.children, index, JSON.parse(JSON.stringify(this.config.prototype)));
            this.$set(this.viewValue, index, value);
        },
        /**
         * removes the item identified by the index
         * @param {Number} index
         */
        removeItem(index) {
            this.$delete(this.config.children, index);
            this.$delete(this.viewValue, index);
        }
    },
}
</script>
