<template>
    <div>
        <v-toolbar>
            <v-toolbar-title>
                Custom Filters and disabled pagination
            </v-toolbar-title>
            <v-spacer/>
            <v-toolbar-items>
                <v-btn tile icon large @click="showFilter = !showFilter">
                    <v-icon>mdi-magnify</v-icon>
                </v-btn>
            </v-toolbar-items>
        </v-toolbar>
        <asa-table
            :loading="false"
            :data="config"
            info-disabled
            :show-filter="showFilter"
            storage-key="test_table_filter_storage_custom"
            :items-per-page-options="[10, 50, 100]"
            :disable-pagination="true"
            @filter_closed="filterClosed"
            @filter_resetted="filterResetted"
        >
            <template v-slot:filter="props">
                <v-card-text>
                    <v-form>
                        <v-container fluid grid-list-lg>
                            <v-layout row wrap>
                                <v-flex xs12>
                                    <AsaTableFilterText
                                        :config="textSearchConfig"
                                    />
                                </v-flex>
                            </v-layout>
                        </v-container>
                        <v-container fluid grid-list-lg>
                            <v-layout row wrap>
                                <v-flex
                                    v-for="filter in props.filters"
                                    v-if="!filter.hidden && !(filter.type === 'action') && filter.searchable"
                                    :key="filter.key"
                                    lg4
                                    xs12
                                >
                                    <asa-table-filter :config="filter"></asa-table-filter>
                                </v-flex>
                            </v-layout>
                        </v-container>
                    </v-form>
                </v-card-text>
            </template>
            <template v-slot:col_actions="props">
                <asa-table-action v-if="actions !== null" :actions="actions" :row="props.row" icon-size="small" btn-size="default">
                    <v-btn slot="button" icon text>
                        <v-icon>more_vert</v-icon>
                    </v-btn>
                </asa-table-action>
            </template>
        </asa-table>
    </div>
</template>

<script>
import {
    VForm,
    VContainer,
    VLayout,
    VFlex,
    VCardText,
    VToolbar,
    VToolbarTitle,
    VSpacer,
    VToolbarItems,
    VBtn,
    VIcon
} from 'vuetify/lib';
import AsaTableFilterText from "../../../src/component/AsaTableFilterText";

export default {
    name: "table_filters_custom",
    components: {
        AsaTableFilterText,
        VForm,
        VContainer,
        VLayout,
        VFlex,
        VCardText,
        VToolbar,
        VToolbarTitle,
        VSpacer,
        VToolbarItems,
        VBtn,
        VIcon
    },
    data() {
        return {
            textSearchConfig: {
                clearable: true,
                key: 'search',
                label: 'Textsuche',
                value: null
            },
            search: null,
            showFilter: true,
            config: {
                columns: [
                    {
                        key: 'string',
                        label: 'string'
                    },
                    {
                        key: 'string2',
                        label: 'string2'
                    },
                    {
                        key: 'boolean',
                        label: 'boolean',
                    },
                    {
                        key: 'date',
                        label: 'date',
                        type: 'date'
                    },
                    {
                        key: 'time',
                        label: 'time',
                        type: 'time'
                    },
                    {
                        key: 'actions',
                        label: 'actions',
                        actions: {
                            edit: {
                                icon: 'fa-pencil',
                                route: '#',
                                label: 'edit'
                            },
                            delete: {
                                icon: 'fa-trash',
                                route: '#',
                                label: 'delete'
                            }
                        }
                    }
                ],
                rows: null,
                filter: [
                    {
                        key: 'boolean',
                        label: 'boolean',
                        filter: {
                            select: {
                                options: [
                                    {value: true, text: 'True'},
                                    {value: false, text: 'False'}
                                ]
                            }
                        },
                    },
                    {
                        key: 'date',
                        label: 'date',
                        type: 'date',
                        filter: {
                            date: {
                                range: true,
                            },
                        }
                    },
                ]
            },
            data: [
                {
                    string: 'Eins',
                    string2: 'Weiterer String',
                    boolean: true,
                    date: '1970-01-01',
                    time: '01:00:00'
                },
                {
                    string: 'Zwei',
                    string2: 'Nochmals ein String',
                    boolean: false,
                    date: '1970-01-02',
                    time: '02:00:00'
                },
                {
                    string: 'Drei',
                    string2: 'dDer 3. weitere String',
                    boolean: false,
                    date: '1970-01-03',
                    time: '03:00:00'
                },
                {
                    string: 'Vier',
                    string2: 'Noch ein String',
                    boolean: true,
                    date: '1970-01-04',
                    time: '04:00:00'
                },
                {
                    string: 'Fünf',
                    string2: 'Weiterer String',
                    boolean: true,
                    date: '1975-08-13',
                    time: '01:00:00'
                },
                {
                    string: 'Sechs',
                    string2: 'Nochmals ein String',
                    boolean: false,
                    date: '1970-01-02',
                    time: '02:00:00'
                },
                {
                    string: 'Sieben',
                    string2: 'dDer 3. weitere String',
                    boolean: false,
                    date: '1970-01-03',
                    time: '03:00:00'
                },
                {
                    string: 'Acht',
                    string2: 'Noch ein String',
                    boolean: true,
                    date: '1970-01-04',
                    time: '04:00:00'
                },
                {
                    string: 'Neun',
                    string2: 'Weiterer String',
                    boolean: true,
                    date: '1970-01-01',
                    time: '01:00:00'
                },
                {
                    string: 'Zehn',
                    string2: 'Nochmals ein String',
                    boolean: false,
                    date: '2020-012-04',
                    time: '19:15:30'
                },
                {
                    string: 'Elf',
                    string2: 'dDer 3. weitere String',
                    boolean: false,
                    date: '1970-01-03',
                    time: '03:00:00'
                },
                {
                    string: 'Zwölf',
                    string2: 'Noch ein String',
                    boolean: true,
                    date: '2022-05-04',
                    time: '04:35:00'
                },
            ]
        }
    },
    computed: {
        actions () {
            const actions = this.config.columns.find(column => column.key === 'actions')
            if(typeof actions === 'undefined') {
                return null
            }
            return actions.actions
        }
    },
    watch: {
        'textSearchConfig.value'() {
            this.filterRows()
        }
    },
    mounted() {
        this.filterRows()
    },
    methods: {
        filterClosed () {
            this.showFilter = false
        },
        filterResetted () {
            this.$set(this.textSearchConfig, 'value', null)
        },
        filterRows () {
            let rows = JSON.parse(JSON.stringify(this.data))
            if (this.textSearchConfig.value !== null) {
                rows = rows.filter(item => {
                    return item.string.toLowerCase().includes(this.textSearchConfig.value) || item.string2.toLowerCase().includes(this.textSearchConfig.value)
                })
            }
            this.$set(this.config,'rows', rows)
        }
    }
}
</script>
