export default {
    "$vuetify.badge": "Žetons",
    "$vuetify.close": "Aizvērt",
    "$vuetify.dataIterator.noResultsText": "Nekas netika atrasts",
    "$vuetify.dataIterator.loadingText": "Ielādē...",
    "$vuetify.dataTable.itemsPerPageText": "Rādīt lapā:",
    "$vuetify.dataTable.ariaLabel.sortDescending": "Sakārtots dilstošā secībā.",
    "$vuetify.dataTable.ariaLabel.sortAscending": "Sakārtots augošā secībā.",
    "$vuetify.dataTable.ariaLabel.sortNone": "Nav sakārtots.",
    "$vuetify.dataTable.ariaLabel.activateNone": "Aktivizēt, lai noņemtu kārtošanu.",
    "$vuetify.dataTable.ariaLabel.activateDescending": "Aktivizēt, lai sakārtotu dilstošā secībā.",
    "$vuetify.dataTable.ariaLabel.activateAscending": "Aktivizēt, lai sakārtotu augošā secībā.",
    "$vuetify.dataTable.sortBy": "Sort by",
    "$vuetify.dataFooter.itemsPerPageText": "Rādīt lapā:",
    "$vuetify.dataFooter.itemsPerPageAll": "Visu",
    "$vuetify.dataFooter.nextPage": "Nākamā lapa",
    "$vuetify.dataFooter.prevPage": "Iepriekšējā lapa",
    "$vuetify.dataFooter.firstPage": "Pirmā lapa",
    "$vuetify.dataFooter.lastPage": "Pēdējā lapa",
    "$vuetify.dataFooter.pageText": "{0}-{1} no {2}",
    "$vuetify.datePicker.itemsSelected": "{0} izvēlēts",
    "$vuetify.datePicker.nextMonthAriaLabel": "Nākammēnes",
    "$vuetify.datePicker.nextYearAriaLabel": "Nākamgad",
    "$vuetify.datePicker.prevMonthAriaLabel": "Iepriekšējais mēnesis",
    "$vuetify.datePicker.prevYearAriaLabel": "Iepriekšējais gads",
    "$vuetify.noDataText": "Nav pieejamu datu",
    "$vuetify.carousel.prev": "Iepriekšējais slaids",
    "$vuetify.carousel.next": "Nākamais slaids",
    "$vuetify.carousel.ariaLabel.delimiter": "Carousel slide {0} of {1}",
    "$vuetify.calendar.moreEvents": "Vēl {0}",
    "$vuetify.input.clear": "Clear {0}",
    "$vuetify.input.prependAction": "{0} prepended action",
    "$vuetify.input.appendAction": "{0} appended action",
    "$vuetify.fileInput.counter": "{0} files",
    "$vuetify.fileInput.counterSize": "{0} files ({1} in total)",
    "$vuetify.timePicker.am": "AM",
    "$vuetify.timePicker.pm": "PM",
    "$vuetify.pagination.ariaLabel.wrapper": "Navigācija paginationā",
    "$vuetify.pagination.ariaLabel.next": "Nākamā lapa",
    "$vuetify.pagination.ariaLabel.previous": "Iepriekšējā lapa",
    "$vuetify.pagination.ariaLabel.page": "Iet uz lapu {0}",
    "$vuetify.pagination.ariaLabel.currentPage": "Pašreizējā lapa, lapa {0}",
    "$vuetify.rating.ariaLabel.icon": "Rating {0} of {1}",
    "$vuetify.loading": "Loading..."
};