<template>
    <asa-form :config="cfg" :value="value">
        <template v-slot:title>XXX</template>
    </asa-form>
</template>

<script>
import AsaForm from "../../../src/component/AsaForm";

export default {
    name: "Html",
    components: {AsaForm},
    data() {
        return {
            value: {},
            cfg: {
                tab: {
                    type: "tab",
                    label: "first tab",
                    children: {
                        fieldType: {
                            name: "fieldFype",
                            label: "FieldType",
                            type: "radio",
                            multiOptions: [
                                {label: "No", value: false},
                                {label: "Yepp", value: true}
                            ],
                            value: false
                        },
                        a: {
                            name: "a",
                            type: "html",
                            label: "Html content",
                            fieldType: false,
                            value: "<div><b>Mitarbeiterschulung</b> (Testuser, AMAG bis zum 17.11.2021)<br>klaus; Essen gehen (Muster, Hans bis zum 22.12.2021)<hr>jkhw jhwq lkcj lkj lkj lkj lkj ö</div>"
                        }
                    }
                }
            }
        }
    },
    watch: {
        "value": {
            deep: true,
            handler(n) {
                this.$set(this.cfg.tab.children.a, 'fieldType', n.fieldType);
            }
        }
    }
}
</script>

<style scoped>

</style>
