var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app",
    { attrs: { id: "app" } },
    [
      _c(
        "v-navigation-drawer",
        {
          attrs: { app: "", clipped: "" },
          model: {
            value: _vm.drawer,
            callback: function ($$v) {
              _vm.drawer = $$v
            },
            expression: "drawer",
          },
        },
        [
          _c(
            "v-list",
            { attrs: { dense: "" } },
            [
              _c(
                "v-list-group",
                {
                  attrs: { value: true, "prepend-icon": "fa-input-pipe" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function () {
                        return [_c("v-list-item-title", [_vm._v("Forms")])]
                      },
                      proxy: true,
                    },
                  ]),
                },
                [
                  _vm._v(" "),
                  _vm._l(
                    _vm.$router
                      .getRoutes()
                      .filter((r) => r.name.match(/^form_/)),
                    function (route) {
                      return _c(
                        "v-list-item",
                        {
                          key: route.name,
                          attrs: { to: { name: route.name } },
                        },
                        [
                          _c("v-list-item-icon"),
                          _vm._v(" "),
                          _c("v-list-item-title", [
                            _vm._v(_vm._s(route.meta.title || route.name)),
                          ]),
                        ],
                        1
                      )
                    }
                  ),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "v-list-group",
                {
                  attrs: { value: false, "prepend-icon": "fa-table" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function () {
                        return [_c("v-list-item-title", [_vm._v("Tables")])]
                      },
                      proxy: true,
                    },
                  ]),
                },
                [
                  _vm._v(" "),
                  _vm._l(
                    _vm.$router
                      .getRoutes()
                      .filter((r) => r.name.match(/^table_/)),
                    function (route) {
                      return _c(
                        "v-list-item",
                        {
                          key: route.name,
                          attrs: { to: { name: route.name } },
                        },
                        [
                          _c("v-list-item-icon"),
                          _vm._v(" "),
                          _c("v-list-item-title", [
                            _vm._v(_vm._s(route.meta.title || route.name)),
                          ]),
                        ],
                        1
                      )
                    }
                  ),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "v-list-group",
                {
                  attrs: { value: false, "prepend-icon": "fa-highlighter" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function () {
                        return [_c("v-list-item-title", [_vm._v("Formatter")])]
                      },
                      proxy: true,
                    },
                  ]),
                },
                [
                  _vm._v(" "),
                  _vm._l(
                    _vm.$router
                      .getRoutes()
                      .filter((r) => r.name.match(/^formatter_/)),
                    function (route) {
                      return _c(
                        "v-list-item",
                        {
                          key: route.name,
                          attrs: { to: { name: route.name } },
                        },
                        [
                          _c("v-list-item-icon"),
                          _vm._v(" "),
                          _c("v-list-item-title", [
                            _vm._v(_vm._s(route.meta.title || route.name)),
                          ]),
                        ],
                        1
                      )
                    }
                  ),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "v-list-group",
                {
                  attrs: { value: false, "prepend-icon": "fa-browser" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function () {
                        return [_c("v-list-item-title", [_vm._v("Misc")])]
                      },
                      proxy: true,
                    },
                  ]),
                },
                [
                  _vm._v(" "),
                  _vm._l(
                    _vm.$router
                      .getRoutes()
                      .filter((r) => r.name.match(/^misc_/)),
                    function (route) {
                      return _c(
                        "v-list-item",
                        {
                          key: route.name,
                          attrs: { to: { name: route.name } },
                        },
                        [
                          _c("v-list-item-icon"),
                          _vm._v(" "),
                          _c("v-list-item-title", [
                            _vm._v(_vm._s(route.meta.title || route.name)),
                          ]),
                        ],
                        1
                      )
                    }
                  ),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "v-list-group",
                {
                  attrs: { value: false, "prepend-icon": "fa-vial" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function () {
                        return [
                          _c("v-list-item-title", [_vm._v("Component Test")]),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                },
                [
                  _vm._v(" "),
                  _vm._l(
                    _vm.$router
                      .getRoutes()
                      .filter((r) => r.name.match(/^test_/)),
                    function (route) {
                      return _c(
                        "v-list-item",
                        {
                          key: route.name,
                          attrs: { to: { name: route.name } },
                        },
                        [
                          _c("v-list-item-icon"),
                          _vm._v(" "),
                          _c("v-list-item-title", [
                            _vm._v(_vm._s(route.meta.title || route.name)),
                          ]),
                        ],
                        1
                      )
                    }
                  ),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "v-list-group",
                {
                  attrs: { value: false, "prepend-icon": "fa-sync" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function () {
                        return [_c("v-list-item-title", [_vm._v("Playground")])]
                      },
                      proxy: true,
                    },
                  ]),
                },
                [
                  _vm._v(" "),
                  _vm._l(
                    _vm.$router
                      .getRoutes()
                      .filter((r) => r.name.match(/^playground_/)),
                    function (route) {
                      return _c(
                        "v-list-item",
                        {
                          key: route.name,
                          attrs: { to: { name: route.name } },
                        },
                        [
                          _c("v-list-item-icon"),
                          _vm._v(" "),
                          _c("v-list-item-title", [
                            _vm._v(_vm._s(route.meta.title || route.name)),
                          ]),
                        ],
                        1
                      )
                    }
                  ),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-app-bar",
        { attrs: { app: "", "clipped-left": "", "clipped-right": "" } },
        [
          _c("v-app-bar-nav-icon", {
            on: {
              click: function ($event) {
                _vm.drawer = !_vm.drawer
              },
            },
          }),
          _vm._v(" "),
          _c("v-toolbar-title", [
            _vm._v("\n            LumUI\n            "),
            _c("span", [
              _vm._v(
                "\n                " +
                  _vm._s(_vm.$route.meta.title) +
                  "\n            "
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("v-spacer"),
          _vm._v(" "),
          _c("v-select", {
            attrs: { items: _vm.locales },
            on: { change: _vm.setLocale },
            model: {
              value: _vm.currentLocale,
              callback: function ($$v) {
                _vm.currentLocale = $$v
              },
              expression: "currentLocale",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("v-main", [_c("router-view")], 1),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }