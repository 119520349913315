<template>
    <div>
        <v-toolbar flat>
            <v-toolbar-title>Form Playground</v-toolbar-title>
        </v-toolbar>
        <v-divider />
        <v-container>
            <v-row>
                <v-col>
                    <v-textarea v-model="input" label="Form Config" />
                    <v-btn @click="format()" :disabled="input.length === 0 || error != null" icon title="Format code" class="float-right">
                        <i class="fa-solid fa-code"></i>
                    </v-btn>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="8">
                    <v-alert v-if="empty" type="info">
                        Bitte kopieren sie eine Formularkonfiguration in das obige Textfeld.
                    </v-alert>
                    <v-alert v-else-if="error" type="error">
                        {{ error }}
                    </v-alert>
                    <asa-form v-if="config" v-model="value" :config="config" :abort-btn="false">
                        <template #title>
                            Formularvorschau
                        </template>
                    </asa-form>
                </v-col>
                <v-col cols="auto">
                    <pre>
{{ value }}
                    </pre>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <v-textarea v-model="errorInput" label="Set error" :disabled="!config"></v-textarea>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import {VAlert, VBtn, VCol, VContainer, VDivider, VRow, VTextarea, VToolbar, VToolbarTitle} from 'vuetify/lib'
import AsaForm from "../../../src/component/AsaForm";

export default {
    name: "PlaygroundForm",
    components: {AsaForm, VToolbar, VToolbarTitle, VDivider, VBtn, VTextarea, VAlert, VContainer, VRow, VCol},
    data() {
        return {
            input: '',
            errorInput: '',
            formErrors: null,
            config: null,
            error: null,
            value: {},
        }
    },
    computed: {
        empty() {
            return this.input.length === 0;
        }
    },
    watch: {
        input(newVal) {
            this.value = {}
            if (newVal.length === 0) {
                this.error = null;
                this.config = null;
                return;
            }

            try {
                this.config = JSON.parse(newVal)
                this.error = null;
            } catch (error) {
                this.error = error;
            }
        },
        errorInput(newVal) {
            this.formErrors = {};

            try {
                this.formErrors = JSON.parse(newVal);
            } catch (e) {
                console.error(e);
            }
        },
        formErrors(n) {
            if (this.config) {
                this.setFormErrors(this.config, n);
            }
        }
    },
    methods: {
        format() {
            try {
                const src = JSON.parse(this.input);
                this.input = JSON.stringify(src, null, 2);
            } catch (e) {
                console.error(e);
            }
        }
    }
}
</script>
