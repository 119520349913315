var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "asa-dialog",
    [
      _c("asa-form", {
        attrs: { config: _vm.config, value: _vm.value },
        scopedSlots: _vm._u([
          {
            key: "title",
            fn: function () {
              return [_vm._v("\n            Form Title\n        ")]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }