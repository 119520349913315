var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: {
        value: _vm.show,
        fullscreen: _vm.fullscreen,
        "max-width": _vm.width,
        persistent: _vm.persistent,
      },
    },
    [
      _c(
        "v-card",
        { attrs: { color: _vm.color } },
        [
          _c(
            "v-card-title",
            { staticClass: "subheading" },
            [_vm._t("title")],
            2
          ),
          _vm._v(" "),
          _c("v-card-text", [_vm._t("content")], 2),
          _vm._v(" "),
          _c("v-card-actions", [_vm._t("actions")], 2),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }