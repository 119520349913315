export default {
    "$vuetify.badge": "Infopiste",
    "$vuetify.close": "Sulje",
    "$vuetify.dataIterator.noResultsText": "Ei osumia",
    "$vuetify.dataIterator.loadingText": "Ladataan kohteita...",
    "$vuetify.dataTable.itemsPerPageText": "Rivejä sivulla:",
    "$vuetify.dataTable.ariaLabel.sortDescending": ": Järjestetty laskevasti. Poista järjestäminen aktivoimalla.",
    "$vuetify.dataTable.ariaLabel.sortAscending": ": Järjestetty nousevasti. Järjestä laskevasti aktivoimalla.",
    "$vuetify.dataTable.ariaLabel.sortNone": ": Ei järjestetty. Järjestä nousevasti aktivoimalla.",
    "$vuetify.dataTable.ariaLabel.activateNone": "Aktivoi lajittelun poistamiseksi.",
    "$vuetify.dataTable.ariaLabel.activateDescending": "Aktivoi laskevien laskevien lajittelemiseksi.",
    "$vuetify.dataTable.ariaLabel.activateAscending": "Aktivoi lajitella nouseva.",
    "$vuetify.dataTable.sortBy": "Järjestä",
    "$vuetify.dataFooter.itemsPerPageText": "Kohteita sivulla:",
    "$vuetify.dataFooter.itemsPerPageAll": "Kaikki",
    "$vuetify.dataFooter.nextPage": "Seuraava sivu",
    "$vuetify.dataFooter.prevPage": "Edellinen sivu",
    "$vuetify.dataFooter.firstPage": "Ensimmäinen sivu",
    "$vuetify.dataFooter.lastPage": "Viimeinen sivu",
    "$vuetify.dataFooter.pageText": "{0}-{1} ({2})",
    "$vuetify.datePicker.itemsSelected": "{0} valittu",
    "$vuetify.datePicker.nextMonthAriaLabel": "Seuraava kuukausi",
    "$vuetify.datePicker.nextYearAriaLabel": "Ensi vuosi",
    "$vuetify.datePicker.prevMonthAriaLabel": "Edellinen kuukausi",
    "$vuetify.datePicker.prevYearAriaLabel": "Edellinen vuosi",
    "$vuetify.noDataText": "Ei dataa",
    "$vuetify.carousel.prev": "Edellinen kuva",
    "$vuetify.carousel.next": "Seuraava kuva",
    "$vuetify.carousel.ariaLabel.delimiter": "Karusellin kuva {0}/{1}",
    "$vuetify.calendar.moreEvents": "{0} lisää",
    "$vuetify.input.clear": "Clear {0}",
    "$vuetify.input.prependAction": "{0} prepended action",
    "$vuetify.input.appendAction": "{0} appended action",
    "$vuetify.fileInput.counter": "{0} tiedostoa",
    "$vuetify.fileInput.counterSize": "{0} tiedostoa ({1} yhteensä)",
    "$vuetify.timePicker.am": "ap.",
    "$vuetify.timePicker.pm": "ip.",
    "$vuetify.pagination.ariaLabel.wrapper": "Pagination Navigation",
    "$vuetify.pagination.ariaLabel.next": "Seuraava sivu",
    "$vuetify.pagination.ariaLabel.previous": "Edellinen sivu",
    "$vuetify.pagination.ariaLabel.page": "Mene sivulle {0}",
    "$vuetify.pagination.ariaLabel.currentPage": "Nykyinen sivu, Sivu {0}",
    "$vuetify.rating.ariaLabel.icon": "Luokitus {0}/{1}",
    "$vuetify.loading": "Loading..."
};