var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-container",
        [
          _c(
            "v-row",
            [
              _c("v-col", [
                _c("h1", { staticClass: "text-h3" }, [_vm._v("No Data")]),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c(
                    "v-card",
                    [
                      _c("v-card-title", [_vm._v("No Data")]),
                      _vm._v(" "),
                      _c("v-divider"),
                      _vm._v(" "),
                      _c(
                        "v-card-text",
                        [_c("asa-table", { attrs: { data: _vm.table1 } })],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c(
                    "v-card",
                    [
                      _c("v-card-title", [_vm._v("Custom no data message")]),
                      _vm._v(" "),
                      _c("v-divider"),
                      _vm._v(" "),
                      _c(
                        "v-card-text",
                        [
                          _c("asa-table", {
                            attrs: { data: _vm.table1 },
                            scopedSlots: _vm._u([
                              {
                                key: "no-data",
                                fn: function () {
                                  return [
                                    _c(
                                      "div",
                                      {
                                        staticStyle: { "text-align": "center" },
                                      },
                                      [
                                        _c("em", [
                                          _vm._v("Custom no data message"),
                                        ]),
                                      ]
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c(
                    "v-card",
                    [
                      _c("v-card-title", [_vm._v("Custom no results message")]),
                      _vm._v(" "),
                      _c("v-divider"),
                      _vm._v(" "),
                      _c(
                        "v-card-text",
                        [
                          _c("asa-table", {
                            attrs: { data: _vm.table2, showFilter: true },
                            scopedSlots: _vm._u([
                              {
                                key: "no-results",
                                fn: function () {
                                  return [
                                    _c(
                                      "div",
                                      {
                                        staticStyle: { "text-align": "center" },
                                      },
                                      [
                                        _c("em", [
                                          _vm._v("Custom no results message"),
                                        ]),
                                      ]
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }