<template>
    <div>
        <v-container>
            <v-row>
                <v-col>
                    <h1 class="text-h3">Number</h1>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" md="8">
                    <asa-form :config="config" v-model="value">
                        <template v-slot:title>
                            Eingabe einer Ganzzahl (min: {{ config.p.min }}, max: {{ config.p.max }}, step: {{ config.p.step }}, clearable:  {{ config.p.clearable }})
                        </template>
                    </asa-form>
                </v-col>
                <v-col cols="12" md="4">
                    <v-card class="fill-height">
                        <v-card-title class="elevation-1">
                            Value
                        </v-card-title>
                        <v-card-text class="py-4">
                            <pre>{{ JSON.stringify(value, null, 4) }}</pre>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" md="8">
                    <asa-form :config="config2" v-model="value2">
                        <template v-slot:title>
                            Eingabe einer Ganzzahl (min: {{ config2.p.min }}, step: {{ config2.p.step }})
                        </template>
                    </asa-form>
                </v-col>
                <v-col cols="12" md="4">
                    <v-card class="fill-height">
                        <v-card-title class="elevation-1">
                            Value
                        </v-card-title>
                        <v-card-text class="py-4">
                            <pre>{{ JSON.stringify(value2, null, 4) }}</pre>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" md="8">
                    <asa-form :config="config3" v-model="value3">
                        <template v-slot:title>
                            Eingabe einer Ganzzahl (max: {{ config3.p.max }}, step: {{ config3.p.step }})
                        </template>
                    </asa-form>
                </v-col>
                <v-col cols="12" md="4">
                    <v-card class="fill-height">
                        <v-card-title class="elevation-1">
                            Value
                        </v-card-title>
                        <v-card-text class="py-4">
                            <pre>{{ JSON.stringify(value3, null, 4) }}</pre>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import AsaForm from "../../../src/component/AsaForm";
import { VRow, VCol, VCard, VCardText, VCardTitle, VContainer } from 'vuetify/lib'

export default {
    name: "Radio",
    components: {AsaForm, VRow, VCol, VCard, VCardText, VCardTitle, VContainer},
    data() {
        return {
            value: {p:"4"},
            config: JSON.parse('{"p":{"type":"number","label":"Ganzzahl","value":"","required":true,"step":1,"min":5,"max":1239,"clearable":true}}'),
            value2: {p:"-6"},
            config2: JSON.parse('{"p":{"type":"number","label":"Ganzzahl","value":"","required":true,"step":5,"min":10}}'),
            value3: {p:"980"},
            config3: JSON.parse('{"p":{"type":"number","label":"Ganzzahl","value":"","required":true,"step":10,"max":1000}}'),
        };
    }
}
</script>

