var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.disabled
    ? _c(
        "v-dialog",
        {
          ref: "timepicker",
          attrs: { "return-value": _vm.time, persistent: "", width: "290px" },
          on: {
            "update:returnValue": function ($event) {
              _vm.time = $event
            },
            "update:return-value": function ($event) {
              _vm.time = $event
            },
          },
          scopedSlots: _vm._u(
            [
              {
                key: "activator",
                fn: function ({ on }) {
                  return [
                    _c(
                      "v-text-field",
                      _vm._g(
                        {
                          attrs: {
                            id: _vm.id,
                            filled: "",
                            disabled: _vm.disabled,
                            error: !_vm.valid,
                            "error-messages": _vm.errorBucket,
                            label: _vm.config.label,
                            name: _vm.name,
                            required: _vm.required,
                            rules: _vm.rules,
                            autocomplete: "off",
                            clearable: "",
                            "prepend-inner-icon": "$event",
                            readonly: "",
                          },
                          model: {
                            value: _vm.viewValue,
                            callback: function ($$v) {
                              _vm.viewValue = $$v
                            },
                            expression: "viewValue",
                          },
                        },
                        on
                      )
                    ),
                  ]
                },
              },
            ],
            null,
            false,
            3758381438
          ),
          model: {
            value: _vm.modal,
            callback: function ($$v) {
              _vm.modal = $$v
            },
            expression: "modal",
          },
        },
        [
          _vm._v(" "),
          _c(
            "v-time-picker",
            {
              attrs: {
                "allowed-minutes": _vm.allowedMinutes,
                color: "red darken-3",
                format: _vm.timeFormat,
              },
              model: {
                value: _vm.time,
                callback: function ($$v) {
                  _vm.time = $$v
                },
                expression: "time",
              },
            },
            [
              _c("v-spacer"),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  attrs: { color: "primary", text: "" },
                  on: {
                    click: function ($event) {
                      _vm.modal = false
                    },
                  },
                },
                [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.$lumui.i18n.t("lumui.form.time.close")) +
                      "\n        "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  attrs: { color: "primary", text: "" },
                  on: { click: _vm.save },
                },
                [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.$lumui.i18n.t("lumui.form.time.save")) +
                      "\n        "
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      )
    : _c("v-text-field", {
        attrs: {
          id: _vm.name,
          disabled: _vm.disabled,
          label: _vm.config.label,
          name: _vm.name,
          autocomplete: "off",
          "prepend-inner-icon": "$event",
          readonly: "",
        },
        model: {
          value: _vm.viewValue,
          callback: function ($$v) {
            _vm.viewValue = $$v
          },
          expression: "viewValue",
        },
      })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }