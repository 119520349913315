var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("asa-form", {
        attrs: { loading: false, value: _vm.value, config: _vm.config },
        on: { save: _vm.addError },
      }),
      _vm._v(" "),
      _c("v-container", [_c("p", [_vm._v("Values: " + _vm._s(_vm.value))])]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }