import {merge} from "webpack-merge";
import fr from './base/fr.js';

export default merge(fr, {
    "lumui.confirm.accept": "OK",
    "lumui.confirm.reject": "Abandonner",
    "lumui.formatter.boolean.true": "Oui",
    "lumui.formatter.boolean.false": "No",
    "lumui.form.close": "Fermer",
    "lumui.form.cancel": "Abandonner",
    "lumui.form.save": "Sauvegarder",
    "lumui.lightbox.title": "Images existantes",
    "lumui.loading.message": "Les donn\u00e9es sont en cours de chargement",
    "lumui.qr_scanner.camera": "cam\u00e9ra",
    "lumui.qr_scanner.mirror": "Retourner la cam\u00e9ra",
    "lumui.qr_scanner.torch": "Lumi\u00e8re de la cam\u00e9ra",
    "lumui.table.reset_filters": "remise \u00e0 l'\u00e9tat initial",
    "lumui.table.close_filters": "Relever le filtre",
    "lumui.table.details": "Affichage d\u00e9taill\u00e9",
    "lumui.table.previous": "Record pr\u00e9c\u00e9dent",
    "lumui.table.next": "Enregistrement suivant",
    "lumui.table.no_data": "Aucune donn\u00e9e trouv\u00e9e.\n",
    "lumui.table.no_filter_results": "Votre recherche n'a donn\u00e9 aucun r\u00e9sultat",
    "lumui.table.filters_active": "R\u00e9sultats filtr\u00e9s",
    "lumui.table.rows_per_page": "Lignes par page",
    "lumui.table.page_text": "{0}-{1} de {2}",
    "lumui.table.btn.export": "Exporter la liste",
    "lumui.table_action.menu": "Menu",
    "lumui.table_columns.save": "Enregistrer le fichier",
    "lumui.form.row.invalid_email": "Veuillez saisir une adresse e-mail correcte.",
    "lumui.form.row.required": "C'est un champ obligatoire",
    "lumui.form.autocomplete.selected_count": "{c} Entr\u00e9es s\u00e9lectionn\u00e9es",
    "lumui.form.autocomplete.select_all": "Tout s\u00e9lectionner",
    "lumui.form.autocomplete.deselect_all": "Rien s\u00e9lectionner",
    "lumui.form.autocomplete.search_hint": "S'il vous pla\u00eet entrez ce que vous cherchez",
    "lumui.form.autocomplete.no_data": "Aucune donn\u00e9e disponible",
    "lumui.form.autocomplete.nothing_selected": "Aucune entr\u00e9e s\u00e9lectionn\u00e9e",
    "lumui.form.autocomplete.selected_items": "Entr\u00e9es s\u00e9lectionn\u00e9es",
    "lumui.form.date.close": "Fermer",
    "lumui.form.date.save": "OK",
    "lumui.form.date.after_max": "La date sélectionnée est postérieure à la date maximale autorisée {max_date}",
    "lumui.form.date.before_min": "La date sélectionnée est antérieure à la date minimale autorisée {min_date}",
    "lumui.form.file.drag": "Faites glisser vos fichiers ici ou cliquez pour s\u00e9lectionner",
    "lumui.form.file.error": "Le t\u00e9l\u00e9chargement a \u00e9chou\u00e9.",
    "lumui.form.file.extensions.accepted": "Les fichiers de type {types} sont autoris\u00e9s.",
    "lumui.form.file.extensions.all": "Tous les types de fichiers sont autoris\u00e9s.",
    "lumui.form.file.maxSize": "La taille de fichier maximale autoris\u00e9e est {size}.",
    "lumui.form.file.maxSizeError": "fileuploader.maxSizeError",
    "lumui.form.file.previewError": "Un aper\u00e7u du fichier {name} n'est pas support\u00e9.",
    "lumui.form.file.uploading": "{count} fichiers sont t\u00e9l\u00e9charg\u00e9s .... ",
    "lumui.form.file.warning.removed_file": "Le fichier {name} n'a pas été téléchargé. Le type de fichier {type} ne fait pas partie des types autorisés {acceptedTypes}.",
    "lumui.form.file.select_file": "T\u00e9l\u00e9charger la liste sign\u00e9e des participants ",
    "lumui.form.file.unknown_size": "pas d'information",
    "lumui.form.localized.copy_to_all": "Accepter l'entr\u00e9e pour toutes les langues",
    "lumui.form.ordered_choice.no_data": "aucune entr\u00e9e disponible",
    "lumui.form.ordered_choice.select_placeholder": "S\u00e9lectionnez une nouvelle entr\u00e9e",
    "lumui.form.password.repeat": "R\u00e9p\u00e9ter le mot de passe",
    "lumui.form.password.not_matching_error": "Les deux mots de passe doivent correspondre",
    "lumui.form.signature.clear": "Supprimer la signature",
    "lumui.form.signature.upload": "T\u00e9l\u00e9charger la signature",
    "lumui.form.time.close": "Fermer",
    "lumui.form.time.save": "OK",
    "lumui.form.password.requirements.title": "Le mot de passe doit répondre aux exigences suivantes:",
    "lumui.form.password.requirements.lower_chars": "au moins une lettre minuscule | au moins {count} lettres minuscules",
    "lumui.form.password.requirements.upper_chars": "au moins une lettre majuscule | au moins {count} lettres majuscules",
    "lumui.form.password.requirements.number_chars": "au moins un chiffre | au moins {count} chiffres",
    "lumui.form.password.requirements.special_chars": "au moins un caractère spécial | au moins {count} caractères spéciaux",
    "lumui.form.password.requirements.total_chars": "au moins un caractère | au moins {count} caractères",
    "lumui.locale.lang.german": "Allemand",
    "lumui.locale.lang.english": "Anglais",
    "lumui.locale.lang.italian": "Italien",
    "lumui.locale.lang.french": "Fran\u00e7ais",
    "lumui.status.no_action_required": "aucune action requise",
    "lumui.status.hint": "Indication",
    "lumui.status.action_required_soon": "Action rapide requise !",
    "lumui.status.action_required_immediately": "action imm\u00e9diate requise !",
    "lumui.locale.de-DE": "German",
    "lumui.locale.de-CH": "German (Suisse)",
    "lumui.locale.fr-CH": "French (Suisse)",
    "lumui.locale.it-CH": "Italian (Suisse)",
    "lumui.locale.en-US": "English (USA)",
    "lumui.locale.en-GB": "English (GB)",
    "lumui.locale.fi-FI": "Finnish",
    "lumui.locale.et-EE": "Estonian",
    "lumui.locale.lt-LT": "Lithuanian",
    "lumui.locale.lv-LV": "Latvian",
    "lumui.locale.lang.finnish": "Finlandais",
    "lumui.locale.lang.estonian": "Estonien",
    "lumui.locale.lang.lithuanian": "Lituanien",
    "lumui.locale.lang.latvian": "Letton",
    "lumui.locale.lang.hungarian": "Hongrois",
});
