(function($R)
{
    $R.lang['zh_tw'] = {
        "format": "樣式",
        "image": "插入圖片",
        "file": "插入文件",
        "link": "連結",
        "bold": "將文字變成粗體",
        "italic": "將文字變成斜體",
        "deleted": "刪除線",
        "underline": "底線",
        "superscript": "Superscript",
        "subscript": "Subscript",
        "bold-abbr": "B",
        "italic-abbr": "I",
        "deleted-abbr": "S",
        "underline-abbr": "U",
        "superscript-abbr": "Sup",
        "subscript-abbr": "Sub",
        "lists": "列表",
        "link-insert": "插入連結",
        "link-edit": "編輯連結",
        "link-in-new-tab": "開啟新分頁",
        "unlink": "移除連結",
        "cancel": "取消",
        "close": "關閉",
        "insert": "插入",
        "save": "儲存",
        "delete": "刪除",
        "text": "內文",
        "edit": "編輯",
        "title": "標題",
        "paragraph": "段落",
        "quote": "引用",
        "code": "原始碼",
        "heading1": "標題 1",
        "heading2": "標題 2",
        "heading3": "標題 3",
        "heading4": "標題 4",
        "heading5": "標題 5",
        "heading6": "標題 6",
        "filename": "檔案名稱",
        "optional": "optional",
        "unorderedlist": "項目列表",
        "orderedlist": "編號列表",
        "outdent": "減少縮排",
        "indent": "增加縮排",
        "horizontalrule": "插入水平線",
        "upload": "Upload",
        "upload-label": "Drop files here or click to upload",
        "upload-change-label": "Drop a new image to change",
        "accessibility-help-label": "Rich text editor",
        "caption": "Caption",
        "bulletslist": "Bullets",
        "numberslist": "Numbers",
        "image-position": "Position",
        "none": "None",
        "left": "Left",
        "right": "Right",
        "center": "Center",
        "undo": "Undo",
        "redo": "Redo"
    };
})(Redactor);