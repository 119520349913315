var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _vm._l(_vm.configs, function (config, index) {
        return [
          _c(
            "v-row",
            {
              key: index,
              staticStyle: { "border-bottom": "1px solid lightgray" },
              attrs: { id: config.id + "_row" },
            },
            [
              _c("v-col", { attrs: { cols: "8" } }, [
                _c("span", { staticClass: "subtitle-1 text-uppercase" }, [
                  _vm._v(_vm._s(config.label)),
                ]),
              ]),
              _vm._v(" "),
              _c("v-divider", { attrs: { vertical: "" } }),
              _vm._v(" "),
              _c(
                "v-col",
                { attrs: { cols: "8" } },
                [
                  _c("span", { staticClass: "subtitle-2" }, [
                    _vm._v("Element"),
                  ]),
                  _vm._v(" "),
                  _c("form-row", {
                    ref: config.id,
                    refInFor: true,
                    attrs: { id: config.id, config: config },
                    model: {
                      value: _vm.values[index],
                      callback: function ($$v) {
                        _vm.$set(_vm.values, index, $$v)
                      },
                      expression: "values[index]",
                    },
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "subtitle-2" }, [
                    _vm._v("Behavior"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "ul",
                    { staticClass: "text-body-2" },
                    _vm._l(config.behavior, function (item, index) {
                      return _c("li", { key: config.id + "_" + index }, [
                        _vm._v(_vm._s(item)),
                      ])
                    }),
                    0
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("v-divider", { attrs: { vertical: "" } }),
              _vm._v(" "),
              _c(
                "v-col",
                { attrs: { cols: "4" } },
                [
                  _c("span", { staticClass: "subtitle-2" }, [
                    _vm._v("Inspector"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "v-list",
                    [
                      _c(
                        "v-list-item",
                        { attrs: { "two-lines": "" } },
                        [
                          _c(
                            "v-list-item-content",
                            [
                              _c("v-list-item-subtitle", [
                                _vm._v(
                                  "\n                                Value\n                            "
                                ),
                              ]),
                              _vm._v(" "),
                              _c("v-list-item-title", [
                                _c(
                                  "span",
                                  { attrs: { id: config.id + "_value" } },
                                  [_vm._v(_vm._s(_vm.values[index]))]
                                ),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-list-item",
                        { attrs: { "two-lines": "" } },
                        [
                          _c(
                            "v-list-item-content",
                            [
                              _c("v-list-item-subtitle", [
                                _vm._v(
                                  "\n                                Valid\n                            "
                                ),
                              ]),
                              _vm._v(" "),
                              _c("v-list-item-title", [
                                _c(
                                  "span",
                                  { attrs: { id: config.id + "_valid" } },
                                  [_vm._v(_vm._s(_vm.isValid(config.id)))]
                                ),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }