(function($R)
{
    $R.lang['fa'] = {
        "format": "فرمت",
        "image": "تصویر",
        "file": "فایل",
        "link": "لینک",
        "bold": "ضخیم",
        "italic": "کج",
        "deleted": "خط خورده",
        "underline": "زیرخط دار",
        "superscript": "Superscript",
        "subscript": "Subscript",
        "bold-abbr": "B",
        "italic-abbr": "I",
        "deleted-abbr": "S",
        "underline-abbr": "U",
        "superscript-abbr": "Sup",
        "subscript-abbr": "Sub",
        "lists": "لیست",
        "link-insert": "اضافه کردن لینک",
        "link-edit": "ویرایش لینک",
        "link-in-new-tab": "باز شدن لینک در صفحه جدید",
        "unlink": "غیرفعال کردن لینک",
        "cancel": "لغو",
        "close": "بستن",
        "insert": "اضافه",
        "save": "ذخیره",
        "delete": "حذف",
        "text": "متن",
        "edit": "ویرایش",
        "title": "عنوان",
        "paragraph": "متن معمولی",
        "quote": "نقل قول",
        "code": "کد",
        "heading1": "سربرگ ۱",
        "heading2": "سربرگ ۲",
        "heading3": "سربرگ ۳",
        "heading4": "سربرگ ۴",
        "heading5": "سربرگ ۵",
        "heading6": "سربرگ ۶",
        "filename": "نام",
        "optional": "اختیاری",
        "unorderedlist": "لیست نامرتب",
        "orderedlist": "لیست مرتب",
        "outdent": "بیرون آمدگی",
        "indent": "تورفتگی",
        "horizontalrule": "خط",
        "upload": "Upload",
        "upload-label": "Drop files here or click to upload",
        "upload-change-label": "Drop a new image to change",
        "accessibility-help-label": "ویرایشگر متن پیشرفته",
        "caption": "Caption",
        "bulletslist": "Bullets",
        "numberslist": "Numbers",
        "image-position": "Position",
        "none": "None",
        "left": "Left",
        "right": "Right",
        "center": "Center",
        "undo": "Undo",
        "redo": "Redo"
    };
})(Redactor);