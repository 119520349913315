(function($R)
{
    $R.lang['zh_cn'] = {
        "format": "格式",
        "image": "图片",
        "file": "文件",
        "link": "链接",
        "bold": "加粗",
        "italic": "斜体",
        "deleted": "删除线",
        "underline": "底线",
        "superscript": "Superscript",
        "subscript": "Subscript",
        "bold-abbr": "B",
        "italic-abbr": "I",
        "deleted-abbr": "S",
        "underline-abbr": "U",
        "superscript-abbr": "Sup",
        "subscript-abbr": "Sub",
        "lists": "列表",
        "link-insert": "插入链接",
        "link-edit": "编辑链接",
        "link-in-new-tab": "在新页面中打开",
        "unlink": "取消链接",
        "cancel": "取消",
        "close": "关闭",
        "insert": "插入",
        "save": "保存",
        "delete": "删除",
        "text": "文本",
        "edit": "编辑",
        "title": "标题",
        "paragraph": "段落",
        "quote": "引用",
        "code": "代码",
        "heading1": "标题 1",
        "heading2": "标题 2",
        "heading3": "标题 3",
        "heading4": "标题 4",
        "heading5": "标题 5",
        "heading6": "标题 6",
        "filename": "文件名",
        "optional": "optional",
        "unorderedlist": "无序列表",
        "orderedlist": "有序列表",
        "outdent": "向左缩进",
        "indent": "向右缩进",
        "horizontalrule": "水平分隔线",
        "upload": "Upload",
        "upload-label": "Drop files here or click to upload",
        "upload-change-label": "Drop a new image to change",
        "accessibility-help-label": "富文本编辑器",
        "caption": "Caption",
        "bulletslist": "Bullets",
        "numberslist": "Numbers",
        "image-position": "Position",
        "none": "None",
        "left": "Left",
        "right": "Right",
        "center": "Center",
        "undo": "Undo",
        "redo": "Redo"
    };
})(Redactor);