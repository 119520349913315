var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-toolbar",
        [
          _c("v-toolbar-title", [
            _vm._v("\n            Link Columns\n        "),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c("asa-table", { attrs: { data: _vm.data, loading: false } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }