(function($R)
{
    $R.add('plugin', 'tokens', {
        translations: {
            de: {
                "tokens": "Platzhalter einfügen"
            }
        },
        init: function(app)
        {
            this.app = app;
            this.lang = app.lang;
            this.toolbar = app.toolbar;
            this.insertion = app.insertion;
            this.tokens = app.rootOpts.tokens;
        },
        // public
        start: function()
        {
            const btnObj = {
                title: this.lang.get('tokens')
            };

            const $dropdown = this._buildDropdown();

            this.$button = this.toolbar.addButton('specialchars', btnObj);
            this.$button.setIcon('<i class="fa fa-angle-double-down"></i>');
            this.$button.setDropdown($dropdown);
        },

        // private
        _set: function(character)
        {
            this.insertion.insertChar(character);
        },
        _buildDropdown: function()
        {
            const self = this;
            const $dropdown = $R.dom('<div class="redactor-dropdown-link">');
            const func = function (e) {
                e.preventDefault();

                const $el = $R.dom(e.target);
                self._set($el.data('token'));
            };

            for (let token in this.tokens)
            {
                if (!this.tokens.hasOwnProperty(token)) {
                    continue;
                }
                let label = this.tokens[token];
                let $a = $R.dom('<a>');
                $a.attr({ 'href': '#', 'data-token': token });
                $a.addClass('redactor-dropdown-item-link');
                $a.html(label + ' (' + token + ')');
                $a.on('click', func);

                $dropdown.append($a);
            }

            return $dropdown;
        }
    });
})(Redactor);
