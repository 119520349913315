import {merge} from "webpack-merge";
import et from './base/et.js';

export default merge(et, {
    "lumui.confirm.accept": "OK",
    "lumui.confirm.reject": "Katkesta",
    "lumui.formatter.boolean.true": "Jah",
    "lumui.formatter.boolean.false": "Ei",
    "lumui.form.close": "Sulge",
    "lumui.form.cancel": "Katkesta",
    "lumui.form.save": "Salvesta",
    "lumui.lightbox.title": "Olemasolevad pildid",
    "lumui.loading.message": "Andmete laadimine",
    "lumui.qr_scanner.camera": "Kaamera",
    "lumui.qr_scanner.mirror": "Peegelkaamera ",
    "lumui.qr_scanner.torch": "Kaamera valgus",
    "lumui.table.reset_filters": "lähtesta",
    "lumui.table.close_filters": "Filtri kokkuklappimine",
    "lumui.table.details": "Detailvaade",
    "lumui.table.previous": "Eelmine andmekogum",
    "lumui.table.next": "Järgmine andmekogum",
    "lumui.table.no_data": "Andmeid ei leitud..",
    "lumui.table.no_filter_results": "Teie otsing ei andnud tulemusi",
    "lumui.table.filters_active": "Tulemused filtreeritud",
    "lumui.table.rows_per_page": "Rida leheküljel",
    "lumui.table.btn.export": "Loetelu eksportimine",
    "lumui.table.page_text": "{0}-{1} of {2}",
    "lumui.table_action.menu": "Menüü",
    "lumui.table_columns.save": "Faili salvestamine",
    "lumui.form.row.invalid_email": "Palun sisestage õige e-posti aadress.",
    "lumui.form.row.required": "See on kohustuslik väli",
    "lumui.form.autocomplete.selected_count": "Valitud on {c} üksust",
    "lumui.form.autocomplete.select_all": "Vali kõik",
    "lumui.form.autocomplete.deselect_all": "Ära vali midagi",
    "lumui.form.autocomplete.search_hint": "Palun sisestage otsisõna",
    "lumui.form.autocomplete.no_data": "Andmed puuduvad",
    "lumui.form.autocomplete.nothing_selected": "Ühtegi kirjet pole valitud",
    "lumui.form.autocomplete.selected_items": "Valitud kirjed",
    "lumui.form.date.close": "Sulge",
    "lumui.form.date.save": "OK",
    "lumui.form.date.after_max": "Valitud kuupäev on hilisem kui maksimaalne lubatud kuupäev {max_date}",
    "lumui.form.date.before_min": "Valitud kuupäev on enne minimaalset lubatud kuupäeva {min_date}",
    "lumui.form.file.drag": "Lohistage fail siia või klõpsake valimiseks",
    "lumui.form.file.error": "Üleslaadimine ebaõnnestus.",
    "lumui.form.file.extensions.accepted": "Lubatud failitüübid: {types}",
    "lumui.form.file.extensions.all": "Kõik failitüübid on lubatud",
    "lumui.form.file.maxSize": "Maksimaalne lubatud failisuurus {size}.",
    "lumui.form.file.maxSizeError": "Fail on suurem kui maksimaalne lubatud suurus {size}.",
    "lumui.form.file.previewError": "Faili {name} eelvaade pole saadaval.",
    "lumui.form.file.uploading": "{count} faili laaditakse üles…",
    "lumui.form.file.warning.removed_file": "Faili {name} ei laaditud üles. Failitüüp {type} ei kuulu lubatud tüüpide {acceptedTypes} piiridesse.",
    "lumui.form.file.select_file": "Valige fail",
    "lumui.form.file.unknown_size": "täpsustamata",
    "lumui.form.localized.copy_to_all": "Sisestuse ülevõtmine kõikide keelte jaoks",
    "lumui.form.ordered_choice.no_data": "Kirjed puuduvad",
    "lumui.form.ordered_choice.select_placeholder": "uue kirje valimine",
    "lumui.form.password.repeat": "Parooli kordamine",
    "lumui.form.password.not_matching_error": "Mõlemad paroolid peavad kattuma.",
    "lumui.form.signature.clear": "Kustuta allkiri",
    "lumui.form.signature.upload": "Laadi allkiri üles",
    "lumui.form.time.close": "Sulge",
    "lumui.form.time.save": "OK",
    "lumui.form.password.requirements.title": "Parool peab vastama järgmistele nõudmistele:",
    "lumui.form.password.requirements.lower_chars": "vähemalt üks väike täht | vähemalt {count} väikest tähte",
    "lumui.form.password.requirements.upper_chars": "vähemalt üks suur täht | vähemalt {count} suurt tähte",
    "lumui.form.password.requirements.number_chars": "vähemalt üks number | vähemalt {count} numbrit",
    "lumui.form.password.requirements.special_chars": "vähemalt üks erimärk | vähemalt {count} erimärki",
    "lumui.form.password.requirements.total_chars": "vähemalt üks märk | vähemalt {count} märki",
    "lumui.locale.lang.german": "Saksa",
    "lumui.locale.lang.english": "Inglise",
    "lumui.locale.lang.italian": "Itaalia",
    "lumui.locale.lang.french": "Prantsuse",
    "lumui.status.no_action_required": "ükski toiming pole nõutav",
    "lumui.status.hint": "Juhis",
    "lumui.status.action_required_soon": "Nõutud peatsed toimingud!",
    "lumui.status.action_required_immediately": "nõutav kohene toiming!",
    "lumui.locale.de-DE": "German",
    "lumui.locale.de-CH": "German (Suisse)",
    "lumui.locale.fr-CH": "French (Suisse)",
    "lumui.locale.it-CH": "Italian (Suisse)",
    "lumui.locale.en-US": "English (USA)",
    "lumui.locale.en-GB": "English (GB)",
    "lumui.locale.fi-FI": "Finnish",
    "lumui.locale.et-EE": "Estonian",
    "lumui.locale.lt-LT": "Lithuanian",
    "lumui.locale.lv-LV": "Latvian",
    "lumui.locale.lang.finnish": "Soome",
    "lumui.locale.lang.estonian": "Eesti",
    "lumui.locale.lang.lithuanian": "Leedu",
    "lumui.locale.lang.latvian": "Läti",
    "lumui.locale.lang.hungarian": "Ungari",
});
