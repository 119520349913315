import de from './de';
import fr from './fr';
import en from './en';
import hu from './hu';
import it from './it';
import et from './et';
import fi from './fi';
import lt from './lt';
import lv from './lv';
const translations = {
    de,
    fr,
    en,
    hu,
    it,
    fi,
    lt,
    lv,
    et
};
export default translations;
