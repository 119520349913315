var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("asa-form", {
        attrs: { config: _vm.config, "abort-btn": false },
        scopedSlots: _vm._u([
          {
            key: "title",
            fn: function () {
              return [_vm._v("\n            Unterschrift\n        ")]
            },
            proxy: true,
          },
        ]),
        model: {
          value: _vm.value,
          callback: function ($$v) {
            _vm.value = $$v
          },
          expression: "value",
        },
      }),
      _vm._v(" "),
      _c("pre", [_vm._v(_vm._s(_vm.value))]),
      _vm._v(" "),
      _c("img", {
        staticStyle: {
          border: "1px solid black",
          "min-width": "50px",
          "min-height": "50px",
        },
        attrs: { src: _vm.value.sig },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }