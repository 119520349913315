var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-container",
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12", md: "8" } },
                [
                  _c(
                    "asa-form",
                    {
                      attrs: { config: _vm.formConf, abortBtn: false },
                      model: {
                        value: _vm.data,
                        callback: function ($$v) {
                          _vm.data = $$v
                        },
                        expression: "data",
                      },
                    },
                    [_c("template", { slot: "title" }, [_vm._v("WYSIWYG")])],
                    2
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-col",
                { attrs: { cols: "12", md: "4" } },
                [
                  _c(
                    "v-card",
                    { staticClass: "fill-height" },
                    [
                      _c("v-card-title", { staticClass: "elevation-1" }, [
                        _vm._v(
                          "\n                        Raw\n                    "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("v-card-text", { staticClass: "py-4" }, [
                        _c("pre", [
                          _vm._v(_vm._s(JSON.stringify(_vm.data, null, 4))),
                        ]),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }