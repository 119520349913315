<template>
    <div :id="name + '-redactor'" :class="containerClasses">
        <v-label left="12px" :absolute="true" :focused="isFocused" :value="true">
            {{ label }}
        </v-label>
        <textarea :id="name" ref="redactor" :name="name" :value="value" placeholder="" style="width: 100%" />
    </div>
</template>

<script>
import {VInput, VLabel} from 'vuetify/lib';
import Validatable from 'vuetify/lib/mixins/validatable';

require('../lib/redactor/redactor');
require('../lib/redactor/_langs/de');
require('../lib/redactor/_langs/it');
require('../lib/redactor/_langs/fr');
require('../lib/redactor/_langs/en');
require('../lib/redactor/_plugins/tokens/tokens');
require('../lib/redactor/_plugins/table/table');

/**
 * WYSIWYG html editor
 *
 * **Note:** Assumes that the locale can be read from `this.$store.getters.currentLocale`
 *
 * ## Config
 *
 * | key                    | type                       | required | default    | description |
 * |------------------------|----------------------------|----------|------------|-------------|
 * | type                   | `String`                   | yes      |            | field type  |
 * | label                  | `String`, `false`          | no       | `false`    | fields label |
 * | visible                | `Boolean`, `eval(String)`  | no       | `false`    | field is rendered.  |
 * | redactor               | `Object`                   | no       | `{lang: this.locale, styles: false, minHeight: "100px", source: false}` | Redactor config |
 */
export default {
    name: 'VueRedactor',
    components: {VLabel},
    extends: VInput,
    mixins: [
        Validatable,
    ],
    props: {
        value: {
            default: '',
            type: String
        },
        /** placeholder */
        placeholder: {
            type: String,
            default: null
        },
        /** element name */
        name: {
            type: String,
            default: null
        },
        /** element label */
        label: {
            type: String,
            default: null
        },
        /** redactor config */
        config: {
            default: () => ({}),
            type: Object
        }
    },
    data() {
        return {
            isFocused: false,
            redactor: null,
            empty: true
        }
    },
    computed: {
        containerClasses() {
            return {
                'v-text-field': true,
                'v-text-field--filled ': true,
                'v-input--is-label-active': true,
                redactorContainer: true,
                movedLabel: this.isFocused || !this.empty,
                focused: this.isFocused,
                'primary--text': this.isFocused,
            }
        },
        locale() {
            try {
                const currentLocale = this.$store.getters['lumui/i18n/locale'];
                if (currentLocale && currentLocale.length === 2) {
                    return currentLocale;
                } else if (currentLocale && currentLocale.length !== 2) {
                    return currentLocale.substring(0, 2)
                } else {
                    return 'de'
                }
            } catch (e) {
                return 'de';
            }
        },
    },
    watch: {
        value(newValue) {
            if (this.redactor &&
                this.redactor.editor &&
                !this.redactor.editor.isFocus()
            ) {
                this.redactor.source.setCode(newValue)
            }
        }
    },
    async mounted() {
        await this.init();
    },
    methods: {
        /** focuses the editor */
        focus() {
            $R(this.$refs.redactor, {focus: true });
        },
        /* @private */
        async init() {
            try {
                await require('../lib/redactor/_langs/' + this.locale);
            } catch(e) {
                console.warn(`REDACTOR: Locale "${this.locale}" not found, falling back to default.`)
            }

            let me = this;
            let callbacks = {
                ...me.config.callbacks,
                changed: function (html) {
                    me.empty = html.length === 0;
                    me.handleInput(html);
                    return html
                },
                focus: () => {
                    me.isFocused = true;
                    this.isFocused = true;
                    this.hasFocused = true;
                },
                blur: () => {
                    me.isFocused = false;
                    this.isFocused = false;
                },
            };

            const defaultConfig = {
                buttons: [
                    'html', 'format', 'bold', 'italic', 'deleted', 'sup', 'sub', 'underline', 'ol', 'ul', 'lists', 'image', 'file', 'link', 'line',
                    'indent', 'outdent',  'redo', 'undo'],
                lang: this.locale,
                styles: false,
                minHeight: "100px",
                source: false
            };
            for (const key in defaultConfig) {
                if (typeof this.config[key] === 'undefined') {
                    this.$set(this.config, key, defaultConfig[key]);
                }
            }
            // extend config
            this.$set(this.config, 'callbacks', callbacks);
            this.$set(this.config, 'imageUpload', function (data, files, e, upload) {
                return new Promise(function (resolve, reject) {
                    const reader = new FileReader();
                    reader.readAsDataURL(files[0]);
                    reader.onload = () => resolve(reader.result);
                    reader.onerror = error => reject(error);
                }).then(function (response) {
                    // success
                    upload.complete(response);

                }).catch(function (response) {
                    // fail
                    upload.complete(response);
                });
            });
            this.$set(this.config, 'imageFigure', false);
            this.$set(this.config, 'lang', this.locale);
            if (!this.config.hasOwnProperty('linkNewTab')) {
                this.$set(this.config, 'linkNewTab', true);
            }
            const plugins = (this.config.plugins || []);
            plugins.push('table');
            this.$set(this.config, 'plugins', plugins);


            // call Redactor
            let app = $R(this.$refs.redactor, this.config);

            // set instance
            this.redactor = app;
            this.$parent.redactor = app;
            if (this.value) {
                this.empty = false;
            }
        },
        /* @private */
        handleInput(val) {
            this.internalValue = val.replace(/<[^>]*>?/gm, '');
            /**
             * user input
             * @param {String} val
             */
            this.$emit('input', val);
        }
    }
}
</script>

<style>
@import "../lib/redactor/redactor.css";

.redactor-box {
    width: 100%;
}

.redactor-in {
    color: black !important;
}

.redactorContainer {
    position: relative;
    margin-bottom: 10px;
    padding-top: 22px;
}

.redactorContainer > label {
    /* top: 70px; */
    z-index: 7;
}

.redactor-toolbar {
    z-index: auto !important;
    background-color: rgba(0,0,0,.06);
    padding:5px 5px 5px 12px !important;
}

.redactor-toolbar > a {
    margin-bottom: 0;
}

.redactorContainer:before {
    bottom: -1px;
    content: '';
    left: 0;
    position: absolute;
    transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
    width: 100%;
    border: 0 solid rgba(0, 0, 0, 0.42);
    border-top-width: thin;
}

.redactorContainer:hover:before {
    bottom: -1px;
    content: '';
    left: 0;
    position: absolute;
    transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
    width: 100%;
    border: 0 solid rgba(0, 0, 0, 0.87);
    border-top-width: thin;
}

.redactorContainer:after {
    bottom: -1px;
    content: '';
    left: 0;
    position: absolute;
    transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
    width: 100%;
    border-color: currentColor;
    border-style: solid;
    border-width: thin 0 thin 0;
    transform: scaleX(0);
}

.redactorContainer.focused:after {
    bottom: -1px;
    content: '';
    left: 0;
    position: absolute;
    transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
    width: 100%;
    border-color: currentColor;
    border-style: solid;
    border-width: thin 0 thin 0;
    transform: scaleX(1);
}
.redactorContainer,
.redactor-in {
    background-color: rgba(0,0,0,.06);
}
.redactor-in {
    padding: 12px 12px 24px 12px;
    height: 170px;
    overflow: auto;
}
</style>
<style>
.redactor-focus.redactor-styles-on, .redactor-focus:focus.redactor-styles-on {
    border-color:rgba(0,0,0,0.075) !important;
}
/** make tables visible */
.redactor-in td {
    border: 1px solid rgba(0,0,0, 0.3);
    padding-left: 5px;
    padding-right: 5px;
    border-collapse: collapse;
}
</style>
