var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-input",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.multiple || !_vm.hasFiles,
              expression: "multiple || !hasFiles",
            },
          ],
          ref: "inputField",
          staticClass: "input--file",
          attrs: {
            "avoid-drag": _vm.avoidDrag,
            disabled: _vm.disabled || _vm.isSaving,
            error: _vm.error,
            "error-messages": _vm.errorMessages,
            label: _vm.config.label,
            messages: _vm.msg,
            required: _vm.required,
            rules: _vm.validationRules,
          },
        },
        [
          !_vm.disabled
            ? _c(
                "div",
                {
                  staticClass: "dropbox",
                  class: { disabled: _vm.disabled, hover: _vm.dragOver },
                  on: {
                    drop: _vm.onDrop,
                    dragover: function ($event) {
                      $event.preventDefault()
                      _vm.dragOver = true
                    },
                    dragleave: function ($event) {
                      $event.preventDefault()
                      _vm.dragOver = false
                    },
                  },
                },
                [
                  _c("input", {
                    ref: "file",
                    staticClass: "input-file",
                    attrs: {
                      id: "file",
                      accept: _vm.acceptValues,
                      multiple: _vm.multiple,
                      name: "files",
                      type: "file",
                    },
                    on: {
                      change: function ($event) {
                        return _vm.filesChange(
                          $event.target.name,
                          $event.target.files
                        )
                      },
                    },
                  }),
                  _vm._v(" "),
                  _vm.isSaving
                    ? _c("p", [
                        _vm._v(
                          "\n                " +
                            _vm._s(
                              _vm.$lumui.i18n.t("lumui.form.file.uploading", {
                                count: _vm.fileCount,
                              })
                            ) +
                            "\n            "
                        ),
                      ])
                    : _c(
                        "p",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.launchFilePicker()
                            },
                          },
                        },
                        [
                          _vm.$vuetify.breakpoint.smAndDown || _vm.avoidDrag
                            ? [
                                _vm._v(
                                  "\n                    " +
                                    _vm._s(
                                      _vm.$lumui.i18n.t(
                                        "lumui.form.file.select_file"
                                      )
                                    ) +
                                    "\n                "
                                ),
                              ]
                            : [
                                _vm._v(
                                  "\n                    " +
                                    _vm._s(
                                      _vm.$lumui.i18n.t("lumui.form.file.drag")
                                    ) +
                                    "\n                "
                                ),
                              ],
                        ],
                        2
                      ),
                ]
              )
            : _vm._e(),
        ]
      ),
      _vm._v(" "),
      _vm.isInitial || _vm.isSelected || _vm.isSuccess
        ? [
            !_vm.multiple && _vm.hasFiles
              ? _c(
                  "v-label",
                  {
                    attrs: {
                      color: _vm.hasError ? "error" : "",
                      focused: true,
                    },
                  },
                  [
                    _vm._v(
                      "\n            " + _vm._s(_vm.config.label) + "\n        "
                    ),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm._l(_vm.files, function (item, index) {
              return [
                _c(
                  "v-card",
                  { key: "card-" + index, attrs: { flat: "" } },
                  [
                    _c(
                      "v-card-text",
                      [
                        _c(
                          "v-row",
                          {
                            key: `${index}-$(item.name)`,
                            class: { "mb-2": _vm.files.length > 0 },
                            attrs: { "no-gutters": "" },
                          },
                          [
                            item !== null && _vm.isImage(item)
                              ? _c(
                                  "v-col",
                                  { staticClass: "pr-2", attrs: { md: "3" } },
                                  [
                                    _c("v-img", {
                                      attrs: {
                                        alt: item.name,
                                        src: _vm.base64(item),
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "v-col",
                              {
                                staticClass: "pr-2 align-self-center",
                                attrs: { cols: "sm" },
                              },
                              [
                                _c("span", { staticClass: "subheading" }, [
                                  _vm._v(_vm._s(item.name)),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "v-chip",
                                  { attrs: { outlined: "", small: "" } },
                                  [
                                    item.size
                                      ? [
                                          _vm._v(
                                            "\n                                    " +
                                              _vm._s(
                                                _vm.printfilesize(item.size)
                                              ) +
                                              "\n                                "
                                          ),
                                        ]
                                      : item.src
                                      ? [
                                          _vm._v(
                                            "\n                                    " +
                                              _vm._s(
                                                _vm.printfilesize(
                                                  item.src.length
                                                )
                                              ) +
                                              "\n                                "
                                          ),
                                        ]
                                      : [
                                          _vm._v(
                                            "\n                                    " +
                                              _vm._s(
                                                _vm.$lumui.i18n.t(
                                                  "lumui.form.file.unknown_size"
                                                )
                                              ) +
                                              "\n                                "
                                          ),
                                        ],
                                  ],
                                  2
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "v-col",
                              {
                                staticClass: "text-no-wrap align-self-center",
                                attrs: { md: "2" },
                              },
                              [
                                !_vm.disabled
                                  ? _c(
                                      "v-btn",
                                      {
                                        staticClass: "ma-0",
                                        attrs: { icon: "" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.remove(item)
                                          },
                                        },
                                      },
                                      [_c("v-icon", [_vm._v("$delete")])],
                                      1
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.isImage(item)
                                  ? _c(
                                      "v-btn",
                                      {
                                        staticClass: "ma-0",
                                        attrs: { icon: "" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.preview(item)
                                          },
                                        },
                                      },
                                      [_c("v-icon", [_vm._v("$zoomIn")])],
                                      1
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "ma-0",
                                    attrs: { icon: "" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.download(item)
                                      },
                                    },
                                  },
                                  [_c("v-icon", [_vm._v("$download")])],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("v-messages", {
                          attrs: { value: _vm.errorMessages, color: "error" },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("v-divider", { key: index }),
              ]
            }),
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.isFailed
        ? [
            _c("v-alert", { attrs: { value: true, type: "error" } }, [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.$lumui.i18n.t("lumui.form.file.error"))
              ),
              _c("br"),
              _vm._v(" "),
              _c("pre", [_vm._v(_vm._s(_vm.uploadError))]),
            ]),
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm._l(_vm.removedFiles, function (file, i) {
        return _c(
          "v-alert",
          { key: i, attrs: { value: true, type: "warning" } },
          [
            _vm._v(
              "\n        " +
                _vm._s(
                  _vm.$lumui.i18n.t("lumui.form.file.warning.removed_file", {
                    name: file.name,
                    type: file.type,
                    acceptedTypes: _vm.printAccept,
                  })
                ) +
                "\n    "
            ),
          ]
        )
      }),
      _vm._v(" "),
      _c(
        "v-dialog",
        {
          attrs: {
            fullscreen: _vm.$vuetify.breakpoint.smAndDown,
            "max-width": "800",
            scrollable: "",
            transition: "dialog-bottom-transition",
          },
          model: {
            value: _vm.dialog,
            callback: function ($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog",
          },
        },
        [
          _vm.previewFile
            ? _c(
                "v-card",
                { attrs: { tile: "" } },
                [
                  _c(
                    "v-toolbar",
                    { attrs: { color: "primary", dark: "", flat: "" } },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { dark: "", icon: "" },
                          nativeOn: {
                            click: function ($event) {
                              _vm.dialog = false
                            },
                          },
                        },
                        [_c("v-icon", [_vm._v("$close")])],
                        1
                      ),
                      _vm._v(" "),
                      _c("v-toolbar-title", [
                        _vm._v(_vm._s(_vm.previewFile.name)),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-card-text",
                    { attrs: { id: "scrollarea" } },
                    [
                      _vm.previewFile !== null && _vm.isImage(_vm.previewFile)
                        ? _c("v-img", {
                            attrs: {
                              alt: _vm.previewFile.name,
                              src: _vm.base64(_vm.previewFile),
                              contain: "",
                            },
                          })
                        : _c("p", [
                            _vm._v(
                              "\n                    " +
                                _vm._s(
                                  _vm.$lumui.i18n.t(
                                    "lumui.form.file.previewError",
                                    { name: _vm.previewFile.name }
                                  )
                                ) +
                                "\n                "
                            ),
                          ]),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }