<template>
    <div>
        <asa-form v-model="value" :config="config" :loading="loading" :readonly="true" />
        <asa-form v-model="valueTabbed" :loading="loading" :readonly="true" :config="configTabbed">
            <template #title>
                Tabbed form
            </template>
        </asa-form>
    </div>
</template>
<script>
import AsaForm from "../../../src/component/AsaForm";

export default {
    components: {AsaForm},
    data() {
        return {
            valueTabbed: {tab1: {}},
            value: {},
            loading: false,
            config: {
                a: {type: "text", value: "asdf", label: "A", disabled: true},
                b: {type: "text", value: "asdf", label: "B"},
                c: {type: "text", value: "asdf", label: "C"},
            },
            configTabbed: {
                tab1: {
                    type: "tab",
                    label: "Tab A",
                    children: {
                        a1: {type: "text", label: "Vorname", required: true},
                        a2: {type: "text", label: "Nachname", required: true},
                        a3: {type: "email", label: "E-Mail"},
                        a4: {type: "textarea", label: "Adresse"},
                    }
                },
                tab2: {
                    type: "tab",
                    label: "Tab B",
                    children: {
                        b0: {type: "text", label: "Error", errors: ['error from config']},
                        b1: {type: "textarea", label: "Anmerkungen", required: true},
                    }
                },
            }
        }
    }
}
</script>
