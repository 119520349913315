<template>

    <div class="container">
        <p>noop formatter is invoked if formatter.js is called without type option</p>

        noop with option isHtmlRequired:<br>
        <asa-formatter value="<strong>Hans</strong>" :options="{'isHtmlRequired': true}" /><br>
        noop without option isHtmlRequired:<br>
        <asa-formatter value="<strong>Hans</strong>" />
    </div>
</template>

<script>
import AsaFormatter from "../../../src/component/AsaFormatter";

export default {
    name: "FormatterNoop",
    components: {
        AsaFormatter
    }
}
</script>
