export default {
    "$vuetify.badge": "Badge",
    "$vuetify.close": "Fermer",
    "$vuetify.dataIterator.noResultsText": "Aucun enregistrement correspondant trouvé",
    "$vuetify.dataIterator.loadingText": "Chargement de l'élément...",
    "$vuetify.dataTable.itemsPerPageText": "Lignes par page :",
    "$vuetify.dataTable.ariaLabel.sortDescending": "Tri décroissant.",
    "$vuetify.dataTable.ariaLabel.sortAscending": "Tri croissant.",
    "$vuetify.dataTable.ariaLabel.sortNone": "Non trié.",
    "$vuetify.dataTable.ariaLabel.activateNone": "Activer pour supprimer le tri.",
    "$vuetify.dataTable.ariaLabel.activateDescending": "Activer pour trier par ordre décroissant.",
    "$vuetify.dataTable.ariaLabel.activateAscending": "Activer pour trier par ordre croissant.",
    "$vuetify.dataTable.sortBy": "Trier par",
    "$vuetify.dataFooter.itemsPerPageText": "Élements par page :",
    "$vuetify.dataFooter.itemsPerPageAll": "Tous",
    "$vuetify.dataFooter.nextPage": "Page suivante",
    "$vuetify.dataFooter.prevPage": "Page précédente",
    "$vuetify.dataFooter.firstPage": "Première page",
    "$vuetify.dataFooter.lastPage": "Dernière page",
    "$vuetify.dataFooter.pageText": "{0}-{1} de {2}",
    "$vuetify.datePicker.itemsSelected": "{0} sélectionné(s)",
    "$vuetify.datePicker.nextMonthAriaLabel": "Le mois prochain",
    "$vuetify.datePicker.nextYearAriaLabel": "L'année prochaine",
    "$vuetify.datePicker.prevMonthAriaLabel": "Le mois précédent",
    "$vuetify.datePicker.prevYearAriaLabel": "Année précédente",
    "$vuetify.noDataText": "Aucune donnée disponible",
    "$vuetify.carousel.prev": "Visuel précédent",
    "$vuetify.carousel.next": "Visuel suivant",
    "$vuetify.carousel.ariaLabel.delimiter": "Diapositive {0} de {1}",
    "$vuetify.calendar.moreEvents": "{0} de plus",
    "$vuetify.input.clear": "Clear {0}",
    "$vuetify.input.prependAction": "{0} prepended action",
    "$vuetify.input.appendAction": "{0} appended action",
    "$vuetify.fileInput.counter": "{0} fichier(s)",
    "$vuetify.fileInput.counterSize": "{0} fichier(s) ({1} au total)",
    "$vuetify.timePicker.am": "AM",
    "$vuetify.timePicker.pm": "PM",
    "$vuetify.pagination.ariaLabel.wrapper": "Navigation de pagination",
    "$vuetify.pagination.ariaLabel.next": "Page suivante",
    "$vuetify.pagination.ariaLabel.previous": "Page précédente",
    "$vuetify.pagination.ariaLabel.page": "Aller à la page {0}",
    "$vuetify.pagination.ariaLabel.currentPage": "Page actuelle, Page {0}",
    "$vuetify.rating.ariaLabel.icon": "Note de {0} sur {1}",
    "$vuetify.loading": "Loading..."
};