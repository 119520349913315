<template>
    <v-expansion-panels v-if="hasAccordion" v-model="activeAccordion" accordion focusable>
        <v-expansion-panel v-for="(element, index) in accordions" :key="index">
            <v-expansion-panel-header :disable-icon-rotate="element.hasOwnProperty('error')">
                {{ element.label }}
                <template v-if="element.hasOwnProperty('error')" #actions>
                    <v-icon color="error">
                        $warning
                    </v-icon>
                </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content eager>
                <form-rows :config="element.children" :value="value" />
            </v-expansion-panel-content>
        </v-expansion-panel>
    </v-expansion-panels>
</template>

<script>
import FormRows from './FormRows'
import {VExpansionPanels, VExpansionPanel, VExpansionPanelHeader, VExpansionPanelContent} from 'vuetify/lib';

/** Renders elements with type accordion in the top level of the `config` object as accordions. */
export default {
    name: "AsaFormAccordion",
    components: {
        FormRows,
        VExpansionPanels,
        VExpansionPanel,
        VExpansionPanelHeader,
        VExpansionPanelContent
    },
    props: {
        /** form value */
        value: {
            type: Object,
            required: true,
        },
        /** form config */
        config: {
            type: Object,
            required: true
        },
    },
    data() {
        return {
            activeAccordion: 0,
            valid: false
        }
    },
    computed: {
        accordions() {
            let res = {};
            for (let i in this.config) {
                if (!this.config.hasOwnProperty(i)) {
                    continue;
                }
                let element = this.config[i];
                if (element.type && element.type === 'accordion') {
                    res[i] = element;
                }
            }
            return res;
        },
        hasAccordion() {
            if (!this.config) {
                return false;
            }
            for (const key in this.config) {
                if (!this.config.hasOwnProperty(key)) {
                    continue;
                }
                const entry = this.config[key];
                if (entry.type === 'accordion') {
                    return true;
                }
            }
            return false;
        }
    },
    watch: {
        config(n, old) {
            if (old == null) {
                for (const key in this.config) {
                    if (!this.config.hasOwnProperty(key)) {
                        continue;
                    }
                    const entry = this.config[key];
                    if (entry.type === 'accordion') {
                        this.activeAccordion = key;
                        /** when config is initially set and contains at least one accordion */
                        this.$emit('triggerValidation');
                        break;
                    }
                }
            }
        }
    },
}
</script>
