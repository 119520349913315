(function($R)
{
    $R.lang['da'] = {
        "format": "Format",
        "image": "Billede",
        "file": "Fil",
        "link": "link",
        "bold": "Fed",
        "italic": "Kursiv",
        "deleted": "Slettet",
        "underline": "Understreg",
        "superscript": "Hævet",
        "subscript": "Sænket",
        "bold-abbr": "B",
        "italic-abbr": "I",
        "deleted-abbr": "S",
        "underline-abbr": "U",
        "superscript-abbr": "Sup",
        "subscript-abbr": "Sub",
        "lists": "Lister",
        "link-insert": "Indsæt link",
        "link-edit": "Redigér link",
        "link-in-new-tab": "Åbn link i ny fane",
        "unlink": "Fjern link",
        "cancel": "Fortryd",
        "close": "Luk",
        "insert": "Indsæt",
        "save": "Gem",
        "delete": "Slet",
        "text": "Tekst",
        "edit": "Redigér",
        "title": "Titel",
        "paragraph": "Paragraf",
        "quote": "Citat",
        "code": "Kode",
        "heading1": "Overskrift 1",
        "heading2": "Overskrift 2",
        "heading3": "Overskrift 3",
        "heading4": "Overskrift 4",
        "heading5": "Overskrift 5",
        "heading6": "Overskrift 6",
        "filename": "Navn",
        "optional": "valgfri",
        "unorderedlist": "Usorteret liste",
        "orderedlist": "Sorteret liste",
        "outdent": "Formindsk indrykning",
        "indent": "Forøg indrykning",
        "horizontalrule": "Vandret linje",
        "upload": "Upload",
        "upload-label": "Slip filer her eller klik for at uploade",
        "upload-change-label": "Drop a new image to change",
        "accessibility-help-label": "Tekst editor",
        "caption": "Caption",
        "bulletslist": "Punktliste",
        "numberslist": "Nummereret liste",
        "image-position": "Position",
        "none": "Ingen",
        "left": "Venstre",
        "right": "Højre",
        "center": "Center",
        "undo": "Fortryd",
        "redo": "Annullér fortryd"
    };
})(Redactor);