import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const requireViews = require.context(
    '../views/',
    true,
    /[\w-]+\.vue$/
);

const routes = [];

requireViews.keys().forEach((fileName) => {
    const routeConfig = requireViews(fileName)
    const routeName = fileName
        .replace('./', '')
        .split('/')
        .join('_')
        .replace(/\.\w+$/, '');
    const routePath = fileName
        .replace('./', '/')
        .replace(/\.\w+$/, '');
    const title = fileName
        .split("/")
        .pop()
        .replace(/\.\w+$/, '')
        .replace(/_/, ' ');

    routes.push({
        path: routePath,
        name: routeName,
        component: routeConfig.default,
        meta: {
            title: title.charAt(0).toLocaleUpperCase() + title.slice(1)
        }
    })
});


export default new VueRouter({
    routes
});
