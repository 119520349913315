var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: _vm.containerClasses, attrs: { id: _vm.name + "-redactor" } },
    [
      _c(
        "v-label",
        {
          attrs: {
            left: "12px",
            absolute: true,
            focused: _vm.isFocused,
            value: true,
          },
        },
        [_vm._v("\n        " + _vm._s(_vm.label) + "\n    ")]
      ),
      _vm._v(" "),
      _c("textarea", {
        ref: "redactor",
        staticStyle: { width: "100%" },
        attrs: { id: _vm.name, name: _vm.name, placeholder: "" },
        domProps: { value: _vm.value },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }