<template>
    <div>
        <v-container>
            <v-card>
                <v-toolbar>
                    <v-toolbar-title>
                        Filters & Persistent Filters
                    </v-toolbar-title>
                    <v-spacer />
                    <v-toolbar-items>
                        <v-btn tile icon large @click="showFilter = !showFilter">
                            <v-icon>$search</v-icon>
                        </v-btn>
                    </v-toolbar-items>
                </v-toolbar>
                <v-card-text>
                    <asa-table :data="tableData" info-disabled :show-filter="showFilter" />
                </v-card-text>
            </v-card>
        </v-container>
    </div>
</template>

<script>
import AsaTable from "../../../src/component/AsaTable.vue";
import {VBtn, VCard, VCardText, VContainer, VIcon, VSpacer, VToolbar, VToolbarItems, VToolbarTitle} from 'vuetify/lib';

export default {
    name: "Localesort",
    components: {VContainer, VToolbar, VToolbarTitle, VToolbarItems, VIcon, VCard, VSpacer, VCardText, VBtn, AsaTable},
    data() {
        return {
            showFilter: true,
            tableData: {
                columns: [
                    {
                        key: 'id',
                        hidden: true,
                    },
                    {
                        key: 'locale',
                        label: 'Locale Spalte',
                        type: 'localized'
                    },
                    {
                        key: 'desc',
                        label: 'Beschreibung1',
                    }
                ],
                filter: [
                    {
                        key: 'locale',
                        label: 'Locale Spalte',
                    },
                ],
                rows: [
                    {
                        id: 1,
                        locale: {
                            de: 'Foo',
                            en: 'Foo'
                        },
                        desc: 'Deutsche und Englische Übersetzung',
                    },
                    {
                        id: 2,
                        locale: {
                            de: 'Bar',
                            en: 'Bar'
                        },
                        desc: 'Deutsche und Englische Übersetzung',
                    },
                    {
                        id: 3,
                        locale: {
                            de: 'Baz',
                        },
                        desc: 'Deutsche Übersetzung',
                    },
                    {
                        id: 4,
                        locale: {
                            en: 'Bum'
                        },
                        desc: 'Englische Übersetzung',
                    },
                    {
                        id: 4,
                        locale: {},
                        desc: 'Gar kein Text',
                    },
                ]
            }
        }
    }
}
</script>
