<template>
    <v-tabs>
        <v-tab v-for="locale in availableLocales" :key="locale" v-model="active" ripple @change="tabActivated(locale)">
            {{ $te('lumui.locale.' + locale) ? $t('lumui.locale.' + locale) : locale }}
        </v-tab>
        <v-tab-item v-for="(locale) in availableLocales" :key="locale" :eager="true">
            <v-card flat>
                <!--
                    Content of the tabs. Current locale for the current tab
                    is passed via the locale prop.

                    @prop {string} locale - the locale of the tab being rendered
                -->
                <slot name="default" :locale="locale" :locale-key="$lumui.mapLocale(locale)" />
            </v-card>
        </v-tab-item>
    </v-tabs>
</template>

<script>
import {VCard, VTab, VTabItem, VTabs} from 'vuetify/lib';

/** creates a tabbed container based on the available languages. */
export default {
    name: "AsaMultiLang",
    components: {VTabs, VTab, VTabItem, VCard},
    props: {
        currentLocale: {
            type: String,
            required: true
        },
        availableLocales: {
            type: Array,
            required: true
        },
        overrideLocales: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data() {
        return {
            active: null
        }
    },
    methods: {
        /**
         *  @prop {string} l - locale of the tab
         *  @private
         */
        tabActivated(l) {
            /** Fired when a tab is activated */
            this.$emit("tabActivated", {locale: l});
        }
    }
}
</script>
