<template>
    <v-select
        :id="'filter_' + config.key"
        v-model="config.value"
        :items="items"
        :menu-props="{offsetY: true}"
        :label="config.label"
        :clearable="clearable"
        hide-details
    />
</template>

<script>
import {VSelect} from 'vuetify/lib';

/**
 * Renders a bool filter
 * @internal
 */
export default {
    name: "AsaTableFilterBool",
    components: {VSelect},
    props: {
        config: {
            type: Object,
            required: true,
        }
    },
    data() {
        return {
            items: [
                {value: true, text: this.$lumui.i18n.t('lumui.formatter.boolean.true')},
                {value: false, text: this.$lumui.i18n.t('lumui.formatter.boolean.false')}
            ]
        }
    },
    computed: {
        clearable() {
            if (Object.hasOwn(this.config, 'clearable')) {
                return !!this.config.clearable;
            } else {
                return true;
            }
        }
    },
}
</script>
