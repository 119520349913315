var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-container",
        [
          _c(
            "v-row",
            [
              _c("v-col", [
                _c("h1", { staticClass: "text-h3" }, [
                  _vm._v("Customized Table Top Slot"),
                ]),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c(
                    "v-card",
                    [
                      _c(
                        "v-card-text",
                        [
                          _c("asa-table", {
                            attrs: { data: _vm.table1 },
                            scopedSlots: _vm._u([
                              {
                                key: "top",
                                fn: function (props) {
                                  return [
                                    _c("div", [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(props.pagination.page) +
                                          " / " +
                                          _vm._s(props.pagination.pageCount) +
                                          " (" +
                                          _vm._s(props.pagination.itemsLength) +
                                          ")\n                "
                                      ),
                                    ]),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }