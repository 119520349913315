<template>
    <div>
        <v-container>
            <v-row>
                <v-col cols="12" md="8">
                    <asa-form v-model="value" :config="config" :abort-btn="false" />
                </v-col>
                <v-col cols="12" md="4">
                    <v-card class="fill-height">
                        <v-card-title class="elevation-1">
                            Value
                        </v-card-title>
                        <v-card-text class="py-4">
                            <pre>{{ JSON.stringify(value, null, 4) }}</pre>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import AsaForm from "../../../src/component/AsaForm";
import {VCard, VCardText, VCardTitle, VCol, VContainer, VRow} from 'vuetify/lib';

export default {
    name: "Date",
    components: {AsaForm, VCard, VCardText, VCardTitle, VContainer, VRow, VCol},
    data() {
        return {
            value: {},
            config: {
                "d1": {"type": "date", "label": "Date", "value": new Date(), "required": true},
                "d2": {"type": "date", "label": "Date", "value": new Date(), "required": false}
            },
        };
    },
}
</script>
