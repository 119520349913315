var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-text-field", {
    class: _vm.config.class,
    attrs: {
      id: _vm.id,
      filled: "",
      disabled: _vm.disabled,
      error: _vm.externalError,
      "error-messages": _vm.errorMessages,
      hint: _vm.config.description || "",
      label: _vm.config.label,
      name: _vm.name,
      placeholder: _vm.config.placeholder || "",
      required: _vm.required,
      rules: _vm.computedRules,
      type: _vm.config.type || "text",
      counter: _vm.config.maxLength || false,
      prefix: _vm.config.prefix || undefined,
      suffix: _vm.config.suffix || undefined,
      "hide-details": _vm.config.hideDetails || false,
      clearable: _vm.config.clearable || false,
      autocomplete: _vm.config.autocomplete || "off",
    },
    on: { "update:error": _vm.updateError },
    model: {
      value: _vm.viewValue,
      callback: function ($$v) {
        _vm.viewValue = $$v
      },
      expression: "viewValue",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }