<template>
    <div>
        <v-container>
            <v-row>
                <v-col>
                    <h1 class="text-h3">Field Length Counter</h1>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" md="8">
                    <asa-form v-model="value" :config="config" no-title>
                    </asa-form>
                </v-col>
                <v-col cols="12" md="4">
                    <v-card class="fill-height">
                        <v-card-title class="elevation-1">
                            Value
                        </v-card-title>
                        <v-card-text class="py-4">
                            <pre>{{ JSON.stringify(value, null, 4) }}</pre>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>

    </div>
</template>

<script>
import AsaForm from "../../../src/component/AsaForm";
import { VRow, VCol, VIcon, VCard, VCardText, VCardTitle, VContainer } from 'vuetify/lib'

export default {
    name: "FormCounter",
    components: {AsaForm, VRow, VCol, VIcon, VCard, VCardText, VCardTitle, VContainer},
    data() {
        return {
            value: {
                text: "xxx",
                textarea: "xxasdfdas"
            },
            config: {
                text: {name:"t", label:"Textfield", type: "text", maxLength: "255"},
                textarea: {name:"ta", label:"Textarea", type: "textarea", maxLength: "255"}
            }
        }
    },
}
</script>
