(function($R)
{
    $R.lang['ko'] = {
        "format": "포맷",
        "image": "이미지",
        "file": "파일",
        "link": "링크",
        "bold": "굵게",
        "italic": "기울임꼴",
        "deleted": "취소선",
        "underline": "밑줄",
        "superscript": "Superscript",
        "subscript": "Subscript",
        "bold-abbr": "B",
        "italic-abbr": "I",
        "deleted-abbr": "S",
        "underline-abbr": "U",
        "superscript-abbr": "Sup",
        "subscript-abbr": "Sub",
        "lists": "목록",
        "link-insert": "링크 삽입",
        "link-edit": "링크 편집",
        "link-in-new-tab": "새 탭에 링크 열기",
        "unlink": "링크 끊기",
        "cancel": "취소",
        "close": "닫기",
        "insert": "삽입",
        "save": "저장",
        "delete": "삭제",
        "text": "글",
        "edit": "편집",
        "title": "제목",
        "paragraph": "보통 글",
        "quote": "인용",
        "code": "코드",
        "heading1": "제목 1",
        "heading2": "제목 2",
        "heading3": "제목 3",
        "heading4": "제목 4",
        "heading5": "제목 5",
        "heading6": "제목 6",
        "filename": "이름",
        "optional": "선택",
        "unorderedlist": "주문 안된 목록",
        "orderedlist": "주문 목록",
        "outdent": "내어쓰기",
        "indent": "들여쓰기",
        "horizontalrule": "행",
        "upload": "Upload",
        "upload-label": "Drop files here or click to upload",
        "upload-change-label": "Drop a new image to change",
        "accessibility-help-label": "Rich text 편집기",
        "caption": "Caption",
        "bulletslist": "Bullets",
        "numberslist": "Numbers",
        "image-position": "Position",
        "none": "None",
        "left": "Left",
        "right": "Right",
        "center": "Center",
        "undo": "Undo",
        "redo": "Redo"
    };
})(Redactor);