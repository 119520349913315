<template>
    <div>
        <asa-form v-model="value" :config="config" />
    </div>
</template>

<script>
export default {
    name: "FormTime",
    data() {
        return {
            value: {
                t: "4:20"
            },
            config: {
                t: {name: "t", label: "Time", type: "time", value: "4:20"}
            }
        }
    },
}
</script>

<style scoped>

</style>
