var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("asa-alert", {
    attrs: { show: _vm.show },
    scopedSlots: _vm._u([
      {
        key: "content",
        fn: function () {
          return [_vm._v("\n        Boo\n    ")]
        },
        proxy: true,
      },
      {
        key: "actions",
        fn: function () {
          return [
            _c(
              "v-btn",
              {
                on: {
                  click: function ($event) {
                    _vm.show = false
                  },
                },
              },
              [_vm._v("\n            Close\n        ")]
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }