var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.dialog
    ? _c(
        "v-dialog",
        {
          attrs: {
            value: _vm.loading,
            "hide-overlay": "",
            persistent: "",
            width: "300",
          },
        },
        [
          _c(
            "v-card",
            { attrs: { flat: "" } },
            [
              _c(
                "v-card-text",
                { staticClass: "text-center" },
                [
                  _c("v-progress-circular", {
                    staticClass: "mt-3",
                    attrs: { indeterminate: "" },
                  }),
                  _vm._v(" "),
                  _c("br"),
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.text || _vm.$lumui.i18n.t("lumui.loading.message")
                      ) +
                      "\n        "
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm.loading
    ? _c(
        "v-card",
        { staticClass: "mt-1", attrs: { flat: "" } },
        [
          _c(
            "v-card-text",
            { staticClass: "text-center" },
            [
              _c("v-progress-circular", {
                staticClass: "mt-3",
                attrs: { indeterminate: "" },
              }),
              _vm._v(" "),
              _c("br"),
              _vm._v(
                " " +
                  _vm._s(
                    _vm.text || _vm.$lumui.i18n.t("lumui.loading.message")
                  ) +
                  "\n    "
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }