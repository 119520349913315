import DOMPurifiy from 'dompurify'

export default class SelectFormatter {
    constructor(options) {
        this.map = options;
    }

    requiresHTML() {
        return true;
    }

    format(value) {
        if(typeof value === 'undefined' || value === null || !Array.isArray(this.map)) {
            return ''
        }
        let label = [];
        this.map.forEach((option) => {
            if (typeof value === 'object' && !Array.isArray(value)) {
                value = Object.values(value);
            } else if (!Array.isArray(value)) {
                value = [value];
            }
            if (value.indexOf(option.value) !== -1) {
                label.push(option.text);
            }
        })

        DOMPurifiy.setConfig({
            ALLOWED_TAGS: []
        })
        label = label.map((l) => {
            return DOMPurifiy.sanitize(l);
        })

        if(label.length === 0) {
            return '';
        } else if (Array.isArray(value) && value.length > 1) {
            return '<ul><li>' + label.join('</li><li>') + '</li></ul>';
        }
        return label.join('');
    }
}
