var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.formattedValue.requiresHTML
    ? _c("span", { domProps: { innerHTML: _vm._s(_vm.formattedValue.value) } })
    : _c("span", [_vm._v(_vm._s(_vm.formattedValue.value))])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }