<template>
    <div>
        <asa-form :config="config" :loading="false" :value="value" @save="addError" />
    </div>
</template>

<script>
import AsaForm from "../../../src/component/AsaForm";

export default {
    name: "FormLocalized",
    components: {AsaForm},
    data() {
        return {
            value: {},
            config: {
                f1: {
                    type: 'localized',
                    label: 'Localized w/error',
                    child: 'text',
                    languages: ['de', 'en'],
                    required: true
                },
                f2: {
                    type: 'localized',
                    label: 'Localized w/default langs'
                },
                f3: {
                    type: 'localized',
                    label: 'WYSIWYG',
                    child: 'wysiwyg',
                    required: true
                }
            }
        }
    },
    methods: {
        addError() {
            this.$set(this.config.f1, 'errors', ['Error'])
        }
    },
}
</script>
