var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-layout",
    { attrs: { "justify-center": "", row: "" } },
    [
      _c(
        "v-dialog",
        {
          attrs: {
            value: _vm.value,
            "content-class": "centered-dialog",
            persistent: "",
          },
        },
        [
          _c(
            "v-container",
            { attrs: { "fill-height": "" } },
            [
              _c(
                "v-layout",
                {
                  attrs: {
                    "align-center": "",
                    column: "",
                    "justify-center": "",
                  },
                },
                [
                  _c("v-progress-circular", {
                    attrs: {
                      color: _vm.progressColor,
                      size: 70,
                      width: 7,
                      indeterminate: "",
                    },
                  }),
                  _vm._v(" "),
                  _vm.message != null
                    ? _c("h1", [
                        _vm._v(
                          "\n                    " +
                            _vm._s(_vm.message) +
                            "\n                "
                        ),
                      ])
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }