<template>
    <v-form ref="form" v-model="valid" lazy-validation v-bind="$attrs" @submit="preventEnter">
        <v-card :flat="flat">
            <v-toolbar v-if="!noTitle" :class="(flat ? '' : 'elevation-1 ') + 'flex-grow-0'" color="primary" dark>
                <v-toolbar-title>
                    <!--
                        The form title
                        @prop {object} config the forms config object
                     -->
                    <slot name="title" :config="config">
                        Untitled
                    </slot>
                </v-toolbar-title>
                <v-spacer />
                <!-- slot rendered after toolbar-title -->
                <slot name="title-after" :config="config" />

                <template v-if="abortBtn">
                    <v-icon @click="$router.back()">
                        $close
                    </v-icon>
                </template>
                <template v-if="hasTabs" #extension>
                    <asa-form-tabs :config="config" :render-tabs="true" :value="value" :active-tab="activeTab" @tab="setActiveTab" />
                </template>
            </v-toolbar>
            <v-toolbar v-else-if="hasTabs" color="white flex-grow-0" light>
                <asa-form-tabs :config="config" :render-tabs="true" :value="value" :active-tab="activeTab" color="primary" background-color="white" @tab="setActiveTab" />
            </v-toolbar>

            <!-- auxiliary slot rendered before the forms v-card-text -->
            <slot name="before" :config="config" />

            <v-card-text v-show="loading">
                <asa-loading :loading="loading" />
            </v-card-text>
            <v-card-text v-show="!loading" style="margin-top:2px;">
                <!-- auxiliary slot rendered before the forms content, within the v-card-text -->
                <slot name="form-before" :config="config" />
                <!-- Form errors -->
                <slot name="form-errors" :config="config" :form-errors="formErrors">
                    <template v-if="formErrors">
                        <v-alert v-for="(error, key) in formErrors" :key="key" :value="true" class="mb-4" type="error">
                            <template v-if="!Array.isArray(error)">
                                {{ error }}
                            </template>
                            <template v-else>
                                <div v-for="(e,i) in error" :key="i">
                                    {{ e }}
                                </div>
                            </template>
                        </v-alert>
                    </template>
                </slot>
                <!--
                    Form content

                    @prop {object} config - Form config
                    @prop {function} validate - validate function
                    @prop {object} value - forms value
                -->
                <slot :config="config" :validate="validate" :value="value" :active-tab="activeTab">
                    <form-content :config="config" :value="value" :active-tab="activeTab" @triggerValidation="validate()" @update:error="debouncedUpdateCompoundErrorState" />
                </slot>
                <!--
                    auxiliary slot rendered after the forms content, within the v-card-text
                    @props {object} config
                -->
                <slot name="form-after" :config="config" />
            </v-card-text>
            <!--
                auxiliary slot rendered after the forms v-card-text
                @props {object} config
            -->
            <slot name="after" :config="config" />
            <v-card-actions :class="{ 'flex-column': $vuetify.breakpoint.mobile, 'flex-wrap': !$vuetify.breakpoint.mobile, 'mobile': $vuetify.breakpoint.mobile, 'form-actions': true }">
                <!--
                    slot containing all actions

                    if you override this slot make sure to use the save function passed via slot scope to submit the form,
                    as the form need to be validated before submit.

                    example:

                    ```vue
                    <asa-form ..>
                      <template #actions="{ save }">
                         <v-btn @click="save()">Do it!<v-btn>
                      </template>
                    </asa-form>
                    ```

                    @props {object} config
                -->
                <slot :form-valid="valid" name="actions" :config="config" :save="save">
                    <div v-show="!loading">
                        <!--
                        emitted when the save button is activated
                        @event save
                        -->
                        <v-btn
                            v-if="!readonly"
                            :disabled="loading || !valid || (config === null || Object.keys(config).length === 0)"
                            :block="$vuetify.breakpoint.mobile"
                            color="success"
                            class="mx-2 mb-2"
                            @click="() => save()"
                        >
                            <!-- label for the save button -->
                            <slot name="save-btn-name">
                                {{ $lumui.i18n.t('lumui.form.save') }}
                            </slot>
                        </v-btn>
                        <v-btn
                            v-if="abortBtn"
                            type="button"
                            :block="$vuetify.breakpoint.mobile"
                            class="mx-2 mb-2"
                            @click="$router.back()"
                        >
                            <!-- label for the cancel button -->
                            <slot name="back-btn-name">
                                {{ readonly ? $lumui.i18n.t('lumui.form.close') : $lumui.i18n.t('lumui.form.cancel') }}
                            </slot>
                        </v-btn>
                    </div>
                </slot>
                <!--
                    additional actions
                    @props {object} config
                -->
                <slot name="additional-actions" :form-valid="valid" :config="config" />
            </v-card-actions>
        </v-card>
    </v-form>
</template>

<script>
import {
    VAlert,
    VBtn,
    VCard,
    VCardActions,
    VCardText,
    VForm,
    VIcon,
    VSpacer,
    VToolbar,
    VToolbarTitle
} from 'vuetify/lib';
import FormContent from "./FormContent";
import AsaFormTabs from "./FormTabs";
import AsaLoading from "./AsaLoading";

/**
 * Renders a standardized form based on a config object.
 *
 * **Slot layout:**
 * ```
 * +------------------------------+
 * | Title                      x |
 * +------------------------------+
 * | before                       |
 * +------------------------------+
 * | +--------------------------+ |
 * | | form-before              | |
 * | +--------------------------+ |
 * | | default                  | |
 * | +--------------------------+ |
 * | | form-after               | |
 * | +--------------------------+ |
 * +------------------------------+
 * | after                        |
 * +------------------------------+
 * | actions                      |
 * | +--------------------------+ |
 * | | additional-actions       | |
 * | +--------------------------+ |
 * +------------------------------+
 * ```
 */
export default {
    name: "AsaForm",
    components: {AsaFormTabs, AsaLoading, VBtn, VCard, VCardActions, VCardText, VForm, FormContent, VAlert, VToolbar, VToolbarTitle, VIcon, VSpacer},
    props: {
        /** the value representing the forms content */
        value: {
            type: Object,
            required: false,
            default: () => {
            }
        },
        /** the definition of the form */
        config: {
            type: Object,
            required: true
        },
        /** make all form fields readonly */
        readonly: {
            type: Boolean,
            required: false,
            default: false,
        },
        /** whether the abort button should be shown */
        abortBtn: {
            type: Boolean,
            required: false,
            default: true
        },
        /** display the loading animation and hide form content */
        loading: {
            type: Boolean,
            required: false,
            default: false
        },
        /** makes the v-card flat. Useful for forms that are not displayed in a dialog. */
        flat: {
            type: Boolean,
            required: false,
            default: false
        },
        /** don't render the form title */
        noTitle: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data() {
        return {
            activeTab: '',
            valid: false,
            errorTimeout: 0
        }
    },
    computed: {
        formErrors() {
            return this.config.errors || null;
        },
        hasTabs() {
            if (!this.config) {
                return false;
            }
            for (const key in this.config) {
                if (!Object.hasOwn(this.config, key)) {
                    continue;
                }
                const entry = this.config[key];
                if (Object.hasOwn(entry, 'type') && entry.type === 'tab') {
                    return true;
                }
            }
            return false;
        },
    },
    watch: {
        config: {
            immediate: true,
            handler(n, o) {
                this.disableElements(this.config);
                if (!o) {
                    this.updateCompoundErrorState();
                }
            }
        },
        valid() {
            if (this.valid && this.config !== null && Object.keys(this.config).length !== 0) {
                /**
                 * Emitted when the forms state changes to valid
                 * @event isValid
                 */
                this.$emit('isValid')
            }
        },
    },
    methods: {
        save() {
            const isValid = this.validate();
            if (isValid) {
                this.$emit('save');
            }
        },
        disableElements(o) {
            if (this.readonly) {
                Object.keys(o).forEach((k) => {
                    if (typeof this.config[k] !== 'object') {
                        return;
                    }
                    this.$set(this.config[k], 'disabled', true);

                    if (typeof this.config[k].children === 'object') {
                        Object.keys(this.config[k].children).forEach(c => {
                            this.$set(this.config[k].children[c], 'disabled', true);
                        })
                    }
                });
            }
        },
        /** @private */
        debouncedUpdateCompoundErrorState() {
            if (this.errorTimeout) {
                window.clearTimeout(this.errorTimeout);
            }
            this.errorTimeout = window.setTimeout(this.updateCompoundErrorState, 250);
        },
        /** @private */
        updateCompoundErrorState() {
            let $set = this.$set;
            let update = function (config, errors, parent = null) {
                for (let i in config) {
                    if (!Object.hasOwn(config, i)) {
                        continue;
                    }
                    if (Object.hasOwn(config[i], 'error')) {
                        $set(config[i], 'error', false);
                    }
                    if (Object.hasOwn(errors, i) || (Object.hasOwn(config[i], 'errors') && Array.isArray(config[i].errors) && config[i].errors.length > 0)) {
                        if (parent) {
                            $set(parent, 'error', true);
                        }
                    }
                    if (Object.hasOwn(config[i], 'children')) {
                        update(config[i].children, errors, config[i]);
                    }
                }
            };
            let errors = {};
            if (this.$refs.form) {
                this.$refs.form.inputs.forEach((e) => {
                    if (e.errorBucket && e.errorBucket.length > 0) {
                        errors[e.id] = e.errorBucket;
                    }
                });
            }
            update(this.config, errors);
        },
        /** @private */
        setActiveTab(e) {
            this.activeTab = e;
        },
        /** @private */
        preventEnter(e) {
            e.preventDefault();
        },
        /** @private */
        evaluateExpression(exp) {
            return eval(exp);
        },
        /** validates form */
        validate() {
            return this.$refs.form.validate();
        },
        /** clears form */
        reset() {
            this.$refs.form.reset();
        },
        /** clears validation results */
        resetValidation() {
            this.$refs.form.resetValidation()
        },
        /** assigns errors to fields */
        setErrors(errors) {
            this.setFormErrors(this.config, errors);
        }
    }
}
</script>

<style lang="scss">
.form-actions {
    .v-btn {
        margin: 0 8px 8px 8px !important;

        .v-btn__content {
            text-overflow: ellipsis;
            overflow: hidden !important;
            white-space: nowrap !important;
            text-align: left !important;
            .v-icon--left {
                margin-left: 0;
                margin-right: 12px;
            }
        }
    }
    &.mobile {
        .v-btn {
            display: block;
            width: 100%;
        }
    }
}
</style>
