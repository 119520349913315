(function($R)
{
    $R.lang['es'] = {
        "format": "Formato",
        "image": "Imagen",
        "file": "Archivo",
        "link": "Enlace",
        "bold": "Negrita",
        "italic": "Cursiva",
        "deleted": "Tachado",
        "underline": "Subrayado",
        "superscript": "Superscript",
        "subscript": "Subscript",
        "bold-abbr": "N",
        "italic-abbr": "C",
        "deleted-abbr": "T",
        "underline-abbr": "S",
        "superscript-abbr": "Sup",
        "subscript-abbr": "Sub",
        "lists": "Listas",
        "link-insert": "Insertar enlace",
        "link-edit": "Editar enlace",
        "link-in-new-tab": "Abrir enlace en nueva pestaña",
        "unlink": "Quitar enlace",
        "cancel": "Cancelar",
        "close": "Cerrar",
        "insert": "Insertar",
        "save": "Guardar",
        "delete": "Borrar",
        "text": "Texto",
        "edit": "Editar",
        "title": "Título",
        "paragraph": "Texto normal",
        "quote": "Citar",
        "code": "Código",
        "heading1": "Cabecera 1",
        "heading2": "Cabecera 2",
        "heading3": "Cabecera 3",
        "heading4": "Cabecera 4",
        "heading5": "Cabecera 5",
        "heading6": "Cabecera 6",
        "filename": "Nombre",
        "optional": "Opcional",
        "unorderedlist": "Lista sin orden",
        "orderedlist": "Lista ordenada",
        "outdent": "Quitar sangría",
        "indent": "Sangría",
        "horizontalrule": "Línea",
        "upload": "Upload",
        "upload-label": "Drop files here or click to upload",
        "upload-change-label": "Drop a new image to change",
        "accessibility-help-label": "Editor de texto enriquecido",
        "caption": "Caption",
        "bulletslist": "Bullets",
        "numberslist": "Numbers",
        "image-position": "Position",
        "none": "None",
        "left": "Left",
        "right": "Right",
        "center": "Center",
        "undo": "Undo",
        "redo": "Redo"
    };
})(Redactor);
