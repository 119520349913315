<template>
    <asa-dialog>
        <asa-form :config="config" :value="value">
            <template #title>
                Form Title
            </template>
        </asa-form>
    </asa-dialog>
</template>

<script>
export default {
    name: "DialogTabs",
    data() {
        return {
            value: {
                b0: "asdf"
            },
            config: {
                tab1: {
                    type: "tab",
                    label: "Tab A",
                    children: {
                        a1: {type: "text", label: "Vorname", required: false},
                        a2: {type: "text", label: "Nachname", required: false},
                        a3: {type: "email", label: "E-Mail"},
                        a4: {type: "textarea", label: "Adresse"},
                    }
                },
                tab2: {
                    type: "tab",
                    label: "Tab B",
                    children: {
                        b0: {type: "text", label: "Error"},
                        b1: {type: "textarea", label: "Anmerkungen", required: false},
                    }
                },
            }
        }
    },
    mounted() {
        this.setFormErrors(this.config, { 'unassigned': 'YYY', 'tab2.b1': 'XXX' });
    }
}
</script>
