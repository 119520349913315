<template>
    <asa-form :loading="loading" :config="cfg" v-model="value">
        <template slot="title">Asa User form</template>
    </asa-form>
</template>
<script>
import AsaForm from "../../../src/component/AsaForm";
export default {
    components: {AsaForm},
    data() {
        return {
            loading: true,
            value: {},
            cfg: {},
            source: {
                "tab1": {
                    "type": "tab",
                    "label": "Benutzerdaten",
                    "required": [
                        "firstname",
                        "lastname",
                        "email"
                    ],
                    "disabled": false,
                    "errors": [],
                    "children": {
                        "id": {
                            "type": "hidden",
                            "label": "id",
                            "value": "",
                            "required": false,
                            "disabled": false,
                            "errors": [],
                            "order": 1
                        },
                        "path": {
                            "type": "hidden",
                            "label": "path",
                            "value": "1-2-",
                            "required": false,
                            "disabled": false,
                            "errors": [],
                            "order": 2
                        },
                        "personnelNo": {
                            "type": "text",
                            "label": "Benutzer-ID",
                            "value": "",
                            "required": false,
                            "disabled": false,
                            "errors": [],
                            "order": 3
                        },
                        "firstname": {
                            "type": "text",
                            "label": "Vorname",
                            "value": "",
                            "required": true,
                            "disabled": false,
                            "errors": [],
                            "order": 4
                        },
                        "lastname": {
                            "type": "text",
                            "label": "Nachname",
                            "value": "",
                            "required": true,
                            "disabled": false,
                            "errors": [],
                            "order": 5
                        },
                        "email": {
                            "type": "email",
                            "label": "E-Mail",
                            "value": "",
                            "required": true,
                            "disabled": false,
                            "errors": [],
                            "order": 6,
                            "autocomplete": "email"
                        },
                        "phone": {
                            "type": "text",
                            "label": "Telefon",
                            "description": "Telefonnummer bitte mit Länderkennung und ohne Leer- und Sonderzeichen eingeben (bsp. +412345678).",
                            "value": "",
                            "required": false,
                            "disabled": false,
                            "errors": [],
                            "order": 7
                        },
                        "fax": {
                            "type": "text",
                            "label": "Fax",
                            "description": "Faxnummer bitte mit Länderkennung und ohne Leer- und Sonderzeichen eingeben (bsp. +412345678).",
                            "value": "",
                            "required": false,
                            "disabled": false,
                            "errors": [],
                            "order": 8
                        },
                        "mobile": {
                            "type": "text",
                            "label": "Mobil",
                            "description": "Mobilnummer bitte mit Länderkennung und ohne Leer- und Sonderzeichen eingeben (bsp. +412345678).",
                            "value": null,
                            "required": false,
                            "disabled": false,
                            "errors": [],
                            "order": 9
                        }
                    },
                    "order": 1
                },
                "tab2": {
                    "type": "tab",
                    "label": "Kontodaten",
                    "required": [
                        "status",
                        "language"
                    ],
                    "disabled": false,
                    "errors": [],
                    "children": {
                        "deleted": {
                            "type": "switch",
                            "label": "Gelöscht",
                            "value": "0",
                            "required": false,
                            "disabled": false,
                            "errors": [],
                            "order": 1
                        },
                        "status": {
                            "type": "select",
                            "label": "Status",
                            "value": "1",
                            "required": true,
                            "disabled": false,
                            "errors": [],
                            "multiple": false,
                            "multiOptions": [
                                {
                                    "label": "aktiv",
                                    "value": "1"
                                },
                                {
                                    "label": "inaktiv",
                                    "value": "2"
                                },
                                {
                                    "label": "kein Zugang",
                                    "value": "3"
                                }
                            ],
                            "order": 2
                        },
                        "login": {
                            "type": "text",
                            "label": "Login",
                            "value": "",
                            "required": false,
                            "disabled": false,
                            "errors": [],
                            "order": 3,
                            "autocomplete": "username"
                        },
                        "language": {
                            "type": "select",
                            "label": "Sprache",
                            "value": "de",
                            "required": true,
                            "disabled": false,
                            "errors": [],
                            "multiple": false,
                            "multiOptions": [
                                {
                                    "label": "Deutsch",
                                    "value": "de"
                                },
                                {
                                    "label": "Französisch",
                                    "value": "fr"
                                },
                                {
                                    "label": "Italienisch",
                                    "value": "it"
                                }
                            ],
                            "order": 4
                        },
                        "password": {
                            "type": "password",
                            "label": "Neues Passwort",
                            "disabled": false,
                            "description": "Ihr Passwort muss mindestens 8 Zeichen lang sein, eine Ziffer sowie mindestens jeweils einen Gross- und Kleinbuchstaben enthalten.",
                            "value": "",
                            "required": false,
                            "autocomplete": "new-password",
                            "errors": [],
                            "order": 5
                        }
                    },
                    "order": 2
                },
                "tab3": {
                    "type": "tab",
                    "label": "Rollen",
                    "required": [
                        "role_core",
                        "role_mailtext",
                        "role_tree",
                        "role_user",
                        "role_roles",
                        "role_asset",
                        "role_supplier",
                        "role_userfunction",
                        "role_clsmgr",
                        "role_chkmgr",
                        "role_fmgr",
                        "role_igmmgr",
                        "role_link",
                        "role_notice",
                        "role_newsletter",
                        "role_umgr",
                        "role_ummgr",
                        "role_eu",
                        "role_ekas"
                    ],
                    "disabled": false,
                    "errors": [],
                    "children": {
                        "roleMeta": {
                            "type": "select",
                            "label": "Rollenpaket",
                            "html_options": true,
                            "description": "Metarollen weisen dem Benutzer alle enthaltenen Rollen zu. Benutzern mit der Rolle Superadmin kann keine Metarollen zugewiesen werden.",
                            "value": "",
                            "required": false,
                            "disabled": false,
                            "errors": [],
                            "multiple": false,
                            "multiOptions": [
                                {
                                    "label": "A<span class=\"caption\"> </span>",
                                    "value": "2"
                                },
                                {
                                    "label": "Z<span class=\"caption\"> </span>",
                                    "value": "1"
                                }
                            ],
                            "order": 1
                        },
                        "role_core": {
                            "type": "radio",
                            "label": "Basisfunktionen",
                            "disabled": false,
                            "value": "0",
                            "required": true,
                            "errors": [],
                            "multiple": false,
                            "multiOptions": [
                                {
                                    "label": "keine",
                                    "value": "0"
                                },
                                {
                                    "label": "Superuser",
                                    "value": "1"
                                },
                                {
                                    "label": "Gast",
                                    "value": "2"
                                },
                                {
                                    "label": "System",
                                    "value": "3"
                                },
                                {
                                    "label": "Migrol MA",
                                    "value": "48"
                                },
                                {
                                    "label": "Migrol LV",
                                    "value": "49"
                                },
                                {
                                    "label": "Migrol Kopas Basisberechtigungen",
                                    "value": "50"
                                },
                                {
                                    "label": "Migrol Kopas",
                                    "value": "51"
                                },
                                {
                                    "label": "Migrol Kopas S",
                                    "value": "52"
                                },
                                {
                                    "label": "Migrol Kopas T",
                                    "value": "53"
                                },
                                {
                                    "label": "Migrol Kopas T Bau",
                                    "value": "54"
                                },
                                {
                                    "label": "Migrol Kopas T Betrieb",
                                    "value": "55"
                                },
                                {
                                    "label": "Migrol Kopas T Log",
                                    "value": "56"
                                },
                                {
                                    "label": "Migrol Kopas T TD",
                                    "value": "57"
                                },
                                {
                                    "label": "Migrol Kopas T Sanitäter",
                                    "value": "58"
                                },
                                {
                                    "label": "Migrol UL",
                                    "value": "59"
                                },
                                {
                                    "label": "Kopas-S + LV",
                                    "value": "60"
                                },
                                {
                                    "label": "Kopas-T + LV",
                                    "value": "61"
                                },
                                {
                                    "label": "Kopas T Bau + LV",
                                    "value": "62"
                                },
                                {
                                    "label": "Kopas T Betrieb + LV",
                                    "value": "63"
                                },
                                {
                                    "label": "Kopas T TD + LV",
                                    "value": "64"
                                },
                                {
                                    "label": "Kopas T Log + LV",
                                    "value": "65"
                                },
                                {
                                    "label": "Kopas T Betriebssanität + LV",
                                    "value": "66"
                                }
                            ],
                            "order": 2
                        },
                        "role_mailtext": {
                            "type": "radio",
                            "label": "E-Mail-Texte",
                            "disabled": false,
                            "value": "0",
                            "required": true,
                            "errors": [],
                            "multiple": false,
                            "multiOptions": [
                                {
                                    "label": "keine",
                                    "value": "0"
                                },
                                {
                                    "label": "Vollzugriff (Admin)",
                                    "value": "5"
                                }
                            ],
                            "order": 3
                        },
                        "role_tree": {
                            "type": "radio",
                            "label": "Strukturbaum",
                            "disabled": false,
                            "value": "0",
                            "required": true,
                            "errors": [],
                            "multiple": false,
                            "multiOptions": [
                                {
                                    "label": "keine",
                                    "value": "0"
                                },
                                {
                                    "label": "Baumstruktur verwalten",
                                    "value": "4"
                                }
                            ],
                            "order": 4
                        },
                        "role_user": {
                            "type": "radio",
                            "label": "Benutzer",
                            "disabled": false,
                            "value": "0",
                            "required": true,
                            "errors": [],
                            "multiple": false,
                            "multiOptions": [
                                {
                                    "label": "keine",
                                    "value": "0"
                                },
                                {
                                    "label": "Benutzeradministrator",
                                    "value": "6"
                                },
                                {
                                    "label": "Viewer",
                                    "value": "7"
                                },
                                {
                                    "label": "Benutzeradministrator nur Metarollen",
                                    "value": "4689"
                                }
                            ],
                            "order": 5
                        },
                        "role_roles": {
                            "type": "radio",
                            "label": "Rollen",
                            "disabled": false,
                            "value": "0",
                            "required": true,
                            "errors": [],
                            "multiple": false,
                            "multiOptions": [
                                {
                                    "label": "keine",
                                    "value": "0"
                                },
                                {
                                    "label": "Rollenadministrator",
                                    "value": "8"
                                }
                            ],
                            "order": 6
                        },
                        "role_asset": {
                            "type": "radio",
                            "label": "Dateiverwaltung",
                            "disabled": false,
                            "value": "0",
                            "required": true,
                            "errors": [],
                            "multiple": false,
                            "multiOptions": [
                                {
                                    "label": "keine",
                                    "value": "0"
                                },
                                {
                                    "label": "Viewer",
                                    "value": "9"
                                },
                                {
                                    "label": "Creator",
                                    "value": "10"
                                },
                                {
                                    "label": "Admin",
                                    "value": "11"
                                }
                            ],
                            "order": 7
                        },
                        "role_supplier": {
                            "type": "radio",
                            "label": "Lieferanten/Hersteller",
                            "disabled": false,
                            "value": "0",
                            "required": true,
                            "errors": [],
                            "multiple": false,
                            "multiOptions": [
                                {
                                    "label": "keine",
                                    "value": "0"
                                },
                                {
                                    "label": "Lesen (Viewer)",
                                    "value": "12"
                                },
                                {
                                    "label": "Vollzugriff (Admin)",
                                    "value": "13"
                                }
                            ],
                            "order": 8
                        },
                        "role_userfunction": {
                            "type": "radio",
                            "label": "Funktionen",
                            "disabled": false,
                            "value": "0",
                            "required": true,
                            "errors": [],
                            "multiple": false,
                            "multiOptions": [
                                {
                                    "label": "keine",
                                    "value": "0"
                                },
                                {
                                    "label": "Viewer",
                                    "value": "367"
                                },
                                {
                                    "label": "Admin",
                                    "value": "368"
                                }
                            ],
                            "order": 9
                        },
                        "role_clsmgr": {
                            "type": "radio",
                            "label": "Checklistenvorlagen",
                            "disabled": false,
                            "value": "0",
                            "required": true,
                            "errors": [],
                            "multiple": false,
                            "multiOptions": [
                                {
                                    "label": "keine",
                                    "value": "0"
                                },
                                {
                                    "label": "Checklisten Viewer",
                                    "value": "24"
                                },
                                {
                                    "label": "Checklisten Creator",
                                    "value": "25"
                                },
                                {
                                    "label": "Checklisten Creator mit Niederlassungen",
                                    "value": "26"
                                },
                                {
                                    "label": "Checklisten Admin",
                                    "value": "27"
                                },
                                {
                                    "label": "Checklisten Admin mit Checklistenzuteilung",
                                    "value": "28"
                                },
                                {
                                    "label": "Admin (cls)",
                                    "value": "29"
                                }
                            ],
                            "order": 10
                        },
                        "role_chkmgr": {
                            "type": "radio",
                            "label": "Kontrollen/Checklisten",
                            "disabled": false,
                            "value": "0",
                            "required": true,
                            "errors": [],
                            "multiple": false,
                            "multiOptions": [
                                {
                                    "label": "keine",
                                    "value": "0"
                                },
                                {
                                    "label": "Viewer (nur eigene Vorgänge)",
                                    "value": "30"
                                },
                                {
                                    "label": "Ersteller und Durchführer (nur intern)<div class=\"caption\">Beauftragen und Durchführen von Gefährdungsbeurteilungen und Kontrollisten, die nur intern durchgeführt werden können</div>",
                                    "value": "31"
                                },
                                {
                                    "label": "Ersteller und Durchführer (intern/extern)<div class=\"caption\">Beauftragen und Durchführen von Gefährdungsbeurteilungen und Kontrollisten, die sowohl extern als auch intern durchgeführt werden können</div>",
                                    "value": "32"
                                },
                                {
                                    "label": "Admin<div class=\"caption\">Vollzugriff auf alle Funktionen des Moduls</div>",
                                    "value": "33"
                                },
                                {
                                    "label": "Planer (Hauptplaner)<div class=\"caption\">Zuteilen der extern beauftragten Gefährdungsbeurteilungen zu den externen Durchführern</div>",
                                    "value": "34"
                                },
                                {
                                    "label": "Externer Durchführer<div class=\"caption\">Durchführung von Gefährdungsbeurteilungen, die extern durchgeführt werden (durch Fremdfirmen, externe Kontrolleure o.ä.)</div>",
                                    "value": "35"
                                },
                                {
                                    "label": "Viewer (alle Checklistenvorgänge)",
                                    "value": "610"
                                }
                            ],
                            "order": 11
                        },
                        "role_fmgr": {
                            "type": "radio",
                            "label": "Mängelmeldungen",
                            "disabled": false,
                            "value": "0",
                            "required": true,
                            "errors": [],
                            "multiple": false,
                            "multiOptions": [
                                {
                                    "label": "keine",
                                    "value": "0"
                                },
                                {
                                    "label": "Viewer Meldungen",
                                    "value": "589"
                                },
                                {
                                    "label": "Viewer Protokoll",
                                    "value": "590"
                                },
                                {
                                    "label": "Creator Protokoll",
                                    "value": "591"
                                },
                                {
                                    "label": "Creator Meldungen",
                                    "value": "592"
                                },
                                {
                                    "label": "Admin Meldungen",
                                    "value": "593"
                                },
                                {
                                    "label": "Admin Protokoll",
                                    "value": "594"
                                },
                                {
                                    "label": "Zuteiler",
                                    "value": "595"
                                },
                                {
                                    "label": "Ersteller (Protokolle und Meldungen)",
                                    "value": "596"
                                },
                                {
                                    "label": "Admin",
                                    "value": "597"
                                }
                            ],
                            "order": 12
                        },
                        "role_igmmgr": {
                            "type": "radio",
                            "label": "Unterhalt",
                            "disabled": false,
                            "value": "0",
                            "required": true,
                            "errors": [],
                            "multiple": false,
                            "multiOptions": [
                                {
                                    "label": "keine",
                                    "value": "0"
                                },
                                {
                                    "label": "Viewer + Rückmelder (alle Einträge)<div class=\"caption\">Ansicht aller(!) Geräte und Aufträge im Zuständigkeitsbereich, Rückmeldung zugewiesener Aufträge</div>",
                                    "value": "19"
                                },
                                {
                                    "label": "Rückmelder (Basisrolle)<div class=\"caption\">Ansicht und Rückmeldung zugewiesener Aufträge im Zuständigkeitsbereich</div>",
                                    "value": "20"
                                },
                                {
                                    "label": "Creator<div class=\"caption\">Rolle Viewer + Bearbeitung und Löschung eigener Geräte, Aufträge und Historieneinträge, Hinzufügen von Geräten und Aufträgen</div>",
                                    "value": "21"
                                },
                                {
                                    "label": "Admin<div class=\"caption\">Rolle Creator + alle weiteren Modulrechte</div>",
                                    "value": "22"
                                }
                            ],
                            "order": 13
                        },
                        "role_link": {
                            "type": "radio",
                            "label": "Links und Anleitungen",
                            "disabled": false,
                            "value": "0",
                            "required": true,
                            "errors": [],
                            "multiple": false,
                            "multiOptions": [
                                {
                                    "label": "keine",
                                    "value": "0"
                                },
                                {
                                    "label": "Linkverwaltung",
                                    "value": "36"
                                },
                                {
                                    "label": "Ansicht<div class=\"caption\">Ansicht der Links, bereits in Basisrolle Gast enthalten</div>",
                                    "value": "37"
                                }
                            ],
                            "order": 14
                        },
                        "role_notice": {
                            "type": "radio",
                            "label": "Mitteilungen",
                            "disabled": false,
                            "value": "0",
                            "required": true,
                            "errors": [],
                            "multiple": false,
                            "multiOptions": [
                                {
                                    "label": "keine",
                                    "value": "0"
                                },
                                {
                                    "label": "Mitteilungen",
                                    "value": "38"
                                }
                            ],
                            "order": 15
                        },
                        "role_newsletter": {
                            "type": "radio",
                            "label": "Newsletter",
                            "disabled": false,
                            "value": "0",
                            "required": true,
                            "errors": [],
                            "multiple": false,
                            "multiOptions": [
                                {
                                    "label": "keine",
                                    "value": "0"
                                },
                                {
                                    "label": "Newsletterverwaltung",
                                    "value": "39"
                                },
                                {
                                    "label": "Viewer",
                                    "value": "40"
                                }
                            ],
                            "order": 16
                        },
                        "role_umgr": {
                            "type": "radio",
                            "label": "Ausbildung",
                            "disabled": false,
                            "value": "0",
                            "required": true,
                            "errors": [],
                            "multiple": false,
                            "multiOptions": [
                                {
                                    "label": "keine",
                                    "value": "0"
                                },
                                {
                                    "label": "Viewer",
                                    "value": "271"
                                },
                                {
                                    "label": "Creator",
                                    "value": "272"
                                },
                                {
                                    "label": "Admin",
                                    "value": "273"
                                },
                                {
                                    "label": "Zuteiler",
                                    "value": "274"
                                }
                            ],
                            "order": 17
                        },
                        "role_ummgr": {
                            "type": "radio",
                            "label": "Konzernunterweisung",
                            "disabled": false,
                            "value": "0",
                            "required": true,
                            "errors": [],
                            "multiple": false,
                            "multiOptions": [
                                {
                                    "label": "keine",
                                    "value": "0"
                                },
                                {
                                    "label": "Planer",
                                    "value": "14"
                                },
                                {
                                    "label": "Admin",
                                    "value": "15"
                                },
                                {
                                    "label": "Viewer",
                                    "value": "16"
                                },
                                {
                                    "label": "Viewer 2",
                                    "value": "17"
                                },
                                {
                                    "label": "Branchen Planer",
                                    "value": "18"
                                }
                            ],
                            "order": 18
                        },
                        "role_eu": {
                            "type": "radio",
                            "label": "Elektronische Unterweisung",
                            "disabled": false,
                            "value": "0",
                            "required": true,
                            "errors": [],
                            "multiple": false,
                            "multiOptions": [
                                {
                                    "label": "keine",
                                    "value": "0"
                                },
                                {
                                    "label": "Planer",
                                    "value": "46"
                                },
                                {
                                    "label": "Viewer",
                                    "value": "47"
                                }
                            ],
                            "order": 19
                        },
                        "role_ekas": {
                            "type": "radio",
                            "label": "10 Punkte AS & GS",
                            "disabled": false,
                            "value": "0",
                            "required": true,
                            "errors": [],
                            "multiple": false,
                            "multiOptions": [
                                {
                                    "label": "keine",
                                    "value": "0"
                                },
                                {
                                    "label": "Anzeigen (Viewer)",
                                    "value": "632"
                                },
                                {
                                    "label": "Erzeugen (Creator)",
                                    "value": "633"
                                },
                                {
                                    "label": "Vollzugriff (Admin)",
                                    "value": "634"
                                }
                            ],
                            "order": 20
                        }
                    },
                    "order": 3
                },
                "tab5": {
                    "type": "tab",
                    "label": "Newsletter",
                    "required": true,
                    "disabled": false,
                    "errors": [],
                    "children": {
                        "newsletter": {
                            "type": "switch",
                            "label": "Ja, der Benutzer soll den Newsletter erhalten",
                            "value": null,
                            "required": false,
                            "disabled": false,
                            "errors": [],
                            "order": 1
                        }
                    },
                    "order": 5
                }
            }
        }
    },
    mounted() {
        this.loading = true;
        window.setTimeout(() => {
            this.cfg = this.source;
            this.loading = false;
        }, 1000)
    }
}
</script>
