var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "ma-5" },
    [
      _vm._l(_vm.icons, function (icon, alias) {
        return [
          _c(
            "div",
            { key: alias },
            [
              _c("v-icon", { attrs: { title: icon } }, [_vm._v(_vm._s(icon))]),
              _vm._v(" "),
              _c("span", { staticClass: "text--disabled" }, [
                _vm._v("$" + _vm._s(alias)),
              ]),
            ],
            1
          ),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }