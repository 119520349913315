var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("v-text-field", {
        attrs: {
          id: _vm.name,
          filled: "",
          disabled: _vm.disabled,
          error: _vm.error,
          "error-count": _vm.errorMessages.length,
          "error-messages": _vm.errorMessages,
          hint: _vm.config.description || "",
          label: _vm.config.label,
          name: _vm.name,
          required: _vm.required,
          rules: _vm.rules,
          autocomplete: _vm.config.autocomplete || "off",
          type: _vm.reveal ? "text" : "password",
          "append-icon": _vm.reveal ? "$hide" : "$reveal",
        },
        on: {
          "click:append": function ($event) {
            _vm.reveal = !_vm.reveal
          },
        },
        model: {
          value: _vm.viewValue,
          callback: function ($$v) {
            _vm.viewValue = $$v
          },
          expression: "viewValue",
        },
      }),
      _vm._v(" "),
      !_vm.config.no_confirm
        ? _c("v-text-field", {
            ref: "confirm",
            attrs: {
              id: _vm.name + "_confirm",
              filled: "",
              disabled: _vm.disabled,
              label: _vm.$lumui.i18n.t("lumui.form.password.repeat"),
              name: _vm.name + "_confirm",
              required: _vm.viewValue !== "",
              rules: [
                (v) =>
                  !_vm.viewValue ||
                  v === _vm.viewValue ||
                  _vm.$lumui.i18n.t("lumui.form.password.not_matching_error"),
              ],
              autocomplete: "off",
              type: _vm.reveal ? "text" : "password",
            },
            model: {
              value: _vm.confirm,
              callback: function ($$v) {
                _vm.confirm = $$v
              },
              expression: "confirm",
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }