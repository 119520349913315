var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.config.label
        ? _c(
            "v-label",
            {
              attrs: {
                color:
                  _vm.config.errors && _vm.config.errors.length > 0
                    ? "error"
                    : "xxx",
                focused: true,
              },
            },
            [_vm._v("\n        " + _vm._s(_vm.config.label) + "\n    ")]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        [
          _c(
            "v-container",
            { attrs: { fluid: "" } },
            [
              _vm._l(_vm.config.children, function (child, index) {
                return [
                  _c(
                    "v-row",
                    { key: index, attrs: { dense: "", "no-gutters": "" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: _vm.allowDelete ? 11 : 12 } },
                        [
                          child.type && _vm.viewValue.hasOwnProperty(index)
                            ? _c("form-row", {
                                attrs: { config: child },
                                model: {
                                  value: _vm.viewValue[index],
                                  callback: function ($$v) {
                                    _vm.$set(_vm.viewValue, index, $$v)
                                  },
                                  expression: "viewValue[index]",
                                },
                              })
                            : _vm.viewValue.hasOwnProperty(index)
                            ? _c("form-rows", {
                                attrs: { config: child },
                                model: {
                                  value: _vm.viewValue[index],
                                  callback: function ($$v) {
                                    _vm.$set(_vm.viewValue, index, $$v)
                                  },
                                  expression: "viewValue[index]",
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.allowDelete
                        ? _c(
                            "v-col",
                            { attrs: { cols: "1" } },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: { icon: "" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.removeItem(index)
                                    },
                                  },
                                },
                                [_c("v-icon", [_vm._v("$delete")])],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ]
              }),
            ],
            2
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.allowAdd
        ? _c("v-btn", { on: { click: _vm.addItem } }, [
            _vm._v("\n        " + _vm._s(_vm.addLabel) + "\n    "),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.config.errors && _vm.config.errors.length > 0
        ? _c("v-messages", {
            staticClass: "mt-3",
            attrs: { value: _vm.config.errors, color: "error" },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }