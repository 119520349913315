<template>
    <div v-if="!$vuetify.breakpoint.mobile">
        <v-menu
            v-if="!disabled"
            ref="datepicker"
            v-model="modal"
            :open-on-focus="false"
            :open-on-click="false"
            :close-on-content-click="false"
            :close-on-click="true"
            transition="scroll-y-transition"
            offset-y
            offset-overflow
            max-width="290px"
            min-width="auto"
        >
            <template #activator="{ on, attrs }">
                <v-text-field
                    :id="id"
                    ref="textfield"
                    v-model="viewValue"
                    filled
                    :disabled="disabled"
                    :error="!valid || parseError"
                    :error-messages="combinedErrors"
                    :label="config.label"
                    :name="name"
                    :required="required"
                    :rules="computedRules"
                    :hint="inputFormat"
                    autocomplete="off"
                    clearable
                    prepend-inner-icon="$calendar"
                    @click:clear="clear"
                    @focus.stop="onFocus"
                    @keydown.tab="onBlur"
                />
            </template>
            <v-date-picker
                v-model="internValue"
                :disabled="disabled"
                :max="maxDate"
                :min="minDate"
                no-title
                scrollable
                :locale="locale"
                :first-day-of-week="firstDayOfWeek"
                @click:date="close"
            />
        </v-menu>
        <v-text-field
            v-else
            :id="name"
            ref="textfield"
            v-model="viewValue"
            filled
            :disabled="disabled"
            :error="!valid || parseError"
            :error-messages="combinedErrors"
            :label="config.label"
            :name="name"
            :required="required"
            :rules="computedRules"
            autocomplete="off"
            clearable
            prepend-inner-icon="$calendar"
        />
    </div>
    <div v-else>
        <v-dialog
            v-if="!disabled"
            ref="datepicker"
            v-model="modal"
            :return-value.sync="internValue"
            persistent
            width="290px"
        >
            <template #activator="{ on }">
                <v-text-field
                    :id="id" ref="textfield"
                    v-model="viewValue"
                    filled
                    :disabled="disabled"
                    readonly
                    :error="!valid"
                    :error-messages="errorMessages"
                    :label="config.label"
                    :name="name"
                    :required="required"
                    :rules="computedRules"
                    autocomplete="off"
                    clearable
                    prepend-inner-icon="$calendar"
                    v-on="on"
                    @click:clear="clear"
                />
            </template>
            <v-date-picker
                v-model="internValue"
                :disabled="disabled"
                :max="maxDate"
                :min="minDate"
                no-title
                scrollable
                :locale="locale"
                :first-day-of-week="firstDayOfWeek"
            >
                <v-spacer />
                <v-btn color="primary" text @click="modal = false">
                    {{ $lumui.i18n.t('lumui.form.date.close') }}
                </v-btn>
                <v-btn color="primary" text @click="save">
                    {{ $lumui.i18n.t('lumui.form.date.save') }}
                </v-btn>
            </v-date-picker>
        </v-dialog>
        <v-text-field
            v-else
            :id="name"
            ref="textfield"
            v-model="viewValue"
            filled
            :disabled="disabled"
            :error="!valid"
            :error-messages="errorMessages"
            :label="config.label"
            :name="name"
            :required="required"
            :rules="computedRules"
            autocomplete="off"
            clearable
            prepend-inner-icon="$calendar"
        />
    </div>
</template>

<script>
import {VBtn, VDatePicker, VDialog, VMenu, VSpacer, VTextField} from 'vuetify/lib'
import Moment from 'moment';
import Validatable from 'vuetify/lib/mixins/validatable';
import {debounce} from 'vue-debounce';
import {mapGetters} from "vuex";

/**
 * Date element
 *
 * #### Config
 *
 * | key                    | type                       | required | default    | description |
 * |------------------------|----------------------------|----------|------------|-------------|
 * | type                   | `String`                   | yes      | -          | always `"date"` |
 * | label                  | `String`, `false`          | no       | `false`    | fields label |
 * | required               | `Boolean`, `eval(String)`  | no       | `false`      | field is required.|
 * | disabled               | `Boolean`, `eval(String)`  | no       | `false`      | field is disabled.|
 * | visible                | `Boolean`, `eval(String)`  | no       | `false`      | field is rendered.  |
 * | min                    | `String`                   | no       | `undefined`  | Minimum date allowed. Format 'YYYY-MM-DD' |
 * | max                    | `String`                   | no       | `undefined`  | Maximum date allowed. Format 'YYYY-MM-DD' |
 */
export default {
    name: "FormRowDate",
    components: {VDatePicker, VMenu, VTextField, VSpacer, VBtn, VDialog},
    mixins: [Validatable],
    props: {
        value: {
            type: [Date, String],
            required: false,
            default: null
        },
        /** field config */
        config: {
            type: Object,
            default: () => {
            }
        },
        /** field id */
        id: {
            type: String,
            required: true
        },
        /** field name */
        name: {
            type: String,
            default: ""
        },
        /** field is required */
        required: {
            type: Boolean,
            default: false
        },
        /** field is disabled */
        disabled: {
            type: Boolean,
            default: false
        },
        /** field has validation errors */
        error: {
            type: Boolean,
            default: false,
            required: false
        },
        /** validation messages */
        errorMessages: {
            type: Array,
            default: () => [],
            required: false
        },
        /** validation rules */
        rules: {
            type: Array,
            default: () => [],
            required: false
        }
    },
    data() {
        return {
            modal: false,
            internValue: null,
            viewValue: null,
            hasInput: false,
            parseError: false,
        }
    },
    computed: {
        ...mapGetters('lumui/i18n', ['locale']),
        firstDayOfWeek() {
            return Moment.localeData(this.locale).firstDayOfWeek();
        },
        externalError() {
            return this.errorBucket.length > 0 || this.error
        },
        minDate() {
            // probably workaround for timezone issues.
            return this.config.min ? (Moment(this.config.min, "YYYY-MM-DD")).add(1, 'days').toISOString() : undefined
        },
        maxDate() {
            return this.config.max ? (Moment(this.config.max, "YYYY-MM-DD")).add(1, 'days').toISOString() : undefined
        },
        inputFormat() {
            return Moment().localeData().longDateFormat("L");
        },
        combinedErrors() {
            const errors = [...this.errorMessages];
            if (this.parseError) {
                errors.push(`Das eingegebene Datum entspricht nicht dem erwarteten Format (${this.inputFormat})`);
            }
            return errors;
        },
        computedRules() {
            const rtn = this.rules;
            if (this.config.min) {
                const mMin = Moment(this.config.min, "YYYY-MM-DD");
                rtn.push(() => {
                    const mVal = Moment(this.internValue, "YYYY-MM-DD");
                    if (!mVal || !mVal.isValid()) {
                        return true;
                    }

                    if (mMin.isSameOrBefore(mVal)) {
                        return true;
                    } else {
                        return this.$t('lumui.form.date.before_min', {"min_date": mMin.format("L")});
                    }
                })
            }
            if (this.config.max) {
                const mMax = Moment(this.config.max, "YYYY-MM-DD");
                rtn.push(() => {
                    const mVal = Moment(this.internValue, "YYYY-MM-DD");
                    if (!mVal || !mVal.isValid()) {
                        return true;
                    }

                    if (mMax.isSameOrAfter(mVal)) {
                        return true;
                    } else {
                        return this.$t('lumui.form.date.after_max', {"max_date": mMax.format("L")});
                    }
                })
            }

            return rtn;
        }
    },
    watch: {
        locale() {
            let date = Moment(this.internValue, "YYYY-MM-DD");
            this.viewValue = date.format('L');
        },
        viewValue(val) {
            if (val == null || val.match(/^\s*$/)) {
                this.internValue = null;
                this.parseError = false;
                return;
            }
            const date = Moment(val, "L");
            if (date.isValid() && val === date.format("L")) {
                this.parseError = false;
                this.internValue = date.format('YYYY-MM-DD');
            } else {
                this.internValue = null;
                this.parseError = true;
            }
            this.validate(true);
        },
        internValue() {
            if (this.internValue) {
                let date = Moment(this.internValue, "YYYY-MM-DD");
                this.viewValue = date.format('L');
                /**
                 * user input
                 * @prop {String} internValue
                 */
                this.$emit('input', this.internValue);
            }
        },
        value(newValue) {
            if (newValue) {
                try {
                    let date = Moment(newValue, "YYYY-MM-DD");
                    this.internValue = date.format('YYYY-MM-DD');
                    this.viewValue = date.format('L');
                } catch (ignore) {
                    console.error(ignore);
                }
            } else {
                this.internValue = null
            }
        },
        hasError(val) {
            if (this.shouldValidate) {
                /**
                 * validation error detected
                 * @param {Boolean} val
                 */
                this.$emit('update:error', val)
            }
        },
        modal() {
            this.isFocused = true;
        }
    },
    mounted() {
        this.$nextTick(() => {
            let date;
            if (this.value) {
                try {
                    date = Moment(this.value, "YYYY-MM-DD");
                    this.internValue = date.year() + '-' + (date.month() + 1) + '-' + date.date();
                    this.viewValue = date.format('L');
                } catch (_) {
                    // ignored
                }
            }
            this.validate(true);
        });
        this.$refs.textfield.$el.addEventListener('click', this.onFocus);
    },
    methods: {
        /**
         * @private
         * @todo dead code?
         */
        updateError(e) {
            this.$emit('update:error', e)
        },
        /** clears field */
        clear() {
            this.$emit('input', '');
        },
        close() {
            this.modal = false;
        },
        save() {
            this.$refs.datepicker.save(this.internValue);
            this.isFocused = false;
            this.hasInput = true;
        },
        onFocus(e) {
            debounce(() => {
                this.modal = true;
            }, '100ms')();
            e.stopPropagation();
        },
        onBlur(e) {
            debounce(() => {
                this.modal = false;
            }, '100ms')();
            e.stopPropagation();
        },
    }
}
</script>
