import Vue from 'vue';
import UI from '../../src/';
import store from './store';
import de from "../translations/de";
import vuetify from "./vuetify";
const lumuiCfg = {
    translations: {
        "de-CH": de,
        "en-US": () => import("../translations/en").then(m => m.default),
        "et-EE": {},
        "lv-LV": {},
        "fi-FI": {},
        "lt-LT": {}
    },
    locale: "de-CH",
    fallbackLocale: "de-CH",
    store: store,
    vuetify: vuetify,
    localeKeyMap: {
        "de-CH": "de",
        "en-US": "en",
        "et-EE": "et",
        "lv-LV": "lv",
        "fi-FI": "fi",
        "lt-LT": "lt"
    },
    icons: {
    //  close: 'fas fa-times-circle'
    }
};
Vue.use(UI, lumuiCfg);
