import { render, staticRenderFns } from "./date.vue?vue&type=template&id=4c6aff49&"
import script from "./date.vue?vue&type=script&lang=js&"
export * from "./date.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/builds/lum/lumui/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('4c6aff49')) {
      api.createRecord('4c6aff49', component.options)
    } else {
      api.reload('4c6aff49', component.options)
    }
    module.hot.accept("./date.vue?vue&type=template&id=4c6aff49&", function () {
      api.rerender('4c6aff49', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "test/views/form/date.vue"
export default component.exports