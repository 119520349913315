<template>
    <asa-form v-model="value" :loading="false" :config="cfg" :abort-btn="false">
        <template #title-after>
            <v-menu
                bottom
                left
                close-on-click
            >
                <template #activator="{ on, attrs }">
                    <v-btn
                        icon
                        dark
                        v-bind="attrs"
                        v-on="on"
                    >
                        <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                </template>
                <v-list class="d-print-none">
                    <v-list-item href="#">
                        <v-list-item-icon>
                            <v-icon>fa-file</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>Menu 1</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item href="#">
                        <v-list-item-icon>
                            <v-icon>fa-file-pdf</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>Menu 2</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
            </v-menu>
        </template>
    </asa-form>
</template>

<script>
import {VBtn, VIcon, VList, VListItem, VListItemContent, VListItemIcon, VListItemTitle, VMenu} from 'vuetify/lib';
import AsaForm from "../../../src/component/AsaForm";

export default {
    name: "Autocomplete",
    components: {AsaForm, VBtn, VIcon, VMenu, VList, VListItem, VListItemTitle, VListItemContent, VListItemIcon},
    data() {
        return {
            loading: false,
            value: {
                f1: "a",
                f9: ["a","b"]
            },
            cfg: {
                f0: {
                    label: 'multiple + chips w/o required',
                    type: 'select',
                    multiple: true,
                    chips: true,
                    options: [
                        {value: 'a', text: 'a'},
                        {value: 'b', text: 'b'},
                    ]
                },
                f1: {
                    label: 'multiple + chips w/ selection',
                    type: 'select',
                    multiple: true,
                    chips: true,
                    options: [
                        {value: 'a', text: 'a'},
                        {value: 'b', text: 'b'},
                    ]
                },
                f2: {
                    label: 'multiple + chips',
                    type: 'select',
                    multiple: true,
                    chips: true,
                    required: true,
                    options: [
                        {value: 'a', text: 'a'},
                        {value: 'b', text: 'b'},
                    ]
                },
                f3: {
                    type: 'select',
                    label: 'single + outer icon',
                    multiple: false,
                    required: true,
                    appendOuterIcon: 'fa-trash',
                    appendOuterIconCallback() {
                        alert('Boo');
                    },
                    options: [
                        {value: 'a', text: 'a'},
                        {value: 'b', text: 'b'},
                    ]
                },
                f4: {
                    type: 'select',
                    label: 'groups',
                    multiple: false,
                    required: true,
                    options: [
                        {value: 'a', text: 'a', group: 'Group A'},
                        {value: 'b', text: 'b', group: 'Group B'},
                    ]
                },
                f5: {
                    type: 'select',
                    label: 'dataUrl + multiple + no chips',
                    multiple: true,
                    chips: false,
                    required: false,
                    dataUrl: 'http://localhost:8777/options.json'
                },
                f6: {
                    type: 'select',
                    label: 'dataUrl + multiple + chips',
                    multiple: true,
                    chips: true,
                    required: false,
                    dataUrl: 'http://localhost:8777/options.json'
                },
                f7: {
                    type: 'select',
                    label: 'html_options',
                    multiple: false,
                    chips: false,
                    required: false,
                    html_options: true,
                    options: [
                        {value: 'a', text: '<i class="fa fa-square"></i> Test'},
                        {value: 'b', text: '<b>bold</b> <i>italic</i>'},
                    ]
                },
                f8: {
                    label: 'single with number values',
                    type: 'select',
                    multiple: false,
                    disabled: false,
                    required: true,
                    errors: [],
                    value: "1",
                    options: [
                        {value: '0', label: 'a'},
                        {value: '1', label: 'b'},
                    ]
                },
                f9: {
                    label: 'multiple + chips',
                    type: 'select',
                    multiple: true,
                    disabled: true,
                    chips: true,
                    required: true,
                    options: [
                        {value: 'a', text: 'a'},
                        {value: 'b', text: 'b'},
                    ]
                }
            }
        }
    }
}
</script>
