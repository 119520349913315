var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-container",
        [
          _c(
            "v-row",
            [
              _c("v-col", [
                _c("h1", { staticClass: "text-h3" }, [
                  _vm._v("\n                    Collections\n                "),
                ]),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12", md: "8" } },
                [
                  _c("asa-form", {
                    attrs: { config: _vm.formConf1 },
                    on: {
                      save: function ($event) {
                        return _vm.addErrors(_vm.formConf1, _vm.data1)
                      },
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "title",
                        fn: function () {
                          return [
                            _vm._v(
                              "\n                        Single Field Subformular\n                    "
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                    model: {
                      value: _vm.data1,
                      callback: function ($$v) {
                        _vm.data1 = $$v
                      },
                      expression: "data1",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-col",
                { attrs: { cols: "12", md: "4" } },
                [
                  _c(
                    "v-card",
                    { staticClass: "fill-height" },
                    [
                      _c("v-card-title", { staticClass: "elevation-1" }, [
                        _vm._v(
                          "\n                        Value\n                    "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("v-card-text", { staticClass: "py-4" }, [
                        _c("pre", [
                          _vm._v(_vm._s(JSON.stringify(_vm.data1, null, 4))),
                        ]),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "8" } },
                [
                  _c("asa-form", {
                    attrs: { config: _vm.formConf2 },
                    scopedSlots: _vm._u([
                      {
                        key: "title",
                        fn: function () {
                          return [
                            _vm._v(
                              "\n                        Complex Subformular - Values Async\n                    "
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                    model: {
                      value: _vm.data2,
                      callback: function ($$v) {
                        _vm.data2 = $$v
                      },
                      expression: "data2",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-col",
                { attrs: { cols: "4" } },
                [
                  _c(
                    "v-card",
                    { staticClass: "fill-height" },
                    [
                      _c("v-card-title", { staticClass: "elevation-1" }, [
                        _vm._v(
                          "\n                        Value\n                    "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("v-card-text", { staticClass: "py-4" }, [
                        _c("pre", [
                          _vm._v(_vm._s(JSON.stringify(_vm.data2, null, 4))),
                        ]),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "8" } },
                [
                  _c("asa-form", {
                    attrs: { config: _vm.formConf3 },
                    scopedSlots: _vm._u([
                      {
                        key: "title",
                        fn: function () {
                          return [
                            _vm._v(
                              "\n                        Complex Subformular - Values empty data from formconf\n                    "
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                    model: {
                      value: _vm.data3,
                      callback: function ($$v) {
                        _vm.data3 = $$v
                      },
                      expression: "data3",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-col",
                { attrs: { cols: "4" } },
                [
                  _c(
                    "v-card",
                    { staticClass: "fill-height" },
                    [
                      _c("v-card-title", { staticClass: "elevation-1" }, [
                        _vm._v(
                          "\n                        Value\n                    "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("v-card-text", { staticClass: "py-4" }, [
                        _c("pre", [
                          _vm._v(_vm._s(JSON.stringify(_vm.data3, null, 4))),
                        ]),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "8" } },
                [
                  _c("asa-form", {
                    attrs: { config: _vm.formConf4 },
                    scopedSlots: _vm._u([
                      {
                        key: "title",
                        fn: function () {
                          return [
                            _vm._v(
                              "\n                        UM Problem\n                    "
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                    model: {
                      value: _vm.data4,
                      callback: function ($$v) {
                        _vm.data4 = $$v
                      },
                      expression: "data4",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-col",
                { attrs: { cols: "4" } },
                [
                  _c(
                    "v-card",
                    { staticClass: "fill-height" },
                    [
                      _c("v-card-title", { staticClass: "elevation-1" }, [
                        _vm._v(
                          "\n                        Value\n                    "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("v-card-text", { staticClass: "py-4" }, [
                        _c("pre", [
                          _vm._v(_vm._s(JSON.stringify(_vm.data4, null, 4))),
                        ]),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "8" } },
                [
                  _c("asa-form", {
                    attrs: { config: _vm.formConf5 },
                    scopedSlots: _vm._u([
                      {
                        key: "title",
                        fn: function () {
                          return [
                            _vm._v(
                              "\n                        data from value\n                    "
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                    model: {
                      value: _vm.data5,
                      callback: function ($$v) {
                        _vm.data5 = $$v
                      },
                      expression: "data5",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-col",
                { attrs: { cols: "4" } },
                [
                  _c(
                    "v-card",
                    { staticClass: "fill-height" },
                    [
                      _c("v-card-title", { staticClass: "elevation-1" }, [
                        _vm._v(
                          "\n                        Value\n                    "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("v-card-text", { staticClass: "py-4" }, [
                        _c("pre", [
                          _vm._v(_vm._s(JSON.stringify(_vm.data5, null, 4))),
                        ]),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }