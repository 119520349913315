(function($R)
{
    $R.lang['he'] = {
        "format": "פורמט",
        "image": "תמונה",
        "file": "קובץ",
        "link": "קישור",
        "bold": "מודגש",
        "italic": "נטוי",
        "deleted": "מחוק",
        "underline": "Underline",
        "superscript": "Superscript",
        "subscript": "Subscript",
        "bold-abbr": "B",
        "italic-abbr": "I",
        "deleted-abbr": "S",
        "underline-abbr": "U",
        "superscript-abbr": "Sup",
        "subscript-abbr": "Sub",
        "lists": "רשימות",
        "link-insert": "הוסף קישור",
        "link-edit": "ערוך קישור",
        "link-in-new-tab": "פתח קישור בחלון חדש",
        "unlink": "הסר קישור",
        "cancel": "בטל",
        "close": "סגור",
        "insert": "הכנס",
        "save": "שמור",
        "delete": "מחק",
        "text": "טקסט",
        "edit": "ערוך",
        "title": "כותרת",
        "paragraph": "טקסט רגיל",
        "quote": "ציטוט",
        "code": "קוד",
        "heading1": "כותרת 1",
        "heading2": "כותרת 2",
        "heading3": "כותרת 3",
        "heading4": "כותרת 4",
        "heading5": "כותרת 5",
        "heading6": "כותרת 6",
        "filename": "שם",
        "optional": "אופציונאלי",
        "unorderedlist": "רשימת נקודות",
        "orderedlist": "רשימה ממוספרת",
        "outdent": "קרב לשוליים",
        "indent": "הרחק מהשוליים",
        "horizontalrule": "קו אופקי",
        "upload": "Upload",
        "upload-label": "Drop files here or click to upload",
        "upload-change-label": "Drop a new image to change",
        "accessibility-help-label": "עורך טקסט עשיר",
        "caption": "כיתוב",
        "bulletslist": "נקודות",
        "numberslist": "ממוספר",
        "image-position": "Position",
        "none": "None",
        "left": "Left",
        "right": "Right",
        "center": "Center",
        "undo": "Undo",
        "redo": "Redo"
    };
})(Redactor);