(function($R)
{
    $R.lang['sv'] = {
        "format": "Format",
        "image": "Bild",
        "file": "Fil",
        "link": "Länk",
        "bold": "Fet",
        "italic": "Kursiv",
        "deleted": "Överstruken",
        "underline": "Understruken",
        "superscript": "Upphöjd",
        "subscript": "Nedsänkt",
        "bold-abbr": "B",
        "italic-abbr": "I",
        "deleted-abbr": "S",
        "underline-abbr": "U",
        "superscript-abbr": "Sup",
        "subscript-abbr": "Sub",
        "lists": "Lista",
        "link-insert": "Infoga länk",
        "link-edit": "Redigera länk",
        "link-in-new-tab": "Öppna länk i nytt fönster",
        "unlink": "Radera länk",
        "cancel": "Avbryt",
        "close": "Stäng",
        "insert": "Infoga",
        "save": "Spara",
        "delete": "Radera",
        "text": "Text",
        "edit": "Redigera",
        "title": "Titel",
        "paragraph": "Normal text",
        "quote": "Citat",
        "code": "Kod",
        "heading1": "Titel 1",
        "heading2": "Titel 2",
        "heading3": "Titel 3",
        "heading4": "Titel 4",
        "heading5": "Titel 5",
        "heading6": "Titel 6",
        "filename": "Namn",
        "optional": "valfritt",
        "unorderedlist": "Osorterad lista",
        "orderedlist": "Sorterad lista",
        "outdent": "Minska utdrag",
        "indent": "Öka utdrag",
        "horizontalrule": "Linje",
        "upload": "Upload",
        "upload-label": "Släpp filer här eller klicka för att ladda upp",
        "upload-change-label": "Drop a new image to change",
        "accessibility-help-label": "Textredigerare",
        "caption": "Undertext",
        "bulletslist": "Punkter",
        "numberslist": "Nummer",
        "image-position": "Position",
        "none": "Ingen",
        "left": "Vänster",
        "right": "Höger",
        "center": "Centrerad",
        "undo": "Ångra",
        "redo": "Gör om",

        /* Table plugin */
        "table": "Tabell",
        "insert-table": "Infoga tabell",
        "insert-row-above": "Infoga rad ovanför",
        "insert-row-below": "Infoga rad undertill",
        "insert-column-left": "Infoga kolumn till vänster",
        "insert-column-right": "Infoga kolumn till höger",
        "add-head": "Lägg till rubrikrad",
        "delete-head": "Ta bort rubrikrad",
        "delete-column": "Ta bort kolumn",
        "delete-row": "Ta bort rad",
        "delete-table": "Ta bort tabell",

        /* Fullscreen plugin */
        "fullscreen": "Fullskärm",

        /* Font color plugin */
        "fontcolor": "Textfärg",
        "highlight": "Färgöverstrykning",

        /* Font family plugin */
        "fontfamily": "Typsnitt",
        "remove-font-family": "Återställ typsnitt",

        /* Font size plugin */
        "size": "Storlek",
        "remove-size":  "Återställ textstorlek"
    };
})(Redactor);