<template>
    <v-dialog :value="show" :fullscreen="fullscreen" :max-width="width" :persistent="persistent">
        <v-card :color="color">
            <v-card-title class="subheading">
                <!-- dialog title -->
                <slot name="title" />
            </v-card-title>

            <v-card-text>
                <!-- dialog content -->
                <slot name="content" />
            </v-card-text>

            <v-card-actions>
                <!-- dialog actions -->
                <slot name="actions" />
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>

import VDialog from 'vuetify/lib/components/VDialog';
import {VCard, VCardActions, VCardText, VCardTitle} from 'vuetify/lib/components/VCard';

export default {
    name: 'AsaAlert',
    components: {VDialog, VCard, VCardActions, VCardText, VCardTitle},
    props: {
        /** whether or not to display the alert */
        show: {
            type: Boolean,
            default: false,
        },
        /** color used for the alert */
        color: {
            type: String,
            default: ""
        },
        /** the width of the alert */
        width: {
            type: Number,
            default: 350
        },
        /** whether or not clicks outside the alert should be registered */
        persistent: {
            type: Boolean,
            default: true
        },
        /** cover the whole window */
        fullscreen: {
            type: Boolean,
            default: false
        },
    }
}
</script>
