var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    [
      _c(
        "v-card",
        [
          _c(
            "v-toolbar",
            [
              _c("v-toolbar-title", [
                _vm._v(
                  "\n                Filters & Persistent Filters\n            "
                ),
              ]),
              _vm._v(" "),
              _c("v-spacer"),
              _vm._v(" "),
              _c(
                "v-toolbar-items",
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { tile: "", icon: "", large: "" },
                      on: {
                        click: function ($event) {
                          _vm.showFilter = !_vm.showFilter
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v("$search")])],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("asa-table", {
            attrs: {
              loading: false,
              data: _vm.data,
              "info-disabled": "",
              "show-filter": _vm.showFilter,
              "storage-key": "test_table_filter_storage",
              "items-per-page-options": [10, 50, 100],
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }