export default class MaterializedPathFormatter {
    constructor()
    {
    }

    requiresHTML() {
        return false;
    }

    format(value) {
        if(!value) {
            return "";
        }
        if(typeof value === 'object' && Object.hasOwn(value, 'label')) {
            return value['label'];
        } else {
            return String(value);
        }
    }
}
