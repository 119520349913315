var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-container",
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12", md: "8" } },
                [
                  _c("asa-form", {
                    attrs: { config: _vm.config, "abort-btn": false },
                    model: {
                      value: _vm.value,
                      callback: function ($$v) {
                        _vm.value = $$v
                      },
                      expression: "value",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-col",
                { attrs: { cols: "12", md: "4" } },
                [
                  _c(
                    "v-card",
                    { staticClass: "fill-height" },
                    [
                      _c("v-card-title", { staticClass: "elevation-1" }, [
                        _vm._v(
                          "\n                        Value\n                    "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("v-card-text", { staticClass: "py-4" }, [
                        _c("pre", [
                          _vm._v(_vm._s(JSON.stringify(_vm.value, null, 4))),
                        ]),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }