var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c("p", [
        _vm._v(
          "noop formatter is invoked if formatter.js is called without type option"
        ),
      ]),
      _vm._v("\n\n    noop with option isHtmlRequired:"),
      _c("br"),
      _vm._v(" "),
      _c("asa-formatter", {
        attrs: {
          value: "<strong>Hans</strong>",
          options: { isHtmlRequired: true },
        },
      }),
      _c("br"),
      _vm._v("\n    noop without option isHtmlRequired:"),
      _c("br"),
      _vm._v(" "),
      _c("asa-formatter", { attrs: { value: "<strong>Hans</strong>" } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }