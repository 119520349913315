<template>
    <v-dialog hide-overlay persistent value="true" width="300">
        <v-card color="primary" dark>
            <v-card-text>
                Please stand by
                <v-progress-linear class="mb-0" color="white" indeterminate />
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import {VCard, VCardText, VDialog, VProgressLinear} from 'vuetify/lib';

/**
 * variation on the loading animation theme
 * 
 * @deprecated prefer AsaLoading when possible
 */
export default {
    name: "AsaSpinner",
    components: {VDialog, VCard, VCardText, VProgressLinear}
}
</script>

<style scoped>

</style>
