<template>
    <v-menu
        ref="dialog"
        v-model="modal"
        transition="scroll-y-transition"
        :close-on-content-click="false"
        offset-y
        max-width="290px"
        min-width="auto"
    >
        <template #activator="{ on }">
            <v-text-field
                :id="'filter_' + config.key"
                v-model="viewValue"
                :label="config.label"
                :clearable="clearable"
                prepend-inner-icon="$calendar"
                readonly
                hide-details
                v-on="on"
                @click:clear="reset"
            />
        </template>
        <v-date-picker
            v-model="date"
            :max="max"
            :min="min"
            :range="range"
            no-title
            scrollable
            :locale="locale"
            :first-day-of-week="firstDayOfWeek"
        />
    </v-menu>
</template>

<script>
import {VDatePicker, VMenu, VTextField} from 'vuetify/lib';
import Moment from 'moment'
import {mapGetters} from "vuex";

/**
 * Renders a date filter
 * @internal
 */
export default {
    name: "AsaTableFilterDate",
    components: {VMenu, VTextField, VDatePicker},
    props: {
        config: {
            type: Object,
            required: true,
        }
    },
    data() {
        return {
            date: null,
            modal: false,
            viewValue: null,
            min: undefined,
            max: undefined,
            range: false,
        }
    },
    computed: {
        ...mapGetters('lumui/i18n', ['locale']),
        firstDayOfWeek() {
            return Moment.localeData(this.locale).firstDayOfWeek();
        },
        clearable() {
            if ('clearable' in this.config) {
                return !!this.config.clearable;
            } else {
                return true;
            }
        },
        inputFormat () {
            return Moment.localeData(this.locale).longDateFormat("L");
        },
    },
    watch: {
        config: {
            handler(newConfig) {
                this.updateFromConfig(newConfig);
            },
            deep: true,
        },
        viewValue() {
            if (!this.viewValue) {
                this.$set(this.config, 'value', '');
            }
        },
        modal(val) {
            if (!val) {
                this.$refs.dialog.save(this.date)
            }
        },
        date() {
            try {
                if (this.range && Array.isArray(this.date)) {
                    this.viewValue = this.date.map((val) => {
                        let tmp = Moment(val);
                        return tmp.format("L")
                    }).join(' - ');
                    if (!this.date.equals(this.config.value)) {
                        this.$set(this.config, 'value', this.date);
                    }
                    if (this.date.length === 2) {
                        this.$refs.dialog.save(this.date);
                    }
                } else {
                    let tmp = Moment(this.date);
                    if (tmp.isValid()) {
                        this.viewValue = tmp.format("L")
                    }
                    if (this.config.value !== this.date) {
                        this.$set(this.config, 'value', this.date);
                    }
                    this.$refs.dialog.save(this.date);
                }
            } catch (e) {
                this.viewValue = '';
            }
        }
    },
    mounted() {
        this.updateFromConfig(this.config);
    },
    methods: {
        reset() {
            this.date = this.range ? [] : null;
        },
        updateFromConfig(newConfig) {
            this.min = undefined;
            this.max = undefined;
            this.range = false;

            if ('filter' in newConfig && typeof newConfig.filter !== 'string' && 'date' in newConfig.filter) {
                let filterConfig = newConfig.filter.date;

                if ('min' in filterConfig && filterConfig.min) {
                    this.min = filterConfig.min;
                }

                if ('max' in filterConfig && filterConfig.max) {
                    this.max = filterConfig.max;
                }

                if ('range' in filterConfig && filterConfig.range) {
                    this.range = filterConfig.range;
                }
            }

            try {
                if (this.range && Array.isArray(newConfig.value)) {
                    this.date = newConfig.value.map((date) => {
                        let tmp = new Moment(date);
                        if (!tmp.isValid()) {
                            throw Error('Invalid Date: ' + date);
                        }
                        return date;
                    })
                } else if (newConfig.value) {
                    let tmp = new Moment(newConfig.value);
                    if (!tmp.isValid()) {
                        throw Error('Invalid Date: ' + newConfig.value);
                    }
                    this.date = newConfig.value;
                }
            } catch (e) {
                console.debug(e);
                this.date = this.range ? [] : '';
                this.viewValue = '';
            }

        }
    }
}
</script>
