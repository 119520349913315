<template>
    <v-container>
        <v-card>
            <v-toolbar>
                <v-toolbar-title>
                    Filters & Persistent Filters
                </v-toolbar-title>
                <v-spacer />
                <v-toolbar-items>
                    <v-btn tile icon large @click="showFilter = !showFilter">
                        <v-icon>$search</v-icon>
                    </v-btn>
                </v-toolbar-items>
            </v-toolbar>
            <asa-table
                :loading="false"
                :data="data"
                info-disabled
                :show-filter="showFilter"
                storage-key="test_table_filter_storage"
                :items-per-page-options="[10, 50, 100]"
            />
        </v-card>
    </v-container>
</template>

<script>
import AsaTable from "../../../src/component/AsaTable.vue";
import {VBtn, VCard, VContainer, VIcon, VSpacer, VToolbar, VToolbarItems, VToolbarTitle} from 'vuetify/lib';

export default {
    name: "TableFilters",
    components: {
        VCard,
        VContainer,
        VToolbar,
        VToolbarTitle,
        VSpacer,
        VToolbarItems,
        VBtn,
        VIcon,
        AsaTable
    },
    data() {
        return {
            showFilter: true,
            data: {
                columns: [
                    {
                        key: 'string',
                        label: 'string'
                    },
                    {
                        key: 'boolean',
                        label: 'boolean',
                        type: 'bool'
                    },
                    {
                        key: 'date',
                        label: 'date',
                        type: 'date'
                    },
                    {
                        key: 'time',
                        label: 'time',
                        type: 'time'
                    },
                    {
                        key: 'keyval',
                        label: "Key Val",
                        formatter: {
                            KeyValue: [{1: "foo", 2: "bar"}]
                        }
                    },
                    {
                        key: 's',
                        label: 'Status',
                        type: 'status'
                    },
                    {
                        key: 'l',
                        label: 'Localized',
                        type: 'localized'
                    },
                    {
                        key: 'select',
                        label: 'Select',
                        type: "select",
                        options: [
                            { value: "E1", text: "A" },
                            { value: "E2", text: "B" },
                            { value: "E3", text: "C" },
                            { value: "E4", text: "D" },
                        ]
                    }
                ],
                rows: [
                    {
                        string: 'Eins',
                        keyval: 1,
                        boolean: true,
                        date: '1970-01-01',
                        time: '01:00:00',
                        s: [4, 1],
                        l: { "de": "Deutsch (CH)", "en": "German (CH)" },
                        select: "E1"
                    },
                    {
                        string: 'Zwei',
                        keyval: null,
                        boolean: false,
                        date: '1970-01-02',
                        time: '02:00:00',
                        s: [1, 2],
                        l: { "de": "Stuhl", "en": "Chair" },
                        select: "E2"
                    },
                    {
                        string: 'Drei',
                        keyval: 2,
                        boolean: false,
                        date: new Date().toISOString().substring(0, 10),
                        time: '03:00:00',
                        s: [2],
                        l: null,
                        select: "E3"
                    },
                    {
                        string: 'Vier',
                        keyval: 1,
                        boolean: true,
                        date: '2022-12-24',
                        time: '16:00:00',
                        s: [3],
                        l: null,
                        select: "E4"
                    },
                ],
                filter: [
                    {
                        key: 'string',
                        label: 'string'
                    }, {
                        key: 'boolean',
                        label: 'boolean',
                        type: 'bool'
                    },
                    {
                        key: 'date',
                        label: 'date',
                        type: 'date',
                        filter: {
                            date: {
                                range: true,
                                max: new Date()
                            }
                        }
                    },
                    {
                        key: 'keyval',
                        label: "Key Val",
                        filter: {
                            select: {
                                options: { 1: "foo", 2: "bar" },
                                multiple: true
                            }
                        }
                    },
                    {
                        key: "s",
                        label: "status",
                        type: "status",
                        filter: {
                            'select': {
                                'multiple': true,
                                'options': {
                                    1: {
                                        'text': 'alles gut',
                                        'icon': 'fas fa-check',
                                        'color': 'green darken-4'
                                    },
                                    2: {
                                        'text': 'Hinweis',
                                        'icon': 'fas fa-info-circle',
                                        'color': 'blue darken-2'
                                    },
                                    3: {
                                        'text': 'Warnung',
                                        'icon': 'fas fa-exclamation-triangle',
                                        'color': 'orange darken-2'
                                    },
                                    4: {
                                        'text': 'Kritisch',
                                        'icon': 'fas fa-exclamation-triangle',
                                        'color': 'red'
                                    }
                                }
                            },
                        }
                    },
                    {
                        key: "l",
                        label: "Localized",
                        type: "localized"
                    },
                    {
                        key: "select",
                        filter: {
                            select: {
                                options: [
                                    { value: "E1", text: "A" },
                                    { value: "E2", text: "B" },
                                    { value: "E3", text: "C" },
                                    { value: "E4", text: "D" },
                                ],
                                multiple: true
                            }
                        }
                    }
                ],
            },
        };
    }
};
</script>
