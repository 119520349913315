<template>
    <v-autocomplete
        v-model="config.value"
        :items="items"
        item-text="label"
        item-value="path"
        :label="config.label"
        :clearable="clearable"
        hide-details
    >
        <template #selection="{parent, item, index, selected}">
            <span class="mr-1">{{ item.label }}</span>
            <span v-if="item.parents" class="caption gray--text mr-1">{{ item.parents ?? '???' }}</span>
        </template>
        <template #item="{ item, on }">
            <v-list-item :class="{'v-item--disabled v-list-item--disabled' : item.disabled}" v-on="on">
                <v-list-item-content>
                    <v-list-item-title>
                        {{ item.label }}
                    </v-list-item-title>
                    <v-list-item-subtitle class="caption">
                        {{ item.parents }}
                    </v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
        </template>
    </v-autocomplete>
</template>

<script>
import {VAutocomplete, VListItem, VListItemContent, VListItemSubtitle, VListItemTitle} from 'vuetify/lib';

/**
 * Renders a NestedSet filter
 * @internal
 */
export default {
    name: "AsaTableFilterMaterializedPath",
    components: { VAutocomplete, VListItem, VListItemContent, VListItemTitle, VListItemSubtitle },
    props: {
        config: {
            type: Object,
            required: true,
        }
    },
    data() {
        return {
            byId: new Map(),
            items: [],
            nextIndex: 0,
            parentsTimeout: undefined
        };
    },
    computed: {
        clearable() {
            if (Object.hasOwn(this.config, 'clearable')) {
                return !!this.config.clearable;
            } else {
                return true;
            }
        },
    },
    watch: {
        config: {
            handler(newVal) {
                const calcParents = !Object.hasOwn(this.config, "precalculatedParents") || this.config.precalculatedParents !== true;
                this.items = newVal.filter.materialized_path.map(item => {
                    if (calcParents) {
                        item.parents = '...'; //parents;
                    }
                    return item;
                });
                for (const item of this.items) {
                    const n = item.path.lastIndexOf("-", item.path.length - 2);
                    const id = item.path.substr(n + 1, item.path.length - 2 - n);
                    if (this.byId.has(id)) {
                        console.warn("duplicate id " + id);
                    }
                    this.byId.set(id, item);
                }

                this.nextIndex = 0;
                if (this.parentsTimeout) {
                    window.clearTimeout(this.parentsTimeout);
                }
                if (calcParents) {
                    this.parentsTimeout = window.setTimeout(this.getParentString, 0);
                }
            },
            immediate: true
        }
    },
    methods: {
        getParent(item) {
            const parentIds = item.path.split('-');
            parentIds.pop(); // remove empty string
            parentIds.pop(); // remove own id

            const parts = [];

            for (const pid of parentIds) {
                const parent = this.byId.get(pid);
                if (!parent) {
                    console.warn('Parent not found');
                    parts.push('???');
                } else {
                    console.warn('No label in parent node', parent);
                    parts.push(parent.label ?? '???');
                }
            }
            return parts.join(' / ');
        },
        getParentString() {
            if (this.nextIndex >= this.items.length) {
                return;
            }

            // wir werden hier 20 Einträge berechnen und dann unterbrechen, damit das UI nicht einfriert
            const blockSize = 20;
            const n = Math.min(this.items.length, this.nextIndex + blockSize);
            for (let i = this.nextIndex; i < n; i++) {
                const item = this.items[this.nextIndex];
                item.parents = this.getParent(item);
                this.items[this.nextIndex] = item;
                ++this.nextIndex;
            }

            // setTimeout(x, 0) sorgt dafür, dass wir nach dem zeichen des nächsten Frames wieder dran kommen
            window.setTimeout(this.getParentString, 0);
        }
    },
};
</script>
