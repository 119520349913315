<template>
    <v-app id="app">
        <v-navigation-drawer v-model="drawer" app clipped>
            <v-list dense>
                <v-list-group :value="true" prepend-icon="fa-input-pipe">
                    <template #activator>
                        <v-list-item-title>Forms</v-list-item-title>
                    </template>

                    <v-list-item
                        v-for="route in $router.getRoutes().filter(r => r.name.match(/^form_/))"
                        :key="route.name"
                        :to="{name: route.name }"
                    >
                        <v-list-item-icon />
                        <v-list-item-title>{{ route.meta.title || route.name }}</v-list-item-title>
                    </v-list-item>
                </v-list-group>
                <v-list-group :value="false" prepend-icon="fa-table">
                    <template #activator>
                        <v-list-item-title>Tables</v-list-item-title>
                    </template>

                    <v-list-item
                        v-for="route in $router.getRoutes().filter(r => r.name.match(/^table_/))"
                        :key="route.name"
                        :to="{name: route.name }"
                    >
                        <v-list-item-icon />
                        <v-list-item-title>{{ route.meta.title || route.name }}</v-list-item-title>
                    </v-list-item>
                </v-list-group>
                <v-list-group :value="false" prepend-icon="fa-highlighter">
                    <template #activator>
                        <v-list-item-title>Formatter</v-list-item-title>
                    </template>

                    <v-list-item
                        v-for="route in $router.getRoutes().filter(r => r.name.match(/^formatter_/))"
                        :key="route.name"
                        :to="{name: route.name }"
                    >
                        <v-list-item-icon />
                        <v-list-item-title>{{ route.meta.title || route.name }}</v-list-item-title>
                    </v-list-item>
                </v-list-group>

                <v-list-group :value="false" prepend-icon="fa-browser">
                    <template #activator>
                        <v-list-item-title>Misc</v-list-item-title>
                    </template>

                    <v-list-item
                        v-for="route in $router.getRoutes().filter(r => r.name.match(/^misc_/))"
                        :key="route.name"
                        :to="{name: route.name }"
                    >
                        <v-list-item-icon />
                        <v-list-item-title>{{ route.meta.title || route.name }}</v-list-item-title>
                    </v-list-item>
                </v-list-group>
                <v-list-group :value="false" prepend-icon="fa-vial">
                    <template #activator>
                        <v-list-item-title>Component Test</v-list-item-title>
                    </template>
                    <v-list-item
                        v-for="route in $router.getRoutes().filter(r => r.name.match(/^test_/))"
                        :key="route.name"
                        :to="{name: route.name }"
                    >
                        <v-list-item-icon />
                        <v-list-item-title>{{ route.meta.title || route.name }}</v-list-item-title>
                    </v-list-item>
                </v-list-group>
                <v-list-group :value="false" prepend-icon="fa-sync">
                    <template #activator>
                        <v-list-item-title>Playground</v-list-item-title>
                    </template>
                    <v-list-item
                        v-for="route in $router.getRoutes().filter(r => r.name.match(/^playground_/))"
                        :key="route.name"
                        :to="{name: route.name }"
                    >
                        <v-list-item-icon />
                        <v-list-item-title>{{ route.meta.title || route.name }}</v-list-item-title>
                    </v-list-item>
                </v-list-group>
            </v-list>
        </v-navigation-drawer>

        <v-app-bar app clipped-left clipped-right>
            <v-app-bar-nav-icon @click="drawer = !drawer" />
            <v-toolbar-title>
                LumUI
                <span>
                    {{ $route.meta.title }}
                </span>
            </v-toolbar-title>
            <v-spacer />
            <v-select v-model="currentLocale" :items="locales" @change="setLocale" />
        </v-app-bar>

        <v-main>
            <router-view />
        </v-main>
    </v-app>
</template>

<script>
import {
    VApp,
    VAppBar,
    VAppBarNavIcon,
    VList,
    VListGroup,
    VListItem,
    VListItemIcon,
    VListItemTitle,
    VMain,
    VNavigationDrawer,
    VSelect,
    VSpacer,
    VToolbarTitle
} from 'vuetify/lib'
import {mapActions, mapGetters} from "vuex";

export default {
    components: {
        VApp,
        VAppBar,
        VList,
        VListGroup,
        VListItem,
        VListItemIcon,
        VListItemTitle,
        VMain,
        VNavigationDrawer,
        VSelect,
        VSpacer,
        VToolbarTitle,
        VAppBarNavIcon
    },
    data() {
        return {
            currentLocale: null,
            drawer: null
        };
    },
    computed: {
        ...mapGetters('lumui/i18n', [
            'locale',
            'locales'
        ])
    },
    watch: {
        locale(n) {
            if (this.currentLocale !== n) {
                this.currentLocale = n;
            }
        }
    },
    mounted() {
        this.currentLocale = this.locale;
    },
    methods: {
        ...mapActions('lumui/i18n', ['setLocale']),
    }
};
</script>

<style>
#app {
    font-size: 18px;
    font-family: 'Roboto', sans-serif;
}

.v-navigation-drawer--right {
    padding-top: 4em;
}
</style>
