<template>
    <div class="container">
        <h1>Truncate</h1>
        <asa-formatter type="truncate" :options="{maxLength: 10, truncationSuffix: '…'}" value="012345678901234567890" />
        <h2>No truncation</h2>
        <asa-formatter type="truncate" :options="{maxLength: 10 } " value="0123456789" />
    </div>
</template>

<script>
export default {
    name: "FormatterTime"
}
</script>

<style scoped>

</style>
