var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c("asa-formatter", {
        attrs: {
          value: 0,
          formatter: { KeyValue: [{ 0: "Foo", 1: "Bar" }, {}] },
        },
      }),
      _vm._v(" "),
      _c("asa-formatter", {
        attrs: {
          value: 1,
          formatter: { KeyValue: [{ 0: "Foo", 1: "Bar" }, {}] },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }