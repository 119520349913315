<template>
    <div>
        <template v-for="(image, i) in images">
            <v-img
                v-if="image.src && image.src !== null"
                :key="i"
                :src="image.src"
                aspect-ratio="1"
                class="grey lighten-2 clickable"
                @click="showLightbox(i)"
            >
                <v-layout slot="placeholder" align-center fill-height justify-center ma-0>
                    <v-progress-circular color="grey lighten-5" indeterminate />
                </v-layout>
            </v-img>
            <div v-else :key="i" class="grey lighten-4 pa-4 text-center">
                <v-icon>$error</v-icon>
                <small class="d-block mt-2">{{ image.name }}</small>
            </div>
        </template>

        <asa-lightbox
            v-if="images && images.length > 0"
            :images="images"
            :index="lightbox.index"
            :show="lightbox.show"
            @close="closeLightbox"
        />
    </div>
</template>

<script>
import {VIcon, VImg, VLayout} from 'vuetify/lib';

export default {
    name: 'AsaImage',
    components: {VImg, VLayout, VIcon},
    props: {
        show: {
            type: Boolean,
            default: false,
        },
        images: { // Objekt-Array mit src und title Attributen
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            lightbox: {
                "show": false,
                "index": 0,
            }
        }
    },
    methods: {
        closeLightbox() {
            this.lightbox.show = false
        },
        showLightbox(i) {
            this.lightbox.index = i;
            this.lightbox.show = true
        }
    },
}
</script>
