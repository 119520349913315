<template>
    <div :class="{'mb-7': config.chips && multiple }">
        <v-autocomplete
            :id="name"
            ref="autocomplete"
            v-model="viewValue"
            v-debounce:400ms="loadAsyncron"
            filled
            :cache-items="multiple && isAsyncron"
            :class="styleClass"
            :clearable="(!required && !multiple) || config.clearable"
            :disabled="disabled"
            :error="hasError"
            :error-messages="errorMessages"
            :filter="filter"
            :hide-selected="isAsyncron"
            :hint="config.description"
            :items="options"
            :label="config.label"
            :loading="isLoading"
            :multiple="multiple"
            :name="name"
            :persistent-hint="true"
            :required="required"
            :return-object="isAsyncron"
            :hide-details="config.chips && multiple ? 'auto' : false"
            :rules="rules"
            append-icon=""
            autocomplete="off"
            debounce-events="onkeydown"
            item-disabled="disabled"
            @change="onSelectChange"
            @input="updateError"
        >
            <template #selection="{ parent, item, index, selected }">
                <span v-if="(config.chips && multiple)">
                    <v-chip v-if="index == 0" small>
                        {{ $lumui.i18n.tc('lumui.form.autocomplete.selected_count', count, {c: count}) }}
                    </v-chip>
                </span>
                <v-chip
                    v-else-if="config.chips"
                    :class="(config.multiple ? 'chip--select-multi' : '') + ' mb-2'"
                    :close="config.multiple"
                    :selected="selected"
                    small
                >
                    <span v-if="config.html_options" v-html="sanitize(getLabel(item))"/>
                    <span v-else>{{ getLabel(item) }}</span>
                    <span v-if="item.group" class="caption gray--text" v-html="'(' + item.group + ')'"/>
                </v-chip>
                <template v-else>
                    <span v-if="item.attr && item.attr.icon"><v-icon class="mr-2">{{ item.attr.icon }}</v-icon></span>
                    <span v-if="item.attr && item.attr.image"><img :src="item.attr.image" class="mr-2 mt-1" alt=""></span>
                    <span v-if="config.html_options" class="mr-1" v-html="sanitize(getLabel(item))"/>
                    <span v-else class="mr-1">{{ getLabel(item) }}</span>
                    <span v-if="item.group && config.html_options" class="caption gray--text mr-1" v-html="'(' + sanitize(item.group) + ')'"/>
                    <span v-else-if="item.group && !config.html_options" class="caption gray--text mr-1">
                        {{ item.group }}
                    </span>
                </template>
            </template>
            <template #item="{ parent, item, on }">
                <v-list-item-icon v-if="item.attr && item.attr.icon">
                    <v-icon class="mr-2">
                        {{ item.attr.icon }}
                    </v-icon>
                </v-list-item-icon>
                <v-list-item-icon v-if="item.attr && item.attr.image">
                    <img :src="item.attr.image" alt="" class="mr-2 mt-1">
                </v-list-item-icon>
                <template v-if="config.multiple">
                    <v-list-item-action>
                        <v-simple-checkbox
                            :ripple="false"
                            :disabled:="item.disabled"
                            :value="viewValue.indexOf(item.value) > -1"
                            v-on="on"
                            color="primary"
                        />
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>
                            <span v-if="config.html_options" v-html="sanitize(getLabel(item))"/>
                            <span v-else>{{ getLabel(item) }}</span>
                        </v-list-item-title>
                        <v-list-item-subtitle>
                            <v-chip v-if="item.group && config.html_options" :ripple="false" small v-html="'(' + sanitize(item.group) + ')'"/>
                            <v-chip v-else-if="item.group && !config.html_options" :ripple="false" small>
                                ({{ item.group }})
                            </v-chip>
                        </v-list-item-subtitle>
                    </v-list-item-content>
                </template>
                <v-list-item-content v-else>
                    <v-list-item-title v-if="config.html_options" v-html="sanitize(getLabel(item))"/>
                    <v-list-item-title v-else>
                        {{ getLabel(item) }}
                    </v-list-item-title>
                    <v-list-item-subtitle v-if="item.group && config.html_options" class="caption" v-html="sanitize(item.group)"/>
                    <v-list-item-subtitle v-else-if="item.group && !config.html_options" class="caption">
                        {{ item.group }}
                    </v-list-item-subtitle>
                </v-list-item-content>
            </template>
            <template #append>
                <v-tooltip
                        v-if="config.chips && multiple && (typeof config.select_all === 'undefined' || config.select_all) && !config.disabled"
                        bottom style="z-index: 1000"
                >
                    <template #activator="{ on }">
                        <v-icon class="ml-2" v-on="on" @click.stop.capture="selectAll()">
                            $selectAll
                        </v-icon>
                    </template>
                    <span>{{ $lumui.i18n.t('lumui.form.autocomplete.select_all') }}</span>
                </v-tooltip>
                <v-tooltip
                        v-if="config.chips && multiple && (typeof config.clear === 'undefined' || config.clear) && !config.disabled"
                        bottom style="z-index: 1000"
                >
                    <template #activator="{ on }">
                        <v-icon small class="ml-2" v-on="on" @click.stop.capture="unselectAll()">
                            $clear
                        </v-icon>
                    </template>
                    <span>{{ $lumui.i18n.t('lumui.form.autocomplete.deselect_all') }}</span>
                </v-tooltip>
                <v-icon v-if="isMenuActive" style="cursor: pointer;" small class="ml-2">
                    $dropUp
                </v-icon>
                <v-icon v-else style="cursor: pointer;" small class="ml-2">
                    $dropDown
                </v-icon>
            </template>
            <template #append-outer>
                <v-icon v-if="config.appendOuterIcon" small @click.capture.stop.prevent="outerIconClick"
                        v-text="config.appendOuterIcon"/>
            </template>
            <template #no-data>
                <v-list-item>
                    <v-list-item-title v-if="isAsyncron">
                        {{ $lumui.i18n.t('lumui.form.autocomplete.search_hint') }}
                    </v-list-item-title>
                    <v-list-item-title v-else>
                        {{ $lumui.i18n.t('lumui.form.autocomplete.no_data') }}
                    </v-list-item-title>
                </v-list-item>
            </template>
        </v-autocomplete>
        <div v-if="(config.chips && multiple)">
            <div class="grey--text">
                <small>
                    <em v-if="!viewValue || viewValue.length === 0">{{
                        $lumui.i18n.t('lumui.form.autocomplete.nothing_selected')
                        }}</em>
                    <span v-else>{{ $lumui.i18n.t('lumui.form.autocomplete.selected_items') }}</span>
                </small>
            </div>
            <template v-for="(item, index) in viewValue">
                <v-chip
                        v-if="isAsyncron || getOption(item)"
                        :key="index"
                        :close="!config.disabled"
                        close-icon="$clear"
                        label
                        small
                        class="mr-1"
                        @update:active="unselect(item)"
                >
                    <template v-if="config.html_options">
                        <div v-html="isAsyncron ? sanitize(getLabel(item)) : sanitize(getLabel(getOption(item)))"/>
                    </template>
                    <template v-else>
                        <template v-if="isAsyncron">
                            {{ getLabel(item) }}
                        </template>
                        <template v-else>
                            {{ getLabel(getOption(item)) }}
                        </template>
                    </template>
                </v-chip>
            </template>
        </div>
    </div>
</template>

<script>
import HtmlSanitizer from '../lib/HtmlSanitizer';

import {
    VAutocomplete,
    VChip,
    VIcon,
    VListItem,
    VListItemAction,
    VListItemContent,
    VListItemSubtitle,
    VListItemTitle,
    VSimpleCheckbox,
    VTooltip
} from 'vuetify/lib';
import vueDebounce from 'vue-debounce'
import Validatable from 'vuetify/lib/mixins/validatable';

/**
 * #### Config
 *
 * | key                     | type                       | required | default | description |
 * |-------------------------|----------------------------|------|------------|-------------|
 * | type                    | `String`                   | yes  | `"select"` | always `"select"` |
 * | label                   | `String`, `false`          | no   | `false`    | fields label |
 * | class                   | `String`                   | no   | `null`     | css class for custom styling |
 * | required                | `Boolean`, `eval(String)`  | no   | false      | field is required.|
 * | disabled                | `Boolean`, `eval(String)`  | no   | false      | field is disabled.|
 * | visible                 | `Boolean`, `eval(String)`  | no   | false      | field is rendered.  |
 * | description             | `String`                   | no   | null       | hint to display below field |
 * | html_options            | `Boolean`                  | no   | `false`    | treat options as html. (html is sanitized) |
 * | clearable               | `Boolean`                  | no   | `false` for multiple, `true` otherwise | disable vuetify's clearing x |
 * | dataUrl                 | `String`                   | no   | `null`     | fetch options via http request from url |
 * | options                 | `Array`                    | yes  | -          | Array of {value: "", label: ""} objects. Note that "text" is an alias for label here. |
 * | multiOptions            | `Array`                    | no   | -          | Alias of options |
 * | appendOuterIcon         | `String`                   | no   | `null`     | the icon name for an aditional button after drop down. |
 * | appendOuterIconCallback | `eval(String)`, `function` | no   | `null`     | called when additional button is clicked. |
 * | multiple                | `Boolean`                  | no   | `false`    | allow multiple selections |
 * | chips                   | `Boolean`                  | no   | `false`    | draw chips for selected items. (only multiple) |
 * | select_all              | `Boolean`                  | no   | `false`    | display select all button (only multiple) |
 * | clear                   | `Boolean`                  | no   | `false`    | display clear button (only multiple) |
 */
export default {
    name: "FormRowAutocomplete",
    components: {
        vueDebounce,
        VAutocomplete,
        VIcon,
        VTooltip,
        VListItem,
        VListItemAction,
        VChip,
        VListItemContent,
        VListItemTitle,
        VListItemSubtitle,
        VSimpleCheckbox
    },
    mixins: [
        Validatable
    ],
    props: {
        /** autocomplete value */
        value: {
            type: [String, Array, Number, Object, Boolean],
            default: null
        },
        /** field definition */
        config: {
            type: Object,
            required: true
        },
        /** html id */
        id: {
            type: String,
            required: true
        },
        /** field name */
        name: {
            type: String,
            required: false
        },
        /** unused!? */
        error: {
            type: Boolean,
            default: false,
            required: false
        },
        /** validation messages to display */
        errorMessages: {
            type: Array,
            default: () => [],
            required: false
        },
        /** field must be filed out */
        required: {
            type: Boolean,
            default: false,
            required: false
        },
        /** validate when the field looses focus */
        validateOnBlur: {
            type: Boolean,
            default: true,
            required: false
        },
        /** validation rules */
        rules: {
            type: Array,
            default: () => [],
            required: false
        },
        /** disable user input */
        disabled: {
            type: Boolean,
            default: false,
            required: false
        },
        /** css class for custom styling */
        styleClass: {
            type: String,
            default: null,
            required: false
        },
        /** url from which to fetch data (instead of using `config.options` */
        dataUrl: {
            type: String,
            default: null,
            required: false
        },
    },
    data() {
        return {
            isMounted: false,
            viewValue: [],
            asynchOptions: [],
            isLoading: false,
            isMenuActive: false,
            sanitizeCache: {}
        }
    },
    computed: {
        isAsyncron() {
            return this.dataUrl !== null
        },
        locale() {
            return this.$store.getters['lumui/i18n/mappedLocale'];
        },
        options() {
            if (this.isAsyncron) {
                return this.asynchOptions
            }
            return (typeof this.config.options !== 'undefined') ? this.config.options : this.config.multiOptions
        },
        multiple() {
            return this.config.multiple || false;
        },
        count() {
            if (this.isAsyncron) {
                return this.viewValue.length
            }
            return this.options.filter((option) => {
                return this.viewValue.indexOf(option.value) >= 0;
            }).length
        },
        externalError() {
            return this.errorBucket.length > 0 || this.error
        }
    },
    watch: {
        value(newVal) {
            if (this.multiple) {
                this.viewValue = this.value !== null ? this.value : [];
            } else {
                this.viewValue = newVal;
            }
            // if (this.isAsyncron) {
            //     newVal.forEach((val) => {
            //         this.asynchOptions.push(val)
            //     })
            // }
        },
        viewValue() {
            let values = (typeof this.viewValue == 'object' && !Array.isArray(this.viewValue) && this.viewValue !== null)
                ? (this.viewValue.value || this.viewValue.id)
                : this.viewValue;
            // if (!this.isAsyncron && this.multiple) {
            //     values = this.viewValue.map((item) => item.value || item.id)
            // }
            this.hasFocused = true;
            this.hasInput = !!values;
            /** user input */
            this.$emit('input', values);
            this.$nextTick(() => {
                if (typeof this.$refs.autocomplete !== 'undefined') {
                    this.$refs.autocomplete.validate(true);
                }
                this.validate(true);
            });
        },
        hasError(val) {
            if (this.shouldValidate) {
                /** validation errors */
                this.$emit('update:error', val)
            }
        }
    },
    mounted() {
        this.$nextTick(() => {
            if (typeof this.value !== 'undefined' && this.value !== null) {
                this.viewValue = this.getValue(this.value);
            }
            this.validate(true);
            if (typeof this.$refs.autocomplete !== 'undefined') {
                this.$refs.autocomplete.validate(true);
            }
        });
        this.$watch(
            () => {
                return this.$refs.autocomplete.isMenuActive
            },
            (val) => {
                this.isMenuActive = val;
            }
        );
        this.isMounted = true;
    },
    methods: {
        /** @private */
        sanitize(str) {
            if(Object.hasOwn(this.sanitizeCache, str)) {
                return this.sanitizeCache[str];
            }
            HtmlSanitizer.AllowedAttributes["class"] = true;
            const sanitized = HtmlSanitizer.SanitizeHtml(str);
            this.sanitizeCache[str] = sanitized;
            return sanitized;
        },
        /** @private */
        async loadAsyncron(value) {
            if (this.isAsyncron && value.length >= 3) {
                this.isLoading = true;
                if (!this.dataUrl.match(/^https?:\/\//)) {
                    this.asynchOptions = await this.$call(this.dataUrl, {query: value})
                } else {
                    this.asynchOptions = await fetch(this.dataUrl).then(r => r.json());
                }
                this.isLoading = false
            }
        },
        /** @private */
        getLabel(item) {
            let label = "";
            if (typeof item === 'object' && item !== null) {
                if (Object.hasOwn(item, "label")) {
                    label = item.label
                } else if (Object.hasOwn(item, "text")) {
                    label = item.text
                }
            } else {
                label = item;
            }

            if (typeof label === 'object' && typeof label[this.locale] !== 'undefined') {
                label = label[this.locale]
            }
            return label
        },
        /** @private */
        getValue(value) {
            if (typeof value === 'object') {
                if (this.multiple) {
                    if (this.isAsyncron) {
                        return value
                    }
                    Object.keys(value).forEach((key) => {
                        value[key] = (typeof value[key] === 'object') ? (value[key].value || value[key].id) : value[key]
                    });
                } else {
                    value = (value.value || value.id)
                }
                return value
            }
            if (this.multiple) {
                // TODO: possible bug, if value is an array getValue returns an array in an array
                return (value === null) ? [] : [value];
            }

            return value
        },
        /** @private */
        onSelectChange() {
            if (!this.config.changeSelectCallback) {
                return;
            }
            if (typeof this.config.changeSelectCallback === 'function') {
                this.config.changeSelectCallback();
            } else {
                eval(this.config.changeSelectCallback)
            }
        },
        /** @private */
        outerIconClick() {
            if (!this.config.appendOuterIconCallback) {
                return;
            }
            if (typeof this.config.appendOuterIconCallback === 'function') {
                this.config.appendOuterIconCallback();
            } else {
                eval(this.config.appendOuterIconCallback)
            }
        },
        /*
         * get the option with `value` equal to `id`
         * @param {String|Number} id
         * @return {object}
         */
        getOption(id) {
            return this.options.filter((option) => {
                return option.value == id;
            })[0];
        },
        /** @private */
        unselect(idx) {
            this.viewValue.splice(this.viewValue.indexOf(idx), 1);
        },
        /**
         * select all items. only use with `config.multiple = true`
         * @return {boolean}
         */
        selectAll() {
            this.options.map((option) => {
                if (this.viewValue.indexOf(option.value) < 0) {
                    this.viewValue.push(option.value);
                }
            });
            this.$refs.autocomplete.blur();
            return false;
        },
        /**
         * unselect all items. only use with `config.multiple = true`
         * @return {boolean}
         */
        unselectAll() {
            this.options.forEach((option) => {
                if (this.viewValue.indexOf(option.value) >= 0) {
                    this.unselect(option.value);
                }
            });
            this.$refs.autocomplete.blur();
            return false;
        },
        /** @private */
        filter(item, queryText) {
            const queryParts = queryText.split(" ");
            const itemString = (item.group + " " + this.getLabel(item)).toLowerCase();
            let startIndex = 0;
            for (const queryPart of queryParts) {
                startIndex = itemString.indexOf(queryPart.toLowerCase(), startIndex)
                if (startIndex < 0) {
                    return false;
                }
            }

            return true;
        },
        /** @private */
        updateError() {
        },
        /** activate the autocomplete */
        toggleMenu() {
            this.isMenuActive = !this.isMenuActive;
            if (this.isMenuActive) {
                this.$refs.autocomplete.focus();
                this.$refs.autocomplete.activateMenu();
                this.$refs.autocomplete.isMenuActive = true;
            } else {
                this.$refs.autocomplete.blur();
            }
        }
    },
}
</script>

<style scoped>
autocomplete .v-text-field.v-text-field--enclosed .v-text-field__details {
    margin-bottom: 0;
}

.input-checkboxes .label {
    margin-bottom: -16px
}
</style>
