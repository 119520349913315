var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-container",
        [
          _c(
            "v-row",
            [
              _c("v-col", [
                _c("h1", { staticClass: "text-h3" }, [
                  _vm._v("\n                    Detail View\n                "),
                ]),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c(
                    "v-card",
                    [
                      _c("v-card-title", [
                        _vm._v(
                          "Default Detail View (having max amount of action icons before rendering icons as menu)"
                        ),
                      ]),
                      _vm._v(" "),
                      _c("v-divider"),
                      _vm._v(" "),
                      _c(
                        "v-card-text",
                        [
                          _c("asa-table", {
                            attrs: {
                              data: _vm.tableData,
                              "export-formats": ["pdf", "xls"],
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c(
                    "v-card",
                    [
                      _c("v-card-title", [_vm._v("Custom Detail View")]),
                      _vm._v(" "),
                      _c("v-divider"),
                      _vm._v(" "),
                      _c(
                        "v-card-text",
                        [
                          _c("asa-table", {
                            attrs: { data: _vm.tableData },
                            scopedSlots: _vm._u([
                              {
                                key: "col_col1",
                                fn: function (props) {
                                  return [
                                    _vm._v(
                                      "\n                                " +
                                        _vm._s(props.row.col1) +
                                        " [slot:col_col1]\n                            "
                                    ),
                                  ]
                                },
                              },
                              {
                                key: "detail",
                                fn: function ({ item, columns }) {
                                  return [
                                    _c(
                                      "v-card",
                                      { attrs: { flat: "" } },
                                      [
                                        _c("v-divider"),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "subheading ma-4",
                                            staticStyle: { color: "grey" },
                                          },
                                          [
                                            _vm._v(
                                              "\n                                        Item\n                                    "
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "pre",
                                          { staticClass: "mx-4 my-2" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                JSON.stringify(item, null, 4)
                                              )
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c("v-divider"),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "subheading ma-4",
                                            staticStyle: { color: "grey" },
                                          },
                                          [
                                            _vm._v(
                                              "\n                                        Columns\n                                    "
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "pre",
                                          { staticClass: "mx-4 my-2" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                JSON.stringify(columns, null, 4)
                                              )
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }