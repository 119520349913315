var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-input",
    {
      staticClass: "input--colorpicker",
      attrs: {
        id: _vm.id,
        name: _vm.name,
        filled: "",
        disabled: _vm.disabled,
        error: _vm.hasError,
        "error-messages": _vm.msgs,
        label: _vm.config.label,
        required: _vm.required,
        rules: _vm.rules,
        value: _vm.viewValue,
      },
    },
    [
      _c("v-color-picker", {
        attrs: {
          mode: _vm.computedMode,
          "hide-mode-switch": _vm.computedHideModeSwitch,
          disabled: _vm.disabled,
          "hide-inputs": _vm.hideInputs,
          "hide-canvas": _vm.hideCanvas,
          "show-swatches": _vm.showSwatches,
        },
        model: {
          value: _vm.viewValue,
          callback: function ($$v) {
            _vm.viewValue = $$v
          },
          expression: "viewValue",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }