var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "container" },
    [
      _vm._v("\n    true = "),
      _c("asa-formatter", { attrs: { type: "bool", value: true } }),
      _c("br"),
      _vm._v("\n    1 = "),
      _c("asa-formatter", { attrs: { type: "bool", value: 1 } }),
      _c("br"),
      _vm._v("\n    false = "),
      _c("asa-formatter", { attrs: { type: "bool", value: false } }),
      _c("br"),
      _vm._v("\n    0 = "),
      _c("asa-formatter", { attrs: { type: "bool", value: 0 } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }