(function($R)
{
    $R.lang['ro'] = {
		"format": "Format",
		"image": "Imagine",
		"file": "Fișier",
		"link": "Link",
		"bold": "Aldin",
		"italic": "Cursiv",
		"deleted": "Tăiere cu o linie",
		"underline": "Subliniere",
		"superscript": "Exponent",
		"subscript": "Indice",
		"bold-abbr": "A",
		"italic-abbr": "C",
		"deleted-abbr": "T",
		"underline-abbr": "S",
		"superscript-abbr": "Exp",
		"subscript-abbr": "Ind",
		"lists": "Liste",
		"link-insert": "Inserare link",
		"link-edit": "Editare link",
		"link-in-new-tab": "Deschidere link în filă nouă",
		"unlink": "Anulare link",
		"cancel": "Revocare",
		"close": "Închidere",
		"insert": "Inserare",
		"save": "Salvare",
		"delete": "Ștergere",
		"text": "Text",
		"edit": "Editare",
		"title": "Titlu",
		"paragraph": "Text normal",
		"quote": "Citat",
		"code": "Cod",
		"heading1": "Antet 1",
		"heading2": "Antet 2",
		"heading3": "Antet 3",
		"heading4": "Antet 4",
		"heading5": "Antet 5",
		"heading6": "Antet 6",
		"filename": "Nume",
		"optional": "opțional",
		"unorderedlist": "Listă neordonată",
		"orderedlist": "Listă ordonată",
		"outdent": "Indentare negativă",
		"indent": "Indentare",
		"horizontalrule": "Linie",
		"upload": "Încărcare",
		"upload-label": "Fixați fișierele aici sau faceți clic pentru a le încărca",
		"upload-change-label": "Fixați o nouă imagine pentru a schimba",
		"accessibility-help-label": "Editor de text îmbogățit",
		"caption": "Legendă",
		"bulletslist": "Marcatori",
		"numberslist": "Numere",
		"image-position": "Poziție",
		"none": "Niciuna/niciunul",
		"left": "Stânga",
		"right": "Dreapta",
		"center": "Centru",
		"undo": "Anulare",
		"redo": "Refacere"
    };
})(Redactor);