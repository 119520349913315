export default {
    "$vuetify.badge": "Ženklelis",
    "$vuetify.close": "Uždaryti",
    "$vuetify.dataIterator.noResultsText": "Nerasta atitinkančių įrašų",
    "$vuetify.dataIterator.loadingText": "Kraunama...",
    "$vuetify.dataTable.itemsPerPageText": "Eilutės per puslapį:",
    "$vuetify.dataTable.ariaLabel.sortDescending": "Išrikiuota mažėjimo tvarka.",
    "$vuetify.dataTable.ariaLabel.sortAscending": "Išrikiuota didėjimo tvarka.",
    "$vuetify.dataTable.ariaLabel.sortNone": "Nerikiuota.",
    "$vuetify.dataTable.ariaLabel.activateNone": "Suaktyvinkite, jei norite rikiavimą pašalinti.",
    "$vuetify.dataTable.ariaLabel.activateDescending": "Suaktyvinkite, jei norite rikiuoti mažėjimo tvarka.",
    "$vuetify.dataTable.ariaLabel.activateAscending": "Suaktyvinkite, jei norite rikiuoti didėjimo tvarka.",
    "$vuetify.dataTable.sortBy": "Sort by",
    "$vuetify.dataFooter.itemsPerPageText": "Įrašai per puslapį:",
    "$vuetify.dataFooter.itemsPerPageAll": "Visi",
    "$vuetify.dataFooter.nextPage": "Kitas puslapis",
    "$vuetify.dataFooter.prevPage": "Ankstesnis puslapis",
    "$vuetify.dataFooter.firstPage": "Pirmas puslapis",
    "$vuetify.dataFooter.lastPage": "Paskutinis puslapis",
    "$vuetify.dataFooter.pageText": "{0}-{1} iš {2}",
    "$vuetify.datePicker.itemsSelected": "{0} pasirinkta",
    "$vuetify.datePicker.nextMonthAriaLabel": "Kitą mėnesį",
    "$vuetify.datePicker.nextYearAriaLabel": "Kitais metais",
    "$vuetify.datePicker.prevMonthAriaLabel": "Praeita mėnesį",
    "$vuetify.datePicker.prevYearAriaLabel": "Praeiti metai",
    "$vuetify.noDataText": "Nėra duomenų",
    "$vuetify.carousel.prev": "Ankstesnioji skaidrė",
    "$vuetify.carousel.next": "Kita skaidrė",
    "$vuetify.carousel.ariaLabel.delimiter": "Carousel slide {0} of {1}",
    "$vuetify.calendar.moreEvents": "Daugiau {0}",
    "$vuetify.input.clear": "Clear {0}",
    "$vuetify.input.prependAction": "{0} prepended action",
    "$vuetify.input.appendAction": "{0} appended action",
    "$vuetify.fileInput.counter": "{0} failų",
    "$vuetify.fileInput.counterSize": "{0} failų ({1} iš viso)",
    "$vuetify.timePicker.am": "AM",
    "$vuetify.timePicker.pm": "PM",
    "$vuetify.pagination.ariaLabel.wrapper": "Puslapio naršymas",
    "$vuetify.pagination.ariaLabel.next": "Kitas puslapis",
    "$vuetify.pagination.ariaLabel.previous": "Ankstesnis puslapis",
    "$vuetify.pagination.ariaLabel.page": "Eiti į puslapį {0}",
    "$vuetify.pagination.ariaLabel.currentPage": "Dabartinis puslapis, puslapis {0}",
    "$vuetify.rating.ariaLabel.icon": "Rating {0} of {1}",
    "$vuetify.loading": "Loading..."
};