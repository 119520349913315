var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.hasTabs
    ? _c(
        "v-tabs-items",
        {
          model: {
            value: _vm.active,
            callback: function ($$v) {
              _vm.active = $$v
            },
            expression: "active",
          },
        },
        _vm._l(_vm.tabs, function (tab, index) {
          return _c(
            "v-tab-item",
            { key: index, attrs: { value: "tab-" + index, eager: "" } },
            [
              _c(
                "v-card",
                { attrs: { flat: "" } },
                [
                  _c("form-rows", {
                    attrs: { config: tab.children, value: _vm.value },
                    on: {
                      "update:error": function ($event) {
                        return _vm.$emit("update:error", $event)
                      },
                    },
                  }),
                ],
                1
              ),
            ],
            1
          )
        }),
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }