var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-toolbar",
        [
          _c("v-toolbar-title", [
            _vm._v("\n            Truncate formatter\n        "),
          ]),
          _vm._v(" "),
          _c("v-spacer"),
          _vm._v(" "),
          _c(
            "v-toolbar-items",
            [
              _c(
                "v-btn",
                {
                  attrs: { icon: "", large: "", tile: "" },
                  on: {
                    click: function ($event) {
                      _vm.showFilter = !_vm.showFilter
                    },
                  },
                },
                [_c("v-icon", [_vm._v("mdi-magnify")])],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("asa-table", {
        attrs: {
          data: _vm.data,
          loading: false,
          "show-filter": _vm.showFilter,
          "info-disabled": "",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }