var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c("asa-formatter", {
        attrs: { type: "localized", value: { de: "German", en: "English" } },
      }),
      _c("br"),
      _vm._v(" "),
      _c("asa-formatter", {
        attrs: { type: "localized", value: { de: "German Fallback" } },
      }),
      _c("br"),
      _vm._v(" "),
      _c("asa-formatter", {
        attrs: {
          type: "localized",
          value: { de: "<b>HTML required</b>" },
          options: { isHtmlRequired: true },
        },
      }),
      _c("br"),
      _vm._v(" "),
      _c("asa-formatter", {
        attrs: { type: "localized", value: { de: "<b>HTML NOT required</b>" } },
      }),
      _c("br"),
      _vm._v(" "),
      _c("asa-formatter", {
        attrs: { type: "localized", value: "Kein Multilang Object ohne HTML" },
      }),
      _c("br"),
      _vm._v(" "),
      _c("asa-formatter", {
        attrs: {
          type: "localized",
          value: "<b>Kein Multilang Object ohne HTML</b>",
          options: { isHtmlRequired: true },
        },
      }),
      _c("br"),
      _vm._v(" "),
      _c("asa-formatter", {
        attrs: {
          type: "localized",
          value: { ch: "Weder Locale, noch Fallback vorhanden" },
        },
      }),
      _c("br"),
      _vm._v(" "),
      _c("asa-formatter", {
        attrs: {
          type: "localized",
          value: { ch: "<b>Weder Locale, noch Fallback vorhanden</b>" },
          options: { isHtmlRequired: true },
        },
      }),
      _c("br"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }