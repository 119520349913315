export default class StriptagsFormatter {
    constructor() {
    }

    requiresHTML() {
        return false;
    }

    format(value) {
        //console.log(value)
        return value.replace(/(<([^>]+)>)/ig,"");
    }
}
