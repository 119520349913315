<template>
    <div>
        <v-toolbar>
            <v-toolbar-title>
                Not clearable Filters
            </v-toolbar-title>
            <v-spacer />
            <v-toolbar-items>
                <v-btn tile icon large @click="showFilter = !showFilter">
                    <v-icon>$search</v-icon>
                </v-btn>
            </v-toolbar-items>
        </v-toolbar>
        <asa-table
            :loading="false"
            :data="data"
            info-disabled
            :show-filter="showFilter"
            filters-non-persistent
        />
    </div>
</template>

<script>
import { VToolbar, VToolbarTitle, VSpacer, VToolbarItems, VBtn, VIcon } from 'vuetify/lib';

export default {
    name: "TableFiltersNonPersistent",
    components: { VToolbar, VToolbarTitle, VSpacer, VToolbarItems, VBtn, VIcon },
    data () {
        return {
            showFilter: true,
            data: {
                columns: [
                    {
                        key: 'string',
                        label: 'string'
                    },
                    {
                        key: 'boolean',
                        label: 'boolean',
                    },
                    {
                        key: 'date',
                        label: 'date',
                        type: 'date'
                    },
                    {
                        key: 'time',
                        label: 'time',
                        type: 'time'
                    }
                ],
                rows: [
                    {
                        string: 'Eins',
                        boolean: true,
                        date: '1970-01-01',
                        time: '01:00:00'
                    },
                    {
                        string: 'Zwei',
                        boolean: false,
                        date: '1970-01-02',
                        time: '02:00:00'
                    },
                    {
                        string: 'Drei',
                        boolean: false,
                        date: '1970-01-03',
                        time: '03:00:00'
                    },
                    {
                        string: 'Vier',
                        boolean: true,
                        date: '1970-01-04',
                        time: '04:00:00'
                    },
                ],
                filter: [
                    {
                        key: 'string',
                        label: 'string',
                        clearable: false
                    },
                    {
                        key: 'boolean',
                        label: 'boolean',
                        clearable: false,
                        value: true,
                        filter: {
                            select: {
                                options: [
                                    { value: true, text: 'True' },
                                    { value: false, text: 'False' }
                                ]
                            }
                        },
                    },
                    {
                        key: 'date',
                        label: 'date',
                        type: 'date',
                        clearable: false,
                        filter: {
                            date: {
                                range: true,
                            },
                        }
                    },
                ],
            },
        }
    }
}
</script>

<style scoped>

</style>
