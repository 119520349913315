<template>
    <v-layout justify-center row>
        <v-dialog :value="value" content-class="centered-dialog" persistent>
            <v-container fill-height>
                <v-layout align-center column justify-center>
                    <v-progress-circular :color="progressColor" :size="70" :width="7" indeterminate />
                    <h1 v-if="message != null">
                        {{ message }}
                    </h1>
                </v-layout>
            </v-container>
        </v-dialog>
    </v-layout>
</template>

<script>
import {VContainer, VDialog, VLayout, VProgressCircular} from 'vuetify/lib';

/**
 * Fullscreen variant of the AsaLoading component
 * @deprecated use AsaLoading instead
 */
export default {
    name: "AsaLoadingFullscreen",
    components: {VLayout, VDialog, VContainer, VProgressCircular},
    props: {
        /** determines whether the dialog is shown */
        value: {type: Boolean, default: false},
        /** the message that will be displayed */
        message: {type: String, default: 'Loading...'},
        /** color of the progress animation */
        progressColor: {type: String, default: 'primary'},
    },
    data: function () {
        return {}
    },
}
</script>

<style>
.dialog.centered-dialog,
.v-dialog.centered-dialog {
    background: transparent;
    box-shadow: none;
    border-radius: 6px;
    width: auto;
    color: whitesmoke;
}
</style>
