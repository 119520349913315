<template>
    <div>
        <asa-confirm ref="confirmDialog" />
        <v-btn color="danger" type="button" @click="ask">
            delete
        </v-btn>
    </div>
</template>

<script>
import {VBtn} from 'vuetify/lib';
import AsaConfirm from '../../../src/component/AsaConfirm';

export default {
    name: "confirm",
    components: {
        VBtn,
        AsaConfirm
    },
    methods: {
        async ask() {
            let res = await this.$refs.confirmDialog.open('Title', 'Do you confirm?', {
                // confirmLabel: 'Yey',
                // rejectLabel: 'Nay'
            });
            alert(res ? 'Confirmed' : 'Rejected');
        }
    }
};
</script>

<style scoped>

</style>
