var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "asa-dialog",
    [
      _c(
        "v-card",
        [
          _c("v-card-title", [_vm._v("QR Scanner ")]),
          _vm._v(" "),
          _c(
            "v-card-text",
            [
              _c("asa-qr-scanner", {
                ref: "scanner",
                on: {
                  active: function ($event) {
                    _vm.isActive = $event
                  },
                  detected: _vm.log,
                },
              }),
              _vm._v(" "),
              _vm.data
                ? _c("v-alert", { attrs: { type: "info" } }, [
                    _vm._v(_vm._s(_vm.data)),
                  ])
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c("v-btn", { on: { click: _vm.toggle } }, [
            _vm._v(
              "\n            " +
                _vm._s(
                  _vm.isActive ? "erkennung anhalten" : "erkennung starten"
                ) +
                "\n        "
            ),
          ]),
          _vm._v(" "),
          _c(
            "v-btn",
            {
              on: {
                click: function ($event) {
                  return _vm.$router.back()
                },
              },
            },
            [_vm._v("Close")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }