(function($R)
{
    $R.lang['sk'] = {
        "format": "Formát",
        "image": "Obrázok",
        "file": "Súbor",
        "link": "Odkaz",
        "bold": "Tučné",
        "italic": "Kurzíva",
        "deleted": "Preškrtnuté",
        "underline": "Podčiarknuté",
        "superscript": "Horný index",
        "subscript": "Spodný index",
        "bold-abbr": "B",
        "italic-abbr": "I",
        "deleted-abbr": "S",
        "underline-abbr": "U",
        "superscript-abbr": "Sup",
        "subscript-abbr": "Sub",
        "lists": "Zoznam",
        "link-insert": "Vložiť odkaz",
        "link-edit": "Upraviť odkaz",
        "link-in-new-tab": "Otvoriť odkaz v novom okne",
        "unlink": "Zrušiť odkaz",
        "cancel": "Zrušiť",
        "close": "Zatvoriť",
        "insert": "Vložiť",
        "save": "Uložiť",
        "delete": "Vymazať",
        "text": "Text",
        "edit": "Upraviť",
        "title": "Názov",
        "paragraph": "Odsek",
        "quote": "Citácia",
        "code": "Kód",
        "heading1": "Nadpis 1",
        "heading2": "Nadpis 2",
        "heading3": "Nadpis 3",
        "heading4": "Nadpis 4",
        "heading5": "Nadpis 5",
        "heading6": "Nadpis 6",
        "filename": "Meno",
        "optional": "voliteľné",
        "unorderedlist": "Nezoradený zoznam",
        "orderedlist": "Zoradený zoznam",
        "outdent": "Predsadiť",
        "indent": "Odsadiť",
        "horizontalrule": "Linka",
        "upload": "Nahrať",
        "upload-label": "Presuňte sem súbory alebo kliknite pre výber",
        "upload-change-label": "Drop a new image to change",
        "accessibility-help-label": "Rich text editor",
        "caption": "Titulok",
        "bulletslist": "odrážkový zoznam",
        "numberslist": "číslovaný zoznam",
        "image-position": "Pozícia",
        "none": "Žiadne",
        "left": "Vľavo",
        "right": "Vpravo",
        "center": "Na stred",
        "undo": "Späť",
        "redo": "Obnoviť"
    };
})(Redactor);
