<template>
  <v-input
      :id="id"
      :name="name"
      filled
      :disabled="disabled"
      :error="hasError"
      :error-messages="msgs"
      :label="config.label"
      :required="required"
      :rules="rules"
      class="input--colorpicker"
      :value="viewValue"
  >
    <v-color-picker
        v-model="viewValue"
        :mode="computedMode"
        :hide-mode-switch="computedHideModeSwitch"
        :disabled="disabled"
        :hide-inputs="hideInputs"
        :hide-canvas="hideCanvas"
        :show-swatches="showSwatches"
    />
  </v-input>
</template>

<script>
import {VInput, VColorPicker} from 'vuetify/lib';

/**
 * Color picker.
 *
 * #### Config
 *
 * | key                    | type                       | required | default    | description            |
 * |------------------------|----------------------------|----------|------------|------------------------|
 * | type                   | `String`                   | yes      | -          | always `"colorpicker"` |
 * | mode                   | `String`                   | no       | `rgba`     |                        |
 * | hideModeSwitch         | `Boolean`                  | no       | false      |                        |
 * | label                  | `String`, `false`          | no       | `false`    | fields label           |
 * | required               | `Boolean`, `eval(String)`  | no       | false      | field is required.     |
 * | disabled               | `Boolean`, `eval(String)`  | no       | false      | field is disabled.     |
 * | visible                | `Boolean`, `eval(String)`  | no       | false      | field is rendered.     |
 */
export default {
  name: "FormRowColorPicker",
  components: {VInput, VColorPicker},
  props: {
    /** element id */
    id: {
      type: String,
      required: true
    },
    /** field value */
    value: {
      type: String,
      required: false,
      default: ""
    },
    /** field config */
    config: {
      type: Object,
      required: true
    },
    /** element name */
    name: {
      type: String,
      required: true
    },
    /** validation errors exist */
    hasError: {
      type: Boolean,
      default: false,
      required: false
    },
    /** validation messages */
    msgs: {
      type: Array,
      default: () => [],
      required: false
    },
    /** field is required */
    required: {
      type: Boolean,
      default: false,
      required: false
    },
    /** validation rules */
    rules: {
      type: Array,
      default: () => [],
      required: false
    },
    /** field is disabled */
    disabled: {
      type: Boolean,
      default: false,
      required: false
    },
    /** show/hide color value input fields */
    hideInputs: {
      type: Boolean,
      default: true,
      required: false
    },
    /** show/hide canvas */
    hideCanvas: {
      type: Boolean,
      default: true,
      required: false
    },
    /** show/hide swatches */
    showSwatches: {
      type: Boolean,
      default: false,
      required: false
    },
  },
  data() {
    return {
      viewValue: "",
    }
  },
  watch: {
    viewValue() {
      this.updateValue()
    },
    value(newVal) {
      this.viewValue = newVal
    }
  },
  mounted() {
    this.viewValue = this.value

    if ((this.value === null || typeof this.value === 'undefined')) {
      this.viewValue = this.config.value
      this.updateValue()
    }
  },
  computed: {
    computedMode() {
      if (Object.hasOwn(this.config, 'mode')) {
        return this.config.mode;
      } else {
        return 'rgba';
      }
    },
    computedHideModeSwitch() {
      if (Object.hasOwn(this.config, 'hideModeSwitch')) {
        return this.config.hideModeSwitch;
      } else {
        return false;
      }
    }
  },
  methods: {
    /** @private */
    updateValue() {
      /**
       * user input
       * @param {String} viewValue
       */
      this.$emit('input', this.viewValue)
    }
  }
}
</script>

<style lang="scss">
.v-color-picker {
  min-width: 300px;
}

/*.v-color-picker__alpha {
    display: none;
}
.v-color-picker__hue {
    align-items: center !important;
    justify-content: center !important;
    margin-bottom: 0 !important;
}*/
.input--colorpicker .v-input__slot {
  display: block;
}
</style>
