var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c("h1", [_vm._v("Truncate")]),
      _vm._v(" "),
      _c("asa-formatter", {
        attrs: {
          type: "truncate",
          options: { maxLength: 10, truncationSuffix: "…" },
          value: "012345678901234567890",
        },
      }),
      _vm._v(" "),
      _c("h2", [_vm._v("No truncation")]),
      _vm._v(" "),
      _c("asa-formatter", {
        attrs: {
          type: "truncate",
          options: { maxLength: 10 },
          value: "0123456789",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }