<template>
    <v-icon small v-text="icon" />
</template>

<script>
import {VIcon} from 'vuetify/lib';

/** Used to draw Icons in the AsaTreeView context */
export default {
    name: "AsaTreeIcons",
    components: {VIcon},
    props: {
        /** current node */
        node: {
            type: Object,
            required: true,
            default: () => {}
        }
    },
    data() {
        return {}
    },
    computed: {
        icon() {
            switch (this.node.type) {
            case 'root':
                return 'fas fa-globe';
            case 'division':
                return 'fas fa-sitemap';
            case 'company':
                return 'far fa-building';
            case 'asset':
                return 'far fa-file-alt';
            default:
                if (this.node.children && this.node.children.length > 0) {
                    return 'far fa-folder'
                }
                return 'fas fa-list-ul'
            }
        }
    }
}
</script>
