<template>
    <v-simple-table>
        <thead>
            <tr>
                <th class="text-left">
                    Unformatted Value
                </th>
                <th class="text-left">
                    Formatted Value
                </th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="date in dates">
                <td>{{ date }}</td>
                <td><asa-formatter type="datetime" :value="date" /></td>
            </tr>
        </tbody>
    </v-simple-table>
</template>

<script>

import {VSimpleTable,} from 'vuetify/lib';
import AsaFormatter from "../../../src/component/AsaFormatter";
export default {
    name: "FormatterDateTime",
    data() {
        return {
            dates: [
                '2023-01-01T01:01:01',
                '2023-01-01 01:01:01',
                '2023-01-01T01:01:01Z',
                '2023-01-01 01:01:01Z',
                '2022-12-20 08:00:00',
                '2022-12-20 08:00',
                '2022-12-20 08:00Z',
                '2022-12-20T08:00',
                '2022-12-20T08:00Z',
            ]
        }
    },
    components: {
        AsaFormatter,
        VSimpleTable,
    }
}
</script>

<style scoped>

</style>
