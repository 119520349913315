var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-switch", {
    attrs: {
      filled: "",
      disabled: _vm.disabled,
      error: _vm.hasError,
      "error-messages": _vm.errorMessages,
      label: _vm.config.label,
      required: _vm.required,
      rules: _vm.rules,
      "false-value": 0,
      "true-value": 1,
    },
    model: {
      value: _vm.viewValue,
      callback: function ($$v) {
        _vm.viewValue = $$v
      },
      expression: "viewValue",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }