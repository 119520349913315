import { render, staticRenderFns } from "./timecol.vue?vue&type=template&id=2a2969fc&scoped=true&"
import script from "./timecol.vue?vue&type=script&lang=js&"
export * from "./timecol.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2a2969fc",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/builds/lum/lumui/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('2a2969fc')) {
      api.createRecord('2a2969fc', component.options)
    } else {
      api.reload('2a2969fc', component.options)
    }
    module.hot.accept("./timecol.vue?vue&type=template&id=2a2969fc&scoped=true&", function () {
      api.rerender('2a2969fc', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "test/views/table/timecol.vue"
export default component.exports