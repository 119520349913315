var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("asa-form", {
    attrs: { loading: false, config: _vm.cfg, "abort-btn": false },
    scopedSlots: _vm._u([
      {
        key: "title-after",
        fn: function () {
          return [
            _c(
              "v-menu",
              {
                attrs: { bottom: "", left: "", "close-on-click": "" },
                scopedSlots: _vm._u([
                  {
                    key: "activator",
                    fn: function ({ on, attrs }) {
                      return [
                        _c(
                          "v-btn",
                          _vm._g(
                            _vm._b(
                              { attrs: { icon: "", dark: "" } },
                              "v-btn",
                              attrs,
                              false
                            ),
                            on
                          ),
                          [_c("v-icon", [_vm._v("mdi-dots-vertical")])],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              },
              [
                _vm._v(" "),
                _c(
                  "v-list",
                  { staticClass: "d-print-none" },
                  [
                    _c(
                      "v-list-item",
                      { attrs: { href: "#" } },
                      [
                        _c(
                          "v-list-item-icon",
                          [_c("v-icon", [_vm._v("fa-file")])],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "v-list-item-content",
                          [_c("v-list-item-title", [_vm._v("Menu 1")])],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "v-list-item",
                      { attrs: { href: "#" } },
                      [
                        _c(
                          "v-list-item-icon",
                          [_c("v-icon", [_vm._v("fa-file-pdf")])],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "v-list-item-content",
                          [_c("v-list-item-title", [_vm._v("Menu 2")])],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
    ]),
    model: {
      value: _vm.value,
      callback: function ($$v) {
        _vm.value = $$v
      },
      expression: "value",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }