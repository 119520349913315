import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import {i18n} from '../../src';

Vue.use(Vuetify);

export default new Vuetify({
    lang: {
        t: (k,  ...args) => i18n.t(k, args),
    },
    icons: {
        iconfont: 'fa',
    }
});
