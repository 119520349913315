var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("asa-form", {
        attrs: { config: _vm.config, loading: false, value: _vm.value },
        on: { save: _vm.addError },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }