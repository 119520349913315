var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c("h1", [_vm._v("Time")]),
      _vm._v(" "),
      _c("asa-formatter", { attrs: { type: "time", value: "04:20" } }),
      _vm._v(" "),
      _c("asa-formatter", { attrs: { type: "time", value: "13:42" } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }