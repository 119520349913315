import {merge} from "webpack-merge";
import en from './base/en.js';

export default merge(en, {
    "lumui.confirm.accept": "OK",
    "lumui.confirm.reject": "Cancel",
    "lumui.formatter.boolean.true": "Yes",
    "lumui.formatter.boolean.false": "No",
    "lumui.form.close": "Close",
    "lumui.form.cancel": "Cancel",
    "lumui.form.save": "Save",
    "lumui.lightbox.title": "Existing images",
    "lumui.loading.message": "Data is being loaded",
    "lumui.qr_scanner.camera": "Camera",
    "lumui.qr_scanner.mirror": "Mirror camera ",
    "lumui.qr_scanner.torch": "Camera light",
    "lumui.table.reset_filters": "Reset",
    "lumui.table.close_filters": "Minimise filters",
    "lumui.table.details": "Detailed view",
    "lumui.table.previous": "Previous record",
    "lumui.table.next": "Next record",
    "lumui.table.no_data": "No data found",
    "lumui.table.no_filter_results": "Your search produced no results",
    "lumui.table.filters_active": "Results filtered",
    "lumui.table.rows_per_page": "Lines per page",
    "lumui.table.page_text": "{0}-{1} of {2}",
    "lumui.table.btn.export": "Export list",
    "lumui.table_action.menu": "Menu",
    "lumui.table_columns.save": "Save file",
    "lumui.form.row.invalid_email": "Please enter a valid email address.",
    "lumui.form.row.required": "This is a required field",
    "lumui.form.autocomplete.selected_count": "{c} items selected",
    "lumui.form.autocomplete.select_all": "Select all",
    "lumui.form.autocomplete.deselect_all": "Select nothing",
    "lumui.form.autocomplete.search_hint": "Please enter what you are looking for",
    "lumui.form.autocomplete.no_data": "No data available",
    "lumui.form.autocomplete.nothing_selected": "No entries selected",
    "lumui.form.autocomplete.selected_items": "Selected entries",
    "lumui.form.date.close": "Close",
    "lumui.form.date.save": "OK",
    "lumui.form.date.after_max": "The selected date is after the maximum allowed {max_date}",
    "lumui.form.date.before_min": "The selected date is before the minimum allowed date {min_date}",
    "lumui.form.file.drag": "Drag file here or click to select",
    "lumui.form.file.error": "Upload failed.",
    "lumui.form.file.extensions.accepted": "Allowed filetypes: {types}",
    "lumui.form.file.extensions.all": "All filetypes allowed",
    "lumui.form.file.maxSize": "Maximum allowed file size {size}.",
    "lumui.form.file.maxSizeError": "The file is larger than the maximum allowed size of {size}.",
    "lumui.form.file.previewError": "No preview for file {name} available.",
    "lumui.form.file.uploading": "{count} files are being uploaded…",
    "lumui.form.file.warning.removed_file": "File {name} was not uploaded. Filetype {type} is not within permitted types {acceptedTypes}.",
    "lumui.form.file.select_file": "Select a file",
    "lumui.form.file.unknown_size": "not specified",
    "lumui.form.localized.copy_to_all": "Accept input for all languages",
    "lumui.form.ordered_choice.no_data": "no entries available",
    "lumui.form.ordered_choice.select_placeholder": "Select new entry",
    "lumui.form.password.repeat": "Repeat password",
    "lumui.form.password.not_matching_error": "Both passwords must match.",
    "lumui.form.signature.clear": "Delete signature",
    "lumui.form.signature.upload": "Upload signature",
    "lumui.form.time.close": "Close",
    "lumui.form.time.save": "OK",
    "lumui.form.password.requirements.title": "The password must meet the following requirements:",
    "lumui.form.password.requirements.lower_chars": "at least one lowercase letter | at least {count} lowercase letters",
    "lumui.form.password.requirements.upper_chars": "at least one uppercase letter | at least {count} uppercase letters",
    "lumui.form.password.requirements.number_chars": "at least one digit | at least {count} digits",
    "lumui.form.password.requirements.special_chars": "at least one special character | at least {count} special characters",
    "lumui.form.password.requirements.total_chars": "at least one character | at least {count} characters",
    "lumui.locale.lang.german": "German",
    "lumui.locale.lang.english": "English",
    "lumui.locale.lang.italian": "Italian",
    "lumui.locale.lang.french": "French",
    "lumui.status.no_action_required": "no action required",
    "lumui.status.hint": "Note",
    "lumui.status.action_required_soon": "action required soon!",
    "lumui.status.action_required_immediately": "immediate action required!",
    "lumui.locale.de-DE": "German",
    "lumui.locale.de-CH": "German (Suisse)",
    "lumui.locale.fr-CH": "French (Suisse)",
    "lumui.locale.it-CH": "Italian (Suisse)",
    "lumui.locale.en-US": "English (USA)",
    "lumui.locale.en-GB": "English (GB)",
    "lumui.locale.fi-FI": "Finnish",
    "lumui.locale.et-EE": "Estonian",
    "lumui.locale.lt-LT": "Lithuanian",
    "lumui.locale.lv-LV": "Latvian",
    "lumui.locale.lang.finnish": "Finnish",
    "lumui.locale.lang.estonian": "Estonian",
    "lumui.locale.lang.lithuanian": "Lithuanian",
    "lumui.locale.lang.latvian": "Latvian",
    "lumui.locale.lang.hungarian": "Hungarian",
});
