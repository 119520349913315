(function($R)
{
    $R.lang['ar'] = {
        "format": "تنسيق",
        "image": "صورة",
        "file": "ملف",
        "link": "رابط",
        "bold": "متين",
        "italic": "مائل",
        "deleted": "مشطوب",
        "underline": "مسطر",
        "superscript": "Superscript",
        "subscript": "Subscript",
        "bold-abbr": "B",
        "italic-abbr": "I",
        "deleted-abbr": "S",
        "underline-abbr": "U",
        "superscript-abbr": "Sup",
        "subscript-abbr": "Sub",
        "lists": "قوائم",
        "link-insert": "إدراج رابط",
        "link-edit": "تحرير الرابط",
        "link-in-new-tab": "فتح الرابط في تبويب جديد",
        "unlink": "إلغاء الرابط",
        "cancel": "إلغاء",
        "close": "إغلاق",
        "insert": "إدراج",
        "save": "حفظ",
        "delete": "حذف",
        "text": "النص",
        "edit": "تحرير",
        "title": "العنوان",
        "paragraph": "نص عادي",
        "quote": "اقتباس",
        "code": "كود",
        "heading1": "عنوان 1",
        "heading2": "عنوان 2",
        "heading3": "عنوان 3",
        "heading4": "عنوان 4",
        "heading5": "عنوان 5",
        "heading6": "عنوان 6",
        "filename": "الاسم",
        "optional": "اختياري",
        "unorderedlist": "قائمة نقطية",
        "orderedlist": "قائمة رقمية",
        "outdent": "زيادة المسافة البادئة",
        "indent": "إنقاض المسافة البادئة",
        "horizontalrule": "خط أفقي",
        "upload": "Upload",
        "upload-label": "Drop files here or click to upload",
        "upload-change-label": "Drop a new image to change",
        "accessibility-help-label": "محرر النص الغني",
        "caption": "الشرح",
        "bulletslist": "Bullets",
        "numberslist": "Numbers",
        "image-position": "Position",
        "none": "None",
        "left": "Left",
        "right": "Right",
        "center": "Center",
        "undo": "Undo",
        "redo": "Redo"
    };
})(Redactor);