var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: {
        show: _vm.show,
        fullscreen: _vm.$vuetify.breakpoint.smAndDown,
        "max-width": _vm.width,
        "hide-overlay": "",
        scrollable: "",
        transition: "dialog-bottom-transition",
      },
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-toolbar",
            [
              _c("v-toolbar-title", [
                _vm._v(_vm._s(_vm.$lumui.i18n.t("lumui.lightbox.title"))),
              ]),
              _vm._v(" "),
              _c("v-spacer"),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  attrs: { icon: "" },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.close()
                    },
                  },
                },
                [_c("v-icon", [_vm._v("$close")])],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("v-divider"),
          _vm._v(" "),
          _c(
            "v-card-text",
            { staticStyle: { position: "relative" } },
            [
              _vm.image.src
                ? _c(
                    "v-img",
                    {
                      staticClass: "grey lighten-2",
                      attrs: { src: _vm.image.src },
                    },
                    [
                      _c(
                        "v-layout",
                        {
                          attrs: {
                            slot: "placeholder",
                            "align-center": "",
                            "fill-height": "",
                            "justify-center": "",
                            "ma-0": "",
                          },
                          slot: "placeholder",
                        },
                        [
                          _c("v-progress-circular", {
                            attrs: {
                              color: "grey lighten-5",
                              indeterminate: "",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  staticStyle: { top: "50%", left: "5%" },
                  attrs: {
                    disabled: _vm.i <= 0,
                    absolute: "",
                    color: "primary",
                    dark: "",
                    fab: "",
                    small: "",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.prev()
                    },
                  },
                },
                [
                  _c("v-icon", { attrs: { dark: "" } }, [
                    _vm._v("\n                    $prev\n                "),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  staticStyle: { top: "50%", right: "5%" },
                  attrs: {
                    disabled: _vm.i + 1 >= _vm.images.length,
                    absolute: "",
                    color: "primary",
                    dark: "",
                    fab: "",
                    small: "",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.next()
                    },
                  },
                },
                [
                  _c("v-icon", { attrs: { dark: "" } }, [
                    _vm._v("\n                    $next\n                "),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }