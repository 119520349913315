<template>
    <div class="input-checkboxes">
        <div v-if="config.label" class="label">
            {{ config.label }}
        </div>
        <template v-for="(item, key) in config.multiOptions">
            <v-checkbox
                :key="key"
                v-model="viewValue"
                filled
                :disabled="disabled"
                :error="hasError"
                :error-messages="msgs"
                :label="getLabel(item)"
                :required="required"
                :rules="rules"
                :value="getValue(item)"
            />
        </template>
    </div>
</template>

<script>
import {VCheckbox} from 'vuetify/lib';

/**
 * #### config
 * | key                    | type                       | required | default    | description |
 * |------------------------|----------------------------|----------|------------|-------------|
 * | type                   | `String`                   | yes      | -          | always `"checkbox"` |
 * | label                  | `String`, `false`          | no       | `false`    | fields label |
 * | required               | `Boolean`, `eval(String)`  | no       | false      | field is required.|
 * | disabled               | `Boolean`, `eval(String)`  | no       | false      | field is disabled.|
 * | visible                | `Boolean`, `eval(String)`  | no       | false      | field is rendered.  |
 * | multiOptions           | `Array`                    | yes      | -          | Checkbox options. |
 *
 * multiOptions is an `Array` of item either `{value: "id", label: "string"}` or `{value: "id", lable: {de: "string", en: "string"}}` objects.
 * Note that current user locale is assumed to be defined in the options, there is no fallback or further check.
 */
export default {
    name: "FormRowCheckbox",
    components: {VCheckbox},
    props: {
        /** field value */
        value: {
            required: false,
            default: []
        },
        /** field config */
        config: {
            type: Object,
            required: true
        },
        /** field name/id (@todo: unused!?) */
        name: {
            type: String,
            required: true
        },
        required: {
            type: Boolean,
            required: false,
            default: false
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false
        },
        /** mark field as having validation errors */
        hasError: {
            type: Boolean,
            required: false,
            default: false
        },
        /** validation messages */
        msgs: {
            type: Array,
            required: false,
            default: () => []
        },
        /** validation rules */
        rules: {
            type: Array,
            required: false,
            default: () => []
        }
    },
    data() {
        return {
            viewValue: () => [],
        }
    },
    computed: {
        locale() {
            return this.$store.getters['lumui/i18n/mappedLocale'];
        }
    },
    watch: {
        viewValue() {
            /**
             * user input
             * @param viewValue the fields value
             */
            this.$emit('input', this.viewValue);
        }
    },
    mounted() {
        this.$nextTick(() => {
            if (this.value === null) {
                this.viewValue = [];
            } else {
                this.viewValue = (typeof this.value === 'object') ? this.value : [this.value];
            }
        });
    },
    methods: {
        /* @private */
        getLabel(item) {
            let label = (typeof item === 'object') ? (item.label || item.text) : item;
            if (typeof label === 'object' && typeof label[this.locale] !== 'undefined') {
                return label[this.locale]
            }
            return label
        },
        /* @private */
        getValue(item) {
            return (typeof item === 'object') ? item.value : item
        }
    }
}
</script>

<style scoped>
.input-checkboxes .label {
    margin-bottom: -16px
}
</style>
