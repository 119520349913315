<template>
    <div class="input-radios">
        <div v-if="config.label" class="label">
            {{ config.label }}
        </div>
        <v-radio-group
            :id="name"
            v-model="viewValue"
            filled
            :disabled="disabled"
            :error="error"
            :error-messages="errorMessages"
            :name="name"
            :required="required"
            :rules="rules"
            :value-comparator="itemCompare"
        >
            <v-radio
                v-for="(item, key) in config.multiOptions"
                :id="name + '-' + key"
                :key="key"
                :name="name"
                :value="getValue(item)"
            >
                <template #label>
                    <span v-html="getLabel(item)" />
                </template>
            </v-radio>
        </v-radio-group>
    </div>
</template>

<script>
import HtmlSanitizer from '../lib/HtmlSanitizer';
import {VRadio, VRadioGroup} from 'vuetify/lib';
import Validatable from 'vuetify/lib/mixins/validatable';

/**
 * | key                    | type                       | required | default    | description |
 * |------------------------|----------------------------|----------|------------|-------------|
 * | type                   | `String`                   | yes      |            | field type  |
 * | label                  | `String`, `false`          | no       | `false`    | fields label |
 * | required               | `Boolean`, `eval(String)`  | no       | `false`    | field is required.|
 * | disabled               | `Boolean`, `eval(String)`  | no       | `false`    | field is disabled.|
 * | visible                | `Boolean`, `eval(String)`  | no       | `false`    | field is rendered.  |
 * | multiOptions           | `Array`                    | yes      |            | Array of {value: "val", lable: {de: "de label"}} |
 */
export default {
    name: "FormRowRadio",
    components: {VRadio, VRadioGroup},
    mixins: [
        Validatable
    ],
    props: {
        /** field value */
        value: {
            required: false
        },
        /** configuration */
        config: {
            type: Object,
            required: true
        },
        /** field name */
        name: {
            type: String,
            required: true
        },
        /** field is required */
        required: {
            type: Boolean,
            required: false,
            default: true
        },
        /** field is disabled */
        disabled: {
            type: Boolean,
            required: false,
            default: false
        },
        /** validation errors */
        error: {
            type: Boolean,
            required: false,
            default: false
        },
        /** validation messages */
        errorMessages: {
            type: Array,
            required: false,
            default: () => []
        }
    },
    data() {
        return {
            viewValue: 1,
        }
    },
    computed: {
        locale() {
            return this.$store.getters['lumui/i18n/mappedLocale'];
        }
    },
    watch: {
        viewValue() {
            /**
             * user input
             */
            this.$emit('input', this.viewValue);
        },
        value: {
            deep: true,
            handler(newVal) {
                this.viewValue = this.getValue(newVal);
            }
        }
    },
    mounted() {
        this.$nextTick(() => {
            if (typeof this.value !== 'undefined' && this.value !== null) {
                this.viewValue = this.getValue(this.value);
            }
        });
    },
    methods: {
        /* @private */
        getLabel(item) {
            let label = (typeof item === 'object') ? (item.label || item.text) : item;
            if (typeof label === 'object' && typeof label[this.locale] !== 'undefined') {
                return HtmlSanitizer.SanitizeHtml(label[this.locale]);
            }
            return HtmlSanitizer.SanitizeHtml(label);
        },
        /* @private */
        getValue(item) {
            return (typeof item === 'object' && item !== null) ? item.value : item
        },
        /* @private */
        itemCompare(a, b) {
            if (a === b) return true;

            if (a instanceof Date && b instanceof Date && a.getTime() !== b.getTime()) {
                // If the values are Date, compare them as timestamps
                return false
            }

            if (a !== Object(a) || b !== Object(b)) {
                // If the values aren't objects, they were already checked for equality
                // Check if they are equal when they are converted to strings
                return String(a) === String(b);
            }

            const props = Object.keys(a);

            if (props.length !== Object.keys(b).length) {
                // Different number of props, don't bother to check
                return false
            }

            return props.every(p => this.itemCmp(a[p], b[p]))
        }

    }
}
</script>

<style scoped>
.input-radios .label {
    margin-bottom: -16px
}
</style>
