<template>
    <div>
        <v-toolbar>
            <v-toolbar-title>
                Link Columns
            </v-toolbar-title>
        </v-toolbar>
        <asa-table :data="data" :loading="false" />
    </div>
</template>

<script>
import AsaTable from "../../../src/component/AsaTable";
import {VToolbar, VToolbarTitle} from 'vuetify/lib'
export default {
    name: "TableLink",
    components: {AsaTable, VToolbar, VToolbarTitle},
    data() {
        return {
            data: {
                columns: [
                    {
                        key: 'id',
                        hidden: true,
                    },
                    {
                        key: 'url',
                        hidden: true
                    },
                    {
                        key: 'col1',
                        label: 'Column 1 (String)',
                        formatter: {
                            Link: {
                                hrefCol: 'url'
                            }
                        }
                    },
                    {
                        key: 'col2',
                        label: 'target="_blank"',
                        formatter: {
                            Link: {
                                hrefCol: 'url',
                                target: '_blank'
                            }
                        }
                    },

                    {
                        key: 'action',
                        label: '',
                        type: 'action',
                        formatter: 'action',
                        actions: {}
                    }
                ],
                rows: [
                    {
                        id: 1,
                        url: 'https://en.wikipedia.org/wiki/Lorem_ipsum',
                        col1: 'Lorem Ipsum',
                        col2: 'blank Lorem Ipsum',
                        action: []
                    },
                    {
                        id: 2,
                        url: 'https://de.wikipedia.org/wiki/Lorem_ipsum',
                        col1: 'Lorem Ipsum (Deutsch)',
                        col2: 'blank Lorem Ipsum (Deutsch)',
                        action: []
                    },
                    {
                        id: 3,
                        url: 'https://de.wikipedia.org/wiki/Franz_Kafka',
                        col1: 'Kafka',
                        col2: 'blank Kafka',
                        action: []
                    }
                ]
            }
        }
    }
}
</script>
