<template>
    <div class="ma-5">
        <template v-for="(icon,alias) in icons">
            <div :key="alias">
                <v-icon :title="icon">{{ icon }}</v-icon>
                <span class="text--disabled">${{ alias }}</span>
            </div>
        </template>
    </div>
</template>

<script>
import { VRow, VCol, VIcon } from 'vuetify/lib'

export default {
    name: "icons",
    components: { VRow, VCol, VIcon },
    computed: {
        icons() {
            return this.$vuetify.icons.values;
        }
    }
}
</script>

<style scoped>

</style>
