<template>
    <div>
        <v-input
            v-show="multiple || !hasFiles"
            ref="inputField"
            :avoid-drag="avoidDrag"
            :disabled="disabled || isSaving"
            :error="error"
            :error-messages="errorMessages"
            :label="config.label"
            :messages="msg"
            :required="required"
            :rules="validationRules"
            class="input--file"
        >
            <div
                v-if="!disabled"
                :class="{ disabled: disabled, hover:dragOver }"
                class="dropbox"
                @drop="onDrop"
                @dragover.prevent="dragOver = true"
                @dragleave.prevent="dragOver = false"
            >
                <input
                    id="file"
                    ref="file"
                    :accept="acceptValues"
                    :multiple="multiple"
                    class="input-file"
                    name="files"
                    type="file"
                    @change="filesChange($event.target.name, $event.target.files)"
                />
                <p v-if="isSaving">
                    {{ $lumui.i18n.t('lumui.form.file.uploading', {count: fileCount}) }}
                </p>
                <p v-else @click="launchFilePicker()">
                    <template v-if="$vuetify.breakpoint.smAndDown || avoidDrag">
                        {{ $lumui.i18n.t('lumui.form.file.select_file') }}
                    </template>
                    <template v-else>
                        {{ $lumui.i18n.t('lumui.form.file.drag') }}
                    </template>
                </p>
            </div>
        </v-input>
        <template v-if="isInitial || isSelected || isSuccess">
            <v-label v-if="!multiple && hasFiles" :color="hasError ? 'error' : ''" :focused="true">
                {{ config.label }}
            </v-label>
            <!-- v-subheader>Uploaded {{ files.length }} file(s) successfully.</v-subheader -->
            <template v-for="(item, index) in files">
                <v-card :key="'card-' + index" flat>
                    <v-card-text>
                        <v-row :key="`${index}-$(item.name)`" :class="{'mb-2':files.length > 0}" no-gutters>
                            <v-col v-if="item !== null && isImage(item)" class="pr-2" md="3">
                                <v-img :alt="item.name" :src="base64(item)" />
                            </v-col>
                            <v-col class="pr-2 align-self-center" cols="sm">
                                <span class="subheading">{{ item.name }}</span>
                                <v-chip outlined small>
                                    <template v-if="item.size">
                                        {{ printfilesize(item.size) }}
                                    </template>
                                    <template v-else-if="item.src">
                                        {{ printfilesize(item.src.length) }}
                                    </template>
                                    <template v-else>
                                        {{ $lumui.i18n.t('lumui.form.file.unknown_size') }}
                                    </template>
                                </v-chip>
                            </v-col>
                            <v-col class="text-no-wrap align-self-center" md="2">
                                <v-btn v-if="!disabled" class="ma-0" icon @click="remove(item)">
                                    <v-icon>$delete</v-icon>
                                </v-btn>
                                <v-btn v-if="isImage(item)" class="ma-0" icon @click="preview(item)">
                                    <v-icon>$zoomIn</v-icon>
                                </v-btn>
                                <v-btn class="ma-0" icon @click="download(item)">
                                    <v-icon>$download</v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                        <v-messages :value="errorMessages" color="error" />
                    </v-card-text>
                </v-card>

                <v-divider :key="index" />
            </template>
        </template>

        <template v-if="isFailed">
            <v-alert :value="true" type="error">
                {{ $lumui.i18n.t('lumui.form.file.error') }}<br />
                <pre>{{ uploadError }}</pre>
            </v-alert>
        </template>

        <v-alert v-for="(file,i) in removedFiles" :key="i" :value="true" type="warning">
            {{
                $lumui.i18n.t('lumui.form.file.warning.removed_file', {
                    name: file.name,
                    type: file.type,
                    acceptedTypes: printAccept
                })
            }}
        </v-alert>

        <v-dialog
            v-model="dialog"
            :fullscreen="$vuetify.breakpoint.smAndDown"
            max-width="800"
            scrollable
            transition="dialog-bottom-transition"
        >
            <v-card v-if="previewFile" tile>
                <v-toolbar color="primary" dark flat>
                    <v-btn dark icon @click.native="dialog = false">
                        <v-icon>$close</v-icon>
                    </v-btn>
                    <v-toolbar-title>{{ previewFile.name }}</v-toolbar-title>
                </v-toolbar>
                <v-card-text id="scrollarea">
                    <v-img
                        v-if="previewFile !== null && isImage(previewFile)"
                        :alt="previewFile.name"
                        :src="base64(previewFile)"
                        contain
                    />
                    <p v-else>
                        {{ $lumui.i18n.t('lumui.form.file.previewError', {name: previewFile.name}) }}
                    </p>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import filesize from 'filesize';
import {
    VAlert,
    VBtn,
    VCard,
    VCardText,
    VChip,
    VCol,
    VDialog,
    VDivider,
    VIcon,
    VImg,
    VInput,
    VLabel,
    VMessages,
    VRow,
    VToolbar,
    VToolbarTitle
} from 'vuetify/lib';
import Validatable from 'vuetify/lib/mixins/validatable';
import resizeImage from '../lib/resizeImage';
import {saveAs} from 'file-saver';

const STATUS_INITIAL = 0;
const STATUS_SELECTED = 1;
const STATUS_SAVING = 2;
const STATUS_SUCCESS = 3;
const STATUS_FAILED = 4;

/**
 * | key                    | type                       | required | default    | description |
 * |------------------------|----------------------------|----------|------------|-------------|
 * | type                   | `String`                   | yes      |            | field type 'file' or 'image' |
 * | label                  | `String`, `false`          | no       | `false`    | fields label |
 * | class                  | `String`                   | no       | `null`     | css class for custom styling |
 * | required               | `Boolean`, `eval(String)`  | no       | `false`    | field is required.|
 * | disabled               | `Boolean`, `eval(String)`  | no       | `false`    | field is disabled.|
 * | visible                | `Boolean`, `eval(String)`  | no       | `false`    | field is rendered.  |
 * | maxFileSize            | `Number`                   | no       | 52428800   | maximum file size |
 * | avoidDrag              | `Boolean`                  | no       | `false`    | ???               |
 * | accept                 |  `Object`                  | no       | `{png: 'image/png', jpeg: 'image/jpeg', jpg: 'image/jpg', pdf: 'application/pdf'}` | allowed mime types |
 * | multiple               | `Boolean`                  | no       | `false`   | Allow multiple file uploads |
 * | maxImageSize            | `Number`                   | no       | 0   | maximum image width / height |
 */
export default {
    components: {
        VDialog,
        VCard,
        VCardText,
        VToolbar,
        VToolbarTitle,
        VIcon,
        VBtn,
        VAlert,
        VCol,
        VRow,
        VChip,
        VInput,
        VMessages,
        VImg,
        VDivider,
        VLabel
    },
    mixins: [
        Validatable
    ],
    props: {
        /**
         * An array of file objects `{name: "filename", src: "base64 encoded file content", size: "file size" }`
         * **Note:** Passing a string, is probably broken
         */
        value: {
            type: [Object, Array, String],
            default: null
        },
        /** element config */
        config: {
            type: Object,
            required: true
        },
        /**
         * element name. unused?
         * @todo: unused?
         */
        name: {
            type: String,
            required: true
        },
        /** validation errors exist */
        error: {
            type: Boolean,
            default: false,
            required: false
        },
        /** validation messages */
        errorMessages: {
            type: Array,
            default: () => [],
            required: false
        },
        /** field is required. atleast one file must be uploaded */
        required: {
            type: Boolean,
            default: false,
            required: false
        },
        /** validation rules */
        rules: {
            type: Array,
            default: () => [],
            required: false
        },
        /** field is disabled */
        disabled: {
            type: Boolean,
            default: false,
            required: false
        },
        /** allow more than one file to be uploaded */
        multiple: {
            type: Boolean,
            default: false
        },
        /**
         * the mime types to allow. the keys are used for hint and error message,
         * but have no significance for validation
         */
        accept: {
            type: Object,
            default: () => {
                return {
                    png: 'image/png',
                    jpeg: 'image/jpeg',
                    jpg: 'image/jpg',
                    pdf: 'application/pdf'
                }
            },
            required: true
        },
        /**
         * Maximum file size allowed in bytes
         */
        maxFileSize: {
            type: Number,
            default: 52428800 //50 MB
        },
        /**
         * Maximum image width / height allowed in pixel
         */
        maxImageSize: {
            type: Number,
            default: 0
        }
    },
    data() {
        return {
            files: [],
            removedFiles: [],
            uploadError: null,
            currentStatus: null,
            previewFile: null,
            dialog: false,
            msg: [],
            dragOver: false
        }
    },
    computed: {
        hasFiles() {
            return this.files !== null && this.files.length > 0;
        },
        isInitial() {
            return this.currentStatus === STATUS_INITIAL
        },
        isSelected() {
            return this.currentStatus === STATUS_SELECTED
        },
        isSaving() {
            return this.currentStatus === STATUS_SAVING
        },
        isSuccess() {
            return this.currentStatus === STATUS_SUCCESS
        },
        isFailed() {
            return this.currentStatus === STATUS_FAILED
        },
        maxFilesize() {
            return this.printfilesize(this.maxFileSize)
        },
        acceptValues() {
            return Object.values(this.accept).join(',')
        },
        printAccept() {
            return Object.keys(this.accept).join(', ')
        },
        validationRules() {
            return [() => (!this.required || this.hasFiles) || this.$lumui.i18n.t('lumui.form.row.required')]
        },
        externalError() {
            return this.errorBucket.length > 0 || this.error
        },
        avoidDrag() {
            return typeof this.config.avoidDrag !== 'undefined' ? !!this.config.avoidDrag : false;
        }
    },
    watch: {
        value() {
            if (this.value !== null) {
                this.files = Array.isArray(this.value) ? this.value : [this.value]
            } else {
                this.files = []
            }
        },
        hasError(val) {
            if (this.shouldValidate) {
                /**
                 * validation error detected
                 * @param {Bool} val
                 */
                this.$emit('update:error', val)
            }
        }
    },
    mounted() {
        let msg = this.$lumui.i18n.t('lumui.form.file.maxSize', {size: this.maxFilesize});
        msg += ' ';
        msg += this.acceptValues !== '*' ? this.$lumui.i18n.t('lumui.form.file.extensions.accepted', {types: this.printAccept}) : this.$lumui.i18n.t('lumui.form.file.extensions.all');
        this.msg = [msg];
        this.reset()
    },
    methods: {
        /** @private */
        onDrop: function (e) {
            e.stopPropagation();
            e.preventDefault();
            this.dragOver = false;
            this.filesChange(e.target.name, e.dataTransfer.files)
        },
        /** trigger the file selection dialog */
        launchFilePicker() {
            this.$refs.file.click()
        },
        /** clear the field */
        reset() {
            this.currentStatus = STATUS_INITIAL;
            this.files = [];
            this.uploadError = null
        },
        /** @private */
        save(formData) {
            // upload data to the server
            this.currentStatus = STATUS_SAVING;

            this.upload(formData)
                .then(x => {
                    if (this.multiple) {
                        this.files = this.files.concat(x)
                    } else {
                        this.files = x
                    }
                    this.currentStatus = STATUS_SUCCESS;
                    this.hasFocused = true;
                    this.hasInput = this.hasFiles;
                    this.$emit('input', this.files);
                    this.$nextTick(() => {
                        this.$refs.inputField.validate();
                        this.validate(true)
                    });
                })
                .catch(err => {
                    this.uploadError = err.response;
                    this.currentStatus = STATUS_FAILED
                })
        },
        /** @private */
        async upload(formData) {
            let promises = formData.map((x) => {
                if (this.isImage(x) && this.maxImageSize > 0) {
                    return resizeImage({file: x, maxSize: this.maxImageSize})
                        .then(f => {
                            return this.readFile(f)
                                .then(file => {
                                    return {
                                        name: f.name,
                                        mimetype: f.type,
                                        size: f.size,
                                        src: file
                                    }
                                })
                        })
                }
                return this.readFile(x)
                    .then(file => ({
                        name: x.name,
                        mimetype: x.type,
                        size: x.size,
                        src: file
                    }))
            });
            return Promise.all(promises)
        },
        /** @private */
        readFile(file) {
            return new Promise((resolve, reject) => {
                let fReader = new FileReader();
                fReader.onload = () => {
                    resolve(fReader.result)
                };
                fReader.readAsDataURL(file)
            })
        },
        /** @private */
        filesChange(fieldName, fileList) {
            let removedFiles = [];
            // handle file changes
            let formData = [];
            let size = 0;
            this.fileCount = fileList.length;
            if (!fileList.length) {
                return
            }
            for (let i = 0; i < fileList.length; i++) {
                if (this.isAccepted(fileList[i])) {
                    size += fileList[i].size;
                    formData.push(fileList[i])
                } else {
                    removedFiles.push({name: fileList[i].name, type: fileList[i].type});
                }
            }
            this.removedFiles = removedFiles;
            if (size > this.maxFileSize) {
                this.currentStatus = STATUS_FAILED;
                this.uploadError = this.$lumui.i18n.t('lumui.form.file.maxSizeError', {size: this.maxFilesize});
                return;
            }
            this.currentStatus = STATUS_SELECTED;
            // append the files to FormData
            this.save(formData);
            // Clear the form element 'file' to ensure the onChange handler is responsive
            document.getElementById('file').value = ''
        },
        /** @private */
        isAccepted(file) {
            for (let key in this.accept) {
                if (!this.accept.hasOwnProperty(key)) {
                    continue;
                }
                let types = this.accept[key];
                if (!Array.isArray(types)) {
                    types = [types];
                }
                for (let i = 0; i < types.length; i++) {
                    let type = types[i];
                    let n = type.indexOf('*');
                    if(!file.type && file.name) {
                        if(file.name.split('.').pop().toLowerCase() === key.toLowerCase()) {
                            return true
                        }
                    } else if (file.type && n < 0) {
                        if (file.type.toLowerCase() === type.toLowerCase()) {
                            return true;
                        }
                    } else {
                        let prefix = type.slice(0, n);
                        if (file.type.startsWith(prefix)) {
                            return true;
                        }
                    }
                }
            }
            return false;
        },
        /** @private */
        remove(file) {
            let index = this.files.indexOf(file);
            this.files.splice(index, 1);
            this.currentStatus = STATUS_SUCCESS
        },
        /** @private */
        printfilesize(size) {
            return filesize(size)
        },
        /** @private */
        preview(file) {
            this.previewFile = file;
            this.dialog = true
        },
        /** @private */
        download(file) {
            saveAs(this.dataURItoBlob(file.src), file.name)
        },
        /** @private */
        base64(file) {
            if (file.src === null) {
                return '';
            }
            const type = this.getType(file) !== null ? this.getType(file) : 'image/jpg';
            return file.src.indexOf(';base64,') > 0 ? file.src : `data:${type};base64,${file.src}`
        },
        /** @private */
        isImage(file) {
            return this.getType(file) !== null ? this.getType(file).indexOf('image/') > -1 : false
        },
        getType(file) {
            return typeof file.type !== 'undefined' ? file.type : (typeof file.mimetype !== 'undefined' ? file.mimetype : null)
        },
        /** @private */
        dataURItoBlob(dataURI, mimeString) {
            let byteString = atob(dataURI.split(',')[1]);
            if (typeof mimeString === 'undefined') {
                mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]
            }
            let ab = new ArrayBuffer(byteString.length);
            let ia = new Uint8Array(ab);
            for (let i = 0; i < byteString.length; i++) {
                ia[i] = byteString.charCodeAt(i);
            }
            return new Blob([ab], {type: mimeString});
        }
    }
}
</script>

<style scoped>
.dropbox {
    outline: 2px dashed grey; /* the dash box */
    outline-offset: -10px;
    background: rgba(0, 0, 0, .06);
    color: dimgray;
    padding: 10px 10px;
    min-height: 100px; /* minimum height */
    position: relative;
    cursor: pointer;
    width: 100%;
}

.input-file {
    opacity: 0; /* invisible but it's there! */
    width: 100%;
    height: 100px;
    position: absolute;
    cursor: pointer;
}

.dropbox:hover, .dropbox.hover {
    background: lightblue; /* when mouse over to the drop zone, change color */
}

.dropbox p {
    font-size: 1.2em;
    text-align: center;
    padding: 25px 0;
    margin: 0;
}

.dropbox.disabled {
    color: #C0C0C0;
    outline: 2px dashed #C0C0C0;
}

.dropbox.disabled:hover {
    color: #C0C0C0;
    cursor: default;
}
</style>

<style>
.input--file .v-input__slot {
    display: block;
}
</style>
