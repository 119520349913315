import moment from "moment";

export default class DateFormatter {
    constructor(settings)
    {
        this.formatter = new Intl.DateTimeFormat(settings.locale, {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
        });
    }

    requiresHTML() {
        return false;
    }
    format(value) {
        if(!value || typeof value === 'undefined') {
            return '';
        }
        if(!(value instanceof Date)) {
            /* make sure it's an untimed date */
            if(value.indexOf(' ') && value.indexOf(':')) {
                value = value.split(' ').shift();
            }
            /* Workaround for Safari Browsers */
            let parts = value.match(/(\d+)/g);
            let i = 0;
            let fmt = {};
            'yyyy-mm-dd'.replace(/(yyyy|dd|mm)/g, function(part) { fmt[part] = i++; });
            if(parts === null){
                return ''
            }
            value = new Date(parts[fmt['yyyy']], parts[fmt['mm']]-1, parts[fmt['dd']]);
        }
        return moment(value).format('L');
    }
}
