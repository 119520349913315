<template>
    <span v-if="formattedValue.requiresHTML" v-html="formattedValue.value" />
    <span v-else>{{ formattedValue.value }}</span>
</template>

<script>

import FormatterFactory from '../lib/formatter';
import {mapGetters} from "vuex";

export default {
    props: {
        /** the value that will be formatted */
        "value": {
            default: ''
        },
        /**
         * bool, date, datetime, currency, localized, select, checkbox, image,
         * status, statusdetail, striptags, float, text, textarea, locale, time,
         * mimeicon, truncate or link
         */
        "type": {
            type: String,
            default: "",
        },
        /** special case for AsaTable */
        "formatter": {
            type: Object,
            default: null,
        },
        /** options to be passed to the formatter. depends on the formatter selected via the type prop. */
        "options": {
            type: [Object, Array],
            default: () => []
        },
        /** special case for AsaTable */
        "context": {
            type: [Object],
            default: () => {}
        }
    },
    computed: {
        ...mapGetters('lumui/i18n', ['locale']),
        formattedValue() {
            let value = this.value;

            const settings = this.options;
            let formatter = null;
            let isHTML = false;

            switch (this.type.toLowerCase()) {
            case 'bool':
                formatter = FormatterFactory('Bool', {})
                break;
            case 'date':
                formatter = FormatterFactory('Date', {locale: this.locale})
                break;
            case 'datetime':
                formatter = FormatterFactory('DateTime', {locale: this.locale})
                break;
            case 'currency':
                formatter = FormatterFactory('Currency', {})
                break;
            case 'localized':
                formatter = FormatterFactory('Localized', {...settings, ...{locale: this.locale}})
                break;
            case 'select':
                formatter = FormatterFactory('Select', settings)
                break;
            case 'checkbox':
                formatter = FormatterFactory('Checkbox', settings)
                break;
            case 'image':
                formatter = FormatterFactory('Image', {})
                break;
            case 'status':
                formatter = FormatterFactory('Status', settings)
                break;
            case 'statusdetail':
                formatter = FormatterFactory('Statusdetail', settings)
                break;
            case 'striptags':
                formatter = FormatterFactory('Striptags', {})
                break;
            case 'float':
                formatter = FormatterFactory('Float', {locale: this.locale})
                break;
            case 'text':
            case 'textarea':
                formatter = FormatterFactory('Striptags', {})
                break;
            case 'locale':
                formatter = FormatterFactory('Locale', {})
                break;
            case 'time':
                formatter = FormatterFactory('Time', {});
                break;
            case 'mimeicon':
                formatter = FormatterFactory('MimeIcon', {})
                break;
            case 'nestedset':
                formatter = FormatterFactory('NestedSet', settings)
                break;
            case 'materialized_path':
                formatter = FormatterFactory('MaterializedPath', settings);
                break;
            case 'truncate':
                formatter = FormatterFactory('Truncate', settings);
                break;
            case 'link':
                formatter = FormatterFactory('Link', settings);
                break;
            case 'noop':
            default:
                if (this.type) {
                    console.error('AsaFormatter: unknown type ' + this.type);
                }

                formatter = FormatterFactory('Noop', settings)
                break;
            }

            if (formatter) {
                value = formatter.format(value);
                isHTML = formatter.requiresHTML();
            }

            if (this.formatter) {
                Object.keys(this.formatter).forEach((formatterName) => {
                    try {
                        //console.log(formatterName)
                        //console.log(this.formatter[formatterName])
                        let f = FormatterFactory(formatterName, this.formatter[formatterName]);
                        value = f.format(value, this.context);
                        isHTML |= f.requiresHTML();
                    } catch (e) {
                        console.error(this.type, e);
                    }
                })
            }

            return {
                value,
                requiresHTML: isHTML
            };
        }
    },
}
</script>
