var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "table-container" },
    [
      _vm.showFilter
        ? _c(
            "v-card",
            [
              _vm._t(
                "filter",
                function () {
                  return [
                    _c(
                      "v-card-text",
                      [
                        _c(
                          "v-form",
                          [
                            _c(
                              "v-container",
                              { attrs: { fluid: "", "grid-list-lg": "" } },
                              [
                                _c(
                                  "v-layout",
                                  { attrs: { row: "", wrap: "" } },
                                  [
                                    _vm._l(_vm.filters, function (filter) {
                                      return [
                                        !filter.hidden &&
                                        !(filter.type === "action") &&
                                        filter.searchable
                                          ? _c(
                                              "v-flex",
                                              {
                                                key: filter.key,
                                                attrs: { lg4: "", xs12: "" },
                                              },
                                              [
                                                _c("asa-table-filter", {
                                                  attrs: { config: filter },
                                                }),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ]
                                    }),
                                  ],
                                  2
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "v-card-actions",
                      { staticClass: "text-right" },
                      [
                        _c("v-spacer"),
                        _vm._v(" "),
                        _c(
                          "v-btn",
                          {
                            attrs: {
                              disabled: !_vm.filterActive,
                              color: "secondary",
                              text: "",
                            },
                            on: { click: _vm.resetFilter },
                          },
                          [
                            _vm._v(
                              "\n                    " +
                                _vm._s(
                                  _vm.$lumui.i18n.t("lumui.table.reset_filters")
                                ) +
                                "\n                "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "v-btn",
                          {
                            attrs: { color: "primary", text: "" },
                            on: {
                              click: function ($event) {
                                return _vm.closeFilter()
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n                    " +
                                _vm._s(
                                  _vm.$lumui.i18n.t("lumui.table.close_filters")
                                ) +
                                "\n                "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]
                },
                { filters: _vm.filters }
              ),
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _c("v-spacer"),
      _vm._v(" "),
      _c(
        "v-navigation-drawer",
        {
          staticClass: "elevation-4 hide-overflow",
          attrs: {
            value: _vm.infoVisible,
            width: _vm.asideWidth,
            clipped: "",
            "disable-resize-watcher": "",
            "disable-route-watcher": "",
            fixed: "",
            "hide-overlay": "",
            right: "",
            stateless: "",
          },
          scopedSlots: _vm._u(
            [
              {
                key: "prepend",
                fn: function () {
                  return [
                    _c(
                      "v-toolbar",
                      { attrs: { flat: "", "max-height": "64" } },
                      [
                        _c(
                          "v-app-bar-nav-icon",
                          {
                            on: {
                              click: function ($event) {
                                _vm.infoVisible = false
                              },
                            },
                          },
                          [_c("v-icon", [_vm._v("$close")])],
                          1
                        ),
                        _vm._v(" "),
                        _c("v-toolbar-title", [
                          _vm._v(
                            "\n                    " +
                              _vm._s(_vm.$lumui.i18n.t("lumui.table.details")) +
                              "\n                "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("v-spacer"),
                        _vm._v(" "),
                        _vm._t(
                          "infoActions",
                          function () {
                            return [
                              _vm.currentItem
                                ? _c(
                                    "asa-table-action",
                                    {
                                      attrs: {
                                        actions: _vm.actionColumn.actions,
                                        row: _vm.currentItem,
                                      },
                                      on: { rowaction: _vm.handleAction },
                                    },
                                    [
                                      _c(
                                        "v-btn",
                                        { attrs: { icon: "", text: "" } },
                                        [_c("v-icon", [_vm._v("$moreVert")])],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ]
                          },
                          {
                            currentitem: _vm.currentItem,
                            actionsColumn: _vm.actionColumn,
                            handleAction: _vm.handleAction,
                          }
                        ),
                      ],
                      2
                    ),
                  ]
                },
                proxy: true,
              },
              {
                key: "append",
                fn: function () {
                  return [
                    _c(
                      "v-toolbar",
                      { attrs: { color: "white", "max-height": "64" } },
                      [
                        _c(
                          "v-tooltip",
                          {
                            attrs: { top: "" },
                            scopedSlots: _vm._u([
                              {
                                key: "activator",
                                fn: function ({ on }) {
                                  return [
                                    _c(
                                      "v-btn",
                                      _vm._g(
                                        {
                                          attrs: {
                                            disabled: !_vm.hasPrevRow,
                                            color: "grey lighten-2",
                                            fab: "",
                                            icon: "",
                                            small: "",
                                          },
                                          on: { click: _vm.prevRow },
                                        },
                                        on
                                      ),
                                      [_c("v-icon", [_vm._v("$prev")])],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ]),
                          },
                          [
                            _vm._v(" "),
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.$lumui.i18n.t("lumui.table.previous")
                                )
                              ),
                            ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c("v-spacer"),
                        _vm._v(" "),
                        _c("div", [
                          _vm._v(
                            _vm._s(_vm.currentIndex + 1) +
                              " / " +
                              _vm._s(_vm.filteredAndSortedItems.length)
                          ),
                        ]),
                        _vm._v(" "),
                        _c("v-spacer"),
                        _vm._v(" "),
                        _c(
                          "v-tooltip",
                          {
                            attrs: { top: "" },
                            scopedSlots: _vm._u([
                              {
                                key: "activator",
                                fn: function ({ on }) {
                                  return [
                                    _c(
                                      "v-btn",
                                      _vm._g(
                                        {
                                          attrs: {
                                            disabled: !_vm.hasNextRow,
                                            color: "grey lighten-2",
                                            fab: "",
                                            icon: "",
                                            small: "",
                                          },
                                          on: { click: _vm.nextRow },
                                        },
                                        on
                                      ),
                                      [_c("v-icon", [_vm._v("$next")])],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ]),
                          },
                          [
                            _vm._v(" "),
                            _c("span", [
                              _vm._v(
                                _vm._s(_vm.$lumui.i18n.t("lumui.table.next"))
                              ),
                            ]),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
            ],
            null,
            true
          ),
        },
        [
          _vm._v(" "),
          _vm._v(" "),
          !_vm.loading
            ? [
                _vm._t(
                  "detail",
                  function () {
                    return [
                      _vm._l(_vm.detailsColumn, function (column) {
                        return [
                          column.details.type !== "action" && _vm.currentItem
                            ? _c(
                                "v-card",
                                {
                                  key: column.details.value,
                                  attrs: { flat: "" },
                                },
                                [
                                  _c("v-card-text", [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "subheading",
                                        staticStyle: { color: "grey" },
                                      },
                                      [
                                        _vm._v(
                                          "\n                                " +
                                            _vm._s(column.details.text) +
                                            "\n                            "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "body-2" },
                                      [
                                        _vm._t(
                                          "detail_" + column.details.value,
                                          function () {
                                            return [
                                              _c("asa-table-column", {
                                                attrs: {
                                                  column: column.details,
                                                  value:
                                                    _vm.currentItem[
                                                      column.details.value
                                                    ],
                                                  row: _vm.currentItem,
                                                },
                                              }),
                                            ]
                                          },
                                          {
                                            value:
                                              _vm.currentItem[
                                                column.details.value
                                              ],
                                            row: _vm.currentItem,
                                            columns: _vm.detailsColumn,
                                          }
                                        ),
                                      ],
                                      2
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("v-divider"),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]
                      }),
                    ]
                  },
                  { item: _vm.currentItem, columns: _vm.detailsColumn }
                ),
              ]
            : [_c("asa-loading", { attrs: { loading: _vm.loading } })],
        ],
        2
      ),
      _vm._v(" "),
      _vm.filterActive && !_vm.showFilter
        ? _c(
            "div",
            { staticClass: "text-center primary" },
            [
              _c("small", { staticClass: "white--text" }, [
                _vm._v(_vm._s(_vm.$lumui.i18n.t("lumui.table.filters_active"))),
              ]),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  attrs: { small: "", icon: "", color: "white" },
                  on: { click: _vm.resetAndCloseFilter },
                },
                [
                  _c("v-icon", { attrs: { small: "" } }, [
                    _vm._v("\n                $clear\n            "),
                  ]),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("v-data-table", {
        attrs: {
          id: "datatable",
          "custom-sort": _vm.sortData,
          "footer-props": _vm.footerProps,
          headers: _vm.gridColumn,
          "hide-default-footer": _vm.hideFooter || _vm.disablePagination,
          items: _vm.allRows,
          "items-per-page": _vm.rowsPerPageOption,
          loading: _vm.loading,
          page: _vm.page,
          "show-expand": _vm.showExpand,
          "single-expand": true,
          "sort-by": _vm.defaultSort.sortBy,
          "sort-desc": _vm.defaultSort.sortDesc,
          "disable-pagination": _vm.disablePagination,
          "show-select": _vm.showSelect,
          light: "",
        },
        on: {
          "update:page": function ($event) {
            _vm.page = $event
          },
        },
        scopedSlots: _vm._u(
          [
            !_vm.loading
              ? {
                  key: "top",
                  fn: function ({ pagination }) {
                    return [_vm._t("top", null, { pagination: pagination })]
                  },
                }
              : null,
            {
              key: "item",
              fn: function (props) {
                return [
                  _c(
                    "tr",
                    {
                      staticClass: "clickable",
                      class: {
                        grey: _vm.currentItem === props.item,
                        "lighten-2": _vm.currentItem === props.item,
                        "v-data-table__mobile-table-row": _vm.isMobile,
                      },
                    },
                    [
                      _vm.showSelect
                        ? _c(
                            "td",
                            [
                              _c("v-checkbox", {
                                attrs: { "input-value": props.isSelected },
                                on: {
                                  change: function ($event) {
                                    return props.select($event)
                                  },
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm._l(_vm.gridColumn, function (column, index) {
                        return [
                          column.value !== "data-table-expand" && !column.hidden
                            ? _c(
                                "td",
                                {
                                  key: `${column.value}-${index}`,
                                  class: {
                                    "v-data-table__mobile-row": _vm.isMobile,
                                    "justify-end px-0":
                                      column.type === "action",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.rowClicked(props)
                                    },
                                  },
                                },
                                [
                                  _vm.isMobile && column.type !== "action"
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "v-data-table__mobile-row__header",
                                        },
                                        [
                                          _vm._v(
                                            "\n                            " +
                                              _vm._s(column.text) +
                                              "\n                        "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    {
                                      class: {
                                        "d-block v-data-table__mobile-row__cell":
                                          _vm.isMobile,
                                      },
                                    },
                                    [
                                      _vm._t(
                                        "col_" + column.value,
                                        function () {
                                          return [
                                            _vm.infoDisabled &&
                                            column.type === "action" &&
                                            !!Object.keys(column.actions).length
                                              ? _c("asa-table-action", {
                                                  attrs: {
                                                    actions: column.actions,
                                                    row: props.item,
                                                    "icon-size": "small",
                                                    "btn-size": "default",
                                                  },
                                                  on: {
                                                    rowaction: _vm.handleAction,
                                                  },
                                                })
                                              : column.type !== "action"
                                              ? _c("asa-table-column", {
                                                  attrs: {
                                                    column: column,
                                                    row: props.item,
                                                    value:
                                                      props.item[column.value],
                                                  },
                                                })
                                              : _vm._e(),
                                          ]
                                        },
                                        {
                                          value: props.item[column.value],
                                          row: props.item,
                                          columns: _vm.gridColumn,
                                        }
                                      ),
                                    ],
                                    2
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ]
                      }),
                      _vm._v(" "),
                      !_vm.infoDisabled
                        ? _c(
                            "td",
                            {
                              class: _vm.isMobile
                                ? "v-data-table__mobile-row justify-end px-0"
                                : "justify-center px-0",
                              on: {
                                click: function ($event) {
                                  return _vm.rowClicked(props)
                                },
                              },
                            },
                            [
                              _vm.isMobile
                                ? _c(
                                    "v-btn",
                                    { attrs: { text: "", small: "" } },
                                    [
                                      _vm._v(
                                        "\n                        " +
                                          _vm._s(
                                            _vm.$lumui.i18n.t(
                                              "lumui.table.details"
                                            )
                                          ) +
                                          "\n                        "
                                      ),
                                      _c("v-icon", { attrs: { right: "" } }, [
                                        _vm._v(
                                          "\n                            $next\n                        "
                                        ),
                                      ]),
                                    ],
                                    1
                                  )
                                : [
                                    _c(
                                      "v-tooltip",
                                      {
                                        attrs: { bottom: "" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "activator",
                                              fn: function ({ on, attrs }) {
                                                return [
                                                  _c(
                                                    "v-icon",
                                                    _vm._g(
                                                      _vm._b(
                                                        {
                                                          attrs: { small: "" },
                                                        },
                                                        "v-icon",
                                                        attrs,
                                                        false
                                                      ),
                                                      on
                                                    ),
                                                    [
                                                      _vm._v(
                                                        "\n                                    $next\n                                "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      },
                                      [
                                        _vm._v(" "),
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$lumui.i18n.t(
                                                "lumui.table.details"
                                              )
                                            )
                                          ),
                                        ]),
                                      ]
                                    ),
                                  ],
                            ],
                            2
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.showExpand
                        ? _c(
                            "td",
                            { staticClass: "justify-center px-0" },
                            [
                              !props.isExpanded
                                ? _c(
                                    "v-icon",
                                    {
                                      on: {
                                        click: function ($event) {
                                          return props.expand(!props.isExpanded)
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                        $down\n                    "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              props.isExpanded
                                ? _c(
                                    "v-icon",
                                    {
                                      on: {
                                        click: function ($event) {
                                          return props.expand(!props.isExpanded)
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                        $up\n                    "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    2
                  ),
                ]
              },
            },
            {
              key: "no-data",
              fn: function () {
                return [
                  _vm._t("no-data", function () {
                    return [
                      _c("div", { staticClass: "text-sm-center" }, [
                        _c("em", [
                          _vm._v(
                            _vm._s(_vm.$lumui.i18n.t("lumui.table.no_data"))
                          ),
                        ]),
                      ]),
                    ]
                  }),
                ]
              },
              proxy: true,
            },
            {
              key: "no-results",
              fn: function () {
                return [
                  _vm._t("no-results", function () {
                    return [
                      _c("div", { staticClass: "text-sm-center" }, [
                        _c("em", [
                          _vm._v(
                            _vm._s(
                              _vm.$lumui.i18n.t("lumui.table.no_filter_results")
                            )
                          ),
                        ]),
                      ]),
                    ]
                  }),
                ]
              },
              proxy: true,
            },
            {
              key: "body.append",
              fn: function ({ headers }) {
                return [
                  _c("tr", { staticClass: "export" }, [
                    _c(
                      "td",
                      { attrs: { colspan: headers.length } },
                      [
                        _vm.filteredAndSortedItems.length > 0 && !_vm.hideExport
                          ? _c(
                              "v-menu",
                              {
                                attrs: { bottom: "", left: "" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function ({ on: onMenu }) {
                                        return [
                                          _c(
                                            "v-btn",
                                            _vm._g(
                                              {
                                                staticClass: "export-menu",
                                                attrs: { plain: "" },
                                              },
                                              { ...onMenu }
                                            ),
                                            [
                                              _c(
                                                "v-icon",
                                                { attrs: { left: "" } },
                                                [_vm._v("$download")]
                                              ),
                                              _vm._v(
                                                "\n                                " +
                                                  _vm._s(
                                                    _vm.$lumui.i18n.t(
                                                      "lumui.table.btn.export"
                                                    )
                                                  ) +
                                                  "\n                            "
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              },
                              [
                                _vm._v(" "),
                                _c(
                                  "v-list",
                                  [
                                    _vm.exportFormats.includes("pdf")
                                      ? _c(
                                          "v-list-item",
                                          {
                                            on: {
                                              click: function ($event) {
                                                return _vm.exportTable("pdf")
                                              },
                                            },
                                          },
                                          [
                                            _c("v-list-item-title", [
                                              _vm._v(
                                                "Portable Document Format (pdf)"
                                              ),
                                            ]),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.exportFormats.includes("csv")
                                      ? _c(
                                          "v-list-item",
                                          {
                                            on: {
                                              click: function ($event) {
                                                return _vm.exportTable("csv")
                                              },
                                            },
                                          },
                                          [
                                            _c("v-list-item-title", [
                                              _vm._v(
                                                "Comma Seperated Values (csv)"
                                              ),
                                            ]),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.exportFormats.includes("xls")
                                      ? _c(
                                          "v-list-item",
                                          {
                                            on: {
                                              click: function ($event) {
                                                return _vm.exportTable("xls")
                                              },
                                            },
                                          },
                                          [
                                            _c("v-list-item-title", [
                                              _vm._v("Excel 97 (xls)"),
                                            ]),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.exportFormats.includes("xlsx")
                                      ? _c(
                                          "v-list-item",
                                          {
                                            on: {
                                              click: function ($event) {
                                                return _vm.exportTable("xlsx")
                                              },
                                            },
                                          },
                                          [
                                            _c("v-list-item-title", [
                                              _vm._v("Excel 2007 (xlsx)"),
                                            ]),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.exportFormats.includes("ods")
                                      ? _c(
                                          "v-list-item",
                                          {
                                            on: {
                                              click: function ($event) {
                                                return _vm.exportTable("ods")
                                              },
                                            },
                                          },
                                          [
                                            _c("v-list-item-title", [
                                              _vm._v(
                                                "OpenDocument Spreadsheet (ods)"
                                              ),
                                            ]),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "text-center" },
                          [
                            _c(
                              "v-chip",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.filterActive,
                                    expression: "filterActive",
                                  },
                                ],
                                attrs: {
                                  color: "primary",
                                  "text-color": "white",
                                },
                              },
                              [
                                _c(
                                  "v-icon",
                                  { attrs: { color: "white", left: "" } },
                                  [
                                    _vm._v(
                                      "\n                                $search\n                            "
                                    ),
                                  ]
                                ),
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(
                                      _vm.$lumui.i18n.t(
                                        "lumui.table.filters_active"
                                      )
                                    ) +
                                    "\n                            "
                                ),
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "ml-2",
                                    staticStyle: { "margin-right": "-12px" },
                                    attrs: { icon: "", color: "white" },
                                    on: { click: _vm.resetAndCloseFilter },
                                  },
                                  [_c("v-icon", [_vm._v("$clear")])],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ]
              },
            },
            {
              key: "actions-append",
              fn: function () {
                return undefined
              },
              proxy: true,
            },
            {
              key: "expanded-item",
              fn: function ({ headers, item }) {
                return [
                  _c(
                    "td",
                    { attrs: { colspan: headers.length } },
                    [
                      _vm._t(
                        "expanded-item",
                        function () {
                          return [
                            _vm._v(
                              "\n                    Hier Custom Content einfügen!\n                "
                            ),
                          ]
                        },
                        { item: item }
                      ),
                    ],
                    2
                  ),
                ]
              },
            },
          ],
          null,
          true
        ),
        model: {
          value: _vm.selectedItems,
          callback: function ($$v) {
            _vm.selectedItems = $$v
          },
          expression: "selectedItems",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }