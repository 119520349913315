<template>
    <v-dialog ref="d" v-bind="defaultAttrs" :fullscreen="breakpoint" :value="true" :retain-focus="false">
        <!-- dialog contents -->
        <slot />
    </v-dialog>
</template>

<script>
import VDialog from "vuetify/lib/components/VDialog";

/**
 * See [VDialog](https://vuetifyjs.com/en/api/v-dialog/) for additional information
 * @extends VDialog
 */
export default {
    name: "AsaDialog",
    components: {VDialog},
    extends: VDialog,
    props: {
        /** width limit for the dialog. */
        scrollable: {
            type: Boolean,
            default: true
        },
        persistent: {
            type: Boolean,
            default: true
        },
        maxWidth: {
            type: [String, Number],
            default: 800
        },
        fullscreen: {
            type: [Function, Boolean],
            default: null
        }
    },
    computed: {
        breakpoint() {
            let v = this.fullscreen;
            if (typeof v === 'function') {
                v = this.fullscreen();
            }
            return v === null ? this.$vuetify.breakpoint.mobile : v;
        },
        /** @private merges attributes set on this component with the default values */
        defaultAttrs() {
            return Object.assign(this.$props, this.$attrs);
        }
    },
    beforeDestroy() {
        this.unbind();
    },
    beforeMount() {
        this.$nextTick(() => this.bind());
    },
    methods: {
        /** @private workaround for redactor */
        onFocusin(e) {
            if (!e || !this.retainFocus) return;
            const target = e.target;

            if (!!target && // It isn't the document or the dialog body
                ![document, this.$refs.content].includes(target) && // It isn't inside the dialog body
                !this.$refs.d.$refs.content.contains(target) && // We're the topmost dialog
                this.activeZIndex >= this.getMaxZIndex() && // It isn't inside a dependent element (like a menu)
                !this.getOpenDependentElements().some(el => el.contains(target)) && // So we must have focused something outside the dialog and its children
                !target.closest('redactor-modal') // target is not in a redactor modal
            ) {
                // Find and focus the first available element inside the dialog
                const focusable = this.$refs.content.querySelectorAll('button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])');
                const el = [...focusable].find(el => !el.hasAttribute('disabled'));
                el && el.focus();
            }
        },
        /** @private add focusin listener */
        bind() {
            window.addEventListener('focusin', this.onFocusin);
        },
        /** @private removes focusin listener */
        unbind() {
            if (typeof window !== 'undefined') {
                window.removeEventListener('focusin', this.onFocusin);
            }
        },
    }
}
</script>
