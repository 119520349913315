<template>
    <asa-form :config="config" :value="value" no-title />
</template>

<script>
export default {
    name: "DialogTabs",
    data() {
        return {
            value: {
                b0: "asdf"
            },
            config: {
                tab1: {
                    type: "tab",
                    label: "Tab A",
                    children: {
                        a1: {type: "text", label: "Vorname", required: true},
                        a2: {type: "text", label: "Nachname", required: true},
                        a3: {type: "email", label: "E-Mail"},
                        a4: {type: "textarea", label: "Adresse"},
                    }
                },
                tab2: {
                    type: "tab",
                    label: "Tab B",
                    children: {
                        b0: {type: "text", label: "Error", errors: ['error from config']},
                        b1: {type: "textarea", label: "Anmerkungen", required: true},
                    }
                },
            }
        }
    },
    mounted() {
        //this.setFormErrors(this.config, { 'unassigned': 'YYY', 'b0': 'XXX' });
    }
}
</script>

<style scoped>

</style>
