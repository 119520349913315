var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("asa-form", {
    attrs: { config: _vm.config, value: _vm.value, "no-title": "" },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }