<template>
    <div class="table-container">
        <v-card v-if="showFilter">
            <slot name="filter" :filters="filters">
                <v-card-text>
                    <v-form>
                        <v-container fluid grid-list-lg>
                            <v-layout row wrap>
                                <template v-for="filter in filters">
                                    <v-flex
                                        v-if="!filter.hidden && !(filter.type === 'action') && filter.searchable"
                                        :key="filter.key"
                                        lg4
                                        xs12
                                    >
                                        <asa-table-filter :config="filter" />
                                    </v-flex>
                                </template>
                            </v-layout>
                        </v-container>
                    </v-form>
                </v-card-text>
                <v-card-actions class="text-right">
                    <v-spacer />
                    <v-btn :disabled="!filterActive" color="secondary" text @click="resetFilter">
                        {{ $lumui.i18n.t('lumui.table.reset_filters') }}
                    </v-btn>
                    <v-btn color="primary" text @click="closeFilter()">
                        {{ $lumui.i18n.t('lumui.table.close_filters') }}
                    </v-btn>
                </v-card-actions>
            </slot>
        </v-card>
        <v-spacer />
        <v-navigation-drawer
            :value="infoVisible"
            :width="asideWidth"
            class="elevation-4 hide-overflow"
            clipped
            disable-resize-watcher
            disable-route-watcher
            fixed
            hide-overlay
            right
            stateless
        >
            <template #prepend>
                <v-toolbar flat max-height="64">
                    <v-app-bar-nav-icon @click="infoVisible = false">
                        <v-icon>$close</v-icon>
                    </v-app-bar-nav-icon>
                    <v-toolbar-title>
                        {{ $lumui.i18n.t('lumui.table.details') }}
                    </v-toolbar-title>

                    <v-spacer />

                    <slot name="infoActions" :currentitem="currentItem" :actions-column="actionColumn" :handle-action="handleAction">
                        <asa-table-action
                            v-if="currentItem" :actions="actionColumn.actions" :row="currentItem"
                            @rowaction="handleAction"
                        >
                            <v-btn icon text>
                                <!-- @todo does this serve a function? asa-table-action does not have this slot -->
                                <v-icon>$moreVert</v-icon>
                            </v-btn>
                        </asa-table-action>
                    </slot>
                </v-toolbar>
            </template>
            <template #append>
                <v-toolbar color="white" max-height="64">
                    <v-tooltip top>
                        <template #activator="{ on }">
                            <v-btn
                                :disabled="!hasPrevRow"
                                color="grey lighten-2"
                                fab
                                icon
                                small
                                v-on="on"
                                @click="prevRow"
                            >
                                <v-icon>$prev</v-icon>
                            </v-btn>
                        </template>
                        <span>{{ $lumui.i18n.t('lumui.table.previous') }}</span>
                    </v-tooltip>
                    <v-spacer />
                    <div>{{ currentIndex + 1 }} / {{ filteredAndSortedItems.length }}</div>
                    <v-spacer />
                    <v-tooltip top>
                        <template #activator="{ on }">
                            <v-btn
                                :disabled="!hasNextRow"
                                color="grey lighten-2"
                                fab
                                icon
                                small
                                v-on="on"
                                @click="nextRow"
                            >
                                <v-icon>$next</v-icon>
                            </v-btn>
                        </template>
                        <span>{{ $lumui.i18n.t('lumui.table.next') }}</span>
                    </v-tooltip>
                </v-toolbar>
            </template>
            <template v-if="!loading">
                <!--
                    sidebar containing details for the current row
                    @props {Object} item - current item
                    @props {Object} columns - column definitions for current row
                -->
                <slot :item="currentItem" :columns="detailsColumn" name="detail">
                    <template v-for="column in detailsColumn">
                        <v-card
                            v-if="column.details.type !== 'action' && currentItem"
                            :key="column.details.value" flat
                        >
                            <v-card-text>
                                <div class="subheading" style="color:grey">
                                    {{ column.details.text }}
                                </div>
                                <div class="body-2">
                                    <!--
                                        slot to override default detail column rendering
                                        @props value - current column's value
                                        @props {Object} row - current row
                                        @props {Object} columns - details columns
                                    -->
                                    <slot
                                        :name="'detail_' + column.details.value"
                                        :value="currentItem[column.details.value]"
                                        :row="currentItem"
                                        :columns="detailsColumn"
                                    >
                                        <asa-table-column
                                            :column="column.details"
                                            :value="currentItem[column.details.value]"
                                            :row="currentItem"
                                        />
                                    </slot>
                                </div>
                            </v-card-text>
                            <v-divider />
                        </v-card>
                    </template>
                </slot>
            </template>
            <template v-else>
                <asa-loading :loading="loading" />
            </template>
        </v-navigation-drawer>

        <div v-if="filterActive && !showFilter" class="text-center primary">
            <small class="white--text">{{ $lumui.i18n.t('lumui.table.filters_active') }}</small>
            <v-btn
                small
                icon
                color="white"
                @click="resetAndCloseFilter"
            >
                <v-icon small>
                    $clear
                </v-icon>
            </v-btn>
        </div>

        <v-data-table
            id="datatable"
            v-model="selectedItems"
            :custom-sort="sortData"
            :footer-props="footerProps"
            :headers="gridColumn"
            :hide-default-footer="hideFooter || disablePagination"
            :items="allRows"
            :items-per-page="rowsPerPageOption"
            :loading="loading"
            :page.sync="page"
            :show-expand="showExpand"
            :single-expand="true"
            :sort-by="defaultSort.sortBy"
            :sort-desc="defaultSort.sortDesc"
            :disable-pagination="disablePagination"
            :show-select="showSelect"
            light
        >
            <template v-if="!loading" #top="{ pagination }">
                <slot name="top" :pagination="pagination">
                </slot>
            </template>
            <template #item="props">
                <tr
                    class="clickable"
                    :class="{'grey': currentItem === props.item, 'lighten-2': currentItem === props.item, 'v-data-table__mobile-table-row': isMobile }"
                >
                    <td v-if="showSelect">
                        <v-checkbox
                            :input-value="props.isSelected"
                            @change="props.select($event)"
                        />
                    </td>
                    <template v-for="(column, index) in gridColumn">
                        <td
                            v-if="column.value !== 'data-table-expand' && !column.hidden"
                            :key="`${column.value}-${index}`"
                            :class="{'v-data-table__mobile-row': isMobile, 'justify-end px-0' : column.type === 'action' }"
                            @click="rowClicked(props)"
                        >
                            <div v-if="isMobile && column.type !== 'action'" class="v-data-table__mobile-row__header">
                                {{ column.text }}
                            </div>
                            <!--
                                slot to override default column rendering
                                @props value - current column's value
                                @props {Object} row - current row
                                @props {Object} columns - grid columns
                            -->
                            <span :class="{ 'd-block v-data-table__mobile-row__cell' : isMobile }">
                                <slot
                                    :name="'col_' + column.value"
                                    :value="props.item[column.value]"
                                    :row="props.item"
                                    :columns="gridColumn"
                                >
                                    <asa-table-action
                                        v-if="infoDisabled && column.type === 'action' && !!Object.keys(column.actions).length"
                                        :actions="column.actions"
                                        :row="props.item"
                                        icon-size="small"
                                        btn-size="default"
                                        @rowaction="handleAction"
                                    />
                                    <asa-table-column
                                        v-else-if="column.type !== 'action'"
                                        :column="column"
                                        :row="props.item"
                                        :value="props.item[column.value]"
                                    />
                                </slot>
                            </span>
                        </td>
                    </template>
                    <td
                        v-if="!infoDisabled"
                        :class="isMobile ? 'v-data-table__mobile-row justify-end px-0': 'justify-center px-0'"
                        @click="rowClicked(props)"
                    >
                        <v-btn v-if="isMobile" text small>
                            {{ $lumui.i18n.t('lumui.table.details') }}
                            <v-icon right>
                                $next
                            </v-icon>
                        </v-btn>
                        <template v-else>
                            <v-tooltip bottom>
                                <template #activator="{ on, attrs }">
                                    <v-icon v-bind="attrs" small v-on="on">
                                        $next
                                    </v-icon>
                                </template>
                                <span>{{ $lumui.i18n.t('lumui.table.details') }}</span>
                            </v-tooltip>
                        </template>
                    </td>
                    <td v-if="showExpand" class="justify-center px-0">
                        <v-icon v-if="!props.isExpanded" @click="props.expand(!props.isExpanded)">
                            $down
                        </v-icon>
                        <v-icon v-if="props.isExpanded" @click="props.expand(!props.isExpanded)">
                            $up
                        </v-icon>
                    </td>
                </tr>
            </template>
            <template #no-data>
                <!-- rendered, when the table has no data -->
                <slot name="no-data">
                    <div class="text-sm-center">
                        <em>{{ $lumui.i18n.t('lumui.table.no_data') }}</em>
                    </div>
                </slot>
            </template>
            <template #no-results>
                <!-- rendered, when filtering returend no results. -->
                <slot name="no-results">
                    <div class="text-sm-center">
                        <em>{{ $lumui.i18n.t('lumui.table.no_filter_results') }}</em>
                    </div>
                </slot>
            </template>
            <template #body.append="{ headers }">
                <tr class="export">
                    <td :colspan="headers.length">
                        <v-menu v-if="filteredAndSortedItems.length > 0 && !hideExport" bottom left>
                            <template #activator="{ on: onMenu }">
                                <v-btn plain class="export-menu" v-on="{ ...onMenu }">
                                    <v-icon left>$download</v-icon>
                                    {{ $lumui.i18n.t('lumui.table.btn.export') }}
                                </v-btn>
                            </template>

                            <v-list>
                                <v-list-item v-if="exportFormats.includes('pdf')" @click="exportTable('pdf')">
                                    <v-list-item-title>Portable Document Format (pdf)</v-list-item-title>
                                </v-list-item>
                                <v-list-item v-if="exportFormats.includes('csv')" @click="exportTable('csv')">
                                    <v-list-item-title>Comma Seperated Values (csv)</v-list-item-title>
                                </v-list-item>
                                <v-list-item v-if="exportFormats.includes('xls')" @click="exportTable('xls')">
                                    <v-list-item-title>Excel 97 (xls)</v-list-item-title>
                                </v-list-item>
                                <v-list-item v-if="exportFormats.includes('xlsx')" @click="exportTable('xlsx')">
                                    <v-list-item-title>Excel 2007 (xlsx)</v-list-item-title>
                                </v-list-item>
                                <v-list-item v-if="exportFormats.includes('ods')" @click="exportTable('ods')">
                                    <v-list-item-title>OpenDocument Spreadsheet (ods)</v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                        <div class="text-center">
                            <v-chip v-show="filterActive" color="primary" text-color="white">
                                <v-icon color="white" left>
                                    $search
                                </v-icon>
                                {{ $lumui.i18n.t('lumui.table.filters_active') }}
                                <v-btn
                                    icon class="ml-2" color="white" style="margin-right: -12px;"
                                    @click="resetAndCloseFilter"
                                >
                                    <v-icon>$clear</v-icon>
                                </v-btn>
                            </v-chip>
                        </div>
                    </td>
                </tr>
            </template>
            <template #actions-append />
            <template #expanded-item="{ headers, item }">
                <td :colspan="headers.length">
                    <!--
                        rendered when the row is expanded
                     -->
                    <slot :item="item" name="expanded-item">
                        Hier Custom Content einfügen!
                    </slot>
                </td>
            </template>
        </v-data-table>
    </div>
</template>

<script>
import {
    VAppBarNavIcon,
    VBtn,
    VCard,
    VCardActions,
    VCardText,
    VCheckbox,
    VChip,
    VContainer,
    VDataTable,
    VDivider,
    VFlex,
    VForm,
    VIcon,
    VLayout,
    VList,
    VListItem,
    VListItemTitle,
    VMenu,
    VNavigationDrawer,
    VSpacer,
    VToolbar,
    VToolbarTitle,
    VTooltip
} from 'vuetify/lib';
import FormatterFactory from '../lib/formatter';
import {StorageService} from '../services';
import {saveAs} from 'file-saver';
import AsaTableFilter from "./AsaTableFilter.vue";
import AsaTableAction from "./AsaTableAction.vue";
import AsaTableColumn from "./AsaTableColumn.vue";
import AsaLoading from "./AsaLoading.vue";
import {
    filterDate,
    filterMaterializedPath,
    filterNestedSet,
    filterSelect,
    filterSelectMultiple,
    filterStatus,
    getFilterGeneric,
    getFilterLocalized
} from "../lib/filter";

export default {
    components: {
        AsaLoading,
        AsaTableColumn,
        AsaTableAction,
        AsaTableFilter,
        VSpacer,
        VNavigationDrawer,
        VToolbar,
        VIcon,
        VTooltip,
        VBtn,
        VFlex,
        VCard,
        VCardText,
        VDataTable,
        VChip,
        VLayout,
        VAppBarNavIcon,
        VToolbarTitle,
        VMenu,
        VList,
        VListItem,
        VListItemTitle,
        VDivider,
        VCardActions,
        VForm,
        VContainer,
        VCheckbox
    },
    props: {
        /**
         * Object containing the table data. Layout is roughly:
         * ```json
         * {
         *     columns: [],
         *     filters: [],
         *     rows: [],
         *     defaultSort: {
         *         sortBy: []
         *         sortDesc: []
         *     }
         * }
         * ```
         */
        data: {
            type: Object,
            required: true
        },
        /** data is being loaded */
        loading: {
            type: Boolean,
            default: false,
        },
        /** expand search filters */
        showFilter: {
            type: Boolean,
            default: false
        },
        /** disable sidebar */
        infoDisabled: {
            type: Boolean,
            default: false
        },
        /** disables table footer */
        hideFooter: {
            type: Boolean,
            default: false
        },
        /** Filename to be used when export is downloaded */
        exportName: {
            type: String,
            default: 'export'
        },
        exportFormats: {
            type: Array,
            default: () => ['pdf', 'csv', 'xls', 'xlsx', 'ods']
        },
        /** show row expansion handle */
        showExpand: {
            type: Boolean,
            default: false
        },
        /** show selectable */
        showSelect: {
            type: Boolean,
            default: false
        },
        selected: {
            type: Array,
            required: false,
            default: () => []
        },
        /** disable pagination */
        disablePagination: {
            type: Boolean,
            default: false
        },
        /**
         * identifier for storing grid state in local storage. Per default a
         * key is generated based on the current route.
         */
        storageKey: {
            type: String,
            default: null,
        },
        /** overrides $lumui.table.persistentFilters */
        filtersNonPersistent: {
            type: Boolean,
            default: null,
        },
        /** disables export buttons */
        hideExport: {
            type: Boolean,
            default: false
        },
        /** items per page options */
        itemsPerPageOptions: {
            type: Array,
            default: () => [5, 10, 20, 50, 100, 500, 1000]
        },
        /** initial rows per page option */
        rowsPerPageOption: {
          type: Number,
          default: () => 10
        }
    },
    data() {
        return {
            filterCollapsed: true,
            filters: [],
            columns: [],
            allRows: [],
            alert: true,
            selectedItems: [],
            infoVisible: false,
            currentItem: null,
            currentIndex: 0,
            start: 0,
            page: 1,
            defaultSort: {
                sortBy: [],
                sortDesc: []
            },
            filteredAndSortedItems: [],
            searchString: '',
            searchObject: [],
            searchTimeout: null
        }
    },
    computed: {
        localizedValue() {
            return FormatterFactory('Localized', {locale: this.locale}).format
        },
        isMobile() {
            return this.$vuetify.breakpoint.xsOnly
        },
        persistentFilters() {
            if (this.filtersNonPersistent != null) {
                return !this.filtersNonPersistent;
            }
            return this.$lumui.table.persistentFilters;
        },
        actionColumn() {
            return this.columns.filter(function (column) {
                return column.type === 'action'
            })[0];
        },
        actionColumnIndex() {
            return this.columns.findIndex(column => column.type === 'action')
        },
        locale() {
            return this.$store.getters['lumui/i18n/locale'];
        },
        mappedLocale() {
            return this.$store.getters['lumui/i18n/mappedLocale'];
        },
        gridColumn() {
            const filterLocalized = getFilterLocalized(this.localizedValue.bind(this));
            const filterGeneric = getFilterGeneric(this.localizedValue.bind(this));
            const columns = this.columns.map((column) => {
                let index;
                let filter;
                for (index = 0; index < this.filters.length; index++) {
                    if (this.filters[index].key === column.value) {
                        filter = this.filters[index];
                        break;
                    }
                }
                if (column.hidden) {
                    column.class += ' d-none';
                    column.sortable = false;
                }
                if (typeof column.actions !== 'undefined') {
                    column.type = 'action';
                }

                if (filter) {
                    if (filter.type === 'status') {
                        column.filter = (v) => filterStatus(v, filter.value);
                    } else if (filter.type === 'date') {
                        column.filter = (v) => filterDate(v, filter.value);
                    } else if (filter.type === 'localized') {
                        column.filter = (v) => filterLocalized(v, filter.value);
                    } else if (typeof filter.filter !== 'undefined' && typeof filter.filter.select !== 'undefined') {
                        if (filter.filter?.select?.multiple) {
                            column.filter = (v) => filterSelectMultiple(v, filter.value);
                        } else {
                            column.filter = (v) => filterSelect(v, filter.value);
                        }
                    } else if (typeof filter.filter != 'undefined' && typeof filter.filter.nestedset !== 'undefined') {
                        column.filter = (v) => filterNestedSet(v, filter.value);
                    } else if (typeof filter.filter != 'undefined' && typeof filter.filter.materialized_path !== 'undefined') {
                        column.filter = (v) => filterMaterializedPath(v, filter.value);
                    } else {
                        column.filter = (v) => filterGeneric(v, filter.value);
                    }
                }
                return column;
            });

            if (this.actionColumn && Object.keys(this.actionColumn.actions).length === 0) {
                columns.splice(this.actionColumnIndex, 1)
            }

            this.showExpand ? columns.push({text: '', value: 'data-table-expand'}) : null;

            return columns;
        },
        detailsColumn() {
            return this.columns.filter(function (column) {
                return column.details.hidden !== true
            });
        },
        exportCols: function () {
            //indices must match getExportData
            return this.columns
                .filter(col => !col.export.hidden && col.export.type !== 'action');
        },
        asideWidth() {
            let xs = this.$vuetify.breakpoint.thresholds.xs;
            return this.$vuetify.breakpoint.smAndDown ? this.$vuetify.breakpoint.width : xs
        },
        hasNextRow() {
            return this.currentIndex < this.filteredAndSortedItems.length - 1
        },
        hasPrevRow() {
            return this.currentIndex > 0
        },
        filterActive() {
            return this.filters.filter((filter) => {
                return !(filter.value === '' || filter.value === undefined || filter.value === null)
            }).length > 0;
        },
        sessionStorageKey() {
            if (this.storageKey) {
                return this.storageKey;
            } else {
                return this.$route.name ? this.$route.name : this.$route.path.replace(/\//gi, '_');
            }
        },
        footerProps() {
            return {
                'items-per-page-options': this.itemsPerPageOptions,
                'pageText': this.$t('lumui.table.page_text'),
                'itemsPerPageText': this.$t('lumui.table.rows_per_page')
            }
        },
    },
    watch: {
        data: {
            handler() {
                this.processData()
            },
            deep: true
        },
        filters: {
            handler() {
                const filterString = JSON.stringify(this.filters);
                if (this.persistentFilters) {
                    StorageService.set(this.sessionStorageKey, JSON.parse(filterString));
                }
                this.start = 0;
                this.$emit('filter', this.filters);
            },
            deep: true
        },
        selected() {
            this.selectedItems = this.selected
        },
        selectedItems() {
            this.onselect()
        }
    },
    destroyed() {
        if (this.persistentFilters) {
            StorageService.remove(this.sessionStorageKey);
        }
    },
    mounted() {
        this.processData();
        this.selectedItems = this.selected
    },
    methods: {
        /** @private */
        processData() {
            if (!this.data.columns) return;
            let cols = this.data.columns;
            if (!Array.isArray(cols)) {
                cols = Object.values(cols);
            }

            let filter = this.data.filter;
            if (!filter) {
                filter = [];
            } else if (!Array.isArray(filter)) {
                filter = Object.values(filter);
            }

            this.columns = this.processColumns(cols);
            let rows = this.data.rows || [];
            this.allRows = rows;
            this.filteredAndSortedItems = rows;
            let filterStorage;
            if (this.persistentFilters) {
                filterStorage = StorageService.get(this.sessionStorageKey);
                filterStorage = filterStorage === null ? [] : filterStorage;
            } else {
                filterStorage = [];
            }
            let filterData = JSON.parse(JSON.stringify(filter));
            Object.keys(filterData).forEach((key) => {
                if (Object.keys(filterData[key]).indexOf('searchable') === -1) {
                    filterData[key].searchable = true;
                }

                let filterStorageItem = filterStorage.find(element => element.key === filterData[key].key);
                let filtersItem = filterData[key];
                if (typeof filterStorageItem !== 'undefined' && filterStorageItem.value !== null) {
                    filterData[key].value = filterStorageItem.value
                } else if (typeof filtersItem !== 'undefined' && filtersItem.value !== null) {
                    filterData[key].value = filtersItem.value;
                } else {
                    filterData[key].value = '';
                }
            });
            this.filters = filterData;
            if (!(this.data.defaultSort === undefined)) {
                this.defaultSort.sortBy = !(this.data.defaultSort.sortBy === undefined) ? this.data.defaultSort.sortBy : [];
                this.defaultSort.sortDesc = !(this.data.defaultSort.sortDesc === undefined) ? this.data.defaultSort.sortDesc : [];
            }
            // Detailansicht nach Update aktualisieren
            this.updateCurrentItem()
        },
        /** @private */
        processColumns(columns) {
            if (!columns) {
                return [];
            }

            return columns.map((c) => {
                return {
                    text: c.label,
                    value: c.key,
                    align: c.align || 'left',
                    hidden: c.hidden === true,
                    sortable:
                        ((typeof c.sortable === 'undefined' && c.type !== 'action')
                            || (typeof c.sortable !== 'undefined' && c.sortable)),
                    type: c.type,
                    formatter: c.formatter,
                    actions: c.actions,
                    options: c.options || {},
                    details: c.details ?
                        {
                            text: c.details.label || c.label,
                            value: c.details.key || c.key,
                            align: c.details.align || c.align || 'left',
                            hidden: typeof c.details.hidden !== 'undefined' ?
                                c.details.hidden === true :
                                c.hidden === true,
                            type: c.details.type || c.type,
                            formatter: c.details.formatter || c.formatter,
                            actions: c.details.actions,
                            options: c.details.options || c.options || {},
                        } : {
                            text: c.label,
                            value: c.key,
                            align: c.align || 'left',
                            hidden: c.hidden === true || false,
                            type: c.type,
                            formatter: c.formatter,
                            actions: c.actions,
                            options: c.options || {},
                        },
                    export: c.export ?
                        {
                            text: c.export.label || c.label,
                            value: c.export.key || c.key,
                            align: c.export.align || c.align || 'left',
                            hidden: typeof c.export.hidden !== "undefined" ?
                                c.export.hidden === true :
                                c.hidden === true,
                            type: c.export.type || c.type,
                            formatter: c.export.formatter || c.formatter,
                            width: c.export.width || null,
                            height: c.export.height || null
                        } : {
                            text: c.label,
                            value: c.key,
                            align: c.align || 'left',
                            hidden: c.hidden === true || false,
                            type: c.type,
                            formatter: c.formatter,
                        }
                }
            });
        },
        /** @private */
        sortData(items, sortBy, isDescending) {
            if (sortBy.length === 0) {
                this.filteredAndSortedItems = items;
                /**
                 * Fired when table data is set or sorted.
                 * @param {Array} filteredAndSortedItems
                 */
                this.$emit('tableData', this.filteredAndSortedItems);
                return items;
            }
            if (sortBy.length > 1) {
                throw "Multi column ordering is not supported";
            }
            let colDefinition = this.gridColumn.filter((col) => {
                return col.value === sortBy[0]
            })[0];
            let sortFunc;
            let formatter;
            switch (colDefinition.type) {
            case 'localized':
                sortFunc = (a, b) => {
                    let aText = this.localizedValue(a[sortBy[0]]) || '';
                    let bText = this.localizedValue(b[sortBy[0]]) || '';
                    return aText < bText ? -1 : 1;
                };
                break;
            case 'materialized_path':
                formatter = FormatterFactory('MaterializedPath', {});
                sortFunc = (a, b) => {
                    const aLabel = formatter.format(a[sortBy[0]]);
                    const bLabel = formatter.format(b[sortBy[0]]);

                    return aLabel < bLabel ? -1 : 1;
                };
                break;
            case 'nestedset':
                formatter = FormatterFactory('NestedSet', {});
                sortFunc = (a, b) => {
                    const aLabel = formatter.format(a[sortBy[0]]);
                    const bLabel = formatter.format(b[sortBy[0]]);

                    return aLabel < bLabel ? -1 : 1;
                };
                break;
            default:
                sortFunc = (a, b) => {
                    return a[sortBy[0]] < b[sortBy[0]] ? -1 : 1;
                };
                break;
            }
            if (isDescending[0]) {
                this.filteredAndSortedItems = items.sort(sortFunc).reverse();
            } else {
                this.filteredAndSortedItems = items.sort(sortFunc);
            }
            this.$emit('tableData', this.filteredAndSortedItems);
            return this.filteredAndSortedItems;
        },
        /** Fire filter_closed event */
        closeFilter() {
            /** Fired when the filter area should close */
            this.$emit('filter_closed')
        },
        resetFilter() {
            this.filters.map((filter) => {
                filter.value = null;
            });
            /** Fired when the filter area resetted */
            this.$emit('filter_resetted')
        },
        /** clear filters and fire filter_closed event */
        resetAndCloseFilter() {
            this.resetFilter();
            this.closeFilter();
        },
        /** @private */
        rowClicked(props) {
            if (!this.infoDisabled) {
                this.currentItem = props.item;
                this.infoVisible = true;
                this.updateCurrentItem()
            } else if (this.infoDisabled && this.showExpand) {
                props.expand(!props.isExpanded)
            }
        },
        /** @private */
        updateCurrentItem() {
            if (this.infoVisible && Object.hasOwn(this.currentItem, 'id')) {
                let index = this.filteredAndSortedItems.findIndex((item) => item.id === this.currentItem.id);
                if (index >= 0) {
                    this.currentIndex = index;
                    this.currentItem = this.filteredAndSortedItems[index];
                    return
                }
            }
            if (this.infoVisible && !Object.hasOwn(this.currentItem, 'id')) {
                console.warn('sidebar wont expand because the row has no column "id"')
            }
            this.infoVisible = false
        },
        /** navigate to the next row */
        nextRow() {
            this.currentIndex = this.hasNextRow ? this.currentIndex + 1 : this.filteredAndSortedItems.length - 1;
            this.currentItem = this.filteredAndSortedItems[this.currentIndex];
            this.paginate()
        },
        /** navigate to the previous row */
        prevRow() {
            this.currentIndex = this.hasPrevRow ? this.currentIndex - 1 : 0;
            this.currentItem = this.filteredAndSortedItems[this.currentIndex];
            this.paginate()
        },
        /** @private */
        paginate() {
            if (this.rowsPerPageOption !== null) {
                this.page = Math.ceil((this.currentIndex + 1) / this.rowsPerPageOption)
            }
        },
        /** @private */
        handleScroll(event) {
            if (event.deltaY > 0) {
                this.start = Math.min(this.allRows.length - this.rowsPerPageOption, ++this.start);
            } else if (event.deltaY < 0) {
                this.start = Math.max(0, --this.start);
            }
        },
        /** @return {Array} Formatted representation of table data */
        getExportData: function () {
            const data = [];
            data.push(
                this.exportCols.map(col => {
                    return col.export.text
                })
            );

            const items = this.showSelect && !!this.selected.length ? this.selected : this.filteredAndSortedItems;
            items.forEach(row => {
                data.push(
                    this.exportCols.map(col => {
                        if (col.export.hidden === true) {
                            return null;
                        }
                        if (Object.keys(row).indexOf(col.value) >= 0) {
                            let value = row[col.value];
                            if (col.export.type) {
                                try {
                                    switch (col.export.type) {
                                    case 'bool':
                                        value = this.formatBool(value);
                                        break;
                                    case 'date':
                                        value = FormatterFactory('Date', {locale: this.locale}).format(value);
                                        break;
                                    case 'currency':
                                        value = FormatterFactory('Currency', {}).format(value);
                                        break;
                                    case 'localized':
                                        value = FormatterFactory('Localized', {locale: this.locale}).format(value);
                                        break;
                                    case 'select':
                                        value = FormatterFactory('Select', col.options).format(value);
                                        break;
                                    case 'checkbox':
                                        value = FormatterFactory('Checkbox', col.options).format(value);
                                        break;
                                    case 'image':
                                        value = FormatterFactory('Image', {}).format(value);
                                        break;
                                    case 'status':
                                        value = FormatterFactory('Status', {onlyText: true}).format(value);
                                        break;
                                    case 'statusdetail':
                                        value = FormatterFactory('Statusdetail', {}).format(value);
                                        break;
                                    case 'striptags':
                                        value = FormatterFactory('Striptags', {}).format(value);
                                        break;
                                    case 'nestedset':
                                        value = FormatterFactory('NestedSet', col.options).format(value);
                                        break;
                                    case 'materialized_path':
                                        value = FormatterFactory('MaterializedPath', col.options).format(value);
                                        break;
                                    case 'text':
                                        break;
                                    default:
                                        value = "unknown type: " + col.export.type;
                                        break;
                                    }
                                } catch (e) {
                                    console.error(value, e);
                                }
                            }

                            if (col.export.formatter) {
                                Object.keys(col.export.formatter).forEach((formatterName) => {
                                    try {
                                        col.export.formatter[formatterName];
                                        let formatter = FormatterFactory(formatterName, col.export.formatter[formatterName]);
                                        value = formatter.format(value, row);
                                    } catch (e) {
                                        console.error(e);
                                    }
                                })
                            }

                            return value ?? "";
                        } else {
                            return "";
                        }
                    })
                );
            });

            return data;
        },
        /**
         * Download table as spreadsheet file
         * @param {String} type csv, xls, xlsx or ods
         */
        exportSpreadsheet: async function (type) {
            const xlsx = await import (/* webpackChunkName: "XLSX" */ 'xlsx');
            const workbook = xlsx.utils.book_new();

            let data = this.getExportData().map((row, rdx) => {
                return row.map((cell, idx) => {
                    const col = this.exportCols[idx];
                    // Only pro version supports images [https://sheetjs.com/pro]
                    return rdx > 0 && typeof col !== 'undefined' && col.type === 'image'
                        ? ''
                        : cell;
                });
            });

            if (type === 'csv') {
                data = data.map(row => {
                    return row.map(cell => {
                        return typeof cell === 'string'
                            ? cell.replace(/^([+\-@=])/, '\'$1')
                            : cell;
                    });
                });
            }
            const ws = xlsx.utils.aoa_to_sheet(data);
            xlsx.utils.book_append_sheet(workbook, ws, 'type');

            const wopts = {bookType: type, bookSST: false, type: 'array'};

            const wbout = xlsx.write(workbook, wopts);

            saveAs(new Blob([wbout], {type: "application/octet-stream"}), this.exportName + '.' + type);
        },
        /** Download table data as PDF */
        async exportPdf() {
            const jspdf = await import ('jspdf');
            await import ('jspdf-autotable');
            const pdf = jspdf.default;
            const data = this.getExportData();
            const doc = new pdf({
                orientation: 'l'
            });

            const pdfData = data.map((row, rdx) => {
                return row.map((col, idx) => {
                    let c = this.exportCols[idx];
                    let style = {};
                    if (c.export && c.export.minWidth) {
                        style['minCellWidth'] = c.export.minWidth;
                    }
                    if (c.export && c.export.width) {
                        style['cellWidth'] = c.export.width;
                    }
                    let cellData = null;
                    let cellContent = col;
                    if (rdx > 0 && c.type === 'image' && col !== null) {
                        style['minCellHeight'] = c.export.height || 40;
                        const indexes = [...col.matchAll(new RegExp('data:image', 'gi'))].map(a => a.index);
                        indexes.forEach(start => {
                            const end = col.indexOf('"', start);
                            if (end >= 0) {
                                if (cellData === null) cellData = [];
                                cellData.push(col.substring(start, end));
                                cellContent = ''
                            }
                        })
                    }

                    return {
                        rowSpan: 1,
                        colSpan: 1,
                        styles: style,
                        content: cellContent,
                        type: c.type,
                        data: cellData,
                        height: c.export.height
                    };
                })
            });

            const header = pdfData.shift();
            doc.autoTable({
                headStyles: {fillColor: this.$vuetify.theme.currentTheme.primary},
                head: [header],
                styles: {
                    fontSize: 9
                },
                body: pdfData,
                didDrawCell(data) {
                    if (data.cell.section === 'body' && data.cell.raw.type === 'image' && data.cell.raw.data !== null) {
                        const y = (data.cell.y + data.cell.padding('vertical') / 2);
                        const height = data.cell.raw.height ? (data.cell.raw.height - data.cell.padding('vertical')) : (data.cell.height - data.cell.padding('vertical'));
                        let lastWidth = 0;
                        data.cell.raw.data.forEach(img => {
                            const image = new Image();
                            image.src = img;
                            image.decode();
                            const ratio = image.width / image.height;
                            const width = isNaN(ratio) ? height : (height * ratio);
                            const x = (data.cell.x + lastWidth);
                            doc.addImage(img, x, y, width, height);
                            lastWidth = width + 1
                        })
                    }
                }
            });

            doc.save(this.exportName + '.pdf');
        },
        /**
         * Download table data as given type
         * @param {string} type csv, xls, xlsx, ods or pdf
         */
        async exportTable(type) {
            switch (type) {
            case 'csv':
            case 'xls':
            case 'xlsx':
            case 'ods':
                await this.exportSpreadsheet(type);
                break;
            case 'pdf':
                await this.exportPdf(type);
                break;
            }

        },
        /** @private */
        formatBool(value) {
            return value ? this.$lumui.i18n.t('lumui.formatter.boolean.true') : this.$lumui.i18n.t('lumui.formatter.boolean.false');
        },
        /** @private */
        handleAction(payload) {
            /**
             * Fired when a row action is triggered that has a route with an `action` field.
             * @param {Object} payload the route of the action
             */
            this.$emit('rowaction', payload)
        },
        /** @private */
        onselect() {
            this.$emit('select', this.selectedItems)
        },
        /**
         * @public
         * @param key the key of the filter
         * @param value the new value
         * dynamically sets a filter value
         */
        setFilter(key, value) {
            const idx = this.filters.findIndex(x => x.key === key);
            this.$set(this.filters[idx], 'value', value);
            if (this.persistentFilters) {
                let filterString = JSON.stringify(this.filters);
                StorageService.set(this.sessionStorageKey, JSON.parse(filterString));
            }
            this.processData();
        },
        /**
         * @public
         * @param key the key of the filter
         * dynamically clears a filter value
         */
        clearFilter(key) {
            const idx = this.filters.findIndex(x => x.key === key);
            this.$delete(this.filters[idx], 'value');
            if (this.persistentFilters) {
                let filterString = JSON.stringify(this.filters);
                StorageService.set(this.sessionStorageKey, JSON.parse(filterString));
            }
            this.processData();
        }
    }
}
</script>

<style>
.clickable {
    cursor: pointer;
}

.table-container {
    overflow: auto;
}

.v-datatable__actions > div:first-child {
    display: flex;
    justify-content: flex-end;
    flex-grow: 1;
}

html {
    overflow-y: auto;
}

.v-data-table tbody tr:nth-of-type(odd) {
    background-color: #F5F5F5;
}

/* align sort icon to the right without forcing line break */
.v-data-table .v-data-table-header th.sortable {
    position: relative;
    padding-right: 20px;
}

.v-data-table .v-data-table-header th.sortable .v-data-table-header__icon {
    position: absolute;
    right: 0;
}
</style>

<style scoped>
tr.export {
    background-color: transparent !important;
}

tr.export td {
    padding-left: 8px !important;
    padding-right: 8px !important;
    position: relative;
}

tr.export .export-menu {
    position: absolute;
    top: 6px;
    left: 10px;
}
</style>
