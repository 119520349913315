import Vue from "vue";
import DOMPurifiy from 'dompurify'

export default class LocalizedFormatter {
    constructor(settings)
    {
        this.locale = settings.locale;
        this.isHtmlRequired = settings.isHtmlRequired && typeof settings.isHtmlRequired == "boolean" ? settings.isHtmlRequired : false
    }

    requiresHTML() {
        return this.isHtmlRequired;
    }

    format(value) {
        if(!value) {
            return "";
        }
        const $lumui = Vue.prototype.$lumui;
        const localeKey = $lumui.mapLocale(this.locale);
        const fallback = $lumui.mapLocale($lumui.i18n.fallbackLocale);
        if(typeof value === 'object' && Object.keys(value).includes(localeKey)) {
            return this.isHtmlRequired ? DOMPurifiy.sanitize(value[localeKey]) : value[localeKey];
        } else if (typeof value === 'object' && Object.keys(value).includes(fallback)) {
            return this.isHtmlRequired ? DOMPurifiy.sanitize(value[fallback]) : value[fallback]
        } else if (typeof value === 'string') {
            console.warn("Using localized formatter on string")
            return this.isHtmlRequired ? DOMPurifiy.sanitize(value) : value;
        } else {
            console.warn(`Neither locale ${localeKey} nor ${fallback} are present in localized value`)
            return this.isHtmlRequired ? DOMPurifiy.sanitize(JSON.stringify(value)) : JSON.stringify(value);
        }
    }
}
