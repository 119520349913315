import {merge} from "webpack-merge";
import et from './base/et.js';

export default merge(et, {
    "lumui.confirm.accept": "OK",
    "lumui.confirm.reject": "Katkesta",
    "lumui.formatter.boolean.true": "Jah",
    "lumui.formatter.boolean.false": "Ei",
    "lumui.form.close": "Sulge",
    "lumui.form.cancel": "Katkesta",
    "lumui.form.save": "Salvesta",
    "lumui.lightbox.title": "Olemasolevad pildid",
    "lumui.loading.message": "Andmete laadimine",
    "lumui.qr_scanner.camera": "Camera",
    "lumui.qr_scanner.mirror": "Mirror camera ",
    "lumui.qr_scanner.torch": "Camera light",
    "lumui.table.reset_filters": "lähtesta",
    "lumui.table.close_filters": "Filtri kokkuklappimine",
    "lumui.table.details": "Detailvaade",
    "lumui.table.previous": "Eelmine andmekogum",
    "lumui.table.next": "Järgmine andmekogum",
    "lumui.table.no_data": "Andmeid ei leitud..",
    "lumui.table.no_filter_results": "Teie otsing ei andnud tulemusi",
    "lumui.table.filters_active": "Tulemused filtreeritud",
    "lumui.table.rows_per_page": "Rida leheküljel",
    "lumui.table.btn.export": "Loetelu eksportimine",
    "lumui.table.page_text": "{0}-{1} of {2}",
    "lumui.table_action.menu": "Menüü",
    "lumui.table_columns.save": "Faili salvestamine",
    "lumui.form.row.invalid_email": "Palun sisestage õige e-posti aadress.",
    "lumui.form.row.required": "See on kohustuslik väli",
    "lumui.form.autocomplete.selected_count": "{c} items selected",
    "lumui.form.autocomplete.select_all": "Vali kõik",
    "lumui.form.autocomplete.deselect_all": "Ära vali midagi",
    "lumui.form.autocomplete.search_hint": "Palun sisestage otsisõna",
    "lumui.form.autocomplete.no_data": "Andmed puuduvad",
    "lumui.form.autocomplete.nothing_selected": "Ühtegi kirjet pole valitud",
    "lumui.form.autocomplete.selected_items": "Valitud kirjed",
    "lumui.form.date.close": "Sulge",
    "lumui.form.date.save": "OK",
    "lumui.form.file.drag": "Drag file here or click to select",
    "lumui.form.file.error": "Upload failed.",
    "lumui.form.file.extensions.accepted": "Allowed filetypes: {types}",
    "lumui.form.file.extensions.all": "All filetypes allowed",
    "lumui.form.file.maxSize": "Maximum allowed file size {size}.",
    "lumui.form.file.maxSizeError": "The file is larger than the maximum allowed size of {size}.",
    "lumui.form.file.previewError": "No preview for file {name} available.",
    "lumui.form.file.uploading": "{count} files are being uploaded…",
    "lumui.form.file.warning.removed_file": "File {name} was not uploaded. Filetype {type} is not within permitted types {acceptedTypes}.",
    "lumui.form.file.select_file": "Select a file",
    "lumui.form.file.unknown_size": "not specified",
    "lumui.form.localized.copy_to_all": "Sisestuse ülevõtmine kõikide keelte jaoks",
    "lumui.form.ordered_choice.no_data": "Kirjed puuduvad",
    "lumui.form.ordered_choice.select_placeholder": "uue kirje valimine",
    "lumui.form.password.repeat": "Parooli kordamine",
    "lumui.form.password.not_matching_error": "Mõlemad paroolid peavad kattuma.",
    "lumui.form.signature.clear": "Delete signature",
    "lumui.form.signature.upload": "Upload signature",
    "lumui.form.time.close": "Sulge",
    "lumui.form.time.save": "OK",
    "lumui.locale.lang.german": "German",
    "lumui.locale.lang.english": "English",
    "lumui.locale.lang.italian": "Italian",
    "lumui.locale.lang.french": "French",
    "lumui.status.no_action_required": "ükski toiming pole nõutav",
    "lumui.status.hint": "Juhis",
    "lumui.status.action_required_soon": "Nõutud peatsed toimingud!",
    "lumui.status.action_required_immediately": "nõutav kohene toiming!",
    "lumui.locale.de-DE": "German",
    "lumui.locale.de-CH": "German (Suisse)",
    "lumui.locale.fr-CH": "French (Suisse)",
    "lumui.locale.it-CH": "Italian (Suisse)",
    "lumui.locale.en-US": "English (USA)",
    "lumui.locale.en-GB": "English (GB)",
    "lumui.locale.fi-FI": "Finnish",
    "lumui.locale.et-EE": "Estonian",
    "lumui.locale.lt-LT": "Lithuanian",
    "lumui.locale.lv-LV": "Latvian",
    "lumui.locale.lang.finnish": "Finnish",
    "lumui.locale.lang.estonian": "Estonian",
    "lumui.locale.lang.lithuanian": "Lithuanian",
    "lumui.locale.lang.latvian": "Latvian",
    "lumui.locale.lang.hungarian": "Hungarian",
});
