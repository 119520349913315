(function($R)
{
    $R.lang['it'] = {
        "format": "Formato",
        "image": "Immagine",
        "file": "File",
        "link": "Link",
        "bold": "Grassetto",
        "italic": "Corsivo",
        "deleted": "Barrato",
        "underline": "Sottolineato",
        "superscript": "Superscript",
        "subscript": "Subscript",
        "bold-abbr": "G",
        "italic-abbr": "C",
        "deleted-abbr": "B",
        "underline-abbr": "S",
        "superscript-abbr": "Sup",
        "subscript-abbr": "Sub",
        "lists": "Liste",
        "link-insert": "Inserisci link",
        "link-edit": "Modifica link",
        "link-in-new-tab": "Apri link in un nuovo tab",
        "unlink": "Elimina link",
        "cancel": "Annulla",
        "close": "Chiudi",
        "insert": "Inserisci",
        "save": "Salva",
        "delete": "Cancella",
        "text": "Testo",
        "edit": "Modifica",
        "title": "Titolo",
        "paragraph": "Testo Normale",
        "quote": "Citazione",
        "code": "Codice",
        "heading1": "Titolo 1",
        "heading2": "Titolo 2",
        "heading3": "Titolo 3",
        "heading4": "Titolo 4",
        "heading5": "Titolo 5",
        "heading6": "Titolo 6",
        "filename": "Nome",
        "optional": "opzionale",
        "unorderedlist": "Lista non ordinata",
        "orderedlist": "Lista ordinata",
        "outdent": "De-indenta",
        "indent": "Indenta",
        "horizontalrule": "Linea",
        "upload": "Upload",
        "upload-label": "Drop files here or click to upload",
        "upload-change-label": "Drop a new image to change",
        "accessibility-help-label": "Editor di testo",
        "caption": "Caption",
        "bulletslist": "Bullets",
        "numberslist": "Numbers",
        "image-position": "Position",
        "none": "None",
        "left": "Left",
        "right": "Right",
        "center": "Center",
        "undo": "Undo",
        "redo": "Redo"
    };
})(Redactor);