var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-icon", {
    attrs: { small: "" },
    domProps: { textContent: _vm._s(_vm.icon) },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }