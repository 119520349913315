var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    _vm._b(
      {
        ref: "d",
        attrs: {
          fullscreen: _vm.breakpoint,
          value: true,
          "retain-focus": false,
        },
      },
      "v-dialog",
      _vm.defaultAttrs,
      false
    ),
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }