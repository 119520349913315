var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm._l(_vm.images, function (image, i) {
        return [
          image.src && image.src !== null
            ? _c(
                "v-img",
                {
                  key: i,
                  staticClass: "grey lighten-2 clickable",
                  attrs: { src: image.src, "aspect-ratio": "1" },
                  on: {
                    click: function ($event) {
                      return _vm.showLightbox(i)
                    },
                  },
                },
                [
                  _c(
                    "v-layout",
                    {
                      attrs: {
                        slot: "placeholder",
                        "align-center": "",
                        "fill-height": "",
                        "justify-center": "",
                        "ma-0": "",
                      },
                      slot: "placeholder",
                    },
                    [
                      _c("v-progress-circular", {
                        attrs: { color: "grey lighten-5", indeterminate: "" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _c(
                "div",
                { key: i, staticClass: "grey lighten-4 pa-4 text-center" },
                [
                  _c("v-icon", [_vm._v("$error")]),
                  _vm._v(" "),
                  _c("small", { staticClass: "d-block mt-2" }, [
                    _vm._v(_vm._s(image.name)),
                  ]),
                ],
                1
              ),
        ]
      }),
      _vm._v(" "),
      _vm.images && _vm.images.length > 0
        ? _c("asa-lightbox", {
            attrs: {
              images: _vm.images,
              index: _vm.lightbox.index,
              show: _vm.lightbox.show,
            },
            on: { close: _vm.closeLightbox },
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }