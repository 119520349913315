export default {
    "$vuetify.badge": "Jelvény",
    "$vuetify.close": "Bezárás",
    "$vuetify.dataIterator.noResultsText": "Nincs egyező találat",
    "$vuetify.dataIterator.loadingText": "Betöltés...",
    "$vuetify.dataTable.itemsPerPageText": "Elem oldalanként:",
    "$vuetify.dataTable.ariaLabel.sortDescending": "Csökkenő sorrendbe rendezve.",
    "$vuetify.dataTable.ariaLabel.sortAscending": "Növekvő sorrendbe rendezve.",
    "$vuetify.dataTable.ariaLabel.sortNone": "Rendezetlen.",
    "$vuetify.dataTable.ariaLabel.activateNone": "Rendezés törlése.",
    "$vuetify.dataTable.ariaLabel.activateDescending": "Aktiváld a csökkenő rendezésért.",
    "$vuetify.dataTable.ariaLabel.activateAscending": "Aktiváld a növekvő rendezésért.",
    "$vuetify.dataTable.sortBy": "Rendezés",
    "$vuetify.dataFooter.itemsPerPageText": "Elem oldalanként:",
    "$vuetify.dataFooter.itemsPerPageAll": "Mind",
    "$vuetify.dataFooter.nextPage": "Következő oldal",
    "$vuetify.dataFooter.prevPage": "Előző oldal",
    "$vuetify.dataFooter.firstPage": "Első oldal",
    "$vuetify.dataFooter.lastPage": "Utolsó oldal",
    "$vuetify.dataFooter.pageText": "{0}-{1} / {2}",
    "$vuetify.datePicker.itemsSelected": "{0} kiválasztva",
    "$vuetify.datePicker.nextMonthAriaLabel": "Következő hónap",
    "$vuetify.datePicker.nextYearAriaLabel": "Következő év",
    "$vuetify.datePicker.prevMonthAriaLabel": "Előző hónap",
    "$vuetify.datePicker.prevYearAriaLabel": "Előző év",
    "$vuetify.noDataText": "Nincs elérhető adat",
    "$vuetify.carousel.prev": "Előző",
    "$vuetify.carousel.next": "Következő",
    "$vuetify.carousel.ariaLabel.delimiter": "Dia {0}/{1}",
    "$vuetify.calendar.moreEvents": "{0} további",
    "$vuetify.input.clear": "Clear {0}",
    "$vuetify.input.prependAction": "{0} prepended action",
    "$vuetify.input.appendAction": "{0} appended action",
    "$vuetify.fileInput.counter": "{0} fájl",
    "$vuetify.fileInput.counterSize": "{0} fájl ({1} összesen)",
    "$vuetify.timePicker.am": "de",
    "$vuetify.timePicker.pm": "du",
    "$vuetify.pagination.ariaLabel.wrapper": "Oldal navigáció",
    "$vuetify.pagination.ariaLabel.next": "Következő oldal",
    "$vuetify.pagination.ariaLabel.previous": "Előző oldal",
    "$vuetify.pagination.ariaLabel.page": "Menj a(z) {0}. oldalra",
    "$vuetify.pagination.ariaLabel.currentPage": "Aktuális oldal: {0}",
    "$vuetify.rating.ariaLabel.icon": "Rating {0} of {1}",
    "$vuetify.loading": "Loading..."
};