<template>
    <v-tabs v-if="hasTabs" v-model="active" :background-color="backgroundColor" :show-arrows="true">
        <v-tabs-slider color="teal" />
        <!-- @private -->
        <v-tab
            v-for="(tab, index) in tabs"
            :key="index"
            :href="'#tab-' + index"
            ripple
            @change="$emit('tab', 'tab-' + index)"
        >
            {{ tab.label }}
            <v-icon v-show="Object.hasOwn(tab, 'error') && tab.error" class="ml-1" small>
                $warning
            </v-icon>
        </v-tab>
    </v-tabs>
</template>

<script>
import {VIcon, VTab, VTabs, VTabsSlider} from 'vuetify/lib';

/**
 * renders the tab headers of children with type "tab"
 * If no tabs exists, nothing is rendered.
 *
 * ## config
 *
 * an object whose elements are element configs
 *
 * @example
 * ```json
 * {
 *   tab1: {
 *     type: "tab",
 *     label: "Tab A",
 *     children: {
 *       a1: {type: "text", label: "Vorname", required: true},
 *       a2: {type: "text", label: "Nachname", required: true},
 *       a3: {type: "email", label: "E-Mail"},
 *       a4: {type: "textarea", label: "Adresse"},
 *     }
 *   },
 *   tab2: {
 *     type: "tab",
 *     label: "Tab B",
 *     children: {
 *       b0: {type: "text", label: "Error", errors: ['error from config']},
 *       b1: {type: "textarea", label: "Anmerkungen", required: true},
 *     }
 *   },
 * }
 * ```
 */
export default {
    name: "AsaFormTabs",
    components: {
        VTabs, VTabsSlider, VTab, VIcon
    },
    props: {
        value: {
            type: Object,
            required: true,
        },
        /** element config */
        config: {
            type: Object,
            required: true
        },
        /** id of the active tab */
        activeTab: {
            type: String,
            required: true
        },
        /** unused */
        color: {
            type: String,
            required: false,
            default: "white"
        },
        /** background color for the tab header */
        backgroundColor: {
            type: String,
            required: false,
            default: 'primary'
        }
    },
    data() {
        return {
            active: true,
            valid: false
        }
    },
    computed: {
        tabs() {
            let res = {};
            for (let i in this.config) {
                if (!this.config.hasOwnProperty(i)) {
                    continue;
                }
                let element = this.config[i];
                if (element.hasOwnProperty('type') && element.type === 'tab') {
                    res[i] = element;
                }
            }
            return res;
        },
        hasTabs() {
            if (!this.config) {
                return false;
            }
            for (const key in this.config) {
                if (!this.config.hasOwnProperty(key)) {
                    continue;
                }
                const entry = this.config[key];
                if (entry.hasOwnProperty('type') && entry.type === 'tab') {
                    return true;
                }
            }
            return false;
        }
    },
    watch: {
        activeTab(val) {
            this.active = val;
        },
    },
    mounted() {
        if (this.hasTabs) {
            if (this.activeTab === '') {
                for (const key in this.tabs) {
                    this.active = key;
                    const activeTab = 'tab-' + key;
                    this.$emit('tab', activeTab);
                    break;
                }
            } else {
                this.active = this.activeTab;
            }
        }
    }
}
</script>
