import { locale as setMomentLocale } from "moment";
export const STORE_NAMESPACE = 'lumui/i18n';
export function createStore(loaders, i18n, defaultLocale, fallbackLocale, mapLocale) {
    const loaded = new Set();
    return {
        namespaced: true,
        state() {
            return {
                loaders,
                loaded: loaded,
                locale: defaultLocale,
                fallbackLocale: fallbackLocale
            };
        },
        getters: {
            fallbackLocale: (state) => state.fallbackLocale,
            locales: (state) => Object.keys(state.loaders),
            locale: (state) => state.locale,
            mappedLocale: (state) => mapLocale(state.locale),
            mappedLocales: (state, getters) => getters.locales.map(mapLocale),
            isLoaded: (state) => (locale) => state.loaded.has(locale)
        },
        mutations: {
            setLoader(state, locale, loader) {
                state.loaders[locale] = loader;
            },
            /** @private */
            _setLoaded(state, locale) {
                if (!(locale in state.loaders)) {
                    throw Error("invalid locale " + locale + "");
                }
                state.loaded.add(locale);
            },
            /** @private */
            _setLocale(state, locale) {
                console.log("setting locale to " + locale);
                state.locale = locale;
                i18n.locale = locale;
                setMomentLocale(locale);
                window.document.documentElement.lang = locale;
            },
            setFallbackLocale(state, fallback) {
                if (!(fallback in state.loaders)) {
                    throw Error("invalid fallback locale " + fallback + "");
                }
                i18n.fallbackLocale = fallback;
                state.fallbackLocale = fallback;
            }
        },
        actions: {
            /** @private */
            _loadLocale: async function ({ commit }, locale) {
                const loader = loaders[locale];
                let messages;
                if (typeof loader !== 'function') {
                    console.debug('loading locale ' + locale);
                    messages = loader;
                }
                else {
                    console.debug('loading async locale ' + locale);
                    messages = await loader();
                }
                if (!(locale in i18n.messages)) {
                    const parts = locale.split('-');
                    while (parts.length > 0) {
                        const base = parts.join('-');
                        if (base in i18n.messages) {
                            messages = Object.assign({}, i18n.messages[base], messages);
                            break;
                        }
                        parts.pop();
                    }
                    i18n.setLocaleMessage(locale, messages);
                }
                else {
                    i18n.mergeLocaleMessage(locale, messages);
                }
                commit('_setLoaded', locale);
            },
            setLocale: async function ({ commit, dispatch, getters }, locale) {
                if (!getters.locales.includes(locale)) {
                    console.error("[lumui] trying to set locale to " + locale + " which is not available. Available locales: " + Object.keys(loaders));
                    return;
                }
                if (!getters.isLoaded(locale)) {
                    await dispatch("_loadLocale", locale);
                }
                i18n.locale = locale;
                setMomentLocale(locale);
                commit("_setLocale", locale);
            }
        }
    };
}
