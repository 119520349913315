<template>
    <span>
        <asa-formatter
            v-if="column.type !== 'file'" :formatter="column.formatter" :options="column.options"
            :type="column.type" :value="value" :context="row"
        />
        <template v-else-if="file !== null">
            <v-tooltip top>
                <template #activator="{ on }">
                    <v-img
                        v-if="isImage(file) && file.hasOwnProperty('src')"
                        :src="base64(file)"
                        aspect-ratio="1.7"
                        class="clickable"
                        v-on="on"
                        @click="download(file)"
                    />
                    <a
                        v-else-if="isFile(file) && file.hasOwnProperty('src')"
                        href="#"
                        v-on="on"
                        @click.prevent="download(file)"
                    >
                        {{ file.originalName }}
                    </a>
                    <a
                        v-else
                        :href="file"
                        target="_blank"
                        v-on="on"
                    >
                        {{ file }}
                    </a>
                </template>
                <span>{{ $lumui.i18n.t('lumui.table_columns.save') }}</span>
            </v-tooltip>
        </template>
    </span>
</template>

<script>
import {VImg, VTooltip} from 'vuetify/lib';
import {saveAs} from 'file-saver';
import AsaFormatter from "./AsaFormatter.vue";

/**
 * used to render AsaTable columns
 * @internal
 * @private
 */
export default {
    components: {
        AsaFormatter,
        VTooltip,
        VImg
    },
    props: {
        /**
         * column definition
         * @type {Object}
         */
        column: {
            type: Object,
            required: true
        },
        /**
         * columns value
         * Null must be a valid value.
         */
        value: {
            type: [String, Number, Object, Array, Boolean],
            default: null,
            validator: (v) => {
                return ['string', 'number', 'object', 'array', 'boolean'].includes(typeof v) || v === null;
            }
        },
        /**
         * current row
         * @type {Object}
         */
        row: {
            type: Object,
            required: false,
            default: () => { return {} }
        }
    },
    data() {
        return {
            file: null
        }
    },

    watch: {
        value() {
            if (this.column.type === 'file') {
                this.getFile()
            }
        }
    },
    mounted() {
        if (this.column.type === 'file') {
            this.getFile()
        }
    },

    methods: {
        /** @private */
        async getFile() {
            this.file = null;
            if (Object.hasOwn(this.value, 'id')) {
                let value = parseInt(this.value.id);
                if (!isNaN(value) && value > 0) {
                    if (this.$store.getters.currentNodeType === 'smartfolder') {
                        this.file = await (this.$call('api.core.asset.lookup.getSmartfolderFileData', {
                            id: value,
                            path: this.$store.getters.currentNodePath
                        }))
                    } else {
                        this.file = await (this.$call('api.core.asset.lookup.getFileData', {id: value}))
                    }
                    this.value.id = 0
                }
            } else if (Object.hasOwn(this.value, 'url')) {
                this.file = this.value.url
            }
        },
        /** @private */
        isImage(file) {
            return Object.hasOwn(file, 'mimetype') && file.mimetype.indexOf('image') >= 0
        },
        /** @private */
        isFile(file) {
            return Object.hasOwn(file, 'mimetype') && file.mimetype.indexOf('image') < 0
        },
        /** @private */
        base64(file) {
            if (file.src === null) {
                return '';
            }
            return file.src.indexOf(';base64,') > 0 ? file.src : `data:${file.mimetype};base64,${file.src}`
        },
        /** @private */
        download(file) {
            saveAs(this.dataURItoBlob(file.src), file.originalName)
        },
        /** @private */
        dataURItoBlob(dataURI, mimeString) {
            let byteString = atob(dataURI.split(',')[1]);
            if (typeof mimeString === 'undefined') {
                mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]
            }
            let ab = new ArrayBuffer(byteString.length);
            let ia = new Uint8Array(ab);
            for (let i = 0; i < byteString.length; i++) {
                ia[i] = byteString.charCodeAt(i);
            }
            return new Blob([ab], {type: mimeString});
        }
    }
}
</script>
