<template>
    <div>
        <asa-form v-model="value" :config="config" />
    </div>
</template>

<script>
import AsaForm from "../../../src/component/AsaForm";

export default {
    name: "Checkbox",
    components: {AsaForm},
    data() {
        return {
            value: {},
            config: {
                "addons": {
                    "type": "checkbox",
                    "label": "customer.order.addons",
                    "multiOptions": [{
                        "value": "57d5af4b-10c7-4f7c-b0ac-ded5f8ef550b",
                        "label": {'de': "Salat", 'en': 'Salad'}
                    }, {
                        "value": "cdd17247-6baf-4f6d-ba4e-0dd311fa3227",
                        "label": {'de': "Nachtisch", 'en': "Dessert"}
                    }],
                    "multiple": true,
                    "value": ["57d5af4b-10c7-4f7c-b0ac-ded5f8ef550b"]
                },
                "extras": {
                    "type": "checkbox",
                    "label": "customer.order.extras",
                    "multiple": true,
                    "multiOptions": [{"value": "7b6c45f8-526f-4fc7-a7d8-fdb3cb8a4927", "label": "Diabetes Typ 1a"}],
                    "value": []
                }
            }
        };
    },
    methods: {}
}
</script>

