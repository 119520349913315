export default {
    "$vuetify.badge": "Badge",
    "$vuetify.close": "Close",
    "$vuetify.dataIterator.noResultsText": "No matching records found",
    "$vuetify.dataIterator.loadingText": "Loading items...",
    "$vuetify.dataTable.itemsPerPageText": "Rows per page:",
    "$vuetify.dataTable.ariaLabel.sortDescending": "Sorted descending.",
    "$vuetify.dataTable.ariaLabel.sortAscending": "Sorted ascending.",
    "$vuetify.dataTable.ariaLabel.sortNone": "Not sorted.",
    "$vuetify.dataTable.ariaLabel.activateNone": "Activate to remove sorting.",
    "$vuetify.dataTable.ariaLabel.activateDescending": "Activate to sort descending.",
    "$vuetify.dataTable.ariaLabel.activateAscending": "Activate to sort ascending.",
    "$vuetify.dataTable.sortBy": "Sort by",
    "$vuetify.dataFooter.itemsPerPageText": "Items per page:",
    "$vuetify.dataFooter.itemsPerPageAll": "All",
    "$vuetify.dataFooter.nextPage": "Next page",
    "$vuetify.dataFooter.prevPage": "Previous page",
    "$vuetify.dataFooter.firstPage": "First page",
    "$vuetify.dataFooter.lastPage": "Last page",
    "$vuetify.dataFooter.pageText": "{0}-{1} of {2}",
    "$vuetify.datePicker.itemsSelected": "{0} selected",
    "$vuetify.datePicker.nextMonthAriaLabel": "Next month",
    "$vuetify.datePicker.nextYearAriaLabel": "Next year",
    "$vuetify.datePicker.prevMonthAriaLabel": "Previous month",
    "$vuetify.datePicker.prevYearAriaLabel": "Previous year",
    "$vuetify.noDataText": "No data available",
    "$vuetify.carousel.prev": "Previous visual",
    "$vuetify.carousel.next": "Next visual",
    "$vuetify.carousel.ariaLabel.delimiter": "Carousel slide {0} of {1}",
    "$vuetify.calendar.moreEvents": "{0} more",
    "$vuetify.input.clear": "Clear {0}",
    "$vuetify.input.prependAction": "{0} prepended action",
    "$vuetify.input.appendAction": "{0} appended action",
    "$vuetify.fileInput.counter": "{0} files",
    "$vuetify.fileInput.counterSize": "{0} files ({1} in total)",
    "$vuetify.timePicker.am": "AM",
    "$vuetify.timePicker.pm": "PM",
    "$vuetify.pagination.ariaLabel.wrapper": "Pagination Navigation",
    "$vuetify.pagination.ariaLabel.next": "Next page",
    "$vuetify.pagination.ariaLabel.previous": "Previous page",
    "$vuetify.pagination.ariaLabel.page": "Goto Page {0}",
    "$vuetify.pagination.ariaLabel.currentPage": "Current Page, Page {0}",
    "$vuetify.rating.ariaLabel.icon": "Rating {0} of {1}",
    "$vuetify.loading": "Loading..."
};