<template>
    <div>
        <asa-form v-model="value" :config="config" :abort-btn="false">
            <template v-slot:title>
                Unterschrift
            </template>
        </asa-form>
        <pre>{{ value }}</pre>
        <img style="border: 1px solid black; min-width: 50px; min-height: 50px" :src="value.sig" />
    </div>
</template>

<script>
import AsaForm from "../../../src/component/AsaForm";

export default {
    name: "signature",
    components: {AsaForm},
    data() {
        return {
            value: {},
            config: JSON.parse('{"sig":{"type":"signatur","label":"Unterschrift","value":"","required":true}}')
        };
    }
}
</script>

