export default class RadioFormatter {
    constructor(options)
    {
        this.map = options;
    }

    requiresHTML() {
        return false;
    }

    format(value) {
        let label = '';
        if(!Array.isArray(this.map)) {
            return label;
        }
        this.map.forEach((option) => {
            if(option.value === value){
                label = option.text
            }
        });
        return label;
    }
}
