import {merge} from "webpack-merge";
import lv from './base/lv.js';

export default merge(lv, {
    "lumui.confirm.accept": "OK",
    "lumui.confirm.reject": "Pārtraukt",
    "lumui.formatter.boolean.true": "Jā",
    "lumui.formatter.boolean.false": "Nē",
    "lumui.form.close": "Aizvērt",
    "lumui.form.cancel": "Pārtraukt",
    "lumui.form.save": "Saglabāt",
    "lumui.lightbox.title": "Esošie attēli",
    "lumui.loading.message": "Dati tiek ielādēti",
    "lumui.qr_scanner.camera": "Kamera",
    "lumui.qr_scanner.mirror": "Spoguļu kamera ",
    "lumui.qr_scanner.torch": "Kameru gaisma",
    "lumui.table.reset_filters": "atiestatīt",
    "lumui.table.close_filters": "Sakļaut filtrus",
    "lumui.table.details": "Detalizēts skats",
    "lumui.table.previous": "Iepriekšējais ieraksts",
    "lumui.table.next": "Nākamais ieraksts",
    "lumui.table.no_data": "Dati nav atrasti",
    "lumui.table.no_filter_results": "Jūsu meklēšana nedeva nekādus rezultātus",
    "lumui.table.filters_active": "Rezultāti filtrēti",
    "lumui.table.rows_per_page": "Rindas lapā",
    "lumui.table.page_text": "{0}-{1} of {2}",
    "lumui.table.btn.export": "Eksporta saraksts",
    "lumui.table_action.menu": "Izvēlne",
    "lumui.table_columns.save": "Saglabāt failu",
    "lumui.form.row.invalid_email": "Lūdzu, ievadiet pareizu e-pasta adresi.",
    "lumui.form.row.required": "Šis ir obligāts lauks",
    "lumui.form.autocomplete.selected_count": "Izvēlēti {c} vienumi",
    "lumui.form.autocomplete.select_all": "Atlasīt visus",
    "lumui.form.autocomplete.deselect_all": "Nekas nav atlasīts",
    "lumui.form.autocomplete.search_hint": "Lūdzu, ievadiet to, ko meklējat",
    "lumui.form.autocomplete.no_data": "Nav pieejami dati",
    "lumui.form.autocomplete.nothing_selected": "Nav atlasīts neviens ieraksts",
    "lumui.form.autocomplete.selected_items": "Atlasītie ieraksti",
    "lumui.form.date.close": "Aizvērt",
    "lumui.form.date.save": "OK",
    "lumui.form.date.after_max": "Atlasītais datums ir pēc maksimāli atļautā datuma {max_date}",
    "lumui.form.date.before_min": "Atlasītais datums ir pirms minimālā atļautā datuma {min_date}",
    "lumui.form.file.drag": "Velciet failu šeit vai noklikšķiniet, lai izvēlētos",
    "lumui.form.file.error": "Augšupielāde neizdevās.",
    "lumui.form.file.extensions.accepted": "Atļautie failu tipi: {types}",
    "lumui.form.file.extensions.all": "Atļauti visi failu tipi",
    "lumui.form.file.maxSize": "Maksimālais atļautais faila lielums ir {size}.",
    "lumui.form.file.maxSizeError": "Fails ir lielāks par maksimālo atļauto lielumu, kas ir {size}.",
    "lumui.form.file.previewError": "Nav priekšskatījuma failam {name}.",
    "lumui.form.file.uploading": "Tiek augšupielādēti {count} faili...",
    "lumui.form.file.warning.removed_file": "Fails {name} netika augšupielādēts. Faila tips {type} nav atļautajos tipos {acceptedTypes}.",
    "lumui.form.file.select_file": "Izvēlieties failu",
    "lumui.form.file.unknown_size": "Nav norādīts",
    "lumui.form.localized.copy_to_all": "Pieņemt ierakstu visās valodās",
    "lumui.form.ordered_choice.no_data": "nav pieejami ieraksti",
    "lumui.form.ordered_choice.select_placeholder": "atlasīt jaunu ierakstu",
    "lumui.form.password.repeat": "Atkārtot paroli",
    "lumui.form.password.not_matching_error": "Abām parolēm ir jāsakrīt.",
    "lumui.form.signature.clear": "Dzēst parakstu",
    "lumui.form.signature.upload": "Augšupielādēt parakstu",
    "lumui.form.time.close": "Aizvērt",
    "lumui.form.time.save": "OK",
    "lumui.form.password.requirements.title": "Parolei jāatbilst šādiem prasībām:",
    "lumui.form.password.requirements.lower_chars": "vismaz viena mazā burts | vismaz {count} mazo burti",
    "lumui.form.password.requirements.upper_chars": "vismaz viena lielā burts | vismaz {count} lielo burti",
    "lumui.form.password.requirements.number_chars": "vismaz viena cipars | vismaz {count} cipari",
    "lumui.form.password.requirements.special_chars": "vismaz viens speciāls rakstzīme | vismaz {count} speciālu rakstzīmju",
    "lumui.form.password.requirements.total_chars": "vismaz viens rakstzīme | vismaz {count} rakstzīmes",
    "lumui.locale.lang.german": "Vācu",
    "lumui.locale.lang.english": "Angļu",
    "lumui.locale.lang.italian": "Itāļu",
    "lumui.locale.lang.french": "Franču",
    "lumui.status.no_action_required": "nekāda darbība nav nepieciešama",
    "lumui.status.hint": "Piezīme",
    "lumui.status.action_required_soon": "drīzumā jārīkojas!",
    "lumui.status.action_required_immediately": "nepieciešama tūlītēja rīcība!",
    "lumui.locale.de-DE": "German",
    "lumui.locale.de-CH": "German (Suisse)",
    "lumui.locale.fr-CH": "French (Suisse)",
    "lumui.locale.it-CH": "Italian (Suisse)",
    "lumui.locale.en-US": "English (USA)",
    "lumui.locale.en-GB": "English (GB)",
    "lumui.locale.fi-FI": "Finnish",
    "lumui.locale.et-EE": "Estonian",
    "lumui.locale.lt-LT": "Lithuanian",
    "lumui.locale.lv-LV": "Latvian",
    "lumui.locale.lang.finnish": "Somu",
    "lumui.locale.lang.estonian": "Igauniskie",
    "lumui.locale.lang.lithuanian": "Lietuviešu",
    "lumui.locale.lang.latvian": "Latviešu",
    "lumui.locale.lang.hungarian": "Ungāru",
});
