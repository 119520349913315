import {merge} from "webpack-merge";
import hu from './base/hu.js';

export default merge(hu, {
    "lumui.confirm.accept": "OK",
    "lumui.confirm.reject": "M\u00e9gse",
    "lumui.formatter.boolean.true": "Igen",
    "lumui.formatter.boolean.false": "Nem",
    "lumui.form.close": "Bez\u00e1r\u00e1s",
    "lumui.form.cancel": "M\u00e9gse",
    "lumui.form.save": "Ment\u00e9s",
    "lumui.lightbox.title": "Megl\u00e9v\u0151 k\u00e9pek",
    "lumui.loading.message": "Az adatok bet\u00f6lt\u00e9se folyamatban",
    "lumui.qr_scanner.camera": "Kamera",
    "lumui.qr_scanner.mirror": "T\u00fck\u00f6rkamera",
    "lumui.qr_scanner.torch": "Kamera f\u00e9ny",
    "lumui.table.reset_filters": "Vissza\u00e1ll\u00edt\u00e1s",
    "lumui.table.close_filters": "Minimaliz\u00e1lja a sz\u0171r\u0151ket",
    "lumui.table.details": "R\u00e9szletes n\u00e9zet",
    "lumui.table.previous": "El\u0151z\u0151 rekord",
    "lumui.table.next": "K\u00f6vetkez\u0151 rekord",
    "lumui.table.no_data": "Nem tal\u00e1lhat\u00f3 adat",
    "lumui.table.no_filter_results": "A keres\u00e9s nem hozott eredm\u00e9nyt",
    "lumui.table.filters_active": "Sz\u0171rt eredm\u00e9nyek",
    "lumui.table.rows_per_page": "Sorok oldalank\u00e9nt",
    "lumui.table.page_text": "{0}-{1} / {2}",
    "lumui.table.btn.export": "Lista exportálása",
    "lumui.table_action.menu": "Menu",
    "lumui.table_columns.save": "F\u00e1jl ment\u00e9se",
    "lumui.form.row.invalid_email": "Bitte geben Sie eine korrekte E-Mail-Adresse an.",
    "lumui.form.row.required": "A mez\u0151 kit\u00f6lt\u00e9se k\u00f6telez\u0151",
    "lumui.form.autocomplete.selected_count": "{c} elem kiválasztva",
    "lumui.form.autocomplete.select_all": "Mindet kiv\u00e1laszt",
    "lumui.form.autocomplete.deselect_all": "Nincs kiv\u00e1lasztva",
    "lumui.form.autocomplete.search_hint": "K\u00e9rj\u00fck, \u00edrja be mit keres",
    "lumui.form.autocomplete.no_data": "Nincs el\u00e9rhet\u0151 adat",
    "lumui.form.autocomplete.nothing_selected": "Nincs kiv\u00e1lasztott bejegyz\u00e9s",
    "lumui.form.autocomplete.selected_items": "Kiv\u00e1lasztott bejegyz\u00e9sek",
    "lumui.form.date.close": "Bez\u00e1r\u00e1s",
    "lumui.form.date.save": "OK",
    "lumui.form.date.after_max": "A kiválasztott dátum a megengedett maximális dátumnál későbbi: {max_date}",
    "lumui.form.date.before_min": "A kiválasztott dátum a minimálisan megengedett dátum előtt van: {min_date}",
    "lumui.form.file.drag": "Húzza ide a fájlt, vagy kattintson a kiválasztáshoz",
    "lumui.form.file.error": "A feltöltés sikertelen.",
    "lumui.form.file.extensions.accepted": "Engedélyezett fájltípusok: {types}",
    "lumui.form.file.extensions.all": "Minden fájltípus engedélyezett",
    "lumui.form.file.maxSize": "Maximális megengedett fájlméret: {size}.",
    "lumui.form.file.maxSizeError": "A fájl nagyobb, mint a maximálisan megengedett {size} méret.",
    "lumui.form.file.previewError": "{name} fájl előnézete nem érhető el.",
    "lumui.form.file.uploading": "{count} fájl feltöltése folyamatban…",
    "lumui.form.file.warning.removed_file": "{name} fájl nem lett feltöltve. A {type} fájltípus nem tartozik az engedélyezett {acceptedTypes} típusok közé.",
    "lumui.form.file.select_file": "T\u00f6ltse fel a r\u00e9sztvev\u0151k al\u00e1\u00edrt list\u00e1j\u00e1t",
    "lumui.form.file.unknown_size": "nem meghat\u00e1rozott",
    "lumui.form.localized.copy_to_all": "Fogadja el az \u00f6sszes nyelv bevitel\u00e9t",
    "lumui.form.ordered_choice.no_data": "nincs el\u00e9rhet\u0151 bejegyz\u00e9s",
    "lumui.form.ordered_choice.select_placeholder": "V\u00e1lasszon egy \u00faj bejegyz\u00e9st",
    "lumui.form.password.repeat": "Jelsz\u00f3 \u00fajra",
    "lumui.form.password.not_matching_error": "Mindk\u00e9t jelsz\u00f3nak meg kell egyezni.",
    "lumui.form.signature.clear": "Al\u00e1\u00edr\u00e1s t\u00f6rl\u00e9se",
    "lumui.form.signature.upload": "Al\u00e1\u00edr\u00e1s felt\u00f6lt\u00e9se",
    "lumui.form.time.close": "Bez\u00e1r\u00e1s",
    "lumui.form.time.save": "Ment\u00e9s",
    "lumui.form.password.requirements.title": "A jelszónak az alábbi követelményeknek kell megfelelnie:",
    "lumui.form.password.requirements.lower_chars": "legalább egy kisbetű | legalább {count} kisbetű",
    "lumui.form.password.requirements.upper_chars": "legalább egy nagybetű | legalább {count} nagybetű",
    "lumui.form.password.requirements.number_chars": "legalább egy számjegy | legalább {count} számjegy",
    "lumui.form.password.requirements.special_chars": "legalább egy speciális karakter | legalább {count} speciális karakter",
    "lumui.form.password.requirements.total_chars": "legalább egy karakter | legalább {count} karakter",
    "lumui.locale.lang.german": "N\u00e9met",
    "lumui.locale.lang.english": "Angol",
    "lumui.locale.lang.italian": "Olasz",
    "lumui.locale.lang.french": "Francia",
    "lumui.status.no_action_required": "nem ig\u00e9nyel beavatkoz\u00e1st",
    "lumui.status.hint": "Jegyzet",
    "lumui.status.action_required_soon": "hamarosan int\u00e9zked\u00e9sre lesz sz\u00fcks\u00e9g!",
    "lumui.status.action_required_immediately": "azonnali int\u00e9zked\u00e9s sz\u00fcks\u00e9ges!",
    "lumui.locale.de-DE": "German",
    "lumui.locale.de-CH": "German (Suisse)",
    "lumui.locale.fr-CH": "French (Suisse)",
    "lumui.locale.it-CH": "Italian (Suisse)",
    "lumui.locale.en-US": "English (USA)",
    "lumui.locale.en-GB": "English (GB)",
    "lumui.locale.fi-FI": "Finnish",
    "lumui.locale.et-EE": "Estonian",
    "lumui.locale.lt-LT": "Lithuanian",
    "lumui.locale.lv-LV": "Latvian",
    "lumui.locale.lang.finnish": "Finn",
    "lumui.locale.lang.estonian": "Észt",
    "lumui.locale.lang.lithuanian": "Litván",
    "lumui.locale.lang.latvian": "Lett",
    "lumui.locale.lang.hungarian": "Magyar",
});
