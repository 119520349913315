(function($R)
{
    $R.lang['ja'] = {
        "format": "フォーマットする",
        "image": "画像",
        "file": "ファイル",
        "link": "リンク",
        "bold": "太字",
        "italic": "イタリック体",
        "deleted": "取り消し線",
        "underline": "下線",
        "superscript": "Superscript",
        "subscript": "Subscript",
        "bold-abbr": "B",
        "italic-abbr": "I",
        "deleted-abbr": "S",
        "underline-abbr": "U",
        "superscript-abbr": "Sup",
        "subscript-abbr": "Sub",
        "lists": "リスト",
        "link-insert": "リンクを挿入する",
        "link-edit": "リンクを編集する",
        "link-in-new-tab": "新しいタブでリンクを開く",
        "unlink": "リンクを解除する",
        "cancel": "取り消す",
        "close": "閉じる",
        "insert": "挿入する",
        "save": "保存する",
        "delete": "削除する",
        "text": "テキスト",
        "edit": "編集する",
        "title": "タイトル",
        "paragraph": "標準テキスト",
        "quote": "引用",
        "code": "コード",
        "heading1": "見出し 1",
        "heading2": "見出し 2",
        "heading3": "見出し 3",
        "heading4": "見出し 4",
        "heading5": "見出し 5",
        "heading6": "見出し 6",
        "filename": "名前",
        "optional": "任意",
        "unorderedlist": "番号なしリスト",
        "orderedlist": "番号付きリスト",
        "outdent": "インデントを戻す",
        "indent": "インデントする",
        "horizontalrule": "線",
        "upload": "Upload",
        "upload-label": "Drop files here or click to upload",
        "upload-change-label": "Drop a new image to change",
        "accessibility-help-label": "リッチテキストエディタ",
        "caption": "Caption",
        "bulletslist": "Bullets",
        "numberslist": "Numbers",
        "image-position": "Position",
        "none": "None",
        "left": "Left",
        "right": "Right",
        "center": "Center",
        "undo": "Undo",
        "redo": "Redo"
    };
})(Redactor);