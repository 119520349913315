<template>
    <div>
        <form-row-localized
            v-for="i in childIndexes"
            :key="i"
            v-model="extractedValues[i]"
            :class="styleClass"
            :config="config"
            :disabled="disabled"
            :error="error"
            :error-messages="errorMessage"
            :name="name"
            :required="required"
            :rules="rules"
            @input="onInputChange(i, $event)"
        />
    </div>
</template>

<script>
import FormRowLocalized from "./FormRowLocalized";

/**
 * Special case for some audit form. don't use.
 * @deprecated
 */
export default {
    name: "FormRowLocalizedMulti",
    components: {
        FormRowLocalized
    },
    props: {
        config: {
            type: Object,
            default: function () {
                return {};
            }
        },
        value: {
            type: [Object, String, Number, Array],
            default: null
        },
        name: {
            type: String,
            default: ""
        },
        required: {
            type: Boolean,
            default: false
        },
        error: {
            type: Boolean,
            default: false
        },
        errorMessage: {
            type: Array,
            default: () => []
        },
        rules: {
            type: Array,
            default: () => []
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            'internalValue': {},
        }
    },
    computed: {
        availableLocales() {
            if (typeof this.config.languages !== 'undefined' && Array.isArray(this.config.languages) && this.config.languages.length > 0) {
                return this.config.languages;
            } else {
                return this.$store.getters['lumui/i18n/mappedLocales'];
            }
        },
        currentLocale() {
            const locale = this.$store.getters['lumui/i18n/mappedLocale'];
            if (this.availableLocales.includes(locale)) {
                return locale;
            }
            return this.availableLocales[0];
        },
        childIndexes() {
            let rtn = [];
            for (let i = 0; i < this.config.count; i++) {
                rtn.push(i);
            }
            return rtn;
        },
        extractedValues() {
            let rtn = [];
            for (let i = 0; i < this.config.count; i++) {
                rtn[i] = {};
                Object.keys(this.internalValue).forEach((key) => {
                    let parsed = JSON.parse(this.internalValue[key]);
                    this.$set(rtn[i], key, [...parsed.keys()].indexOf(i) > -1 ? parsed[i] : '');
                });
            }
            return rtn;
        },
        styleClass() {
            return this.config.class;
        },
    },
    watch: {
        value: {
            handler: function (value) {
                if (typeof value === 'object' && value !== null) {
                    this.internalValue = this.value;
                } else {
                    this.internalValue = {};
                }
                this.availableLocales.forEach((locale) => {
                    if (!Object.keys(this.internalValue).includes(locale)) {
                        this.internalValue[locale] = '[]';
                    }
                });
            },
            immediate: true
        },
        internalValue() {
            this.$emit('input', this.internalValue);
        }
    },
    methods: {
        onInputChange(i, event) {
            let tmp = {};
            let changed = false;
            Object.keys(event).forEach((key) => {
                let parsed = JSON.parse(this.internalValue[key]);
                parsed[i] = event[key];
                let json = JSON.stringify(parsed);
                if (json !== this.internalValue[key]) {
                    changed = true;
                    this.$set(tmp, key, json);
                } else {
                    this.$set(tmp, key, this.internalValue[key]);
                }
            });
            if (changed) {
                this.internalValue = tmp;
            }
        }
    }
}
</script>
