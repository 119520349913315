var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-toolbar",
        [
          _c("v-toolbar-title", [
            _vm._v(
              "\n            Custom Filters and disabled pagination\n        "
            ),
          ]),
          _vm._v(" "),
          _c("v-spacer"),
          _vm._v(" "),
          _c(
            "v-toolbar-items",
            [
              _c(
                "v-btn",
                {
                  attrs: { tile: "", icon: "", large: "" },
                  on: {
                    click: function ($event) {
                      _vm.showFilter = !_vm.showFilter
                    },
                  },
                },
                [_c("v-icon", [_vm._v("mdi-magnify")])],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("asa-table", {
        attrs: {
          loading: false,
          data: _vm.config,
          "info-disabled": "",
          "show-filter": _vm.showFilter,
          "storage-key": "test_table_filter_storage_custom",
          "items-per-page-options": [10, 50, 100],
          "disable-pagination": true,
        },
        on: {
          filter_closed: _vm.filterClosed,
          filter_resetted: _vm.filterResetted,
        },
        scopedSlots: _vm._u([
          {
            key: "filter",
            fn: function (props) {
              return [
                _c(
                  "v-card-text",
                  [
                    _c(
                      "v-form",
                      [
                        _c(
                          "v-container",
                          { attrs: { fluid: "", "grid-list-lg": "" } },
                          [
                            _c(
                              "v-layout",
                              { attrs: { row: "", wrap: "" } },
                              [
                                _c(
                                  "v-flex",
                                  { attrs: { xs12: "" } },
                                  [
                                    _c("AsaTableFilterText", {
                                      attrs: { config: _vm.textSearchConfig },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "v-container",
                          { attrs: { fluid: "", "grid-list-lg": "" } },
                          [
                            _c(
                              "v-layout",
                              { attrs: { row: "", wrap: "" } },
                              _vm._l(props.filters, function (filter) {
                                return !filter.hidden &&
                                  !(filter.type === "action") &&
                                  filter.searchable
                                  ? _c(
                                      "v-flex",
                                      {
                                        key: filter.key,
                                        attrs: { lg4: "", xs12: "" },
                                      },
                                      [
                                        _c("asa-table-filter", {
                                          attrs: { config: filter },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e()
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
          },
          {
            key: "col_actions",
            fn: function (props) {
              return [
                _vm.actions !== null
                  ? _c(
                      "asa-table-action",
                      {
                        attrs: {
                          actions: _vm.actions,
                          row: props.row,
                          "icon-size": "small",
                          "btn-size": "default",
                        },
                      },
                      [
                        _c(
                          "v-btn",
                          {
                            attrs: { slot: "button", icon: "", text: "" },
                            slot: "button",
                          },
                          [_c("v-icon", [_vm._v("more_vert")])],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }