(function($R)
{
    $R.lang['pt_br'] = {
        "format": "Formato",
        "image": "Imagem",
        "file": "Arquivo",
        "link": "Link",
        "bold": "Negrito",
        "italic": "Itálico",
        "deleted": "Tachado",
        "underline": "Sublinhado",
        "superscript": "Superscript",
        "subscript": "Subscript",
        "bold-abbr": "N",
        "italic-abbr": "I",
        "deleted-abbr": "T",
        "underline-abbr": "S",
        "superscript-abbr": "Sup",
        "subscript-abbr": "Sub",
        "lists": "Listas",
        "link-insert": "Inserir link",
        "link-edit": "Editar link",
        "link-in-new-tab": "Abrir link em nova guia",
        "unlink": "Desvincular",
        "cancel": "Cancelar",
        "close": "Fechar",
        "insert": "Inserir",
        "save": "Salvar",
        "delete": "Excluir",
        "text": "Texto",
        "edit": "Editar",
        "title": "Título",
        "paragraph": "Texto normal",
        "quote": "Citação",
        "code": "Código",
        "header1": "Cabeçalho 1",
        "header2": "Cabeçalho 2",
        "header3": "Cabeçalho 3",
        "header4": "Cabeçalho 4",
        "header5": "Cabeçalho 5",
        "header6": "Cabeçalho 6",
        "filename": "Nome",
        "optional": "Opcional",
        "unorderedlist": "Lista não ordenada",
        "orderedlist": "Lista ordenada",
        "outdent": "Diminuir recuo",
        "indent": "Recuar",
        "horizontalrule": "Linha",
        "upload": "Enviar",
        "upload-label": "Solte os arquivos aqui ou clique para enviar",
        "upload-change-label": "Solte uma nova imagem para alterar",
        "accessibility-help-label": "Editor de Rich Text",
        "caption": "Subtitulo",
        "bulletslist": "Lista com ponto",
        "numberslist": "Lista numérica",
        "image-position": "Posição",
        "none": "Nenhum",
        "left": "Esquerda",
        "right": "Direita",
        "center": "Centro",
        "undo": "Desfazer",
        "redo": "Refazer",
        "table": "Tabela",
        "insert-table": "Inserir tabela",
        "insert-row-above": "Inserir linha à acima",
        "insert-row-below": "Inserir linha à abaixo",
        "insert-column-left": "Inserir coluna à esquerda",
        "insert-column-right": "Inserir coluna à direita",
        "add-head": "Adicionar cabeçalho",
        "delete-head": "Remover cabeçalho",
        "delete-column": "Remover coluna",
        "delete-row": "Remover linha",
        "delete-table": "Remover tabela",
        "fontcolor": "Cor do texto",
        "text": "Texto",
        "highlight": "Destaque",
        "fontfamily": "Fonte",
        "remove-font-family":  "Remover fonte",
        "choose": "Escolher",
        "size": "Tamanho",
        "remove-size":  "Remover tamanho da fonte",
        "fullscreen": "Tela cheia",
        "align": "Alinhar",
        "align-left": "Alinhar à esquerda",
        "align-center": "Alinhar ao centro",
        "align-right": "Alinhar à direita",
        "align-justify": "Alinhar justificado",
        "words": "Palavras",
        "chars": "Caracteres",
        "style": "Estilo",
        "properties": "Propriedades",
        "specialchars": "Caracteres especiais",
        "change-text-direction": "Direção do texto",
        "left-to-right": "Esquerda para direita",
        "right-to-left": "Direita para esquerda",
        "change": "Trocar",
        "variable": "Variável",
        "variable-select": "Por favor, selecione a variável",
        "video": "Vídeo",
        "video-html-code": "Código de incorporação de vídeo ou link do YouTube/Vimeo",
        "widget": "Widget",
        "widget-html-code": "Código HTML do Widget"
    };
})(Redactor);