<template>
    <div>
        <v-container>
            <v-row>
                <v-col>
                    <h1 class="text-h3">No Data</h1>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <v-card>
                        <v-card-title>No Data</v-card-title>
                        <v-divider></v-divider>
                        <v-card-text>
                            <asa-table :data="table1"></asa-table>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <v-card>
                        <v-card-title>Custom no data message</v-card-title>
                        <v-divider></v-divider>
                        <v-card-text>
                            <asa-table :data="table1">
                                <template v-slot:no-data><div style="text-align: center;"><em>Custom no data message</em></div></template>
                            </asa-table>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <v-card>
                        <v-card-title>Custom no results message</v-card-title>
                        <v-divider></v-divider>
                        <v-card-text>
                            <asa-table :data="table2" :showFilter="true">
                                <template v-slot:no-results><div style="text-align: center;"><em>Custom no results message</em></div></template>
                            </asa-table>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import {VContainer, VRow, VCol, VCard, VCardTitle, VDivider, VCardText } from 'vuetify/lib';

export default {
    name: "TableNodata",
    components: {VContainer, VRow, VCol, VCard, VCardTitle, VDivider, VCardText },
    data() {
        return {
            table1: {
                columns: [
                    {key: "id", label:"id" }
                ],
                rows: [
                ]
            },
            table2: {
                columns: [
                    {key: "id", label: "id"}
                ],
                filter: [
                    {key: "id", type: "int", value: "123", label: "idx"}
                ],
                rows: [
                    {id: 2}
                ]
            }
        }
    }
}
</script>

<style scoped>

</style>
