export function emptyValue(value) {
    return value === "" || value === null || value === undefined;
}
export function emptyFilterValue(v) {
    return v === "" || v === null || v === undefined || (Array.isArray(v) && v.length === 0);
}
export function filterStatus(value, filterValue) {
    if (emptyFilterValue(filterValue)) {
        return true;
    }
    if (!Array.isArray(value) || value.length == 0) {
        return false;
    }
    if (!Array.isArray(filterValue)) {
        filterValue = [filterValue];
    }
    for (let i = 0; i < filterValue.length; i++) {
        if (value[0] == parseInt(filterValue[i])) {
            return true;
        }
    }
    return false;
}
export function filterDate(value, filterValue) {
    if (emptyFilterValue(filterValue)) {
        return true;
    }
    if (emptyValue(value)) {
        return false;
    }
    if (Array.isArray(filterValue) && filterValue.length === 2) {
        const dateVal = new Date(value);
        const filterValues = filterValue.sort();
        const filterVal1 = new Date(filterValues[0]);
        const filterVal2 = new Date(filterValues[1]);
        return dateVal >= filterVal1 && dateVal <= filterVal2;
    }
    else if (Array.isArray(filterValue) && filterValue.length === 1) {
        const dateVal = new Date(value).setHours(0, 0, 0, 0);
        const filterVal = new Date(filterValue[0]).setHours(0, 0, 0, 0);
        return dateVal === filterVal;
    }
    else {
        const dateVal = new Date(value).setHours(0, 0, 0, 0);
        const filterVal = new Date(filterValue).setHours(0, 0, 0, 0);
        return dateVal === filterVal;
    }
}
export function getFilterLocalized(localize) {
    return function (value, filterValue) {
        if (emptyFilterValue(filterValue)) {
            return true;
        }
        if (emptyValue(value)) {
            return false;
        }
        const v = localize(value);
        return filterSubstr(v, filterValue);
    };
}
export function filterSelect(value, filterValue) {
    if (emptyFilterValue(filterValue)) {
        return true;
    }
    if (emptyValue(value)) {
        return false;
    }
    if (typeof value === "number") {
        return value === parseInt(filterValue);
    }
    else {
        return value === filterValue;
    }
}
export function filterSelectMultiple(value, filterValue) {
    if (emptyFilterValue(filterValue)) {
        return true;
    }
    if (emptyValue(value)) {
        return false;
    }
    if (typeof value === "number") {
        return filterValue.map(x => parseInt(x)).includes(value);
    }
    else {
        return filterValue.includes(value);
    }
}
export function filterNestedSet(value, filterValue) {
    if (emptyFilterValue(filterValue)) {
        return true;
    }
    if (emptyValue(value)) {
        return false;
    }
    return value.root === filterValue.root
        && value.left >= filterValue.left
        && value.right <= filterValue.right;
}
export function filterMaterializedPath(value, filterValue) {
    if (emptyFilterValue(filterValue)) {
        return true;
    }
    if (emptyValue(value)) {
        return false;
    }
    return value.path.startsWith(filterValue);
}
export function filterSubstr(value, filterValue) {
    if (emptyFilterValue(filterValue)) {
        return true;
    }
    if (emptyValue(value)) {
        return false;
    }
    return (("" + value).toLowerCase().indexOf(filterValue.toLowerCase()) > -1);
}
export function filterIdentical(value, filterValue) {
    if (emptyFilterValue(filterValue)) {
        return true;
    }
    if (emptyValue(value)) {
        return false;
    }
    return value === filterValue;
}
export function getFilterGeneric(localize) {
    return function (value, filterValue) {
        if (emptyFilterValue(filterValue)) {
            return true;
        }
        if (emptyValue(value)) {
            return false;
        }
        switch (typeof value) {
            case "boolean":
                return filterIdentical(value, filterValue);
            case "object":
                return filterSubstr(localize(value), filterValue);
            default:
                return filterSubstr(value, filterValue);
        }
    };
}
