<template>
    <div>
        <asa-dialog>
            <asa-form v-model="data" :config="formConf">
                <template v-slot:title>
                    WYIWYG editor im dialog
                </template>
            </asa-form>
        </asa-dialog>
    </div>
</template>

<script>
import AsaDialog from "../../../src/component/AsaDialog";
import AsaForm from "../../../src/component/AsaForm";

export default {
    name: "FormWysiwygDialog",
    components: {AsaForm, AsaDialog},
    data() {
        return {
            formConf: {
                "text": {
                    "type": "wysiwyg",
                    "label": "Text",
                    "order": 1,
                    "newLinkTab": true
                }
            },
            data: {},
        }
    }
}
</script>
