<template>
    <div class="container">
        true = <asa-formatter type="bool" :value="true" /><br>
        1 = <asa-formatter type="bool" :value="1" /><br>
        false = <asa-formatter type="bool" :value="false" /><br>
        0 = <asa-formatter type="bool" :value="0" />
    </div>
</template>

<script>
import AsaFormatter from "../../../src/component/AsaFormatter";

export default {
    name: "FormatterBool",
    components: {
        AsaFormatter
    }
}
</script>
