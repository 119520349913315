import moment from "moment";

export default class TimeFormatter {
    constructor() {
    }

    requiresHTML() {
        return false;
    }

    format(value) {
        if(!value || ((typeof value) === 'undefined') || (value === 'undefined')) {
            return '';
        }

        if(!(value instanceof Date)) {
            const date = moment(value,'LT')
            if (!date.isValid()) {
                return "Invalid date";
            }
            return  date.format("LT");
        }
        return  moment(value).format('LT');
    }
}
