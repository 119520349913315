var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "input-radios" },
    [
      _vm.config.label
        ? _c("div", { staticClass: "label" }, [
            _vm._v("\n        " + _vm._s(_vm.config.label) + "\n    "),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "v-radio-group",
        {
          attrs: {
            id: _vm.name,
            filled: "",
            disabled: _vm.disabled,
            error: _vm.error,
            "error-messages": _vm.errorMessages,
            name: _vm.name,
            required: _vm.required,
            rules: _vm.rules,
            "value-comparator": _vm.itemCompare,
          },
          model: {
            value: _vm.viewValue,
            callback: function ($$v) {
              _vm.viewValue = $$v
            },
            expression: "viewValue",
          },
        },
        _vm._l(_vm.config.multiOptions, function (item, key) {
          return _c("v-radio", {
            key: key,
            attrs: {
              id: _vm.name + "-" + key,
              name: _vm.name,
              value: _vm.getValue(item),
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "label",
                  fn: function () {
                    return [
                      _c("span", {
                        domProps: { innerHTML: _vm._s(_vm.getLabel(item)) },
                      }),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              true
            ),
          })
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }