export default {
    "$vuetify.badge": "Märk",
    "$vuetify.close": "Sulge",
    "$vuetify.dataIterator.noResultsText": "Vastavaid kirjeid ei leitud",
    "$vuetify.dataIterator.loadingText": "Andmeid laaditakse...",
    "$vuetify.dataTable.itemsPerPageText": "Ridu leheküljel:",
    "$vuetify.dataTable.ariaLabel.sortDescending": "Kahanevalt sorteeritud.",
    "$vuetify.dataTable.ariaLabel.sortAscending": "Kasvavalt sorteeritud.",
    "$vuetify.dataTable.ariaLabel.sortNone": "Ei ole sorteeritud.",
    "$vuetify.dataTable.ariaLabel.activateNone": "Vajuta uuesti sorteerimise eemaldamiseks.",
    "$vuetify.dataTable.ariaLabel.activateDescending": "Vajuta uuesti, et sorteerida kahanevalt.",
    "$vuetify.dataTable.ariaLabel.activateAscending": "Vajuta kasvavalt sorteerimiseks.",
    "$vuetify.dataTable.sortBy": "Sorteerimise alus",
    "$vuetify.dataFooter.itemsPerPageText": "Kirjeid leheküljel:",
    "$vuetify.dataFooter.itemsPerPageAll": "Kõik",
    "$vuetify.dataFooter.nextPage": "Järgmine lehekülg",
    "$vuetify.dataFooter.prevPage": "Eelmine lehekülg",
    "$vuetify.dataFooter.firstPage": "Esimene lehekülg",
    "$vuetify.dataFooter.lastPage": "Viimane lehekülg",
    "$vuetify.dataFooter.pageText": "{0}-{1} {2}st",
    "$vuetify.datePicker.itemsSelected": "{0} valitud",
    "$vuetify.datePicker.nextMonthAriaLabel": "Järgmine kuu",
    "$vuetify.datePicker.nextYearAriaLabel": "Järgmine aasta",
    "$vuetify.datePicker.prevMonthAriaLabel": "Eelmine kuu",
    "$vuetify.datePicker.prevYearAriaLabel": "Eelmine aasta",
    "$vuetify.noDataText": "Andmed puuduvad",
    "$vuetify.carousel.prev": "Eelmine visuaalne",
    "$vuetify.carousel.next": "Järgmine visuaalne",
    "$vuetify.carousel.ariaLabel.delimiter": "Carousel slide {0} of {1}",
    "$vuetify.calendar.moreEvents": "{0} veel",
    "$vuetify.input.clear": "Clear {0}",
    "$vuetify.input.prependAction": "{0} prepended action",
    "$vuetify.input.appendAction": "{0} appended action",
    "$vuetify.fileInput.counter": "{0} faili",
    "$vuetify.fileInput.counterSize": "{0} faili (kokku {1})",
    "$vuetify.timePicker.am": "AM",
    "$vuetify.timePicker.pm": "PM",
    "$vuetify.pagination.ariaLabel.wrapper": "Pagination Navigation",
    "$vuetify.pagination.ariaLabel.next": "Järgmine lehekülg",
    "$vuetify.pagination.ariaLabel.previous": "Eelmine lehekülg",
    "$vuetify.pagination.ariaLabel.page": "Mine lehele {0}",
    "$vuetify.pagination.ariaLabel.currentPage": "Praegune leht, leht {0}",
    "$vuetify.rating.ariaLabel.icon": "Rating {0} of {1}",
    "$vuetify.loading": "Loading..."
};