<template>
    <v-dialog v-if="dialog" :value="loading" hide-overlay persistent width="300">
        <v-card flat>
            <v-card-text class="text-center">
                <v-progress-circular class="mt-3" indeterminate />
                <br /> {{ text || $lumui.i18n.t('lumui.loading.message') }}
            </v-card-text>
        </v-card>
    </v-dialog>
    <v-card v-else-if="loading" class="mt-1" flat>
        <v-card-text class="text-center">
            <v-progress-circular class="mt-3" indeterminate />
            <br /> {{ text || $lumui.i18n.t('lumui.loading.message') }}
        </v-card-text>
    </v-card>
</template>

<script>
import {VCard, VCardText, VDialog, VProgressCircular} from 'vuetify/lib';

/** displays a loading animation */
export default {
    name: 'AsaLoading',
    components: {VDialog, VCard, VCardText, VProgressCircular},
    props: {
        /** if true display the animation/dialog */
        loading: {
            type: Boolean,
            default: false,
            required: true
        },
        /** if true renders the animation in an overlaid dialog */
        dialog: {
            type: Boolean,
            default: false
        },
        /** The text message to display alongside the animation */
        text: {
            type: String,
            default: null,
            required: false
        }
    },
}
</script>
