var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.fieldType
        ? _c(
            "v-input",
            {
              staticClass: "v-text-field html-field",
              class: _vm.styleClass,
              attrs: {
                id: _vm.name,
                error: _vm.error,
                "error-count": _vm.errorMessages.length,
                "error-messages": _vm.errorMessages,
                hint: _vm.description || "",
                label: _vm.label !== "html" ? _vm.label : null,
                name: _vm.name,
                required: false,
              },
            },
            [
              _c("div", {
                staticClass: "subtitle-2 input",
                domProps: { innerHTML: _vm._s(_vm.sanitized) },
              }),
            ]
          )
        : [
            _vm.label !== "html"
              ? _c("div", { staticClass: "subtitle-1" }, [
                  _vm._v("\n            " + _vm._s(_vm.label) + "\n        "),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("div", {
              class: _vm.styleClass,
              domProps: { innerHTML: _vm._s(_vm.sanitized) },
            }),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }