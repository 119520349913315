var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-tabs",
    [
      _vm._l(_vm.availableLocales, function (locale) {
        return _c(
          "v-tab",
          {
            key: locale,
            attrs: { ripple: "" },
            on: {
              change: function ($event) {
                return _vm.tabActivated(locale)
              },
            },
            model: {
              value: _vm.active,
              callback: function ($$v) {
                _vm.active = $$v
              },
              expression: "active",
            },
          },
          [
            _vm._v(
              "\n        " +
                _vm._s(
                  _vm.$te("lumui.locale." + locale)
                    ? _vm.$t("lumui.locale." + locale)
                    : locale
                ) +
                "\n    "
            ),
          ]
        )
      }),
      _vm._v(" "),
      _vm._l(_vm.availableLocales, function (locale) {
        return _c(
          "v-tab-item",
          { key: locale, attrs: { eager: true } },
          [
            _c(
              "v-card",
              { attrs: { flat: "" } },
              [
                _vm._t("default", null, {
                  locale: locale,
                  localeKey: _vm.$lumui.mapLocale(locale),
                }),
              ],
              2
            ),
          ],
          1
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }