<template>
    <asa-form v-model="value" :loading="false" :config="cfg">
        <template slot="title">
            LDK form
        </template>
    </asa-form>
</template>
<script>
import AsaForm from "../../../src/component/AsaForm";

export default {
    components: {AsaForm},
    data() {
        return {
            value: {},
            cfg: {
                "tab1": {
                    "type": "tab",
                    "label": "Kontaktdaten",
                    "required": ["incident", "employee", "lastcontactdate"],
                    "disabled": false,
                    "errors": [],
                    "children": {
                        "id": {
                            "type": "hidden",
                            "label": "id",
                            "value": "1",
                            "required": false,
                            "disabled": false,
                            "errors": [],
                            "order": 1
                        },
                        "incident": {
                            "type": "select",
                            "label": "Indexfall",
                            "value": "7",
                            "required": true,
                            "disabled": false,
                            "errors": [],
                            "multiple": false,
                            "multiOptions": [{"label": "Mustermann, Hans", "value": "7"}],
                            "order": 2
                        },
                        "employee": {
                            "type": "select",
                            "label": "Kontaktperson",
                            "appendOuterIcon": "$add",
                            "appendOuterIconCallback": "this.$router.push(this.$route.path + '\/addEmployee')",
                            "value": "6",
                            "required": true,
                            "disabled": false,
                            "errors": [],
                            "multiple": false,
                            "multiOptions": [{"label": "Mustermann, Hans", "value": "5"}, {
                                "label": "Tester, Klaus",
                                "value": "6"
                            }, {"label": "Schmidt, J\u00fcrgen", "value": "7"}, {
                                "label": "qceqrc, wdccwqc",
                                "value": "8"
                            }, {"label": "efveqv, rgvgeqrv", "value": "9"}, {
                                "label": "ewfvewv, erffverv",
                                "value": "10"
                            }, {"label": "gbbwrgb, rwbrgb", "value": "11"}, {
                                "label": "wfrwerfvef, weecw",
                                "value": "12"
                            }, {"label": "eqrveqrvc, 3fwrferf", "value": "13"}, {
                                "label": "gnghfngfj, fhjmfjhmfhjm",
                                "value": "14"
                            }, {"label": "kkkkk, kkkk", "value": "15"}, {
                                "label": "iiiiii, iiiiii",
                                "value": "16"
                            }, {"label": "Klausen, Klaus", "value": "17"}, {
                                "label": "Klausen, Klaus",
                                "value": "18"
                            }, {"label": "Wernersen, Werner", "value": "19"}, {
                                "label": "Hansen, Hans",
                                "value": "20"
                            }, {"label": "Haraldsen, Harald", "value": "21"}, {
                                "label": "J\u00fcrgensen, J\u00fcrgen",
                                "value": "22"
                            }, {"label": "Blume, Maria", "value": "23"}, {"label": "Naumann, Primosz", "value": "24"}],
                            "order": 3
                        },
                        "impfstatus": {
                            "type": "html",
                            "label": "Impfstatus der Kontaktperson",
                            "value": "",
                            "required": false,
                            "disabled": false,
                            "errors": [],
                            "order": 4
                        },
                        "department": {
                            "type": "select",
                            "label": "Abteilung",
                            "appendOuterIcon": "$add",
                            "appendOuterIconCallback": "this.$router.push(this.$route.path + '\/addItem\/abteilung')",
                            "value": "22",
                            "required": false,
                            "disabled": false,
                            "errors": [],
                            "multiple": false,
                            "multiOptions": [{"label": "URO", "value": "20"}, {
                                "label": "KPS",
                                "value": "21"
                            }, {"label": "Apotheke", "value": "22"}, {
                                "label": "Abteilung 1",
                                "value": "47"
                            }, {"label": "Abteilung 2", "value": "48"}, {"label": "M\u00f6belhaus", "value": "59"}],
                            "order": 5
                        },
                        "additional_contacts": {
                            "type": "textarea",
                            "label": "Kontakt zu",
                            "value": "",
                            "required": false,
                            "disabled": false,
                            "errors": [],
                            "order": 6
                        },
                        "indexPpes": {
                            "type": "select",
                            "label": "PSA Indexpatient",
                            "chips": true,
                            "appendOuterIcon": "$add",
                            "appendOuterIconCallback": "this.$router.push(this.$route.path + '\/addItem\/psa')",
                            "value": ["18", "19"],
                            "required": false,
                            "disabled": false,
                            "errors": [],
                            "multiple": true,
                            "multiOptions": [{"label": "FFP2 Maske", "value": "17"}, {
                                "label": "FFP3 Maske",
                                "value": "18"
                            }, {"label": "Kittel", "value": "19"}, {
                                "label": "gr\u00fcne Weste",
                                "value": "55"
                            }, {"label": "rosa Hose", "value": "56"}, {
                                "label": "Warnweste",
                                "value": "57"
                            }, {"label": "M\u00fctze", "value": "58"}],
                            "order": 7
                        },
                        "contactPpes": {
                            "type": "select",
                            "label": "PSA Kontaktperson",
                            "chips": true,
                            "appendOuterIcon": "$add",
                            "appendOuterIconCallback": "this.$router.push(this.$route.path + '\/addItem\/psa')",
                            "value": ["55"],
                            "required": false,
                            "disabled": false,
                            "errors": [],
                            "multiple": true,
                            "multiOptions": [{"label": "FFP2 Maske", "value": "17"}, {
                                "label": "FFP3 Maske",
                                "value": "18"
                            }, {"label": "Kittel", "value": "19"}, {
                                "label": "gr\u00fcne Weste",
                                "value": "55"
                            }, {"label": "rosa Hose", "value": "56"}, {
                                "label": "Warnweste",
                                "value": "57"
                            }, {"label": "M\u00fctze", "value": "58"}],
                            "order": 8
                        },
                        "lastcontactdate": {
                            "type": "date",
                            "label": "Letzter Kontakt",
                            "value": "2021-04-26 00:00:00",
                            "required": true,
                            "disabled": false,
                            "errors": [],
                            "order": 9
                        },
                        "duration": {
                            "type": "text",
                            "label": "kumulative Kontaktdauer",
                            "value": "ca.120 Minuten",
                            "required": false,
                            "disabled": false,
                            "errors": [],
                            "order": 10
                        },
                        "contacttype": {
                            "type": "select",
                            "label": "Art des Kontakts",
                            "appendOuterIcon": "$add",
                            "appendOuterIconCallback": "this.$router.push(this.$route.path + '\/addItem\/kontaktart')",
                            "value": "27",
                            "required": false,
                            "disabled": false,
                            "errors": [],
                            "multiple": false,
                            "multiOptions": [{"label": "Dienst\u00fcbergabe", "value": "26"}, {
                                "label": "Gespr\u00e4ch",
                                "value": "27"
                            }, {"label": "gemeinsame Durchg\u00e4nge", "value": "28"}, {
                                "label": "Mittagessen",
                                "value": "49"
                            }, {"label": "Abendessen", "value": "50"}, {
                                "label": "Fr\u00fchst\u00fcck",
                                "value": "51"
                            }, {"label": "Imbiss", "value": "52"}],
                            "order": 11
                        },
                        "activity": {
                            "type": "textarea",
                            "label": "Aktivit\u00e4t (aerosol produzierend",
                            "value": "aktivity....",
                            "required": false,
                            "disabled": false,
                            "errors": [],
                            "order": 12
                        },
                        "recovery": {
                            "type": "switch",
                            "label": "COVID-19 positiv gewesen?",
                            "value": null,
                            "required": false,
                            "disabled": false,
                            "errors": [],
                            "order": 13
                        },
                        "symptomatic": {
                            "type": "switch",
                            "label": "Krankheitssysmptome",
                            "value": "1",
                            "required": false,
                            "disabled": false,
                            "errors": [],
                            "order": 14
                        },
                        "symptoms": {
                            "type": "select",
                            "label": "Symptome",
                            "chips": true,
                            "appendOuterIcon": "$add",
                            "appendOuterIconCallback": "this.$router.push(this.$route.path + '\/addItem\/symptome')",
                            "value": ["45", "46"],
                            "required": false,
                            "disabled": false,
                            "errors": [],
                            "multiple": true,
                            "multiOptions": [{"label": "Husten", "value": "45"}, {
                                "label": "Fieber",
                                "value": "46"
                            }, {"label": "Lustlosigkeit", "value": "53"}, {
                                "label": "st\u00e4ndiges Hungergef\u00fchl",
                                "value": "54"
                            }],
                            "order": 15
                        },
                        "symptomsDate": {
                            "type": "date",
                            "label": "Symptome seit wann?",
                            "value": "2021-04-27 00:00:00",
                            "required": false,
                            "disabled": false,
                            "errors": [],
                            "order": 16
                        }
                    },
                    "order": 1
                },
                "tab2": {
                    "type": "tab",
                    "label": "Festlegungen",
                    "required": true,
                    "disabled": false,
                    "errors": [],
                    "children": {
                        "entrydate": {
                            "type": "date",
                            "label": "Eingang \/ Feststellungsdatum",
                            "value": "2021-05-06 00:00:00",
                            "required": false,
                            "disabled": false,
                            "errors": [],
                            "order": 1
                        },
                        "measureHyg": {
                            "type": "select",
                            "label": "Festlegung Hygieneabteilung",
                            "value": "33",
                            "required": false,
                            "disabled": false,
                            "errors": [],
                            "multiple": false,
                            "multiOptions": [{
                                "label": "Quarant\u00e4ne",
                                "value": "33"
                            }, {
                                "label": "T\u00e4tigkeitsverbot",
                                "value": "34"
                            }, {"label": "darf mit FFP2 weiter arbeiten", "value": "35"}, {
                                "label": "Krankschreibung",
                                "value": "36"
                            }, {"label": "darf mit Vollschutz weiterarbeiten", "value": "37"}],
                            "order": 2
                        },
                        "measureHygDate": {
                            "type": "date",
                            "label": "Datum Festlegung durch Hygiene",
                            "value": "2021-04-27 00:00:00",
                            "required": false,
                            "disabled": false,
                            "errors": [],
                            "order": 3
                        },
                        "measureGa": {
                            "type": "select",
                            "label": "Festlegung des GA",
                            "value": "34",
                            "required": false,
                            "disabled": false,
                            "errors": [],
                            "multiple": false,
                            "multiOptions": [{
                                "label": "Quarant\u00e4ne",
                                "value": "33"
                            }, {
                                "label": "T\u00e4tigkeitsverbot",
                                "value": "34"
                            }, {"label": "darf mit FFP2 weiter arbeiten", "value": "35"}, {
                                "label": "Krankschreibung",
                                "value": "36"
                            }, {"label": "darf mit Vollschutz weiterarbeiten", "value": "37"}],
                            "order": 4
                        },
                        "measureGaDate": {
                            "type": "date",
                            "label": "Datum Festlegung durch GA",
                            "value": "2021-04-27 00:00:00",
                            "required": false,
                            "disabled": false,
                            "errors": [],
                            "order": 5
                        },
                        "leaveRequired": {
                            "type": "switch",
                            "label": "Freistellung erforderlich?",
                            "value": "1",
                            "required": false,
                            "disabled": false,
                            "errors": [],
                            "order": 6
                        },
                        "measureEndDate": {
                            "type": "date",
                            "label": "Freistellung bis einschl.",
                            "value": "2021-04-21 00:00:00",
                            "required": false,
                            "disabled": false,
                            "errors": [],
                            "order": 7
                        },
                        "contactgrade": {
                            "type": "select",
                            "label": "Kontaktgrad",
                            "value": "42",
                            "required": false,
                            "disabled": false,
                            "errors": [],
                            "multiple": false,
                            "multiOptions": [{"label": "1a", "value": "41"}, {
                                "label": "1b",
                                "value": "42"
                            }, {"label": "2", "value": "43"}, {"label": "3", "value": "44"}],
                            "order": 8
                        },
                        "smearRequired": {
                            "type": "switch",
                            "label": "Abstrich erforderlich?",
                            "value": "1",
                            "required": false,
                            "disabled": false,
                            "errors": [],
                            "order": 9
                        },
                        "completed": {
                            "type": "switch",
                            "label": "Daten vollst\u00e4ndig?",
                            "value": "1",
                            "required": false,
                            "disabled": false,
                            "errors": [],
                            "order": 10
                        }
                    },
                    "order": 2
                },
                "tab3": {
                    "type": "tab",
                    "label": "HR",
                    "required": true,
                    "disabled": false,
                    "errors": [],
                    "children": {
                        "lastshiftdate": {
                            "type": "date",
                            "label": "Letzter Dienst",
                            "value": "2021-04-2800:00:00",
                            "required": false,
                            "disabled": false,
                            "errors": [],
                            "order": 1
                        },
                        "healthinsurance": {
                            "type": "text",
                            "label": "Krankenkasse",
                            "value": "AOK",
                            "required": false,
                            "disabled": false,
                            "errors": [],
                            "order": 2
                        },
                        "noticed": {
                            "type": "select",
                            "label": "Informationsstatus des MA",
                            "value": "38",
                            "required": false,
                            "disabled": false,
                            "errors": [],
                            "multiple": false,
                            "multiOptions": [{"label": "ja", "value": "38"}, {
                                "label": "nicht erreicht",
                                "value": "39"
                            }, {"label": "falsche Telefonnummer", "value": "40"}],
                            "order": 3
                        },
                        "completedHr": {
                            "type": "switch",
                            "label": "Daten vollst\u00e4ndig?",
                            "value": "1",
                            "required": false,
                            "disabled": false,
                            "errors": [],
                            "order": 4
                        }
                    },
                    "order": 3
                }
            }
        }
    }
}
</script>
