(function($R)
{
    $R.lang['tr'] = {
        "format": "Format",
        "image": "Görsel",
        "file": "Dosya",
        "link": "Link",
        "bold": "Kalın",
        "italic": "İtalik",
        "deleted": "Üzeri çizgili",
        "underline": "Altı çizgili",
        "superscript": "Superscript",
        "subscript": "Subscript",
        "bold-abbr": "B",
        "italic-abbr": "I",
        "deleted-abbr": "S",
        "underline-abbr": "U",
        "superscript-abbr": "Sup",
        "subscript-abbr": "Sub",
        "lists": "Listeleme",
        "link-insert": "Link ekle",
        "link-edit": "Linki düzenle",
        "link-in-new-tab": "Yeni bir pencerede aç",
        "unlink": "Linki Kaldır",
        "cancel": "Vazgeç",
        "close": "Kapat",
        "insert": "Ekle",
        "save": "Kaydet",
        "delete": "Sil",
        "text": "Metin",
        "edit": "Düzenle",
        "title": "Başlık",
        "paragraph": "Normal yazı",
        "quote": "Alıntı",
        "code": "Kod",
        "heading1": "Başlık 1",
        "heading2": "Başlık 2",
        "heading3": "Başlık 3",
        "heading4": "Başlık 4",
        "heading5": "Başlık 5",
        "heading6": "Başlık 6",
        "filename": "İsim",
        "optional": "opsiyonel",
        "unorderedlist": "Sırasız Liste",
        "orderedlist": "Sıralı Liste",
        "outdent": "Dışarı Doğru",
        "indent": "İçeri Doğru",
        "horizontalrule": "Çizgi",
        "upload": "Upload",
        "upload-label": "Drop files here or click to upload",
        "upload-change-label": "Drop a new image to change",
        "accessibility-help-label": "Zenginleştirilmiş yazı editorü",
        "caption": "Caption",
        "bulletslist": "Bullets",
        "numberslist": "Numbers",
        "image-position": "Position",
        "none": "None",
        "left": "Left",
        "right": "Right",
        "center": "Center",
        "undo": "Undo",
        "redo": "Redo"
    };
})(Redactor);