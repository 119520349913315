var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-simple-table", [
    _c("thead", [
      _c("tr", [
        _c("th", { staticClass: "text-left" }, [
          _vm._v("\n                Unformatted Value\n            "),
        ]),
        _vm._v(" "),
        _c("th", { staticClass: "text-left" }, [
          _vm._v("\n                Formatted Value\n            "),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "tbody",
      _vm._l(_vm.dates, function (date) {
        return _c("tr", [
          _c("td", [_vm._v(_vm._s(date))]),
          _vm._v(" "),
          _c(
            "td",
            [_c("asa-formatter", { attrs: { type: "datetime", value: date } })],
            1
          ),
        ])
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }