var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.show
    ? _c(
        "asa-dialog",
        [
          _c(
            "v-card",
            [
              _c(
                "v-toolbar",
                {
                  staticClass: "flex-grow-0",
                  attrs: {
                    color: _vm.options.color,
                    dark: "",
                    dense: "",
                    flat: "",
                  },
                },
                [
                  _c("v-toolbar-title", { staticClass: "white--text" }, [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.title) +
                        "\n            "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c("v-icon", { on: { click: _vm.cancel } }, [
                    _vm._v("\n                $close\n            "),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-card-text",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !!_vm.message,
                      expression: "!!message",
                    },
                  ],
                  staticClass: "pa-4",
                },
                [_vm._v("\n            " + _vm._s(_vm.message) + "\n        ")]
              ),
              _vm._v(" "),
              _c(
                "v-card-actions",
                {
                  class: {
                    "flex-column": _vm.$vuetify.breakpoint.mobile,
                    "flex-wrap": !_vm.$vuetify.breakpoint.mobile,
                    mobile: _vm.$vuetify.breakpoint.mobile,
                    "form-actions": true,
                    "pl-2": !_vm.$vuetify.breakpoint.mobile,
                    "pt-0": true,
                  },
                },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        block: _vm.$vuetify.breakpoint.mobile,
                        color: "success",
                        type: "button",
                      },
                      on: { click: _vm.agree },
                    },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.options.confirmLabel) +
                          "\n            "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        block: _vm.$vuetify.breakpoint.mobile,
                        type: "button",
                      },
                      on: { click: _vm.cancel },
                    },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.options.rejectLabel) +
                          "\n            "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }