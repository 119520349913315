<template>
  <div class="container">
    <asa-formatter :value="0" :formatter="{KeyValue: [{0: 'Foo', 1: 'Bar'},{}]}" />
    <asa-formatter :value="1" :formatter="{KeyValue: [{0: 'Foo', 1: 'Bar'},{}]}" />
  </div>
</template>


<script>

import {defineComponent} from "vue";
import AsaFormatter from "../../../src/component/AsaFormatter.vue";

export default defineComponent({
  components: {AsaFormatter}
})
</script>


<style scoped>

</style>
