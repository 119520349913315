import {merge} from "webpack-merge";
import fi from './base/fi.js';

export default merge(fi, {
    "lumui.confirm.accept": "OK",
    "lumui.confirm.reject": "Keskeytä",
    "lumui.formatter.boolean.true": "Kyllä",
    "lumui.formatter.boolean.false": "Ei",
    "lumui.form.close": "Sulje",
    "lumui.form.cancel": "Keskeytä",
    "lumui.form.save": "tallenna",
    "lumui.lightbox.title": "Saatavilla olevat kuvat",
    "lumui.loading.message": "Tietoja ladataan",
    "lumui.qr_scanner.camera": "Camera",
    "lumui.qr_scanner.mirror": "Mirror camera ",
    "lumui.qr_scanner.torch": "Camera light",
    "lumui.table.reset_filters": "nollaa",
    "lumui.table.close_filters": "Sulje suodatin",
    "lumui.table.details": "Yksityiskohtainen näkymä",
    "lumui.table.previous": "Edellinen tietue",
    "lumui.table.next": "Seuraava tietue",
    "lumui.table.no_data": "Tietoja ei löytynyt",
    "lumui.table.no_filter_results": "Hakusi ei tuottanut tuloksia",
    "lumui.table.filters_active": "Suodatetut tulokset",
    "lumui.table.rows_per_page": "Riviä sivua kohti",
    "lumui.table.page_text": "{0}-{1} of {2}",
    "lumui.table.btn.export": "Vientiluettelo",
    "lumui.table_action.menu": "Valikko",
    "lumui.table_columns.save": "Tallenna tiedosto",
    "lumui.form.row.invalid_email": "Anna oikea sähköpostiosoite.",
    "lumui.form.row.required": "Tämä kenttä on pakollinen",
    "lumui.form.autocomplete.selected_count": "{c} items selected",
    "lumui.form.autocomplete.select_all": "Valitse kaikki",
    "lumui.form.autocomplete.deselect_all": "Mitään ei valittu",
    "lumui.form.autocomplete.search_hint": "Kirjoita mitä etsit",
    "lumui.form.autocomplete.no_data": "Tietoja ei ole saatavilla",
    "lumui.form.autocomplete.nothing_selected": "Ei valittuja merkintöjä",
    "lumui.form.autocomplete.selected_items": "Valitut merkinnät",
    "lumui.form.date.close": "Sulje",
    "lumui.form.date.save": "OK",
    "lumui.form.file.drag": "Drag file here or click to select",
    "lumui.form.file.error": "Upload failed.",
    "lumui.form.file.extensions.accepted": "Allowed filetypes: {types}",
    "lumui.form.file.extensions.all": "All filetypes allowed",
    "lumui.form.file.maxSize": "Maximum allowed file size {size}.",
    "lumui.form.file.maxSizeError": "The file is larger than the maximum allowed size of {size}.",
    "lumui.form.file.previewError": "No preview for file {name} available.",
    "lumui.form.file.uploading": "{count} files are being uploaded…",
    "lumui.form.file.warning.removed_file": "File {name} was not uploaded. Filetype {type} is not within permitted types {acceptedTypes}.",
    "lumui.form.file.select_file": "Select a file",
    "lumui.form.file.unknown_size": "not specified",
    "lumui.form.localized.copy_to_all": "Hyväksy kaikkien kielten syötteet",
    "lumui.form.ordered_choice.no_data": "merkintöjä ei ole saatavilla",
    "lumui.form.ordered_choice.select_placeholder": "valitse uusi merkintä",
    "lumui.form.password.repeat": "Toista salasana",
    "lumui.form.password.not_matching_error": "Molempien salasanojen on vastattava toisiaan.",
    "lumui.form.signature.clear": "Delete signature",
    "lumui.form.signature.upload": "Upload signature",
    "lumui.form.time.close": "Sulje",
    "lumui.form.time.save": "OK",
    "lumui.locale.lang.german": "German",
    "lumui.locale.lang.english": "English",
    "lumui.locale.lang.italian": "Italian",
    "lumui.locale.lang.french": "French",
    "lumui.status.no_action_required": "ei vaadittua toimenpidettä",
    "lumui.status.hint": "Huomautus",
    "lumui.status.action_required_soon": "pikaista toimintaa vaaditaan!",
    "lumui.status.action_required_immediately": "tarvitaan välittömiä toimia!",
    "lumui.locale.de-DE": "German",
    "lumui.locale.de-CH": "German (Suisse)",
    "lumui.locale.fr-CH": "French (Suisse)",
    "lumui.locale.it-CH": "Italian (Suisse)",
    "lumui.locale.en-US": "English (USA)",
    "lumui.locale.en-GB": "English (GB)",
    "lumui.locale.fi-FI": "Finnish",
    "lumui.locale.et-EE": "Estonian",
    "lumui.locale.lt-LT": "Lithuanian",
    "lumui.locale.lv-LV": "Latvian",
    "lumui.locale.lang.finnish": "Finnish",
    "lumui.locale.lang.estonian": "Estonian",
    "lumui.locale.lang.lithuanian": "Lithuanian",
    "lumui.locale.lang.latvian": "Latvian",
    "lumui.locale.lang.hungarian": "Hungarian",
});
