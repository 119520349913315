var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "asa-form",
    {
      attrs: { loading: false, config: _vm.cfg },
      model: {
        value: _vm.value,
        callback: function ($$v) {
          _vm.value = $$v
        },
        expression: "value",
      },
    },
    [
      _c(
        "template",
        { slot: "actions" },
        [
          _c("v-btn", { attrs: { color: "success" } }, [
            _vm._v("Extra Long Chilly Cheese Burger"),
          ]),
          _vm._v(" "),
          _c("v-btn", { attrs: { color: "success" } }, [
            _vm._v("Just make it overlong already"),
          ]),
          _vm._v(" "),
          _c("v-btn", { attrs: { color: "success" } }, [
            _vm._v("Yet another really long button label"),
          ]),
          _vm._v(" "),
          _c("v-btn", { attrs: { color: "success" } }, [
            _vm._v("Lorem Ipsum dolor sit amet"),
          ]),
          _vm._v(" "),
          _c("v-btn", { attrs: { color: "success" } }, [
            _vm._v("One Button too many"),
          ]),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }