var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-container",
        [
          _c(
            "v-row",
            [
              _c("v-col", [
                _c("h1", { staticClass: "text-h3" }, [_vm._v("Decimal")]),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12", md: "8" } },
                [
                  _c("asa-form", {
                    attrs: { config: _vm.config },
                    scopedSlots: _vm._u([
                      {
                        key: "title",
                        fn: function () {
                          return [
                            _vm._v(
                              "\n                        Eingabe einer Fließkommazahl (min: " +
                                _vm._s(_vm.config.p.min) +
                                ", max: " +
                                _vm._s(_vm.config.p.max) +
                                ", step: " +
                                _vm._s(_vm.config.p.step) +
                                ")\n                    "
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                    model: {
                      value: _vm.value,
                      callback: function ($$v) {
                        _vm.value = $$v
                      },
                      expression: "value",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-col",
                { attrs: { cols: "12", md: "4" } },
                [
                  _c(
                    "v-card",
                    { staticClass: "fill-height" },
                    [
                      _c("v-card-title", { staticClass: "elevation-1" }, [
                        _vm._v(
                          "\n                        Value\n                    "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("v-card-text", { staticClass: "py-4" }, [
                        _c("pre", [
                          _vm._v(_vm._s(JSON.stringify(_vm.value, null, 4))),
                        ]),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12", md: "8" } },
                [
                  _c("asa-form", {
                    attrs: { config: _vm.config2 },
                    scopedSlots: _vm._u([
                      {
                        key: "title",
                        fn: function () {
                          return [
                            _vm._v(
                              "\n                        Eingabe einer Fließkommazahl (min: " +
                                _vm._s(_vm.config2.p.min) +
                                ", step: " +
                                _vm._s(_vm.config2.p.step) +
                                ")\n                    "
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                    model: {
                      value: _vm.value2,
                      callback: function ($$v) {
                        _vm.value2 = $$v
                      },
                      expression: "value2",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-col",
                { attrs: { cols: "12", md: "4" } },
                [
                  _c(
                    "v-card",
                    { staticClass: "fill-height" },
                    [
                      _c("v-card-title", { staticClass: "elevation-1" }, [
                        _vm._v(
                          "\n                        Value\n                    "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("v-card-text", { staticClass: "py-4" }, [
                        _c("pre", [
                          _vm._v(_vm._s(JSON.stringify(_vm.value2, null, 4))),
                        ]),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12", md: "8" } },
                [
                  _c("asa-form", {
                    attrs: { config: _vm.config3 },
                    scopedSlots: _vm._u([
                      {
                        key: "title",
                        fn: function () {
                          return [
                            _vm._v(
                              "\n                        Eingabe einer Fließkommazahl (max: " +
                                _vm._s(_vm.config3.p.max) +
                                ", step: " +
                                _vm._s(_vm.config3.p.step) +
                                ")\n                    "
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                    model: {
                      value: _vm.value3,
                      callback: function ($$v) {
                        _vm.value3 = $$v
                      },
                      expression: "value3",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-col",
                { attrs: { cols: "12", md: "4" } },
                [
                  _c(
                    "v-card",
                    { staticClass: "fill-height" },
                    [
                      _c("v-card-title", { staticClass: "elevation-1" }, [
                        _vm._v(
                          "\n                        Value\n                    "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("v-card-text", { staticClass: "py-4" }, [
                        _c("pre", [
                          _vm._v(_vm._s(JSON.stringify(_vm.value3, null, 4))),
                        ]),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }