import "regenerator-runtime/runtime";
import "./lib/array";
import FormatterFactory from "./lib/formatter";
import VueDebounce from "vue-debounce";
import VueSignaturePad from "vue-signature-pad";
import { createStore, STORE_NAMESPACE } from "./store";
import disableForm from "./lib/form/disableForm";
import clearFormErrors from "./lib/form/clearFormErrors";
import setFormErrors from "./lib/form/setFormErrors";
import VueI18n from "vue-i18n";
import Vue from "vue";
import translations from "./translations";
let installed = false;
Vue.use(VueDebounce);
Vue.use(VueI18n);
const i18n = new VueI18n({
    messages: translations,
    formatFallbackMessages: true
});
const requireComponent = require.context("./component", false, /[\w-]+\.vue$/);
const defaultIcons = {
    download: "fa-solid fa-download",
    upload: "fa-solid fa-cloud-arrow-up",
    delete: "fa-solid fa-trash",
    prev: "fa-solid fa-chevron-left",
    up: "fa-solid fa-chevron-up",
    down: "fa-solid fa-chevron-down",
    search: "fa-solid fa-magnifying-glass",
    moreVert: "fa-solid fa-ellipsis-vertical",
    warning: "fa-solid fa-triangle-exclamation",
    selectAll: "fa-solid fa-check-double",
    clear: "fa-solid fa-times-circle",
    close: "fa-solid fa-times",
    dropUp: "fa-solid fa-caret-up",
    dropDown: "fa-solid fa-caret-down",
    zoomIn: "fa-solid fa-magnifying-glass-plus",
    zoomOut: "fa-solid fa-magnifying-glass-minus",
    setAllToCurrent: "fa-solid fa-rotate-right",
    calendar: "fa-solid fa-calendar",
    event: "fa-solid fa-clock",
    reveal: "fa-solid fa-eye",
    hide: "fa-solid fa-eye-slash",
    add: "fa-solid fa-plus",
};
const plugin = {
    install(VueInstance, options) {
        if (installed) {
            return;
        }
        installed = true;
        Object.assign(options.vuetify.framework.icons.values, defaultIcons, options.icons);
        const persistentFilters = options.table?.persistentFilters || true;
        const availableLocales = Object.keys(options.translations);
        if (availableLocales.length == 0) {
            throw Error("lumui: no translations provided");
        }
        const localeRegexp = /([a-z]+)(-[a-zA-Z]+)/;
        for (const l in options.translations) {
            const parts = l.match(localeRegexp);
            if (!parts) {
                console.error(l + " does not match " + localeRegexp);
                throw Error(l + " does not match " + localeRegexp);
            }
            if (!(parts[1] in translations)) {
                console.warn("lumui: no default translation for " + l);
            }
        }
        i18n.fallbackLocale = options.fallbackLocale;
        VueInstance.prototype.$lumui = {
            i18n: i18n,
            table: {
                persistentFilters: persistentFilters,
            },
            mapLocale: options.localeKeyMap ?
                (l) => {
                    if (!options.localeKeyMap[l] == undefined) {
                        throw Error("Mapping for locale " + l + " in not defined in the lumui config.");
                    }
                    return options.localeKeyMap[l];
                } : (l) => l,
            lookupLocale: options.localeKeyMap ?
                (k) => {
                    for (const l in options.localeKeyMap) {
                        if (options.localeKeyMap[l] == k) {
                            return l;
                        }
                    }
                    return null;
                } : (k) => k
        };
        /** @deprecated */
        VueInstance.prototype.$lumUi = VueInstance.prototype.$lumui;
        VueInstance.prototype.disableForm = disableForm;
        VueInstance.prototype.clearFormErrors = clearFormErrors;
        VueInstance.prototype.setFormErrors = setFormErrors;
        VueInstance.use(VueSignaturePad);
        const store = options.store;
        const locale = typeof options.translations[options.locale] !== 'undefined' ? options.locale : VueInstance.prototype.$lumui.lookupLocale(options.locale);
        if (!locale) {
            console.error('Could not resolve locale ' + options.locale);
        }
        const fallbackLocale = typeof options.translations[options.fallbackLocale] !== 'undefined' ? options.fallbackLocale : VueInstance.prototype.$lumui.lookupLocale(options.fallbackLocale);
        if (!fallbackLocale) {
            console.error('Could not resolve fallbackLocale ' + options.fallbackLocale);
        }
        store.registerModule(STORE_NAMESPACE, createStore(options.translations, i18n, locale, fallbackLocale, VueInstance.prototype.$lumui.mapLocale));
        for (const l in options.translations) {
            const translation = options.translations[l];
            if (typeof translation !== 'function') {
                store.dispatch("lumui/i18n/_loadLocale", l);
            }
        }
        store.dispatch("lumui/i18n/setLocale", locale);
        requireComponent.keys().forEach((fileName) => {
            const componentConfig = requireComponent(fileName);
            const componentName = fileName
                .split("/")
                .pop()
                .replace(/\.\w+$/, "");
            Vue.component(componentName, componentConfig.default || componentConfig);
        });
    },
};
export default plugin;
export { FormatterFactory, i18n, plugin };
