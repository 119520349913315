var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-autocomplete", {
    attrs: {
      items: _vm.items,
      "item-text": "label",
      "item-value": "key",
      "return-object": "",
      label: _vm.config.label,
      clearable: _vm.clearable,
      "hide-details": "",
    },
    scopedSlots: _vm._u([
      {
        key: "selection",
        fn: function ({ parent, item, index, selected }) {
          return [
            _c("span", { staticClass: "mr-1" }, [_vm._v(_vm._s(item.label))]),
            _vm._v(" "),
            item.parents
              ? _c("span", { staticClass: "caption gray--text mr-1" }, [
                  _vm._v(_vm._s(item.parents)),
                ])
              : _vm._e(),
          ]
        },
      },
      {
        key: "item",
        fn: function ({ item, on }) {
          return [
            _c(
              "v-list-item",
              _vm._g(
                {
                  class: {
                    "v-item--disabled v-list-item--disabled": item.disabled,
                  },
                },
                on
              ),
              [
                _c(
                  "v-list-item-content",
                  [
                    _c("v-list-item-title", [
                      _vm._v(
                        "\n                    " +
                          _vm._s(item.label) +
                          "\n                "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("v-list-item-subtitle", { staticClass: "caption" }, [
                      _vm._v(
                        "\n                    " +
                          _vm._s(item.parents) +
                          "\n                "
                      ),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        },
      },
    ]),
    model: {
      value: _vm.config.value,
      callback: function ($$v) {
        _vm.$set(_vm.config, "value", $$v)
      },
      expression: "config.value",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }