<template>
    <div>
        <v-container>
            <v-row>
                <v-col cols="12" md="8">
                    <asa-form v-model="data" :config="formConf" :abortBtn="false">
                        <template slot="title">WYSIWYG</template>
                    </asa-form>
                </v-col>
                <v-col cols="12" md="4">
                    <v-card class="fill-height">
                        <v-card-title class="elevation-1">
                            Raw
                        </v-card-title>
                        <v-card-text class="py-4">
                            <pre>{{ JSON.stringify(data, null, 4) }}</pre>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import { VRow, VCol, VIcon, VCard, VCardText, VCardTitle, VContainer } from 'vuetify/lib'

export default {
    name: "FormWysiwyg",
    components: { VRow, VCol, VIcon, VCard, VCardText, VCardTitle, VContainer },
    data() {
        return {
            formConf: {
                "text": {
                    "type": "wysiwyg",
                    "label": "Text",
                    "order": 1
                }
            },
            data: {},
        }
    }
}
</script>
