var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("form-tabs-content", {
        attrs: {
          "active-tab": _vm.activeTab,
          config: _vm.config,
          "render-items": true,
          value: _vm.value,
        },
        on: {
          "update:error": function ($event) {
            return _vm.passThroughErrors($event)
          },
        },
      }),
      _vm._v(" "),
      _c("form-accordion", {
        attrs: { config: _vm.config, value: _vm.value },
        on: {
          "update:error": function ($event) {
            return _vm.passThroughErrors($event)
          },
        },
      }),
      _vm._v(" "),
      _c("form-rows", {
        attrs: { config: _vm.config, value: _vm.value },
        on: {
          "update:error": function ($event) {
            return _vm.passThroughErrors($event)
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }