<template>
    <div>
        <v-container>
            <v-row>
                <v-col>
                    <h1 class="text-h3">
                        Password
                    </h1>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" md="8">
                    <asa-form v-model="value" :config="config" @save="save('save')" />
                </v-col>
                <v-col cols="12" md="4">
                    <v-card class="fill-height">
                        <v-card-title class="elevation-1">
                            Value
                        </v-card-title>
                        <v-card-text class="py-4">
                            <pre>{{ JSON.stringify(value, null, 4) }}</pre>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" md="8">
                    <asa-form v-model="value1" :config="config1" @save="save('save1')" />
                </v-col>
                <v-col cols="12" md="4">
                    <v-card class="fill-height">
                        <v-card-title class="elevation-1">
                            Value
                        </v-card-title>
                        <v-card-text class="py-4">
                            <pre>{{ JSON.stringify(value1, null, 4) }}</pre>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" md="8">
                    <asa-form v-model="value2" :config="config2" @save="save('save2')" />
                </v-col>
                <v-col cols="12" md="4">
                    <v-card class="fill-height">
                        <v-card-title class="elevation-1">
                            Value
                        </v-card-title>
                        <v-card-text class="py-4">
                            <pre>{{ JSON.stringify(value2, null, 4) }}</pre>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" md="8">
                    <asa-form v-model="value3" :config="config3" @save="save('save3')">
                        <template #actions="{ save: saveFn }">
                            <v-btn @click="saveFn()">
                                asdf
                            </v-btn>
                        </template>
                    </asa-form>
                </v-col>
                <v-col cols="12" md="4">
                    <v-card class="fill-height">
                        <v-card-title class="elevation-1">
                            Value
                        </v-card-title>
                        <v-card-text class="py-4">
                            <pre>{{ JSON.stringify(value3, null, 4) }}</pre>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" md="8">
                    <asa-form v-model="value4" :config="config4" @save="save('save4')" />
                </v-col>
                <v-col cols="12" md="4">
                    <v-card class="fill-height">
                        <v-card-title class="elevation-1">
                            Value
                        </v-card-title>
                        <v-card-text class="py-4">
                            <pre>{{ JSON.stringify(value4, null, 4) }}</pre>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import AsaForm from "../../../src/component/AsaForm";
import {VCard, VCardText, VCardTitle, VCol, VContainer, VRow, VBtn} from 'vuetify/lib';

export default {
    name: "Password",
    components: { AsaForm, VRow, VCol, VCard, VCardText, VCardTitle, VContainer, VBtn },
    data() {
        return {
            value: {},
            config: {
                "p0": {
                    "type": "password",
                    "label": "Passwort confirm required",
                    "value": "",
                    "required": true
                }
            },
            value1: {},
            config1: {
                "p1": {
                    "type": "password",
                    "label": "Passwort confirm not required",
                    "value": "",
                    "required": false
                }
            },
            value2: {},
            config2: {
                "p2": {
                    "type": "password",
                    "label": "Passwort no confirm required",
                    "value": "",
                    "required": true,
                    "no_confirm": true
                }
            },
            value3: {},
            config3: {
                "p3.1": {
                    type: "text",
                    required: true
                },
                "p3": {
                    "type": "password",
                    "label": "Passwort no confirm not required",
                    "value": "",
                    "required": false,
                    "no_confirm": true
                }
            },
            value4: {
                "login": "",
                "passwort": ""
            },
            config4: {
                "login": {
                    "type": "text",
                    "label": "Real-Life Asa login",
                    "required": true,
                    "disabled": false,
                    "order": 1
                },
                "passwort": {
                    "type": "password",
                    "label": "Passwort",
                    "no_confirm": true,
                    "required": true,
                    "disabled": false,
                    "order": 2
                }
            }

        };
    },
    methods: {
        save(msg) {
            alert(msg);
        }
    }
};
</script>

