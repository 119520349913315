<template>
    <v-text-field :id="'filter_' + config.key" v-model="config.value" :label="config.label" :clearable="clearable" hide-details />
</template>

<script>
import {VTextField} from 'vuetify/lib';

/**
 * Renders a text filter
 * @internal
 */
export default {
    name: "AsaTableFilterText",
    components: {VTextField},
    props: {
        config: {
            type: Object,
            required: true,
        }
    },
    computed: {
        clearable() {
            if (this.config.hasOwnProperty('clearable')) {
                return !!this.config.clearable;
            } else {
                return true;
            }
        }
    },
}
</script>
