import {merge} from "webpack-merge";
import lv from './base/lv.js';

export default merge(lv, {
    "lumui.confirm.accept": "OK",
    "lumui.confirm.reject": "Pārtraukt",
    "lumui.formatter.boolean.true": "Jā",
    "lumui.formatter.boolean.false": "Nē",
    "lumui.form.close": "Aizvērt",
    "lumui.form.cancel": "Pārtraukt",
    "lumui.form.save": "Saglabāt",
    "lumui.lightbox.title": "Esošie attēli",
    "lumui.loading.message": "Dati tiek ielādēti",
    "lumui.qr_scanner.camera": "Camera",
    "lumui.qr_scanner.mirror": "Mirror camera ",
    "lumui.qr_scanner.torch": "Camera light",
    "lumui.table.reset_filters": "atiestatīt",
    "lumui.table.close_filters": "Sakļaut filtrus",
    "lumui.table.details": "Detalizēts skats",
    "lumui.table.previous": "Iepriekšējais ieraksts",
    "lumui.table.next": "Nākamais ieraksts",
    "lumui.table.no_data": "Dati nav atrasti",
    "lumui.table.no_filter_results": "Jūsu meklēšana nedeva nekādus rezultātus",
    "lumui.table.filters_active": "Rezultāti filtrēti",
    "lumui.table.rows_per_page": "Rindas lapā",
    "lumui.table.page_text": "{0}-{1} of {2}",
    "lumui.table.btn.export": "Eksporta saraksts",
    "lumui.table_action.menu": "Izvēlne",
    "lumui.table_columns.save": "Saglabāt failu",
    "lumui.form.row.invalid_email": "Lūdzu, ievadiet pareizu e-pasta adresi.",
    "lumui.form.row.required": "Šis ir obligāts lauks",
    "lumui.form.autocomplete.selected_count": "{c} items selected",
    "lumui.form.autocomplete.select_all": "Atlasīt visus",
    "lumui.form.autocomplete.deselect_all": "Nekas nav atlasīts",
    "lumui.form.autocomplete.search_hint": "Lūdzu, ievadiet to, ko meklējat",
    "lumui.form.autocomplete.no_data": "Nav pieejami dati",
    "lumui.form.autocomplete.nothing_selected": "Nav atlasīts neviens ieraksts",
    "lumui.form.autocomplete.selected_items": "Atlasītie ieraksti",
    "lumui.form.date.close": "Aizvērt",
    "lumui.form.date.save": "OK",
    "lumui.form.file.drag": "Drag file here or click to select",
    "lumui.form.file.error": "Upload failed.",
    "lumui.form.file.extensions.accepted": "Allowed filetypes: {types}",
    "lumui.form.file.extensions.all": "All filetypes allowed",
    "lumui.form.file.maxSize": "Maximum allowed file size {size}.",
    "lumui.form.file.maxSizeError": "The file is larger than the maximum allowed size of {size}.",
    "lumui.form.file.previewError": "No preview for file {name} available.",
    "lumui.form.file.uploading": "{count} files are being uploaded…",
    "lumui.form.file.warning.removed_file": "File {name} was not uploaded. Filetype {type} is not within permitted types {acceptedTypes}.",
    "lumui.form.file.select_file": "Select a file",
    "lumui.form.file.unknown_size": "not specified",
    "lumui.form.localized.copy_to_all": "Pieņemt ierakstu visās valodās",
    "lumui.form.ordered_choice.no_data": "nav pieejami ieraksti",
    "lumui.form.ordered_choice.select_placeholder": "atlasīt jaunu ierakstu",
    "lumui.form.password.repeat": "Atkārtot paroli",
    "lumui.form.password.not_matching_error": "Abām parolēm ir jāsakrīt.",
    "lumui.form.signature.clear": "Delete signature",
    "lumui.form.signature.upload": "Upload signature",
    "lumui.form.time.close": "Aizvērt",
    "lumui.form.time.save": "OK",
    "lumui.locale.lang.german": "German",
    "lumui.locale.lang.english": "English",
    "lumui.locale.lang.italian": "Italian",
    "lumui.locale.lang.french": "French",
    "lumui.status.no_action_required": "nekāda darbība nav nepieciešama",
    "lumui.status.hint": "Piezīme",
    "lumui.status.action_required_soon": "drīzumā jārīkojas!",
    "lumui.status.action_required_immediately": "nepieciešama tūlītēja rīcība!",
    "lumui.locale.de-DE": "German",
    "lumui.locale.de-CH": "German (Suisse)",
    "lumui.locale.fr-CH": "French (Suisse)",
    "lumui.locale.it-CH": "Italian (Suisse)",
    "lumui.locale.en-US": "English (USA)",
    "lumui.locale.en-GB": "English (GB)",
    "lumui.locale.fi-FI": "Finnish",
    "lumui.locale.et-EE": "Estonian",
    "lumui.locale.lt-LT": "Lithuanian",
    "lumui.locale.lv-LV": "Latvian",
    "lumui.locale.lang.finnish": "Finnish",
    "lumui.locale.lang.estonian": "Estonian",
    "lumui.locale.lang.lithuanian": "Lithuanian",
    "lumui.locale.lang.latvian": "Latvian",
    "lumui.locale.lang.hungarian": "Hungarian",
});
