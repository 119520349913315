var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "asa-dialog",
        [
          _c("asa-form", {
            attrs: { config: _vm.formConf },
            scopedSlots: _vm._u([
              {
                key: "title",
                fn: function () {
                  return [
                    _vm._v(
                      "\n                WYIWYG editor im dialog\n            "
                    ),
                  ]
                },
                proxy: true,
              },
            ]),
            model: {
              value: _vm.data,
              callback: function ($$v) {
                _vm.data = $$v
              },
              expression: "data",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }