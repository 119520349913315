<template>
    <asa-form :loading="false" v-model="value" :config="cfg">
        <template slot="actions">
            <v-btn color="success">Extra Long Chilly Cheese Burger</v-btn>
            <v-btn color="success">Just make it overlong already</v-btn>
            <v-btn color="success">Yet another really long button label</v-btn>
            <v-btn color="success">Lorem Ipsum dolor sit amet</v-btn>
            <v-btn color="success">One Button too many</v-btn>
        </template>
    </asa-form>
</template>

<script>
import AsaForm from "../../../src/component/AsaForm";
import { VBtn } from "vuetify/lib";
export default {
    name: "long_button_labels",
    components: {AsaForm, VBtn},
    data() {
        return {
            value: {},
            cfg: {
                field: {
                    type: "text",
                    label: 'Field'
                }
            }
        }
    }
}
</script>

<style scoped>

</style>
