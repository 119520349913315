var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-select", {
    attrs: {
      id: "filter_" + _vm.config.key,
      items: _vm.items,
      "menu-props": { offsetY: true },
      label: _vm.config.label,
      clearable: _vm.clearable,
      "hide-details": "",
    },
    model: {
      value: _vm.config.value,
      callback: function ($$v) {
        _vm.$set(_vm.config, "value", $$v)
      },
      expression: "config.value",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }