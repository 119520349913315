<template>
    <div>
        <v-input
            v-if="fieldType"
            :id="name"
            :class="styleClass"
            :error="error"
            :error-count="errorMessages.length"
            :error-messages="errorMessages"
            :hint="description || ''"
            :label="label !== 'html' ? label : null"
            :name="name"
            :required="false"
            class="v-text-field html-field"
        >
            <div class="subtitle-2 input" v-html="sanitized" />
        </v-input>

        <template v-else>
            <div v-if="label !== 'html'" class="subtitle-1">
                {{ label }}
            </div>
            <div :class="styleClass" v-html="sanitized" />
        </template>
    </div>
</template>

<script>
import HtmlSanitizer from '../lib/HtmlSanitizer';
import {VInput} from 'vuetify/lib';

/**
 * | key                    | type                       | required | default    | description |
 * |------------------------|----------------------------|----------|------------|-------------|
 * | type                   | `String`                   | yes      |            | field type  |
 * | label                  | `String`, `false`          | no       | `false`    | fields label |
 * | class                  | `String`                   | no       | `null`     | css class for custom styling |
 * | required               | `Boolean`, `eval(String)`  | no       | `false`    | field is required.|
 * | disabled               | `Boolean`, `eval(String)`  | no       | `false`    | field is disabled.|
 * | visible                | `Boolean`, `eval(String)`  | no       | `false`    | field is rendered.  |
 * | description            | `String`                   | no       | `""`       | description |
 * | fieldType              | `Boolean`                  | no       | `true`     | wrap in v-input, as to match input element style |
 */
export default {
    name: "FormRowHtml",
    components: {VInput},
    props: {
        /** html string to display. html is sanitized. */
        value: {
            type: String,
            default: ''
        },
        /** label */
        label: {
            type: String,
            default: ""
        },
        /** element name (only used when `fieldType` is `true`) */
        name: {
            type: String,
            default: ""
        },
        /** element description (only used when `fieldType` is `true`) */
        description: {
            type: String,
            default: ""
        },
        /** emulate input element style */
        fieldType: {
            type: Boolean,
            default: true,
        },
        /** validation error exists (only used when `fieldType` is `true`) */
        error: {
            type: Boolean,
            default: false,
        },
        /** validation messages (only used when `fieldType` is `true`) */
        errorMessages: {
            type: Array,
            default: () => [],
        },
        /** css class */
        styleClass: {
            type: String,
            default: ''
        },
    },
    data() {
        return {}
    },
    computed: {
        sanitized() {
            return HtmlSanitizer.SanitizeHtml(this.value);
        }
    }
}
</script>

<style>
.html-field .input {
    display: flex;
    flex: 1 1 auto;
    margin: 0;
    line-height: 20px;
    padding: 8px 0 8px;
    max-width: 100%;
    min-width: 0;
    width: 100%;
    max-height: 32px;
}

.html-field label {
    max-width: 133%;
    transform: translateY(-18px) scale(0.75);
    position: absolute !important;
}
</style>
