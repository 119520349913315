import DOMPurifiy from 'dompurify'

export default class LocalizedFormatter {
    constructor(settings) {
        this.isHtmlRequired = settings.isHtmlRequired && typeof settings.isHtmlRequired == "boolean" ? settings.isHtmlRequired : false
    }

    requiresHTML() {
        return this.isHtmlRequired;
    }

    format(value) {
        if (value === null || value === undefined) {
            return "";
        }
        return this.isHtmlRequired ? DOMPurifiy.sanitize(value) : value;
    }
}
