<template>
    <div>
        <v-text-field
            :id="name"
            v-model="viewValue"
            filled
            :disabled="disabled"
            :error="error"
            :error-count="errorMessages.length"
            :error-messages="errorMessages"
            :hint="config.description || ''"
            :label="config.label"
            :name="name"
            :required="required"
            :rules="computedRules"
            :autocomplete="config.autocomplete || 'off'"
            :type="reveal ? 'text' : 'password'"
            :hide-details="config.no_confirm ? false : 'auto'"
        >
            <template #append>
                <v-icon
                    v-if="reveal"
                    @click="reveal = false"
                >
                    $hide
                </v-icon>
                <v-icon
                    v-else
                    @click="reveal = true"
                >
                    $reveal
                </v-icon>
                <v-icon
                    v-if="showGenerator"
                    class="ml-1"
                    @click="generatePassword"
                >
                    $randomize
                </v-icon>
            </template>
        </v-text-field>
        <div
            v-if="lowerChars + upperChars + numberChars + specialChars + totalChars > 0"
            class="ml-1 my-2 text-caption"
        >
            {{ $lumui.i18n.t('lumui.form.password.requirements.title') }}
            <ul class="p0">
                <li
                    v-if="lowerChars > 0"
                    :class="lowerCharsValid ? 'valid' : 'invalid'"
                >
                    {{ $lumui.i18n.tc('lumui.form.password.requirements.lower_chars', lowerChars, {count: lowerChars}) }}
                </li>
                <li
                    v-if="upperChars > 0"
                    :class="upperCharsValid ? 'valid' : 'invalid'"
                >
                    {{ $lumui.i18n.tc('lumui.form.password.requirements.upper_chars', upperChars, {count: upperChars}) }}
                </li>
                <li
                    v-if="numberChars > 0"
                    :class="numberCharsValid ? 'valid' : 'invalid'"
                >
                    {{ $lumui.i18n.tc('lumui.form.password.requirements.number_chars', numberChars, {count: numberChars}) }}
                </li>
                <li
                    v-if="specialChars > 0"
                    :class="specialCharsValid ? 'valid' : 'invalid'"
                >
                    {{ $lumui.i18n.tc('lumui.form.password.requirements.special_chars', specialChars, {count: specialChars}) }}
                </li>
                <li
                    v-if="totalChars > 0"
                    :class="totalCharsValid ? 'valid' : 'invalid'"
                >
                    {{ $lumui.i18n.tc('lumui.form.password.requirements.total_chars', totalChars, {count: totalChars}) }}
                </li>
            </ul>
        </div>
        <div v-else class="ma-7" />
        <v-text-field
            v-if="!config.no_confirm"
            :id="name+'_confirm'"
            ref="confirm"
            v-model="confirm"
            filled
            :disabled="disabled"
            :label="$lumui.i18n.t('lumui.form.password.repeat')"
            :name="name+'_confirm'"
            :required="viewValue !== ''"
            :rules="[ v => !viewValue || v === viewValue|| $lumui.i18n.t('lumui.form.password.not_matching_error') ]"
            autocomplete="off"
            :type="reveal ? 'text' : 'password'"
        />
    </div>
</template>

<script>
import {VTextField} from 'vuetify/lib';
import {VIcon} from 'vuetify/lib';

/**
 * | key                    | type                      | required | default | description                     |
 * |------------------------|---------------------------|----------|---------|---------------------------------|
 * | type                   | `String`                  | yes      |         | field type                      |
 * | label                  | `String`, `false`         | no       | `false` | fields label                    |
 * | required               | `Boolean`, `eval(String)` | no       | `false` | field is required.              |
 * | disabled               | `Boolean`, `eval(String)` | no       | `false` | field is disabled.              |
 * | visible                | `Boolean`, `eval(String)` | no       | `false` | field is rendered.              |
 * | description            | `String`                  | no       | `""`    | field hint                      |
 * | no_confirm             | `Boolean`                 | no       | `false` | do not require confirmation     |
 * | autocomplete           | `String`                  | no       | `"off"` | set autocomplete value          |
 * | lowerChars             | `Number`                  | no       | `0`     | minimum amount of lower chars   |
 * | upperChars             | `Number`                  | no       | `0`     | minimum amount of upper chars   |
 * | numberChars            | `Number`                  | no       | `0`     | minimum amount of number chars  |
 * | specialChars           | `Number`                  | no       | `0`     | minimum amount of special chars |
 * | totalChars             | `Number`                  | no       | `0`     | minimum amount of total chars   |
 * | showGenerator          | `Boolean`                 | no       | `false` | show password generator         |
 */
export default {
    name: "FormRowPassword",
    components: {VTextField, VIcon},
    props: {
        /** value */
        value: {
            type: String,
            default: ""
        },
        /** field config */
        config: {
            type: Object,
            default: null
        },
        /** field name */
        name: {
            type: String,
            default: ""
        },
        /** validation errors exist */
        error: {
            type: Boolean,
            default: false,
        },
        /** validation messages */
        errorMessages: {
            type: Array,
            default: () => [],
        },
        /** field is required */
        required: {
            type: Boolean,
            default: false,
        },
        /** validate when field looses focus */
        validateOnBlur: {
            type: Boolean,
            default: true,
        },
        /** field is disabled */
        disabled: {
            type: Boolean,
            default: false
        },
        /** validation rules */
        rules: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            viewValue: null,
            confirm: null,
            reveal: false
        }
    },
    computed: {
        lowerCharsValid() {
            return (
                !!this.viewValue &&
                (this.viewValue.match(/[a-z]/g) ? this.viewValue.match(/[a-z]/g).length : 0) >= this.lowerChars
            )
        },
        upperCharsValid() {
            return (
                !!this.viewValue &&
                (this.viewValue.match(/[A-Z]/g) ? this.viewValue.match(/[A-Z]/g).length : 0) >= this.upperChars
            )
        },
        numberCharsValid() {
            return (
                !!this.viewValue &&
                (this.viewValue.match(/\d/g) ? this.viewValue.match(/\d/g).length : 0) >= this.numberChars
            )
        },
        specialCharsValid() {
            return (
                !!this.viewValue &&
                (this.viewValue.match(/[\W_]/g) ? this.viewValue.match(/[\W_]/g).length : 0) >= this.specialChars
            )
        },
        totalCharsValid() {
            return !!this.viewValue && this.viewValue.length >= this.totalChars
        },
        showGenerator() {
            if (this.config.showGenerator) {
                if (
                    this.lowerChars + this.upperChars + this.numberChars + this.specialChars === 0 &&
                    this.totalChars === 0
                ) {
                    console.log('warning: unable to use password generator if there is no requirements given.');

                    return false;
                } else {
                    return true;
                }
            } else {
                return false;
            }
        },
        lowerChars() {
            return this.config.lowerChars ?? 0;
        },
        upperChars() {
            return this.config.upperChars ?? 0;
        },
        numberChars() {
            return this.config.numberChars ?? 0;
        },
        specialChars() {
            return this.config.specialChars ?? 0;
        },
        totalChars() {
            return this.config.totalChars ?? 0;
        },
        lowCharsPool() {
            return 'abcdefghijklmnopqrstuvwxyz';
        },
        upperCharsPool() {
            return 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
        },
        numberCharsPool() {
            return '0123456789';
        },
        specialCharsPool() {
            return "!\"#$%&'()*+,-./:;<=>?@[\\]^_{|}~`";
        },
        completeCharsPool() {
            return this.lowCharsPool + this.upperCharsPool + this.numberCharsPool + this.specialCharsPool
        },
        computedRules() {
            const rules = this.rules;

            rules.push(
                (val) =>
                    !val ||
                    (
                        (val.match(/[a-z]/g) ? val.match(/[a-z]/g).length : 0) >= this.lowerChars &&
                        (val.match(/[A-Z]/g) ? val.match(/[A-Z]/g).length : 0) >= this.upperChars &&
                        (val.match(/\d/g) ? val.match(/\d/g).length : 0) >= this.numberChars &&
                        (val.match(/[\W_]/g) ? val.match(/[\W_]/g).length : 0) >= this.specialChars &&
                        val.length >= this.totalChars
                    )
            )

            return rules;
        }
    },
    watch: {
        viewValue() {
            if (!this.config.no_confirm) {
                this.$refs.confirm.validate(true);
            }
            /**
             * user input
             * @param {String} viewValue
             */
            this.$emit('input', this.viewValue);
        },
        value(newValue) {
            this.viewValue = newValue;
        }
    },
    mounted() {
        if (this.value) {
            this.viewValue = this.value
        }
    },
    methods: {
        generatePassword() {
            let password = '';

            password += this.generateRandomString(this.lowCharsPool, this.lowerChars);
            password += this.generateRandomString(this.upperCharsPool, this.upperChars);
            password += this.generateRandomString(this.numberCharsPool, this.numberChars);
            password += this.generateRandomString(this.specialCharsPool, this.specialChars);
            password += this.generateRandomString(
                this.completeCharsPool,
                (Math.max(this.totalChars, this.lowerChars + this.upperChars + this.numberChars + this.specialChars) - password.length)
            );

            password = this.shuffleString(password);
            this.viewValue = password;
            this.confirm = password;
            this.reveal = true;
        },
        generateRandomString(charPool, count) {
            if (count <= 0) {
                return '';
            }

            let randomString = '';
            for (let i = 0; i < count; i++) {
                const randomIndex = Math.floor(Math.random() * charPool.length);
                randomString += charPool[randomIndex];
            }
            return randomString;
        },
        shuffleString(str) {
            const arr = str.split('');
            for (let i = arr.length - 1; i > 0; i--) {
                const j = Math.floor(Math.random() * (i + 1));
                [arr[i], arr[j]] = [arr[j], arr[i]]; // Swap
            }
            return arr.join('');
        }
    }
}
</script>

<style>
    ul {
        list-style-type: none;
        padding-left: 0;
    }

    li {
        position: relative;
        padding-left: 30px;
    }

    li.valid::before {
        content: '\2713'; /* green check */
        position: absolute;
        left: 0;
        top: 0;
        color: green;
        font-size: 20px;
    }

    li.invalid::before {
        content: '\274C'; /* red cross */
        position: absolute;
        left: 0;
        top: 0;
        color: red;
        font-size: 10px;
    }
</style>
