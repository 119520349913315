var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: {
        "hide-overlay": "",
        persistent: "",
        value: "true",
        width: "300",
      },
    },
    [
      _c(
        "v-card",
        { attrs: { color: "primary", dark: "" } },
        [
          _c(
            "v-card-text",
            [
              _vm._v("\n            Please stand by\n            "),
              _c("v-progress-linear", {
                staticClass: "mb-0",
                attrs: { color: "white", indeterminate: "" },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }