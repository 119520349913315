<template>
    <div>
        <v-alert v-if="error === 'error_no_camera'" type="error">
            {{ $lumui.i18n.t('Es steht keine Kamera zur verfügung') }}
        </v-alert>
        <v-alert v-else-if="error" type="error">
            Error: {{ error }}
        </v-alert>
        <div v-if="!error" id="video-frame">
            <video ref="video" :class="{'mirror': mirror }" />
        </div>
        <div id="config">
            <form-row-autocomplete
                id="cameraId"
                ref="cameraId"
                v-model="cameraId"
                :config="{label: $lumui.i18n.t('lumui.qr_scanner.camera'), options: cameraOptions}"
                :required="true"
            />
            <form-row-switch
                id="mirror"
                v-model="mirror"
                :config="{label: $lumui.i18n.t('lumui.qr_scanner.mirror') }"
            />
            <form-row-switch
                v-if="hasFlash"
                id="torch"
                v-model="flash"
                :config="{label: $lumui.i18n.t('lumui.qr_scanner.torch') }"
            />
        </div>
    </div>
</template>

<script>
import FormRowAutocomplete from './FormRowAutocomplete'
import {VAlert} from 'vuetify/lib';
import QrScanner from 'qr-scanner';
import FormRowSwitch from "./FormRowSwitch";

export default {
    name: "AsaQrScanner",
    components: {
        FormRowSwitch,
        FormRowAutocomplete,
        VAlert
    },
    props: {
        /** cameraId and mirror settings are save under this key if localStorage is available */
        localStorageKey: {
            type: String,
            default: 'lumUiAsaQrScanner'
        },
        /** stop scanning when the first QR is found */
        stopOnDetection: {
            type: Boolean,
            default: true
        },
    },
    data() {
        return {
            active: false,
            qrScanner: null,
            error: null,
            mirror: false,
            flash: false,
            hasFlash: false,
            showConfig: false,
            cameraId: null,
            cameraOptions: []
        }
    },
    computed: {
        haveLocalStorage() {
            return 'localStorage' in window;
        },

    },
    watch: {
        cameraId(n) {
            if (this.qrScanner) {
                this.qrScanner.setCamera(n);
            }
            this.save();
        },
        flash() {
            if (this.hasFlash) {
                this.qrScanner.toggleFlash();
            }
        },
        mirror() {
            this.save();
        }
    },
    async mounted() {
        this.load();
        let hasCamera = await QrScanner.hasCamera();
        if (!hasCamera) {
            this.error = 'error_no_camera';
            return;
        }
        const cameras = await QrScanner.listCameras(true);
        if (cameras.length < 1) {
            this.error = 'error_no_camera';
            return;
        }
        cameras.forEach(x => this.cameraOptions.push({label: x.label, value: x.id}));

        if (!this.cameraId) {
            this.cameraId = cameras[0].id;
        }
        this.qrScanner = new QrScanner(this.$refs.video, this.detected, {
            maxScansPerSecond: 20,
            highlightScanRegion: true,
            highlightCodeOutline: true,
            preferredCamera: this.cameraId
        });

        this.start();
    },
    destroyed() {
        this.qrScanner.stop();
        this.qrScanner = null;
    },
    methods: {
        /** @private */
        load() {
            if (!this.haveLocalStorage) {
                return;
            }
            const settingsStr = localStorage.getItem(this.localStorageKey);
            if (!settingsStr) {
                return;
            }
            const settings = JSON.parse(settingsStr);
            if (!settings) {
                console.error("settings could not be parsed");
                return;
            }
            this.mirror = settings.mirror;
            this.cameraId = settings.cameraId;
        },
        /** @private */
        save() {
            if (!this.haveLocalStorage) {
                return;
            }
            const settings = {
                mirror: !!this.mirror,
                cameraId: this.cameraId
            };
            localStorage.setItem(this.localStorageKey, JSON.stringify(settings));
        },
        stop() {
            try {
                this.qrScanner.stop();
                /** @event active when scanning is ongoing or stopped */
                this.$emit('active', false);
            } catch (e) {
                this.error = e;
            }
        },
        async start() {
            try {
                await this.qrScanner.start();
                this.hasFlash = await this.qrScanner.hasFlash();
                this.flash = this.hasFlash ? this.qrScanner.isFlashOn() : false;
                this.$refs.video.style.transform = 'none';
                this.$emit('active', true);
            } catch (e) {
                this.error = e;
            }
        },
        /** @private */
        detected(result) {
            if (this.stopOnDetection) {
                this.stop();
            }
            /** @event detected qr code content, when one is detected */
            this.$emit("detected", result.data);
        }
    }
}
</script>

<style scoped>
video.mirror, canvas.mirror {
    transform: scaleX(-1) !important;
}

video, canvas {
    transform: scaleX(1) !important;
}

#video-frame {
    position: relative;
    border: 1px solid #999;
    width: 640px;
    height: 480px;
}

#video-frame video {
    max-width: 100%;
}

#video-frame canvas {
    max-width: 100%;
}

#config {
    width: 640px;
}
</style>
