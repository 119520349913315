var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    [
      _vm.$vuetify.breakpoint.smAndDown ||
      _vm.routes.length > _vm.iconMenuThreshold
        ? _c(
            "v-menu",
            {
              attrs: { bottom: "", left: "" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "activator",
                    fn: function ({ on, attrs }) {
                      return [
                        _c(
                          "v-btn",
                          _vm._g(
                            _vm._b(
                              {
                                attrs: {
                                  text: _vm.isMobile,
                                  icon: !_vm.isMobile,
                                },
                              },
                              "v-btn",
                              attrs,
                              false
                            ),
                            on
                          ),
                          [
                            _vm.isMobile
                              ? _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$lumui.i18n.t(
                                        "lumui.table_action.menu"
                                      )
                                    )
                                  ),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _c("v-icon", { attrs: { right: _vm.isMobile } }, [
                              _vm._v("$moreVert"),
                            ]),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ],
                null,
                false,
                1377688528
              ),
            },
            [
              _vm._v(" "),
              _c(
                "v-list",
                [
                  _vm._l(_vm.routes, function (route) {
                    return [
                      route.to || route.action
                        ? _c(
                            "v-list-item",
                            {
                              key: route.key,
                              attrs: { disabled: route.disabled },
                              on: {
                                click: function ($event) {
                                  return _vm.callAction(route)
                                },
                              },
                            },
                            [
                              _c(
                                "v-list-item-icon",
                                [
                                  _c("v-icon", {
                                    domProps: {
                                      textContent: _vm._s(route.icon),
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-list-item-content",
                                [
                                  _c("v-list-item-title", [
                                    _vm._v(
                                      "\n                            " +
                                        _vm._s(route.label) +
                                        "\n                        "
                                    ),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]
                  }),
                ],
                2
              ),
            ],
            1
          )
        : [
            _vm._l(_vm.routes, function (route) {
              return [
                route.to || route.action
                  ? _c(
                      "v-tooltip",
                      {
                        key: route.key,
                        attrs: { bottom: "" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function ({ on }) {
                                return [
                                  _c(
                                    "v-btn",
                                    _vm._g(
                                      {
                                        attrs: {
                                          disabled: route.disabled,
                                          "x-small": _vm.btnSize === "x-small",
                                          small: _vm.btnSize === "small",
                                          large: _vm.btnSize === "large",
                                          "x-large": _vm.btnSize === "x-large",
                                          text: "",
                                          icon: "",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.callAction(route)
                                          },
                                        },
                                      },
                                      on
                                    ),
                                    [
                                      _c("v-icon", {
                                        attrs: {
                                          "x-small": _vm.iconSize === "x-small",
                                          small: _vm.iconSize === "small",
                                          large: _vm.iconSize === "large",
                                          "x-large": _vm.iconSize === "x-large",
                                        },
                                        domProps: {
                                          textContent: _vm._s(route.icon),
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      },
                      [_vm._v(" "), _c("span", [_vm._v(_vm._s(route.label))])]
                    )
                  : _vm._e(),
              ]
            }),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }