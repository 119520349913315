<template>
    <div class="container">
        f = <asa-formatter type="float" :value="900800700.123456" />
    </div>
</template>

<script>
import AsaFormatter from "../../../src/component/AsaFormatter";
export default {
    name: "FormatterFloat",
    components: {
        AsaFormatter
    }
}
</script>
