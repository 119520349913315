var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm._l(_vm.config, function (rowConfig, key) {
        return [
          _vm.isRow(key, rowConfig)
            ? _c("form-row", {
                key: key,
                attrs: { config: rowConfig, name: key, values: _vm.value },
                on: { input: _vm.updateValue, "update:error": _vm.updateError },
                model: {
                  value: _vm.value[key],
                  callback: function ($$v) {
                    _vm.$set(_vm.value, key, $$v)
                  },
                  expression: "value[key]",
                },
              })
            : _vm._e(),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }