<template>
    <div>
        <v-container>
            <v-row>
                <v-col>
                    <h1 class="text-h3">
                        Collections
                    </h1>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" md="8">
                    <asa-form v-model="data1" :config="formConf1" @save="addErrors(formConf1, data1)">
                        <template #title>
                            Single Field Subformular
                        </template>
                    </asa-form>
                </v-col>
                <v-col cols="12" md="4">
                    <v-card class="fill-height">
                        <v-card-title class="elevation-1">
                            Value
                        </v-card-title>
                        <v-card-text class="py-4">
                            <pre>{{ JSON.stringify(data1, null, 4) }}</pre>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="8">
                    <asa-form v-model="data2" :config="formConf2">
                        <template #title>
                            Complex Subformular - Values Async
                        </template>
                    </asa-form>
                </v-col>
                <v-col cols="4">
                    <v-card class="fill-height">
                        <v-card-title class="elevation-1">
                            Value
                        </v-card-title>
                        <v-card-text class="py-4">
                            <pre>{{ JSON.stringify(data2, null, 4) }}</pre>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="8">
                    <asa-form v-model="data3" :config="formConf3">
                        <template #title>
                            Complex Subformular - Values empty data from formconf
                        </template>
                    </asa-form>
                </v-col>
                <v-col cols="4">
                    <v-card class="fill-height">
                        <v-card-title class="elevation-1">
                            Value
                        </v-card-title>
                        <v-card-text class="py-4">
                            <pre>{{ JSON.stringify(data3, null, 4) }}</pre>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="8">
                    <asa-form v-model="data4" :config="formConf4">
                        <template #title>
                            UM Problem
                        </template>
                    </asa-form>
                </v-col>
                <v-col cols="4">
                    <v-card class="fill-height">
                        <v-card-title class="elevation-1">
                            Value
                        </v-card-title>
                        <v-card-text class="py-4">
                            <pre>{{ JSON.stringify(data4, null, 4) }}</pre>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="8">
                    <asa-form v-model="data5" :config="formConf5">
                        <template #title>
                            data from value
                        </template>
                    </asa-form>
                </v-col>
                <v-col cols="4">
                    <v-card class="fill-height">
                        <v-card-title class="elevation-1">
                            Value
                        </v-card-title>
                        <v-card-text class="py-4">
                            <pre>{{ JSON.stringify(data5, null, 4) }}</pre>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import {VCard, VCardText, VCardTitle, VCol, VContainer, VRow} from 'vuetify/lib'

export default {
    name: "FormCollection",
    components: {VRow, VCol, VCard, VCardText, VCardTitle, VContainer},
    data() {
        return {
            formConf1: {
                "tags": {
                    "type": "collection",
                    "label": "Tags",
                    "allow_add": true,
                    "allow_delete": true,
                    "prototype": {
                        "type": "text",
                        "label": "",
                        "value": "",
                        "required": true,
                        "disabled": false
                    },
                    "children": {},
                    "order": 1
                }
            },
            data1: {},
            formConf2: {
                "stuff": {
                    "type": "collection",
                    "label": "Stuff2",
                    "allow_add": true,
                    "allow_delete": true,
                    "prototype": {
                        "name": {
                            "type": "text",
                            "label": "Name",
                            "value": "",
                            "required": true,
                            "disabled": false,
                            "order": 1
                        },
                        "description": {
                            "type": "text",
                            "label": "Description",
                            "value": "",
                            "required": true,
                            "disabled": false,
                            "order": 2
                        }
                    },
                    "children": {},
                    "order": 1
                }
            },
            data2: {},
            formConf3: {
                "stuff": {
                    "type": "collection",
                    "label": "Stuff3",
                    "allow_add": false,
                    "allow_delete": false,
                    "prototype": {
                        "name": {
                            "type": "text",
                            "label": "Name",
                            "value": "",
                            "required": true,
                            "disabled": false,
                            "order": 1
                        },
                        "description": {
                            "type": "text",
                            "label": "Description",
                            "value": "",
                            "required": true,
                            "disabled": false,
                            "order": 2
                        }
                    },
                    "children": {
                        0: {
                            "name": {
                                "type": "text",
                                "label": "Name",
                                "value": "Foo",
                                "required": true,
                                "disabled": false,
                                "order": 1
                            },
                            "description": {
                                "type": "text",
                                "label": "Description",
                                "value": "Da Foo",
                                "required": true,
                                "disabled": false,
                                "order": 2
                            }
                        },
                        1: {
                            "name": {
                                "type": "text",
                                "label": "Name",
                                "value": "Bar",
                                "required": true,
                                "disabled": false,
                                "order": 1
                            },
                            "description": {
                                "type": "text",
                                "label": "Description",
                                "value": "Da Bar",
                                "required": true,
                                "disabled": false,
                                "order": 2
                            }
                        }
                    },
                    "order": 1
                }
            },
            data3: {},
            formConf4: {
                "requirements": {
                    "type": "collection",
                    "label": "um.instruction.requirements",
                    "allow_add": true,
                    "allow_delete": true,
                    "prototype": {
                        "type": "select",
                        "label": "um.label.instruction",
                        "value": "",
                        "required": true,
                        "disabled": false,
                        "errors": [],
                        "multiple": false,
                        "multiOptions": [
                            {
                                "label": "[EM] asdfasdf",
                                "value": "1"
                            }, {
                                "label": "[UI] Unterweisung 1",
                                "value": "3"
                            }, {
                                "label": "[EM] Testunterweisung",
                                "value": "4"
                            }
                        ]
                    },
                    "children": {
                        "0": {
                            "type": "select",
                            "label": "um.label.instruction",
                            "value": "4",
                            "required": true,
                            "disabled": false,
                            "errors": [],
                            "multiple": false,
                            "multiOptions": [
                                {
                                    "label": "[EM] asdfasdf",
                                    "value": "1"
                                }, {
                                    "label": "[UI] Unterweisung 1",
                                    "value": "3"
                                }, {
                                    "label": "[EM] Testunterweisung",
                                    "value": "4"
                                }
                            ]
                        }
                    },
                    "order": 1
                }
            },
            data4: {},
            formConf5: {
                "requirements": {
                    "type": "collection",
                    "label": "um.instruction.requirements",
                    "allow_add": true,
                    "allow_delete": true,
                    "prototype": {
                        "type": "select",
                        "label": "um.label.instruction",
                        "value": "",
                        "required": true,
                        "disabled": false,
                        "errors": [],
                        "multiple": false,
                        "multiOptions": [
                            {"label": "[EM] asdfasdf", "value": "1"},
                            {"label": "[UI] Unterweisung 1", "value": "3"},
                            {"label": "[EM] Testunterweisung", "value": "4"}
                        ]
                    },
                    "children": {
                        "0": {
                            "type": "select",
                            "label": "um.label.instruction",
                            "required": true,
                            "disabled": false,
                            "errors": [],
                            "multiple": false,
                            "multiOptions": [
                                {"label": "[EM] asdfasdf", "value": "1"},
                                {"label": "[UI] Unterweisung 1", "value": "3"},
                                {"label": "[EM] Testunterweisung", "value": "4"}
                            ]
                        }
                    },
                    "order": 1
                }
            },
            data5: {
                "requirements": {"0": "1"}
            }
        }
    },
    mounted() {
        window.setTimeout(() => {
            this.data2 = {
                stuff: {
                    "0": {
                        name: 'Foo',
                        description: 'Da Foo',
                    },
                    "1": {
                        name: 'bar',
                        description: 'Da Bar',
                    }
                }
            }
        }, 1000)
    },
    methods: {
        addErrors(cfg, data) {
            let errors = {
                'tags.0': [],
                'tags.1': ['xxx']
            };
            this.setFormErrors(cfg, errors);
        }
    }
}
</script>
