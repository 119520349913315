<template>
    <div class="container">
        <asa-formatter type="localized" :value="{'de': 'German', 'en': 'English'}" /><br>
        <asa-formatter type="localized" :value="{'de': 'German Fallback'}" /><br>
        <asa-formatter type="localized" :value="{'de': '<b>HTML required</b>'}" :options="{'isHtmlRequired': true}" /><br>
        <asa-formatter type="localized" :value="{'de': '<b>HTML NOT required</b>'}" /><br>
        <asa-formatter type="localized" value="Kein Multilang Object ohne HTML" /><br>
        <asa-formatter type="localized" value="<b>Kein Multilang Object ohne HTML</b>" :options="{isHtmlRequired: true}" /><br>
        <asa-formatter type="localized" :value="{'ch': 'Weder Locale, noch Fallback vorhanden'}" /><br>
        <asa-formatter type="localized" :value="{'ch': '<b>Weder Locale, noch Fallback vorhanden</b>'}" :options="{isHtmlRequired: true}" /><br>
    </div>
</template>

<script>
import AsaFormatter from "../../../src/component/AsaFormatter";

export default {
    name: "FormatterLocalized",
    components: {
        AsaFormatter
    }
}
</script>
