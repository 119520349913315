var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.hasTabs
    ? _c(
        "v-tabs",
        {
          attrs: {
            "background-color": _vm.backgroundColor,
            "show-arrows": true,
          },
          model: {
            value: _vm.active,
            callback: function ($$v) {
              _vm.active = $$v
            },
            expression: "active",
          },
        },
        [
          _c("v-tabs-slider", { attrs: { color: "teal" } }),
          _vm._v(" "),
          _vm._l(_vm.tabs, function (tab, index) {
            return _c(
              "v-tab",
              {
                key: index,
                attrs: { href: "#tab-" + index, ripple: "" },
                on: {
                  change: function ($event) {
                    return _vm.$emit("tab", "tab-" + index)
                  },
                },
              },
              [
                _vm._v("\n        " + _vm._s(tab.label) + "\n        "),
                _c(
                  "v-icon",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: Object.hasOwn(tab, "error") && tab.error,
                        expression: "Object.hasOwn(tab, 'error') && tab.error",
                      },
                    ],
                    staticClass: "ml-1",
                    attrs: { small: "" },
                  },
                  [_vm._v("\n            $warning\n        ")]
                ),
              ],
              1
            )
          }),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }