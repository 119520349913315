<template>
    <div class="container">
        <h1>Time</h1>
        <asa-formatter type="time" value="04:20" />
        <asa-formatter type="time" value="13:42" />
    </div>
</template>

<script>
import AsaFormatter from "../../../src/component/AsaFormatter";
export default {
    name: "FormatterTime",
    components: {
        AsaFormatter
    }
}
</script>
