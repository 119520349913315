var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "asa-form",
    {
      attrs: { loading: _vm.loading, config: _vm.cfg },
      model: {
        value: _vm.value,
        callback: function ($$v) {
          _vm.value = $$v
        },
        expression: "value",
      },
    },
    [_c("template", { slot: "title" }, [_vm._v("Asa User form")])],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }