import {merge} from "webpack-merge";
import it from './base/hu.js';

export default merge(it, {
    "lumui.confirm.accept": "OK",
    "lumui.confirm.reject": "Annullare",
    "lumui.formatter.boolean.true": "S\u00ec",
    "lumui.formatter.boolean.false": "No",
    "lumui.form.close": "Chiudere",
    "lumui.form.cancel": "Annullare",
    "lumui.form.save": "Salvare",
    "lumui.lightbox.title": "Immagini presenti",
    "lumui.loading.message": "Caricamento dati in corso",
    "lumui.qr_scanner.camera": "Camera",
    "lumui.qr_scanner.mirror": "Macchina fotografica a specchio",
    "lumui.qr_scanner.torch": "Luce della camera",
    "lumui.table.reset_filters": "Resettare",
    "lumui.table.close_filters": "Piega nel filtro",
    "lumui.table.details": "Vista dettagliata",
    "lumui.table.previous": "Record di dati precedente",
    "lumui.table.next": "Record di dati successivo",
    "lumui.table.no_data": "Nessun dato trovato.",
    "lumui.table.no_filter_results": "La ricerca non ha dato esito",
    "lumui.table.filters_active": "Risultati filtrati",
    "lumui.table.rows_per_page": "Righe per pagina",
    "lumui.table.page_text": "{0}-{1} di {2}",
    "lumui.table.btn.export": "Elenco di esportazione",
    "lumui.table_action.menu": "Menu",
    "lumui.table_columns.save": "Salvare file",
    "lumui.form.row.invalid_email": "Bitte geben Sie eine korrekte E-Mail-Adresse an.",
    "lumui.form.row.required": "Questo \u00e8 un campo obbligatorio",
    "lumui.form.autocomplete.selected_count": "{c} voci selezionate",
    "lumui.form.autocomplete.select_all": "Selezionare tutto\n",
    "lumui.form.autocomplete.deselect_all": "Non selezionare alcunch\u00e9",
    "lumui.form.autocomplete.search_hint": "Inserire l\u2019oggetto di ricerca",
    "lumui.form.autocomplete.no_data": "Nessun dato presente",
    "lumui.form.autocomplete.nothing_selected": "Nessuna voce selezionata",
    "lumui.form.autocomplete.selected_items": "Voci selezionate",
    "lumui.form.date.close": "Chiudere",
    "lumui.form.date.save": "OK",
    "lumui.form.file.drag": "Trascinare i file qui o fare clic per selezionare",
    "lumui.form.file.error": "Caricamento fallito",
    "lumui.form.file.extensions.accepted": "I file di tipo {types} sono ammessi",
    "lumui.form.file.extensions.all": "Sono ammessi tutti i tipi di file",
    "lumui.form.file.maxSize": "La dimensione massima consentita del file \u00e8 {size}",
    "lumui.form.file.maxSizeError": "fileuploader.maxSizeError",
    "lumui.form.file.previewError": "Un'anteprima del file {name} purtroppo non \u00e8 supportata.",
    "lumui.form.file.uploading": "{count} I file vengono caricati ....",
    "lumui.form.file.warning.removed_file": "File {name} was not uploaded. Filetype {type} is not within permitted types {acceptedTypes}.",
    "lumui.form.file.select_file": "Carica l'elenco firmato dei partecipanti",
    "lumui.form.file.unknown_size": "N.d. (Nessun dato)",
    "lumui.form.localized.copy_to_all": "Accetta l'iscrizione per tutte le lingue",
    "lumui.form.ordered_choice.no_data": "nessuna voce disponibile",
    "lumui.form.ordered_choice.select_placeholder": "Seleziona una nuova voce",
    "lumui.form.password.repeat": "Ripetere password",
    "lumui.form.password.not_matching_error": "Le due password devono coincidere.",
    "lumui.form.signature.clear": "Cancellare la firma",
    "lumui.form.signature.upload": "Carica la firma",
    "lumui.form.time.close": "Chiudere",
    "lumui.form.time.save": "OK",
    "lumui.locale.lang.german": "Tedesco",
    "lumui.locale.lang.english": "Inglese",
    "lumui.locale.lang.italian": "Italiano",
    "lumui.locale.lang.french": "Francese",
    "lumui.status.no_action_required": "Nessuna azione necessaria",
    "lumui.status.hint": "Nota",
    "lumui.status.action_required_soon": "Azione tempestiva necessaria!",
    "lumui.status.action_required_immediately": "azione immediata necessaria!",
    "lumui.locale.de-DE": "German",
    "lumui.locale.de-CH": "German (Suisse)",
    "lumui.locale.fr-CH": "French (Suisse)",
    "lumui.locale.it-CH": "Italian (Suisse)",
    "lumui.locale.en-US": "English (USA)",
    "lumui.locale.en-GB": "English (GB)",
    "lumui.locale.fi-FI": "Finnish",
    "lumui.locale.et-EE": "Estonian",
    "lumui.locale.lt-LT": "Lithuanian",
    "lumui.locale.lv-LV": "Latvian",
    "lumui.locale.lang.finnish": "Finnish",
    "lumui.locale.lang.estonian": "Estonian",
    "lumui.locale.lang.lithuanian": "Lithuanian",
    "lumui.locale.lang.latvian": "Latvian",
    "lumui.locale.lang.hungarian": "Hungarian",
});
