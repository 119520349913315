<template>
    <div :class="cssClasses">
        <v-tooltip bottom>
            <template #activator="{ on, attrs }">
                <v-btn absolute color="black" fab outlined right small v-bind="attrs" @click="clear" v-on="on">
                    <v-icon small>
                        $delete
                    </v-icon>
                </v-btn>
            </template>
            <span>{{ $lumui.i18n.t('lumui.form.signature.clear') }}</span>
        </v-tooltip>
        <v-tooltip bottom>
            <template #activator="{ on, attrs }">
                <v-btn absolute color="black" fab outlined right small style="margin-right: 4em" v-bind="attrs" @click="$refs.fileElement.click()" v-on="on">
                    <v-icon small>
                        $upload
                    </v-icon>
                </v-btn>
            </template>
            <span>{{ $lumui.i18n.t('lumui.form.signature.upload') }}</span>
        </v-tooltip>
        <v-label :absolute="true" left="12px" :focused="focused" :value="true">
            {{ config.label }}
        </v-label>
        <div>
            <vue-signature-pad ref="signaturePad" :options="options" />
        </div>
        <input ref="fileElement" type="file" style="display: none" accept="image/*" @change="imgUpload" />
    </div>
</template>

<script>
import {VBtn, VIcon, VLabel, VTooltip} from 'vuetify/lib';

/**
 * Unterschriftenfeld
 *
 * #### config
 *
 * | key                    | type                       | required | default    | description |
 * |------------------------|----------------------------|----------|------------|-------------|
 * | type                   | `String`                   | yes      |            | field type  |
 * | label                  | `String`, `false`          | no       | `false`    | fields label |
 *
 * @todo cannot be disabled
 * @todo cannot be required
 */
export default {
    name: "FormRowSignatur",
    components: {VBtn, VIcon, VLabel, VTooltip},
    props: {
        /**
         * Konfigurationsobject
         *
         *     {
         *       label: Label der Komponente
         *     }
         *
         */
        'config': {
            type: Object,
            default: () => {
            }
        },
        /**
         * @model
         */
        'value': {
            type: [Object, Array, String, Number, Boolean],
            default: null
        }
    },
    data() {
        return {
            focused: false,
            options: {
                onBegin: () => {
                    this.focused = true;
                },
                onEnd: () => {
                    this.focused = false;
                    this.emitInput();
                }
            },
            onResizeCallback: null,
        }
    }
    ,
    computed: {
        labelClasses() {
            return {
                'v-label': true,
                'v-label--active': true,
                'theme--light': !this.$vuetify.theme.dark,
                'theme--dark': this.$vuetify.theme.dark,
            }
        },
        cssClasses() {
            return {
                'v-text-field': true,
                'v-text-field--filled ': true,
                'v-input--is-label-active': true,
                'signature-pad': true,
                focused: this.focused,
                'primary--text': this.focused
            }
        }
    }
    ,
    watch: {
        value(newValue) {
            if (!this.focused) {
                this.$refs.signaturePad.clearSignature();
                if (newValue) {
                    this.$refs.signaturePad.fromDataURL(newValue);
                }
            }
        }
    }
    ,
    mounted() {
        this.$refs.signaturePad.clearSignature();
        if (this.value) {
            this.$refs.signaturePad.fromDataURL(this.value);
        }

        this.$nextTick(() => this.$refs.signaturePad.resizeCanvas());
    }
    ,
    destroyed() {
        if (this.onResizeCallback) {
            window.removeEventListener('resize', this.onResizeCallback, false);
        }
    }
    ,
    methods: {
        /* @private */
        emitInput() {
            const {isEmpty, data} = this.$refs.signaturePad.saveSignature();
            /**
             * user input
             */
            this.$emit('input', data);
        },
        /** clear signature */
        clear() {
            this.$refs.signaturePad.clearSignature();
            this.emitInput();
        },
        /* @private */
        imgUpload() {
            const file = this.$refs.fileElement.files[0];
            const reader = new FileReader();
            this.$refs.signaturePad.clearSignature();

            reader.addEventListener("load", () => {
                const img = new Image();
                img.onload = () => {
                    try {
                        const ratio = img.width / img.height;
                        const canvas = this.$refs.signaturePad.$refs.signaturePadCanvas;
                        let width = img.width;
                        let height = img.height;

                        if (width > canvas.width) {
                            height = height * (canvas.width / width);
                            width = canvas.width;
                        }

                        if (height > canvas.height) {
                            width = width * (canvas.height / height);
                            height = canvas.height;
                        }
                        this.$refs.signaturePad.fromDataURL(
                          reader.result,
                          {
                              ratio: ratio,
                              width: width,
                              height: height
                          },
                          (e) => {
                              if (!e) {
                                  this.emitInput();
                              }
                          }
                        );

                    } catch (e) {
                        console.log(e)
                    }
                };

                img.src = reader.result;
            }, false);

            if (file) {
                reader.readAsDataURL(file);
            }
        }
    }
}
</script>

<style scoped>
.signature-pad {
    margin-bottom: 10px;
    position: relative;
    background-color: rgba(0,0,0,.06);
    border-bottom: solid rgba(0,0,0,.50) thin;
}

.signature-pad > div {
    position: relative;
    height: 250px;
}

.signature-pad:before {
    bottom: 20%;
    content: '';
    left: 5%;
    right: 5%;
    position: absolute;
    transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
    border: 0 solid rgba(0, 0, 0, 0.42);
    border-top-width: medium;
}

.signature-pad:hover:before {
    bottom: 20%;
    content: '';
    left: 5%;
    right: 5%;
    position: absolute;
    transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
    border: 0 solid rgba(0, 0, 0, 0.87);
    border-top-width: medium;
}

.signature-pad:after {
    bottom: 20%;
    content: '';
    left: 5%;
    right: 5%;
    position: absolute;
    transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
    border-color: currentColor;
    border-style: solid;
    border-width: medium 0 0 0;
    transform: scaleX(0);
}

.signature-pad.focused:after {
    bottom: 20%;
    content: '';
    left: 5%;
    right: 5%;
    position: absolute;
    transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
    border-color: currentColor;
    border-style: solid;
    border-width: medium 0 0 0;
    transform: scaleX(1);
}
</style>
