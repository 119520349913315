<template>
    <div>
        <v-toolbar>
            <v-toolbar-title>
                Nested Set Column
            </v-toolbar-title>
            <v-spacer />
            <v-toolbar-items>
                <v-btn tile icon large @click="showFilter = !showFilter"><v-icon>fa-search</v-icon></v-btn>
            </v-toolbar-items>
        </v-toolbar>
        <asa-table :loading="false" :data="data" info-disabled :show-filter="showFilter" @filter_closed="showFilter = !showFilter"></asa-table>
    </div>
</template>

<script>
import AsaTable from "../../../src/component/AsaTable";
import {VToolbar, VToolbarTitle, VSpacer,  VToolbarItems, VBtn, VIcon} from 'vuetify/lib'

export default {
    name: "nestedset",
    components: {AsaTable, VToolbar, VToolbarTitle, VSpacer,  VToolbarItems, VBtn, VIcon},
    data() {
        return {
            data: {
                columns: [
                    {
                        'key': 'nestedset',
                        'label': 'Nested Set Column',
                        'type': 'nestedset'
                    },
                    {
                        'key': 'label',
                        'label': 'Label',
                    },
                    {
                        'key': 'root',
                        'label': 'Root',
                    },
                    {
                        'key': 'left',
                        'label': 'Left',
                    },
                    {
                        'key': 'right',
                        'label': 'Right',
                    }
                ],
                rows: [
                    {nestedset: {label: "Root A", root: 1, left: 1, right: 6}, label: "Root A", root: 1, left: 1, right: 6},
                    {nestedset: {label: "Child A.1", root: 1, left: 2, right: 3}, label: "Child A.1", root: 1, left: 2, right: 3},
                    {nestedset: {label: "Child A.2", root: 1, left: 4, right: 5}, label: "Child A.2", root: 1, left: 4, right: 5},
                    {nestedset: {label: "Root B", root: 2, left: 1, right: 6}, label: "Root B", root: 2, left: 1, right: 6},
                    {nestedset: {label: "Child B.1", root: 2, left: 2, right: 5}, label: "Child B.1", root: 2, left: 2, right: 5},
                    {nestedset: {label: "Child B.1.1", root: 2, left: 3, right: 4}, label: "Child B.1.1", root: 2, left: 3, right: 4},
                ],
                filter: [
                    {
                        'key': 'nestedset',
                        'label': 'Nested Set Column',
                        'type': 'nestedset',
                        'filter': {
                            nestedset: [
                                {label: "Root A", root: 1, left: 1, right: 6},
                                {label: "Child A.1", root: 1, left: 2, right: 3},
                                {label: "Child A.2", root: 1, left: 4, right: 5},
                                {label: "Root B", root: 2, left: 1, right: 6},
                                {label: "Child B.1", root: 2, left: 2, right: 5},
                                {label: "Child B.1.1", root: 2, left: 3, right: 4},
                            ]
                        }
                    }
                ]
            },
            showFilter: false,
        }
    }
}
</script>

<style scoped>

</style>
