var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("form-row-autocomplete", { attrs: { id: "xxx", config: _vm.cfg.f0 } }),
      _vm._v(" "),
      _c("form-row-autocomplete", { attrs: { id: "yyy", config: _vm.cfg.f1 } }),
      _vm._v(" "),
      _c("v-autocomplete", {
        attrs: { items: _vm.cfg.f0.options, label: "XXX" },
        scopedSlots: _vm._u([
          {
            key: "item",
            fn: function ({ item }) {
              return [
                _c(
                  "v-list-item",
                  [_c("v-list-item-content", [_vm._v(_vm._s(item.text))])],
                  1
                ),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }