var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-toolbar",
        { attrs: { flat: "" } },
        [_c("v-toolbar-title", [_vm._v("Form Playground")])],
        1
      ),
      _vm._v(" "),
      _c("v-divider"),
      _vm._v(" "),
      _c(
        "v-container",
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                [
                  _c("v-textarea", {
                    attrs: { label: "Form Config" },
                    model: {
                      value: _vm.input,
                      callback: function ($$v) {
                        _vm.input = $$v
                      },
                      expression: "input",
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      staticClass: "float-right",
                      attrs: {
                        disabled: _vm.input.length === 0 || _vm.error != null,
                        icon: "",
                        title: "Format code",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.format()
                        },
                      },
                    },
                    [_c("i", { staticClass: "fa-solid fa-code" })]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "8" } },
                [
                  _vm.empty
                    ? _c("v-alert", { attrs: { type: "info" } }, [
                        _vm._v(
                          "\n                        Bitte kopieren sie eine Formularkonfiguration in das obige Textfeld.\n                    "
                        ),
                      ])
                    : _vm.error
                    ? _c("v-alert", { attrs: { type: "error" } }, [
                        _vm._v(
                          "\n                        " +
                            _vm._s(_vm.error) +
                            "\n                    "
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.config
                    ? _c("asa-form", {
                        attrs: { config: _vm.config, "abort-btn": false },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "title",
                              fn: function () {
                                return [
                                  _vm._v(
                                    "\n                            Formularvorschau\n                        "
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          false,
                          347210886
                        ),
                        model: {
                          value: _vm.value,
                          callback: function ($$v) {
                            _vm.value = $$v
                          },
                          expression: "value",
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c("v-col", { attrs: { cols: "auto" } }, [
                _c("pre", [
                  _vm._v(_vm._s(_vm.value) + "\n                    "),
                ]),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                [
                  _c("v-textarea", {
                    attrs: { label: "Set error", disabled: !_vm.config },
                    model: {
                      value: _vm.errorInput,
                      callback: function ($$v) {
                        _vm.errorInput = $$v
                      },
                      expression: "errorInput",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }