export const StorageService = {
    set,
    get,
    has,
    remove
};

function get(key) {
    if (!this.has(key)) {
        return null
    }
    let value = window.sessionStorage.getItem(key);
    try {
        return JSON.parse(value);
    } catch (e) {
        console.error(e);
        return null
    }
}

function set(key, value) {
    if (value === null) {
        this.remove(key)
    } else if (typeof value === 'object') {
        value = JSON.stringify(value);
    } else if (typeof value == 'string') {
        value = JSON.stringify(value);
    }
    window.sessionStorage.setItem(key, value)
}

function has(key) {
    return window.sessionStorage.hasOwnProperty(key)
}

function remove(key) {
    if (this.has(key)) {
        window.sessionStorage.removeItem(key)
    }
}
