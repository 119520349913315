var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.config
    ? _c(
        "div",
        {
          staticClass:
            "v-input v-label--active v-input--is-label-active theme-light mt-2",
        },
        [
          _c(
            "div",
            { staticClass: "v-input__control" },
            [
              _c(
                "label",
                {
                  staticClass:
                    "v-label v-label--active theme--light ordered-choice-label",
                  attrs: { for: _vm.name + "-ac" },
                },
                [
                  _vm._v(
                    "\n            " + _vm._s(_vm.config.label) + "\n        "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "v-list",
                { attrs: { dense: "", flat: "" } },
                [
                  _c(
                    "div",
                    { ref: "dnd" },
                    _vm._l(_vm.selectedItems, function (item) {
                      return _c(
                        "v-list-item",
                        { key: item.value, staticClass: "list-item" },
                        [
                          _c("v-list-item-content", [
                            _c(
                              "span",
                              { staticClass: "list-item-handle mr-2" },
                              [_vm._v("::")]
                            ),
                            _vm._v(
                              " " +
                                _vm._s(_vm.striphtml(item.label)) +
                                "\n                    "
                            ),
                          ]),
                          _vm._v(" "),
                          _c(
                            "v-list-item-action",
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    color: "grey lighten-1",
                                    icon: "",
                                    small: "",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.remove(item.value)
                                    },
                                  },
                                },
                                [_c("v-icon", [_vm._v("$delete")])],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    }),
                    1
                  ),
                  _vm._v(" "),
                  _vm.selectedItems.length === 0
                    ? _c(
                        "v-list-item",
                        { staticClass: "list-no-items" },
                        [
                          _c("v-list-item-content", [
                            _c("i", { staticClass: "text--secondary" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.$lumui.i18n.t(
                                    "lumui.form.ordered_choice.no_data"
                                  )
                                )
                              ),
                            ]),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "v-list-item",
                    { staticClass: "list-add-item" },
                    [
                      _c(
                        "v-list-item-content",
                        [
                          _c(
                            "v-autocomplete",
                            {
                              ref: "ac",
                              staticStyle: { "font-size": "0.8125rem" },
                              attrs: {
                                id: _vm.name + "-ac",
                                filled: "",
                                filter: _vm.filter,
                                items: _vm.availableOptions,
                                placeholder: _vm.$lumui.i18n.t(
                                  "lumui.form.ordered_choice.select_placeholder"
                                ),
                                "search-input": _vm.acSearch,
                                autocomplete: "off",
                                dense: "",
                                "hide-details": "",
                                "prepend-inner-icon": "add",
                              },
                              on: {
                                "update:searchInput": function ($event) {
                                  _vm.acSearch = $event
                                },
                                "update:search-input": function ($event) {
                                  _vm.acSearch = $event
                                },
                                change: _vm.selected,
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "selection",
                                    fn: function ({ item }) {
                                      return [
                                        _vm._v(
                                          "\n                            " +
                                            _vm._s(_vm.striphtml(item.label)) +
                                            "\n                        "
                                        ),
                                      ]
                                    },
                                  },
                                  {
                                    key: "item",
                                    fn: function ({ item }) {
                                      return [
                                        _vm._v(
                                          "\n                            " +
                                            _vm._s(_vm.striphtml(item.label)) +
                                            "\n                        "
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                2418184508
                              ),
                              model: {
                                value: _vm.acValue,
                                callback: function ($$v) {
                                  _vm.acValue = $$v
                                },
                                expression: "acValue",
                              },
                            },
                            [
                              _vm._v(" "),
                              _vm._v(" "),
                              _c("template", { slot: "no-data" }, [
                                _c(
                                  "div",
                                  { staticClass: "mx-3 text--disabled" },
                                  [
                                    _vm._v(
                                      "\n                                " +
                                        _vm._s(
                                          _vm.$lumui.i18n.t(
                                            "lumui.form.ordered_choice.no_data"
                                          )
                                        ) +
                                        "\n                            "
                                    ),
                                  ]
                                ),
                              ]),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }