var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: { "mb-7": _vm.config.chips && _vm.multiple } },
    [
      _c("v-autocomplete", {
        directives: [
          {
            name: "debounce",
            rawName: "v-debounce:400ms",
            value: _vm.loadAsyncron,
            expression: "loadAsyncron",
            arg: "400ms",
          },
        ],
        ref: "autocomplete",
        class: _vm.styleClass,
        attrs: {
          id: _vm.name,
          filled: "",
          "cache-items": _vm.multiple && _vm.isAsyncron,
          clearable: (!_vm.required && !_vm.multiple) || _vm.config.clearable,
          disabled: _vm.disabled,
          error: _vm.hasError,
          "error-messages": _vm.errorMessages,
          filter: _vm.filter,
          "hide-selected": _vm.isAsyncron,
          hint: _vm.config.description,
          items: _vm.options,
          label: _vm.config.label,
          loading: _vm.isLoading,
          multiple: _vm.multiple,
          name: _vm.name,
          "persistent-hint": true,
          required: _vm.required,
          "return-object": _vm.isAsyncron,
          "hide-details": _vm.config.chips && _vm.multiple ? "auto" : false,
          rules: _vm.rules,
          "append-icon": "",
          autocomplete: "off",
          "debounce-events": "onkeydown",
          "item-disabled": "disabled",
        },
        on: { change: _vm.onSelectChange, input: _vm.updateError },
        scopedSlots: _vm._u([
          {
            key: "selection",
            fn: function ({ parent, item, index, selected }) {
              return [
                _vm.config.chips && _vm.multiple
                  ? _c(
                      "span",
                      [
                        index == 0
                          ? _c("v-chip", { attrs: { small: "" } }, [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(
                                    _vm.$lumui.i18n.tc(
                                      "lumui.form.autocomplete.selected_count",
                                      _vm.count,
                                      { c: _vm.count }
                                    )
                                  ) +
                                  "\n                "
                              ),
                            ])
                          : _vm._e(),
                      ],
                      1
                    )
                  : _vm.config.chips
                  ? _c(
                      "v-chip",
                      {
                        class:
                          (_vm.config.multiple ? "chip--select-multi" : "") +
                          " mb-2",
                        attrs: {
                          close: _vm.config.multiple,
                          selected: selected,
                          small: "",
                        },
                      },
                      [
                        _vm.config.html_options
                          ? _c("span", {
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.sanitize(_vm.getLabel(item))
                                ),
                              },
                            })
                          : _c("span", [_vm._v(_vm._s(_vm.getLabel(item)))]),
                        _vm._v(" "),
                        item.group
                          ? _c("span", {
                              staticClass: "caption gray--text",
                              domProps: {
                                innerHTML: _vm._s("(" + item.group + ")"),
                              },
                            })
                          : _vm._e(),
                      ]
                    )
                  : [
                      item.attr && item.attr.icon
                        ? _c(
                            "span",
                            [
                              _c("v-icon", { staticClass: "mr-2" }, [
                                _vm._v(_vm._s(item.attr.icon)),
                              ]),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      item.attr && item.attr.image
                        ? _c("span", [
                            _c("img", {
                              staticClass: "mr-2 mt-1",
                              attrs: { src: item.attr.image, alt: "" },
                            }),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.config.html_options
                        ? _c("span", {
                            staticClass: "mr-1",
                            domProps: {
                              innerHTML: _vm._s(
                                _vm.sanitize(_vm.getLabel(item))
                              ),
                            },
                          })
                        : _c("span", { staticClass: "mr-1" }, [
                            _vm._v(_vm._s(_vm.getLabel(item))),
                          ]),
                      _vm._v(" "),
                      item.group && _vm.config.html_options
                        ? _c("span", {
                            staticClass: "caption gray--text mr-1",
                            domProps: {
                              innerHTML: _vm._s(
                                "(" + _vm.sanitize(item.group) + ")"
                              ),
                            },
                          })
                        : item.group && !_vm.config.html_options
                        ? _c(
                            "span",
                            { staticClass: "caption gray--text mr-1" },
                            [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(item.group) +
                                  "\n                "
                              ),
                            ]
                          )
                        : _vm._e(),
                    ],
              ]
            },
          },
          {
            key: "item",
            fn: function ({ parent, item, on }) {
              return [
                item.attr && item.attr.icon
                  ? _c(
                      "v-list-item-icon",
                      [
                        _c("v-icon", { staticClass: "mr-2" }, [
                          _vm._v(
                            "\n                    " +
                              _vm._s(item.attr.icon) +
                              "\n                "
                          ),
                        ]),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                item.attr && item.attr.image
                  ? _c("v-list-item-icon", [
                      _c("img", {
                        staticClass: "mr-2 mt-1",
                        attrs: { src: item.attr.image, alt: "" },
                      }),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.config.multiple
                  ? [
                      _c(
                        "v-list-item-action",
                        [
                          _c(
                            "v-simple-checkbox",
                            _vm._g(
                              {
                                attrs: {
                                  ripple: false,
                                  "disabled:": item.disabled,
                                  value: _vm.viewValue.indexOf(item.value) > -1,
                                  color: "primary",
                                },
                              },
                              on
                            )
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-list-item-content",
                        [
                          _c("v-list-item-title", [
                            _vm.config.html_options
                              ? _c("span", {
                                  domProps: {
                                    innerHTML: _vm._s(
                                      _vm.sanitize(_vm.getLabel(item))
                                    ),
                                  },
                                })
                              : _c("span", [
                                  _vm._v(_vm._s(_vm.getLabel(item))),
                                ]),
                          ]),
                          _vm._v(" "),
                          _c(
                            "v-list-item-subtitle",
                            [
                              item.group && _vm.config.html_options
                                ? _c("v-chip", {
                                    attrs: { ripple: false, small: "" },
                                    domProps: {
                                      innerHTML: _vm._s(
                                        "(" + _vm.sanitize(item.group) + ")"
                                      ),
                                    },
                                  })
                                : item.group && !_vm.config.html_options
                                ? _c(
                                    "v-chip",
                                    { attrs: { ripple: false, small: "" } },
                                    [
                                      _vm._v(
                                        "\n                            (" +
                                          _vm._s(item.group) +
                                          ")\n                        "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  : _c(
                      "v-list-item-content",
                      [
                        _vm.config.html_options
                          ? _c("v-list-item-title", {
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.sanitize(_vm.getLabel(item))
                                ),
                              },
                            })
                          : _c("v-list-item-title", [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(_vm.getLabel(item)) +
                                  "\n                "
                              ),
                            ]),
                        _vm._v(" "),
                        item.group && _vm.config.html_options
                          ? _c("v-list-item-subtitle", {
                              staticClass: "caption",
                              domProps: {
                                innerHTML: _vm._s(_vm.sanitize(item.group)),
                              },
                            })
                          : item.group && !_vm.config.html_options
                          ? _c(
                              "v-list-item-subtitle",
                              { staticClass: "caption" },
                              [
                                _vm._v(
                                  "\n                    " +
                                    _vm._s(item.group) +
                                    "\n                "
                                ),
                              ]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
              ]
            },
          },
          {
            key: "append",
            fn: function () {
              return [
                _vm.config.chips &&
                _vm.multiple &&
                (typeof _vm.config.select_all === "undefined" ||
                  _vm.config.select_all) &&
                !_vm.config.disabled
                  ? _c(
                      "v-tooltip",
                      {
                        staticStyle: { "z-index": "1000" },
                        attrs: { bottom: "" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function ({ on }) {
                                return [
                                  _c(
                                    "v-icon",
                                    _vm._g(
                                      {
                                        staticClass: "ml-2",
                                        on: {
                                          "!click": function ($event) {
                                            $event.stopPropagation()
                                            return _vm.selectAll()
                                          },
                                        },
                                      },
                                      on
                                    ),
                                    [
                                      _vm._v(
                                        "\n                        $selectAll\n                    "
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3943794872
                        ),
                      },
                      [
                        _vm._v(" "),
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.$lumui.i18n.t(
                                "lumui.form.autocomplete.select_all"
                              )
                            )
                          ),
                        ]),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.config.chips &&
                _vm.multiple &&
                (typeof _vm.config.clear === "undefined" || _vm.config.clear) &&
                !_vm.config.disabled
                  ? _c(
                      "v-tooltip",
                      {
                        staticStyle: { "z-index": "1000" },
                        attrs: { bottom: "" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function ({ on }) {
                                return [
                                  _c(
                                    "v-icon",
                                    _vm._g(
                                      {
                                        staticClass: "ml-2",
                                        attrs: { small: "" },
                                        on: {
                                          "!click": function ($event) {
                                            $event.stopPropagation()
                                            return _vm.unselectAll()
                                          },
                                        },
                                      },
                                      on
                                    ),
                                    [
                                      _vm._v(
                                        "\n                        $clear\n                    "
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          803127462
                        ),
                      },
                      [
                        _vm._v(" "),
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.$lumui.i18n.t(
                                "lumui.form.autocomplete.deselect_all"
                              )
                            )
                          ),
                        ]),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.isMenuActive
                  ? _c(
                      "v-icon",
                      {
                        staticClass: "ml-2",
                        staticStyle: { cursor: "pointer" },
                        attrs: { small: "" },
                      },
                      [_vm._v("\n                $dropUp\n            ")]
                    )
                  : _c(
                      "v-icon",
                      {
                        staticClass: "ml-2",
                        staticStyle: { cursor: "pointer" },
                        attrs: { small: "" },
                      },
                      [_vm._v("\n                $dropDown\n            ")]
                    ),
              ]
            },
            proxy: true,
          },
          {
            key: "append-outer",
            fn: function () {
              return [
                _vm.config.appendOuterIcon
                  ? _c("v-icon", {
                      attrs: { small: "" },
                      domProps: {
                        textContent: _vm._s(_vm.config.appendOuterIcon),
                      },
                      on: {
                        "!click": function ($event) {
                          $event.stopPropagation()
                          $event.preventDefault()
                          return _vm.outerIconClick.apply(null, arguments)
                        },
                      },
                    })
                  : _vm._e(),
              ]
            },
            proxy: true,
          },
          {
            key: "no-data",
            fn: function () {
              return [
                _c(
                  "v-list-item",
                  [
                    _vm.isAsyncron
                      ? _c("v-list-item-title", [
                          _vm._v(
                            "\n                    " +
                              _vm._s(
                                _vm.$lumui.i18n.t(
                                  "lumui.form.autocomplete.search_hint"
                                )
                              ) +
                              "\n                "
                          ),
                        ])
                      : _c("v-list-item-title", [
                          _vm._v(
                            "\n                    " +
                              _vm._s(
                                _vm.$lumui.i18n.t(
                                  "lumui.form.autocomplete.no_data"
                                )
                              ) +
                              "\n                "
                          ),
                        ]),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
        model: {
          value: _vm.viewValue,
          callback: function ($$v) {
            _vm.viewValue = $$v
          },
          expression: "viewValue",
        },
      }),
      _vm._v(" "),
      _vm.config.chips && _vm.multiple
        ? _c(
            "div",
            [
              _c("div", { staticClass: "grey--text" }, [
                _c("small", [
                  !_vm.viewValue || _vm.viewValue.length === 0
                    ? _c("em", [
                        _vm._v(
                          _vm._s(
                            _vm.$lumui.i18n.t(
                              "lumui.form.autocomplete.nothing_selected"
                            )
                          )
                        ),
                      ])
                    : _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.$lumui.i18n.t(
                              "lumui.form.autocomplete.selected_items"
                            )
                          )
                        ),
                      ]),
                ]),
              ]),
              _vm._v(" "),
              _vm._l(_vm.viewValue, function (item, index) {
                return [
                  _vm.isAsyncron || _vm.getOption(item)
                    ? _c(
                        "v-chip",
                        {
                          key: index,
                          staticClass: "mr-1",
                          attrs: {
                            close: !_vm.config.disabled,
                            "close-icon": "$clear",
                            label: "",
                            small: "",
                          },
                          on: {
                            "update:active": function ($event) {
                              return _vm.unselect(item)
                            },
                          },
                        },
                        [
                          _vm.config.html_options
                            ? [
                                _c("div", {
                                  domProps: {
                                    innerHTML: _vm._s(
                                      _vm.isAsyncron
                                        ? _vm.sanitize(_vm.getLabel(item))
                                        : _vm.sanitize(
                                            _vm.getLabel(_vm.getOption(item))
                                          )
                                    ),
                                  },
                                }),
                              ]
                            : [
                                _vm.isAsyncron
                                  ? [
                                      _vm._v(
                                        "\n                        " +
                                          _vm._s(_vm.getLabel(item)) +
                                          "\n                    "
                                      ),
                                    ]
                                  : [
                                      _vm._v(
                                        "\n                        " +
                                          _vm._s(
                                            _vm.getLabel(_vm.getOption(item))
                                          ) +
                                          "\n                    "
                                      ),
                                    ],
                              ],
                        ],
                        2
                      )
                    : _vm._e(),
                ]
              }),
            ],
            2
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }