var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    [
      _c("v-card-title", [_vm._v("CSV Export formula escaping")]),
      _vm._v(" "),
      _c(
        "v-card-text",
        [_c("asa-table", { attrs: { data: _vm.data, loading: false } })],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }