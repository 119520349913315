import { render, staticRenderFns } from "./long_button_labels.vue?vue&type=template&id=1314c204&scoped=true&"
import script from "./long_button_labels.vue?vue&type=script&lang=js&"
export * from "./long_button_labels.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1314c204",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/builds/lum/lumui/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('1314c204')) {
      api.createRecord('1314c204', component.options)
    } else {
      api.reload('1314c204', component.options)
    }
    module.hot.accept("./long_button_labels.vue?vue&type=template&id=1314c204&scoped=true&", function () {
      api.rerender('1314c204', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "test/views/form/long_button_labels.vue"
export default component.exports