import Vue from 'vue';
import '@fortawesome/fontawesome-pro/css/all.css'
import vuetify from './plugins/vuetify';
import router from './plugins/router'
import App from './App';
import store from './plugins/store';
import "./plugins/lumui";
import {i18n} from "../src";

new Vue({
    el: '#app',
    vuetify,
    store,
    router,
    i18n,
    render(h) {
        return h(App)
    },
});
