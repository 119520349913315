var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("asa-form", {
        attrs: { config: _vm.config, loading: _vm.loading, readonly: true },
        model: {
          value: _vm.value,
          callback: function ($$v) {
            _vm.value = $$v
          },
          expression: "value",
        },
      }),
      _vm._v(" "),
      _c("asa-form", {
        attrs: {
          loading: _vm.loading,
          readonly: true,
          config: _vm.configTabbed,
        },
        scopedSlots: _vm._u([
          {
            key: "title",
            fn: function () {
              return [_vm._v("\n            Tabbed form\n        ")]
            },
            proxy: true,
          },
        ]),
        model: {
          value: _vm.valueTabbed,
          callback: function ($$v) {
            _vm.valueTabbed = $$v
          },
          expression: "valueTabbed",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }