<template>
  <div>
    <v-container>
      <v-row>
        <v-col>
          <h1 class="text-h3">Customized Table Top Slot</h1>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-card>
            <v-card-text>
              <asa-table :data="table1">
                <template #top="props">
                  <div>
                    {{ props.pagination.page }} / {{ props.pagination.pageCount }} ({{ props.pagination.itemsLength }})
                  </div>
                </template>
              </asa-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import {VContainer, VRow, VCol, VCard, VCardTitle, VDivider, VCardText} from 'vuetify/lib';

export default {
  name: "TableWithHeader",
  components: {VContainer, VRow, VCol, VCard, VCardTitle, VDivider, VCardText},
  data() {
    return {
      table1: {
        columns: [
          {
            key: "id",
            label: "id"
          }
        ],
        rows: [
          {
            id: 0
          },
          {
            id: 1
          },
          {
            id: 2
          },
          {
            id: 3
          },
          {
            id: 4
          },
          {
            id: 5
          },
          {
            id: 6
          },
          {
            id: 7
          },
          {
            id: 8
          },
          {
            id: 9
          },
          {
            id: 10
          },
          {
            id: 11
          },
          {
            id: 12
          },
          {
            id: 13
          },
          {
            id: 14
          },
          {
            id: 15
          },
          {
            id: 16
          },
          {
            id: 17
          },
          {
            id: 18
          },
          {
            id: 19
          },
          {
            id: 20
          },
          {
            id: 21
          }
        ]
      }
    }
  }
}
</script>

<style scoped>

</style>
