import {merge} from "webpack-merge";
import lt from './base/lt.js';

export default merge(lt, {
    "lumui.confirm.accept": "GERAI",
    "lumui.confirm.reject": "Nutraukti",
    "lumui.formatter.boolean.true": "Taip",
    "lumui.formatter.boolean.false": "Ne",
    "lumui.form.close": "Uždaryti",
    "lumui.form.cancel": "Nutraukti",
    "lumui.form.save": "įrašyti",
    "lumui.lightbox.title": "Esami paveikslėliai",
    "lumui.loading.message": "Duomenys įkeliami",
    "lumui.qr_scanner.camera": "Camera",
    "lumui.qr_scanner.mirror": "Mirror camera ",
    "lumui.qr_scanner.torch": "Camera light",
    "lumui.table.reset_filters": "atkurti",
    "lumui.table.close_filters": "Suskleisti filtrą",
    "lumui.table.details": "Išsamus rodinys",
    "lumui.table.previous": "Esamas duomenų rinkinys",
    "lumui.table.next": "Kitas duomenų rinkinys",
    "lumui.table.no_data": "Duomenų nerasta.",
    "lumui.table.no_filter_results": "Pagal Jūsų paiešką nerasta jokių rezultatų",
    "lumui.table.filters_active": "Rezultatai išfiltruoti",
    "lumui.table.rows_per_page": "Eilutės kiekviename puslapyje",
    "lumui.table.page_text": "{0}-{1} of {2}",
    "lumui.table.btn.export": "Eksporto sąrašas",
    "lumui.table_action.menu": "Meniu",
    "lumui.table_columns.save": "Įrašyti failą",
    "lumui.form.row.invalid_email": "Įveskite teisingą el. pašto adresą.",
    "lumui.form.row.required": "Tai yra privalomasis laukas",
    "lumui.form.autocomplete.selected_count": "{c} items selected",
    "lumui.form.autocomplete.select_all": "Parinkti viską",
    "lumui.form.autocomplete.deselect_all": "Neparinkti nieko",
    "lumui.form.autocomplete.search_hint": "Nurodykite, ko ieškote",
    "lumui.form.autocomplete.no_data": "Duomenų nėra",
    "lumui.form.autocomplete.nothing_selected": "Įrašai neparinkti",
    "lumui.form.autocomplete.selected_items": "Parinkti įrašai",
    "lumui.form.date.close": "Uždaryti",
    "lumui.form.date.save": "GERAI",
    "lumui.form.file.drag": "Drag file here or click to select",
    "lumui.form.file.error": "Upload failed.",
    "lumui.form.file.extensions.accepted": "Allowed filetypes: {types}",
    "lumui.form.file.extensions.all": "All filetypes allowed",
    "lumui.form.file.maxSize": "Maximum allowed file size {size}.",
    "lumui.form.file.maxSizeError": "The file is larger than the maximum allowed size of {size}.",
    "lumui.form.file.previewError": "No preview for file {name} available.",
    "lumui.form.file.uploading": "{count} files are being uploaded…",
    "lumui.form.file.warning.removed_file": "File {name} was not uploaded. Filetype {type} is not within permitted types {acceptedTypes}.",
    "lumui.form.file.select_file": "Select a file",
    "lumui.form.file.unknown_size": "not specified",
    "lumui.form.localized.copy_to_all": "Perimti įvestį visoms kalboms",
    "lumui.form.ordered_choice.no_data": "įrašų nėra",
    "lumui.form.ordered_choice.select_placeholder": "parinkti naują įrašą",
    "lumui.form.password.repeat": "Kartoti slaptažodį",
    "lumui.form.password.not_matching_error": "Abu slaptažodžiai turi sutapti.",
    "lumui.form.signature.clear": "Delete signature",
    "lumui.form.signature.upload": "Upload signature",
    "lumui.form.time.close": "Uždaryti",
    "lumui.form.time.save": "GERAI",
    "lumui.locale.lang.german": "German",
    "lumui.locale.lang.english": "English",
    "lumui.locale.lang.italian": "Italian",
    "lumui.locale.lang.french": "French",
    "lumui.status.no_action_required": "jokių veiksmų nereikia",
    "lumui.status.hint": "Nuoroda",
    "lumui.status.action_required_soon": "Greitai reikės atlikti veiksmą!",
    "lumui.status.action_required_immediately": "būtina nedelsiant imtis veiksmų!",
    "lumui.locale.de-DE": "German",
    "lumui.locale.de-CH": "German (Suisse)",
    "lumui.locale.fr-CH": "French (Suisse)",
    "lumui.locale.it-CH": "Italian (Suisse)",
    "lumui.locale.en-US": "English (USA)",
    "lumui.locale.en-GB": "English (GB)",
    "lumui.locale.fi-FI": "Finnish",
    "lumui.locale.et-EE": "Estonian",
    "lumui.locale.lt-LT": "Lithuanian",
    "lumui.locale.lv-LV": "Latvian",
    "lumui.locale.lang.finnish": "Finnish",
    "lumui.locale.lang.estonian": "Estonian",
    "lumui.locale.lang.lithuanian": "Lithuanian",
    "lumui.locale.lang.latvian": "Latvian",
    "lumui.locale.lang.hungarian": "Hungarian",
});
